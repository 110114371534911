/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import dropin from 'braintree-web-drop-in';
import { Button } from 'evergreen-ui';

export default function BraintreeDropIn({ braintreeToken, show, onPaymentMethodConfirmation }) {
  const [braintreeInstance, setBraintreeInstance] = useState(undefined);

  useEffect(() => {
    if (show) {
      const initializeBraintree = () => dropin.create(
        {
          authorization: braintreeToken, // form a braintree token via customer id in OMS
          container: '#braintree-drop-in-div',
        },
        (error, instance) => {
          if (error) {
            console.error(error);
          } else {
            setBraintreeInstance(instance);
          }
        },
      );

      if (braintreeInstance) {
        braintreeInstance.teardown().then(() => {
          initializeBraintree();
        });
      } else {
        initializeBraintree();
      }
    }
  }, [show]);

  return (
    <div style={{ display: `${show ? 'block' : 'none'}` }}>
      <div id="braintree-drop-in-div" />
      <Button
        type="button"
        disabled={!braintreeInstance}
        onClick={() => {
          if (braintreeInstance) {
            braintreeInstance.requestPaymentMethod((error, payload) => {
              if (error) {
                console.error(error);
              } else {
                const paymentMethodNonce = payload.nonce;
                onPaymentMethodConfirmation(paymentMethodNonce);
              }
            });
          }
        }}
      >
        Confirm Payment Method
      </Button>
    </div>
  );
}
