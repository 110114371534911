import React, { useContext, useEffect, useState } from 'react';
import Footer from '../../common/wizards/Footer';
import * as Icon from 'react-bootstrap-icons';
import IssueSelectionComponent from './IssueSelectionComponent';
import { lineItemRedeliveryReasons, redeliveryReasons } from '../helpers/getReasonType';
import { LookupContext } from '../../../context/lookupContext';
import Select from 'react-select';
import TextInput from '../../common/TextInput';
import { Button, PlusIcon, IconButton, CrossIcon, Alert } from 'evergreen-ui';
import getIdealProductName from '../helpers/getIdealProductName';
import getLatestCareResolution from '../helpers/getLatestCareResolution';
import { DateTime } from 'luxon';
import { AuthContext } from '../../../context/authContext';
import { roleChecks } from '../../../utilities/Role';
import getDisableDivStyle from '../helpers/getDisableDivStyle';

const SUBORDER_INDEX = -1;

const Step1 = ({ order, suborder, state, updateAction, closeModal, updateStepFourState, ...props }) => {
  const [issueInEntirePackage, setIssueInEntirePackage] = useState(state.issueInEntirePackage);
  const [lineItemsWithIssue, setLineItemsWithIssue] = useState(state.lineItemsWithIssue);
  const [selectedLineItemIndices, setSelectedLineItemIndices] = useState(state.lineItemsWithIssue.map((lineItem) => lineItem.index));
  const [suborderLevelReasons, setSuborderLevelReasons] = useState(state.suborderLevelReasons);
  const { productFeed } = useContext(LookupContext);
  const [latestCareResolution, setLatestCareResolution] = useState(getLatestCareResolution(order));
  const { userGroups } = useContext(AuthContext);

  const disableEverything = getDisableDivStyle();

  const canAddAdditionalCareResolution = roleChecks.canAddAdditionalCareResolutionInORW(userGroups);

  const resetStepFour = () => {
    updateStepFourState({
      selectedSkus: [],
      skuToUpgradeMap: {},
      additionalLineItems: [],
      editingRecipient: false,
    });
  };

  useEffect(() => {
    updateAction({ issueInEntirePackage, redeliverySuborder: null, lineItemsWithIssue, suborderLevelReasons });
    resetStepFour();
  }, [issueInEntirePackage, lineItemsWithIssue, suborderLevelReasons]);

  const onSubmit = () => {
    props.nextStep();
  };

  const onLineItemCheckboxSelect = (isChecked, lineItem) => {
    if (isChecked) {
      const newArr = [...selectedLineItemIndices, lineItem.index];
      setSelectedLineItemIndices(newArr);
      setMapOfReasonsAndAdditionalDetails(createReasonEntryForSelectedLineItem(lineItem));
    } else {
      const arr = [...selectedLineItemIndices];
      if (arr.includes(lineItem.index)) {
        const indexInPrevValues = arr.indexOf(lineItem.index);
        arr.splice(indexInPrevValues, 1);
        setSelectedLineItemIndices(arr);
      }
      runOperationOnMapOfReasons((newMapOfReasonsAndAdditionalDetails) => {
        delete newMapOfReasonsAndAdditionalDetails[lineItem.index];
      });
    }
  };

  const getLineItemDisplay = (lineItem) => `${lineItem.quantity}x ${getIdealProductName(productFeed, lineItem)}`;

  const convertOmsReasonIntoReasonAndDetails = (reason, forSuborder) => {
    const { tag, details } = reason;

    if (tag) {
      const splitUp = tag.split(': ');
      const value = splitUp[1];

      const reasonsArray = forSuborder ? redeliveryReasons : lineItemRedeliveryReasons;
      for (const objectWithReasons of reasonsArray) {
        const { options } = objectWithReasons;
        for (const option of options) {
          if (value === option.value) {
            return { reason: option, details };
          }
        }
      }
    }

    return reason;
  };

  const createEmptyReasonAndDetail = () => ({ reason: null, details: '' });

  const createReasonEntryForSelectedLineItem = (lineItem, mapToSet = mapOfReasonsAndAdditionalDetails) => {
    const { index, userActionReasons } = lineItem;
    const filteredUaReasons = userActionReasons ? userActionReasons.filter((uaReason) => !uaReason.createdAt) : [];

    if (!filteredUaReasons || !filteredUaReasons.length) {
      mapToSet[index] = [createEmptyReasonAndDetail()];
    } else {
      mapToSet[index] = filteredUaReasons
        .filter((uaReason) => !uaReason.createdAt)
        .map((uaReason) => convertOmsReasonIntoReasonAndDetails(uaReason, false));
    }

    return mapToSet;
  };

  const createReasonEntryForSuborder = (mapToSet = mapOfReasonsAndAdditionalDetails) => {
    if (suborderLevelReasons.length === 0) {
      mapToSet[SUBORDER_INDEX] = [createEmptyReasonAndDetail()];
    } else {
      mapToSet[SUBORDER_INDEX] = suborderLevelReasons.map((soLevelReason) => convertOmsReasonIntoReasonAndDetails(soLevelReason, true));
    }
    return mapToSet;
  };

  const convertSuborderReasonsAndLineItemsWithIssueToReasonsAndDetails = () => {
    const mapToSet = {};
    Object.assign(mapToSet, createReasonEntryForSuborder(mapToSet));
    for (const lineItem of lineItemsWithIssue) {
      Object.assign(createReasonEntryForSelectedLineItem(lineItem, mapToSet));
    }

    return mapToSet;
  };

  const [mapOfReasonsAndAdditionalDetails, setMapOfReasonsAndAdditionalDetails] = useState(
    convertSuborderReasonsAndLineItemsWithIssueToReasonsAndDetails(),
  );

  const getOmsReasonsFromIndex = (index) =>
    mapOfReasonsAndAdditionalDetails[index]
      .filter((reasonAndDetail) => reasonAndDetail && reasonAndDetail.reason)
      .map((reasonAndDetail) => convertReasonAndDetailsIntoOmsReason(reasonAndDetail, index));

  useEffect(() => {
    const arr = [];
    for (const lineItem of suborder.lineItems) {
      if (selectedLineItemIndices.includes(lineItem.index) && !lineItem.isDeleted) {
        arr.push({ ...lineItem });
      }
    }

    const newLineItemsWithIssue = [];

    if (mapOfReasonsAndAdditionalDetails) {
      const newSuborderLevelReasons = mapOfReasonsAndAdditionalDetails[SUBORDER_INDEX] ? getOmsReasonsFromIndex(SUBORDER_INDEX) : [];
      setSuborderLevelReasons(newSuborderLevelReasons);

      for (const lineItem of arr) {
        const alreadyCreatedReasons = lineItem.userActionReasons ? lineItem.userActionReasons.filter((uaReason) => uaReason.createdAt) : [];

        if (mapOfReasonsAndAdditionalDetails[lineItem.index]) {
          lineItem.userActionReasons = [...alreadyCreatedReasons, ...getOmsReasonsFromIndex(lineItem.index)];
        } else {
          lineItem.userActionReasons = alreadyCreatedReasons;
        }
        newLineItemsWithIssue.push(lineItem);
      }
    }

    setLineItemsWithIssue(mapOfReasonsAndAdditionalDetails ? newLineItemsWithIssue : arr);
  }, [selectedLineItemIndices, mapOfReasonsAndAdditionalDetails]);

  const convertReasonAndDetailsIntoOmsReason = (reasonAndDetails, lineItemIndex) => {
    const { reason, details } = reasonAndDetails;
    const tag = `${reason.categoryLabel}: ${reason.value}`;
    const omsReason = { tag, details, suborderId: suborder.suborderId };
    if (lineItemIndex !== undefined) {
      omsReason.lineItemIndex = lineItemIndex;
    }
    return omsReason;
  };

  const runOperationOnMapOfReasons = (operationFunctionThatTakesInMap) => {
    const newMapOfReasonsAndAdditionalDetails = { ...mapOfReasonsAndAdditionalDetails };
    operationFunctionThatTakesInMap(newMapOfReasonsAndAdditionalDetails);
    setMapOfReasonsAndAdditionalDetails(newMapOfReasonsAndAdditionalDetails);
  };

  const updateMapOfReasonsAndAdditionalDetails = (indexOfArray, indexOfElement, key, value) => {
    runOperationOnMapOfReasons((newMapOfReasonsAndAdditionalDetails) => {
      newMapOfReasonsAndAdditionalDetails[indexOfArray][indexOfElement][key] = value;
    });
  };

  const addEmptyEntryToMapArray = (indexOfWhereToAdd) => {
    runOperationOnMapOfReasons((newMapOfReasonsAndAdditionalDetails) => {
      newMapOfReasonsAndAdditionalDetails[indexOfWhereToAdd].push(createEmptyReasonAndDetail());
    });
  };

  const deleteCurrentIndexFromMapArray = (indexOfArray, indexOfDeletableElement) => {
    runOperationOnMapOfReasons((newMapOfReasonsAndAdditionalDetails) => {
      newMapOfReasonsAndAdditionalDetails[indexOfArray].splice(indexOfDeletableElement, 1);
    });
  };

  const canDeleteInCurrentRow = (indexOfArray) => mapOfReasonsAndAdditionalDetails[indexOfArray].length > 1;

  const lastRowHasAnElement = (indexOfArray) => {
    const focusArray = mapOfReasonsAndAdditionalDetails[indexOfArray];
    return focusArray[focusArray.length - 1].reason !== null;
  };

  const createFormForLineItem = (lineItem) => (
    <div key={`lineitemkey-${lineItem.index}`}>
      {mapOfReasonsAndAdditionalDetails[lineItem.index].map((entry, index) => (
        <div className="row align-items-center" key={`${lineItem.index}-${index}`}>
          <div className="mb-4 col-sm">
            <Select
              id={`selectRedeliveryReason-${index}`}
              onChange={(option) => updateMapOfReasonsAndAdditionalDetails(lineItem.index, index, 'reason', option)}
              options={lineItemRedeliveryReasons}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              value={entry.reason}
              menuPlacement={'auto'}
            />
          </div>
          <div className="col-lg">
            <TextInput
              placeholder="Additional information"
              name="CustomInput"
              value={entry.details}
              onChange={(event) => updateMapOfReasonsAndAdditionalDetails(lineItem.index, index, 'details', event.target.value)}
            />
          </div>
          {canDeleteInCurrentRow(lineItem.index) && (
            <div className="col-auto mb-4">
              <IconButton icon={CrossIcon} appearance="minimal" onClick={() => deleteCurrentIndexFromMapArray(lineItem.index, index)} />
            </div>
          )}
        </div>
      ))}
      {lastRowHasAnElement(lineItem.index) && (
        <Button className="mb-4" iconAfter={PlusIcon} onClick={() => addEmptyEntryToMapArray(lineItem.index)}>
          Add Reason
        </Button>
      )}
    </div>
  );

  const issueInEntirePackageChangeHandler = (isChecked) => {
    setIssueInEntirePackage(isChecked);
    if (isChecked) {
      setMapOfReasonsAndAdditionalDetails(createReasonEntryForSuborder());
    } else {
      runOperationOnMapOfReasons((newMapOfReasonsAndAdditionalDetails) => {
        delete newMapOfReasonsAndAdditionalDetails[SUBORDER_INDEX];
      });
    }
  };

  const getTextForPreviousActionWarning = (careResolution) => {
    const { performedBy, date, suborderId } = careResolution;
    let text = 'Please note that this order was already addressed ';
    if (performedBy && performedBy.includes('@')) {
      text += `by <b>${performedBy}</b> `;
    }
    text += `on <b>${DateTime.fromISO(date).toLocaleString()}</b>.`;

    if (canAddAdditionalCareResolution) {
      text += ' If additional action is required please click the button below to dismiss this message and continue.';
    }

    return text;
  };

  return (
    <>
      <h2 className="mb-2 text-center">
        Which parts of <span style={{ color: 'blue' }}>{suborder.suborderId}</span> have an issue?
      </h2>
      <p className="mb-4 text-center text-muted">Select the entire package or specific items.</p>
      {latestCareResolution && (
        <Alert hasIcon={false} className="row mb-2 text-center" intent="warning">
          <div dangerouslySetInnerHTML={{ __html: getTextForPreviousActionWarning(latestCareResolution) }} />
          {canAddAdditionalCareResolution && (
            <div className="mt-3">
              <Button onClick={() => setLatestCareResolution(null)} appearance="primary">
                Add Additional Resolution
              </Button>
            </div>
          )}
        </Alert>
      )}
      <div style={latestCareResolution ? disableEverything : {}}>
        <div className="row">
          <IssueSelectionComponent
            iconComponent={<Icon.Box size="3em" />}
            issueText={'The entire package'}
            issueSubtext={'Missing or late delivery, damaged box, etc...'}
            isSelected={issueInEntirePackage}
            setIsSelected={issueInEntirePackageChangeHandler}
            allowDeselection={true}
            selectionType={'checkbox'}
            canClickEntireComponent={false}
          />
        </div>
        {issueInEntirePackage && (
          <>
            <h3>{suborder.suborderId}</h3>
            {mapOfReasonsAndAdditionalDetails[SUBORDER_INDEX].map((entry, index) => (
              <div className="row align-items-center" key={`${SUBORDER_INDEX}-${index}`}>
                <div className="mb-4 col-sm">
                  <Select
                    id={`selectRedeliveryReason-${index}`}
                    onChange={(option) => updateMapOfReasonsAndAdditionalDetails(SUBORDER_INDEX, index, 'reason', option)}
                    options={redeliveryReasons}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                    value={entry.reason}
                  />
                </div>
                <div className="col-lg">
                  <TextInput
                    placeholder="Additional information"
                    name="CustomInput"
                    value={entry.details}
                    onChange={(event) => updateMapOfReasonsAndAdditionalDetails(SUBORDER_INDEX, index, 'details', event.target.value)}
                  />
                </div>
                {canDeleteInCurrentRow(SUBORDER_INDEX) && (
                  <div className="col-auto mb-4">
                    <IconButton icon={CrossIcon} appearance="minimal" onClick={() => deleteCurrentIndexFromMapArray(SUBORDER_INDEX, index)} />
                  </div>
                )}
              </div>
            ))}
            {lastRowHasAnElement(SUBORDER_INDEX) && (
              <Button className="mb-4" iconAfter={PlusIcon} onClick={() => addEmptyEntryToMapArray(SUBORDER_INDEX)}>
                Add Reason
              </Button>
            )}
          </>
        )}
        {suborder.lineItems
          .filter((lineItem) => !lineItem.isDeleted)
          .map((lineItem) => (
            <React.Fragment key={`${lineItem.index}key`}>
              <div className="row">
                <IssueSelectionComponent
                  isSelected={selectedLineItemIndices.includes(lineItem.index)}
                  setIsSelected={(val) => onLineItemCheckboxSelect(val, lineItem)}
                  issueText={getLineItemDisplay(lineItem)}
                  allowDeselection={true}
                  selectionType={'checkbox'}
                  canClickEntireComponent={false}
                />
              </div>
              {selectedLineItemIndices.includes(lineItem.index) && createFormForLineItem(lineItem)}
            </React.Fragment>
          ))}
      </div>

      <Footer
        backActionText="Cancel"
        backAction={closeModal}
        forwardActionText="Continue"
        forwardAction={onSubmit}
        disableForwardOn={
          (!issueInEntirePackage && lineItemsWithIssue.length === 0) ||
          (issueInEntirePackage && suborderLevelReasons.length === 0) ||
          lineItemsWithIssue.filter((lineItem) => !lineItem.userActionReasons || !lineItem.userActionReasons.length).length > 0
        }
      />
    </>
  );
};

export default Step1;
