import React, { useState, useContext, useEffect } from 'react';
import { useQuery, useQueryCache } from 'react-query';
import { Link, useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import { toaster } from 'evergreen-ui';
import { DateTime } from 'luxon';

import {
  downloadIPDFile,
  printBatchNotecards,
  printBatchLabels,
  printBatchWorkorder,
  printBatchOnePager,
  downloadBatchSubordersCsv,
  printUpsWorldeaseMasterInvoice,
} from '../../../utilities/BatchActions';
import { ORDER_MANAGEMENT_SERVICE_BASE_URL } from '../../../config';
import { FcContext } from '../../../context/fcContext';
import { LookupContext } from '../../../context/lookupContext';
import { getZplLabelsForBatch, getDocboxLabelForBatch } from '../../../services/OrderManagementService';
import { printZpl } from '../../common/printingHelpers/ZebraPrint';

import { CompleteBatchSuborder } from '../common/CompleteBatchSuborderModal';
import UpdateBatchStatusModal from '../common/UpdateBatchStatusModal';
import sortSubordersByBatchIndex from '../helpers/sortSubordersByBatchIndex';

import BatchSuborders from './BatchSuborders';
import DeleteBatchModal from '../common/DeleteBatchModal';

const ViewBatch = () => {
  const { batchId } = useParams();
  const [batch, setBatch] = useState(null);
  const [isFetchingBatch, setIsFetchingBatch] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { productFeed } = useContext(LookupContext);
  const history = useHistory();

  const [confirmCompleteShow, setConfirmCompleteShow] = useState(false);

  const [showDeleteSuccessAlert, setShowDeleteSuccessAlert] = useState(false);

  const fetchBatch = async () => {
    if (!isFetchingBatch) {
      setIsFetchingBatch(true);

      const { data } = await axios.get(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/batches/${batchId}`);
      const { batch: fetchedBatch } = data.data;

      sortSubordersByBatchIndex(fetchedBatch);

      setBatch(fetchedBatch);

      setIsFetchingBatch(false);
    }
  };

  useEffect(() => {
    if (!batch) {
      fetchBatch();
    }
  }, [batch]);

  const { status, error } = useQuery(['batch', batchId], fetchBatch, {
    retry: false,
  });

  const queryCache = useQueryCache();

  if (status === 'loading' || !batch) {
    return (
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="card bg-transparent">
              <div className="card-body text-center">
                <p className="text-muted">Loading batch...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (status === 'error') {
    return (
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="card bg-transparent">
              <div className="card-body text-center">
                <p className="text-muted">Error: {error.message}</p>
                <Link to="/batches" className="btn btn-primary">
                  Back
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const getLabelsAndPrint = async () => {
    const response = await getZplLabelsForBatch(batchId);
    if (response) {
      const { labels } = response.data;
      if (!labels || labels.length === 0) {
        toaster.danger('Could not generate labels for suborders. Please contact the dev team if you think this is a mistake.');
      } else {
        toaster.notify('Printing labels...');
        printZpl(labels);
      }
    }
  };

  const printDocboxLabel = async () => {
    const response = await getDocboxLabelForBatch(batchId);

    try {
      if (!response || !response.data || !response.data.label || !response.data.label) {
        throw new Error('Cannot generate docbox label for batch. Please contact the dev team if you think this is a mistake.');
      }

      const { label } = response.data;
      toaster.notify('Printing labels...');
      printZpl([label]);
    } catch (e) {
      toaster.danger(e.message);
    }
  };

  const onDoneUpdate = () => {
    setConfirmCompleteShow(false);
    queryCache.invalidateQueries();
  };

  return (
    <FcContext.Consumer>
      {({ fcId, setFcId, fcConfig, setDate }) => (
        <>
          {setFcId(batch.fcId)}
          {setDate(batch.fulfillmentDate || DateTime.now().toISODate())}
          <CompleteBatchSuborder
            visible={confirmCompleteShow}
            fcConfig={fcConfig}
            batchId={batchId}
            batchName={batch.name}
            batchStatus={batch.status}
            onDone={onDoneUpdate}
          />
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="header">
                  <div className="header-body">
                    <div className="row justify-content-center">
                      <div className="col">
                        <h6 className="header-pretitle">Fulfillment</h6>
                        <h1 className="header-title">Batch {batch.name}</h1>
                      </div>
                      <div className="col-auto">
                        <a className="btn" href="#!" onClick={() => downloadBatchSubordersCsv(batch)}>
                          Download CSV
                        </a>
                        <a className="btn" href="#!" onClick={() => printBatchWorkorder(batchId, batch.status, productFeed, fcConfig, queryCache)}>
                          Print workorder
                        </a>
                        {(fcConfig.useGuidedFulfillment || fcConfig.useBulkAndGuidedFulfillment) && (
                          <Link className="btn" to={{ pathname: `/fulfill/${batchId}` }}>
                            Fulfill
                          </Link>
                        )}
                        <a className="btn" href="#!" onClick={() => printBatchNotecards(batchId, batch.status)}>
                          Print notecards
                        </a>
                        {fcConfig.useIPD && (
                          <a className="btn" href="#!" onClick={() => downloadIPDFile(batchId, batch.status)}>
                            Download FedEx IPD
                          </a>
                        )}
                        {fcConfig.isUpsWorldeaseFc && (
                          <a className="btn" href="#!" onClick={getLabelsAndPrint}>
                            Print ZPL Labels
                          </a>
                        )}
                        {!fcConfig.useIPD && !fcConfig.isUpsWorldeaseFc && (
                          <a className="btn" href="#!" onClick={() => printBatchLabels(batchId, batch.status, queryCache, fcConfig)}>
                            Print labels
                          </a>
                        )}
                        <a className="btn" href="#!" onClick={() => printBatchOnePager(batchId, batch.status, productFeed)}>
                          Print 1-pager
                        </a>
                        {fcConfig.isUpsWorldeaseFc && batch.status === 'Completed' && (
                          <a className="btn" href="#!" onClick={printDocboxLabel}>
                            Print Docbox Label
                          </a>
                        )}
                        {fcConfig.isUpsWorldeaseFc && batch.status === 'Completed' && (
                          <a className="btn" href="#!" onClick={() => printUpsWorldeaseMasterInvoice(batchId)}>
                            Print Master Invoice
                          </a>
                        )}
                        {!fcConfig.useGuidedFulfillment && ['SubordersPrinted', 'AtTable'].includes(batch.status) && (
                          <a
                            className="btn"
                            href="#!"
                            onClick={(e) => {
                              e.preventDefault();
                              setConfirmCompleteShow(true);
                            }}
                          >
                            Complete batch
                          </a>
                        )}
                        <a className="btn" href="#!" onClick={() => setShowUpdateModal(true)}>
                          Update batch status
                        </a>
                        <a className="btn" href="#!" onClick={() => setShowDeleteModal(true)}>
                          Delete batch
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <BatchSuborders fcId={fcId} setFcId={setFcId} fcConfig={fcConfig} batch={batch} suborders={batch.suborders} />
              </div>
              {showUpdateModal && (
                <UpdateBatchStatusModal
                  batchId={batchId}
                  currStatus={batch.status}
                  onClose={() => {
                    setShowUpdateModal(false);
                  }}
                />
              )}
              {showDeleteModal && (
                <DeleteBatchModal
                  batchId={batchId}
                  batchName={batch.name}
                  onClose={(response) => {
                    setShowDeleteModal(false);
                    if (response && response.wasDeleted) {
                      setShowDeleteSuccessAlert(true);
                      setTimeout(() => {
                        queryCache.invalidateQueries();
                        history.push('/batches');
                      }, 2000);
                    }
                  }}
                />
              )}
              {showDeleteSuccessAlert && (
                <div className="col-12">
                  <div className="list-alert alert alert-success alert-dismissible border fade show" role="alert">
                    <div className="row align-items-center">
                      <div className="col">{batch.name} was successfully deleted.</div>
                    </div>

                    <button type="button" className="list-alert-close close" onClick={() => setShowDeleteSuccessAlert(false)} aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </FcContext.Consumer>
  );
};

export default ViewBatch;
