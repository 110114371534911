/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTable, useRowSelect, useFilters, useSortBy } from 'react-table';
import { Group, Button, RepeatIcon, toaster, ShareIcon, IconButton } from 'evergreen-ui';
import { TextInputColumnFilter, EvergreenSelectFilter, fuzzyTextFilterFn, multiLineTextFilterFn } from '../../common/tableHelpers/Filters';
import { LookupContext } from '../../../context/lookupContext';
import ExportReactTableToCsv from '../../common/csvExports/ExportReactTableToCsv';
import getProductTypeFromClassificationOrCategory from '../../helpers/getProductTypeFromClassificationOrCategory';
import { getInventoryForInventoryTable } from '../../../services/AggregateService';
import { capitalizeFirstLetter } from '../../../utilities/Strings';
import InventoryRecordModal from './inventory-record/InventoryRecordModal';
import CreateOrEditAdjustmentModal from './CreateOrEditAdjustmentModal';
import BadgeForInventoryRecordStatus from './common/BadgeForInventoryRecordStatus';
import formatDateString from './common/formatDateString';
import getSelectedInventoryRecordData from './inventory-record/common/getSelectedInventoryRecordData';
import getNoImageFoundImageUrl from './inventory-record/common/getNoImageFoundImageUrl';
import openInventoryRecordInNewTab from './inventory-record/common/openInventoryRecordInNewTab';
import './common/styles.css';
import { NETSUITE_INVENTORY_FEATURE_FLAG } from '../../../config';

const ONLY_STOCKED = 'onlyStocked';
const ALL_DATA = 'allData';

const NON_LOTTED = 'Hardgood';

const PRODUCT_AND_SKU_HEADER = 'Product & SKU'; // TODO only this cell will be clickable
const ACTION_HEADER = 'Action';

const COLUMN_HEADERS_THAT_ALLOW_FOR_POINTER_CURSOR = [ACTION_HEADER];

const SEPARATED_VALUE_STRING = ':::';

const preferredFontSizes = {
  general: '10px',
};

const sharedWidth = 70;

const generalColumn = { fontSize: preferredFontSizes.general };

const selectColumnValues = {
  ...generalColumn,
  Filter: EvergreenSelectFilter({ ...generalColumn, minWidth: 85, maxWidth: 100, fontSize: preferredFontSizes.general }),
  // Filter: SelectColumnFilter,
  filter: 'equals',
  minWidth: 120,
};
const dateColumnValues = {
  ...selectColumnValues,
  Cell: ({ value }) => {
    /* For the time being, we should keep these as strings, so ExportReactTableToCsv does not have a hard time rendering.
  We should add a separate work to jsonify them if necessary. */
    const whitespaceSeparated = value.split(' ');
    const [month, day, year] = whitespaceSeparated;
    return (
      <ul style={{ paddingInlineStart: 0 }}>
        <div>{`${month} ${day}`}</div>
        <div>{year}</div>
      </ul>
    );
  },
};
const multiLineTextColumns = { ...generalColumn, filter: 'multiLineText', maxWidth: 80 };
const textInputColumnFilterProps = { ...generalColumn, maxWidth: sharedWidth };

const InventoryTable = ({ date, fcId, fcConfig }) => {
  const { productFeed, fulfillmentCenters } = useContext(LookupContext);

  const [dataToDisplay, setDataToDisplay] = useState([]);

  const [inventoryData, setInventoryData] = useState([]);

  const [latestRefreshTime, setLatestRefreshTime] = useState(DateTime.now());
  const [isLoadingQuery, setIsLoadingQuery] = useState(true);
  const [selectedStockFilter, setSelectedStockFilter] = useState(ONLY_STOCKED);
  const [showInventoryRecordModal, setShowInventoryRecordModal] = useState(false);
  const [selectedLotForInventoryRecordModal, setSelectedLotForInventoryRecordModal] = useState({
    adjustmentsSummary: [],
    adjustments: [],
    changelogs: [],
    imageUrl: '',
    externalLotId: '',
    productName: '',
    classification: '',
    details: [],
  });
  const [afsTotal, setAfsTotal] = useState(0);
  const [onHandTotal, setOnHandTotal] = useState(0);

  const [showNewAdjustment, setShowNewAdjustment] = useState(false);

  const [selectedExternalLotId, setSelectedExternalLotId] = useState(null);
  const [selectedInternalLotId, setSelectedInternalLotId] = useState(null);
  const [selectedProductName, setSelectedProductName] = useState(null);
  const [selectedProductSku, setSelectedProductSku] = useState(null);
  const [selectedInventoryRecordStatus, setSelectedInventoryRecordStatus] = useState(null);

  const arrOfSelectedDeliveryInfoSetters = [
    setSelectedExternalLotId,
    setSelectedInternalLotId,
    setSelectedProductName,
    setSelectedProductSku,
    setSelectedInventoryRecordStatus,
  ];

  const resetLocalLotVariables = () => {
    setShowInventoryRecordModal(false);
    setShowNewAdjustment(false);
    arrOfSelectedDeliveryInfoSetters.forEach((setter) => setter(null));
  };

  useEffect(() => {
    if (showInventoryRecordModal && showNewAdjustment) {
      setShowNewAdjustment(false);
    }
  }, [showInventoryRecordModal]);

  useEffect(() => {
    if (showInventoryRecordModal && showNewAdjustment) {
      setShowInventoryRecordModal(false);
    }
  }, [showNewAdjustment]);

  const instantiateDataToDisplay = (allInventoryData) => {
    if (selectedStockFilter === ONLY_STOCKED) {
      const onlyStockedData = allInventoryData.filter((inventoryElement) => inventoryElement.onHandQty > 0);

      setDataToDisplay(onlyStockedData);
    } else if (selectedStockFilter === ALL_DATA) {
      setDataToDisplay(allInventoryData);
    } else {
      // eslint-disable-next-line no-console
      console.error('The selected group filter is not handled. Using all data as default');
      setDataToDisplay(allInventoryData);
    }
  };

  const groupOptions = useMemo(() => [
    {
      label: 'Only Stocked',
      value: ONLY_STOCKED,
    },
    {
      label: 'View All',
      value: ALL_DATA,
    },
  ]);

  const getAndSetInventoryData = async () => {
    setIsLoadingQuery(true);
    const res = await getInventoryForInventoryTable(fcId, date);
    if (res) {
      const inventoryElements = [];

      for (const breakdown of res) {
        const {
          vendor: vendorName,
          purchase_order: purchaseOrderId,
          arrival_date: arrivalDate,
          buffers: breakdownBuffers,
          stock_initial: stockInitial,
        } = breakdown;

        const startDate = NETSUITE_INVENTORY_FEATURE_FLAG ? breakdown.start_fulfillment_date : breakdown.start_date;
        const spoilDate = NETSUITE_INVENTORY_FEATURE_FLAG ? breakdown.expiration_date : breakdown.spoil_date;

        const internalLotId = NETSUITE_INVENTORY_FEATURE_FLAG ? breakdown.id : breakdown.delivery_id;
        const externalLotId = (NETSUITE_INVENTORY_FEATURE_FLAG ? breakdown.lot_id : breakdown.netsuite_lot_id) || NON_LOTTED;

        const availableForSaleQty = NETSUITE_INVENTORY_FEATURE_FLAG ? breakdown.available_for_sale_qty : breakdown.stock;
        const onHandQty = NETSUITE_INVENTORY_FEATURE_FLAG ? breakdown.on_hand_qty : breakdown.count_on_hand;

        const productFromBreakdown = NETSUITE_INVENTORY_FEATURE_FLAG ? { classification: breakdown.product_classification } : breakdown.product_id;

        const status = NETSUITE_INVENTORY_FEATURE_FLAG ? ((breakdown.inbound_quantity > 0) ? 'On Order' : 'Received') : breakdown.shipment_status;

        const buffers = NETSUITE_INVENTORY_FEATURE_FLAG ? [] : breakdownBuffers.map((buffer) => ({
          type: buffer.adjustment_type,
          quantity: buffer.adjustment_quantity,
        }));

        const sku = NETSUITE_INVENTORY_FEATURE_FLAG ? breakdown.sku : productFromBreakdown.productSKU;

        const productFromFeed = productFeed[sku];

        const productToUse = productFromFeed || productFromBreakdown;

        const filterClass = getProductTypeFromClassificationOrCategory(productToUse);

        const productName = NETSUITE_INVENTORY_FEATURE_FLAG ? breakdown.product_name : productFromBreakdown.name;

        const imageUrl = productFromFeed ? productFromFeed.imageUrl : getNoImageFoundImageUrl();

        /* For the time being, we should keep these as strings, so ExportReactTableToCsv does not have a hard time rendering.
          We should add a separate work to jsonify them if necessary. */

        // eslint-disable-next-line max-len
        const productColumnCsv = `${imageUrl}${SEPARATED_VALUE_STRING}${productName}${SEPARATED_VALUE_STRING}${sku}`;

        const lotIdColumnCsv = `${externalLotId}${SEPARATED_VALUE_STRING}${internalLotId}`;

        const vendorColumnCsv = `${vendorName}${SEPARATED_VALUE_STRING}${purchaseOrderId}${SEPARATED_VALUE_STRING}${stockInitial}`;

        const bufferColumnCsv = buffers.map((buffer) => `${buffer.type}: ${buffer.quantity}`).join(SEPARATED_VALUE_STRING);

        const inventoryElement = {
          productName,
          productSku: sku,
          vendorName,
          externalLotId,
          internalLotId,
          netsuiteTransactionId: purchaseOrderId,
          productColumnCsv,
          filterClass,
          delivery_id: internalLotId,
          lotIdColumnCsv,
          vendorColumnCsv,
          arrivalDate: formatDateString(arrivalDate),
          startDate: formatDateString(startDate),
          spoilDate: formatDateString(spoilDate),
          status: capitalizeFirstLetter(status),
          bufferColumnCsv,
          availableForSaleQty,
          onHandQty,
          stockInitial,
        };
        inventoryElements.push(inventoryElement);
      }

      setInventoryData(inventoryElements);

      instantiateDataToDisplay(inventoryElements);

      setIsLoadingQuery(false);
    }
  };

  const handleInventoryRecordModalInstantiation = async (lotId) => {
    const singleViewData = await getSelectedInventoryRecordData(lotId, productFeed, fulfillmentCenters);

    setSelectedLotForInventoryRecordModal(singleViewData);
  };

  const handleInventoryRecordModalInstantiationIfPossible = () => {
    if (showInventoryRecordModal || selectedInternalLotId) {
      handleInventoryRecordModalInstantiation(selectedInternalLotId);
    }
  };

  useEffect(() => {
    handleInventoryRecordModalInstantiationIfPossible();
  }, [selectedInternalLotId, showInventoryRecordModal]);

  useEffect(() => {
    getAndSetInventoryData();
  }, [fcId, date, productFeed]);

  useEffect(() => {
    instantiateDataToDisplay(inventoryData);
  }, [selectedStockFilter]);

  const getLineBreakedElement = ({ value }) => {
    /* For the time being, we should keep these as strings, so ExportReactTableToCsv does not have a hard time rendering.
          We should add a separate work to jsonify them if necessary. */
    const arr = value.split(SEPARATED_VALUE_STRING);
    return (
      <ul style={{ paddingInlineStart: 0 }}>
        {arr.map((value, index) => (
          <div key={`${value}${index}`}>{value}</div>
        ))}
      </ul>
    );
  };

  const handleShowSingleViewModal = (internalLotId) => {
    setSelectedInternalLotId(internalLotId);
    setShowInventoryRecordModal(true);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: PRODUCT_AND_SKU_HEADER,
        accessor: 'productColumnCsv',
        ...multiLineTextColumns,
        Filter: TextInputColumnFilter({ maxWidth: '100%' }),
        Cell: ({ value, row }) => {
          const { internalLotId } = row.original;
          /* For the time being, we should keep these as strings, so ExportReactTableToCsv does not have a hard time rendering.
          We should add a separate work to jsonify them if necessary. */
          const arrOfValues = value.split(SEPARATED_VALUE_STRING);
          const imageUrl = arrOfValues[0];
          const imageUrlExists = imageUrl.length > 0;
          const styleForImage = { width: '58px', height: 'auto' };
          const valuesAfterImage = arrOfValues.slice(1);
          const name = valuesAfterImage[0];
          const valuesAfterName = valuesAfterImage.slice(1);
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{ flex: '1', display: 'flex', justifyContent: 'center', cursor: 'pointer' }}
                onClick={() => handleShowSingleViewModal(internalLotId)}
              >
                {imageUrlExists ? <img alt="" src={imageUrl} style={styleForImage} /> : <div style={styleForImage} />}
              </div>
              <div style={{ flex: '2', paddingLeft: '10px', cursor: 'pointer' }} onClick={() => handleShowSingleViewModal(internalLotId)}>
                <div
                  style={{
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    color: '#4B93EE',
                    fontSize: preferredFontSizes.general,
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {name}
                  <IconButton
                    style={{
                      outline: 'none',
                      position: 'relative',
                      bottom: 3,
                    }}
                    className="pb-0"
                    size="small"
                    color="info"
                    appearance="minimal"
                    icon={ShareIcon}
                    onClick={(event) => {
                      event.stopPropagation();
                      openInventoryRecordInNewTab(internalLotId);
                    }}
                  />
                </div>
                <ul style={{ paddingInlineStart: 0, maxWidth: 140, paddingLeft: '5px', margin: 0 }}>
                  {valuesAfterName.map((value, index) => (
                    <li key={`${value}${index}`} style={{ listStyleType: 'none', color: '#203A56' }}>
                      {value}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          );
        },
      },
      {
        Header: 'Filter Class',
        accessor: 'filterClass',
        ...selectColumnValues,
      },
      {
        Header: 'Lot ID',
        accessor: 'lotIdColumnCsv',
        ...multiLineTextColumns,
        Cell: ({ value }) => {
          /* For the time being, we should keep these as strings, so ExportReactTableToCsv does not have a hard time rendering.
          We should add a separate work to jsonify them if necessary. */
          const arrOfValues = value.split(SEPARATED_VALUE_STRING);
          const [externalLotId, internalLotId] = arrOfValues;
          return (
            <ul style={{ paddingInlineStart: 0 }}>
              <div>{externalLotId}</div>
              <div>{internalLotId}</div>
            </ul>
          );
        },
      },
      {
        Header: 'Vendor',
        accessor: 'vendorColumnCsv',
        Filter: TextInputColumnFilter({ maxWidth: '100%' }),
        ...multiLineTextColumns,
        Cell: ({ value }) => {
          const arrOfValues = value.split(SEPARATED_VALUE_STRING);
          const [name, purchaseOrderId, stockInitial] = arrOfValues;
          return (
            <ul style={{ paddingInlineStart: 0, whiteSpace: 'pre-wrap' }}>
              <div>{name}</div>
              <div>{purchaseOrderId}</div>
              <div>PO units: {stockInitial}</div>
            </ul>
          );
        },
      },
      {
        Header: 'Arrival Date',
        accessor: 'arrivalDate',
        ...dateColumnValues,
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
        ...dateColumnValues,
      },
      {
        Header: 'Spoil Date',
        accessor: 'spoilDate',
        ...dateColumnValues,
      },
      {
        Header: 'AFS',
        accessor: 'availableForSaleQty',
        disableFilters: true,
        ...generalColumn,
      },
      {
        Header: 'On Hand',
        accessor: 'onHandQty',
        disableFilters: true,
        ...generalColumn,
      },
      {
        Header: 'Buffers',
        accessor: 'bufferColumnCsv',
        Cell: getLineBreakedElement,
        disableFilters: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        ...selectColumnValues,
        Cell: ({ value }) => <BadgeForInventoryRecordStatus status={value} fontSize={preferredFontSizes.general} width={70} />,
      },
      {
        Header: ACTION_HEADER,
        Cell: () => {
          return (
            <Button
              appearance="primary"
              style={{ cursor: 'pointer', backgroundColor: '#4B93EE', maxWidth: sharedWidth + 30, fontSize: preferredFontSizes.general }}
            >
              New Adjustment
            </Button>
          );
        },
        disableFilters: true,
        ...generalColumn,
      },
      {
        Header: 'Product SKU 11',
        accessor: 'productSku',
      },
      {
        Header: 'Product Name',
        accessor: 'productName',
      },
      {
        Header: 'Vendor',
        accessor: 'vendorName',
      },
      {
        Header: 'Netsuite Transaction ID',
        accessor: 'netsuiteTransactionId',
      },
      {
        Header: 'External Lot ID',
        accessor: 'externalLotId',
      },
      {
        Header: 'Internal Lot ID',
        accessor: 'internalLotId',
      },
    ],
    [],
  );

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      multiLineText: multiLineTextFilterFn,
    }),
    [],
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: TextInputColumnFilter(textInputColumnFilterProps),
    }),
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    data: tableData,
    // eslint-disable-next-line no-empty-pattern
    state: { },
    filteredRows,
  } = useTable(
    {
      columns,
      data: dataToDisplay,
      defaultColumn,
      filterTypes,
      initialState: {
        hiddenColumns: ['productName', 'productSku', 'netsuiteTransactionId', 'vendorName', 'stockInitial', 'internalLotId', 'externalLotId'],
      },
    },
    useFilters,
    useSortBy,
    useRowSelect,
  );

  useEffect(() => {
    const afsTotalSum = filteredRows.reduce((sum, row) => sum + row.cells[7].value, 0);
    const onHandTotalSum = filteredRows.reduce((sum, row) => sum + row.cells[8].value, 0);
    setAfsTotal(afsTotalSum);
    setOnHandTotal(onHandTotalSum);
  }, [filteredRows]);

  const rowData = rows.map((row) => {
    prepareRow(row);
    return row.values;
  });

  const desiredCsvFields = [
    {
      key: 'productName',
      columnName: 'Product Name',
    },
    {
      key: 'productSku',
      columnName: 'Product SKU',
    },
    {
      key: 'filterClass',
      columnName: 'Filter Class',
    },
    {
      key: 'internalLotId',
      columnName: 'Internal Lot ID',
    },
    {
      key: 'externalLotId',
      columnName: 'External Lot ID',
    },
    {
      key: 'netsuiteTransactionId',
      columnName: 'Netsuite Transaction ID',
    },
    {
      key: 'vendorName',
      columnName: 'Vendor',
    },
    {
      key: 'stockInitial',
      columnName: 'PO units',
    },
    {
      key: 'netsuiteTransactionId',
      columnName: 'Netsuite Transaction ID',
    },
    {
      key: 'arrivalDate',
      columnName: 'Arrival Date',
    },
    {
      key: 'startDate',
      columnName: 'Start Date',
    },
    {
      key: 'spoilDate',
      columnName: 'Spoil Date',
    },
    {
      key: 'availableForSaleQty',
      columnName: 'Available For Sale',
    },
    {
      key: 'onHandQty',
      columnName: 'On Hand',
    },
    {
      key: 'bufferColumnCsv',
      columnName: 'Buffers',
    },
    {
      key: 'status',
      columnName: 'Status',
    },
  ];

  const refresh = async () => {
    setIsLoadingQuery(true);
    await getAndSetInventoryData(fcId, date);
    setLatestRefreshTime(DateTime.now());
    setIsLoadingQuery(false);
  };

  const handleCellClick = async (cell) => {
    const columnHeader = cell.column.Header;
    const { original: rowValueJson } = cell.row;
    const { internalLotId, externalLotId, productName, productSku, status } = rowValueJson;

    setSelectedInternalLotId(internalLotId);

    if (columnHeader === ACTION_HEADER) {
      setSelectedExternalLotId(externalLotId);
      setSelectedProductName(productName);
      setSelectedProductSku(productSku);
      setSelectedInventoryRecordStatus(status);

      setShowNewAdjustment(true);
    }
  };

  const newAdjustmentSuccess = ({ isFromInventoryRecordModal = false, successFunctionMessage }) => {
    toaster.success(successFunctionMessage);
    getAndSetInventoryData();

    if (isFromInventoryRecordModal) {
      handleInventoryRecordModalInstantiationIfPossible();
    }
  };

  const hasContentBelowHeader = (column) => {
    if (column.canFilter || column.Header === 'AFS' || column.Header === 'On Hand') return true;
    return false;
  };

  const getContentBelowHeader = (column) => {
    if (column.canFilter) return column.render('Filter');
    if (column.Header === 'AFS') return <div style={{ textAlign: 'center', padding: '0.78em 0' }}>{afsTotal}</div>;
    if (column.Header === 'On Hand') return <div style={{ textAlign: 'center', padding: '0.78em 0' }}>{onHandTotal}</div>;
    return null;
  };

  return (
    <div className="card">
      <div className="card-header">
        <div className="row align-items-center">
          <div className="col-auto">
            <Group>
              {groupOptions.map(({ label, value }) => (
                <Button
                  key={label}
                  isActive={selectedStockFilter === value}
                  onClick={() => setSelectedStockFilter(value)}
                  appearance={selectedStockFilter === value ? 'primary' : 'default'}
                  style={selectedStockFilter === value ? { backgroundColor: '#203A56' } : {}}
                >
                  {label}
                </Button>
              ))}
            </Group>
          </div>
          <div className="col" />
          <div className="col-auto">
            <span style={{ display: 'inline-block', verticalAlign: 'middle', paddingRight: '0.5em', fontSize: '13px', color: 'gray' }}>
              <div>Last Refreshed</div>
              {latestRefreshTime.toFormat('h:mm:ssa')}
            </span>
            <Button iconAfter={RepeatIcon} isLoading={isLoadingQuery} onClick={refresh}>
              Refresh
            </Button>
          </div>
          {rows && rows.length > 0 && (
            <div className="col-auto">
              <ExportReactTableToCsv
                filePrefix="inventory"
                fcConfig={fcConfig}
                rowData={rowData}
                tableData={tableData}
                idKey="internalLotId"
                desiredFields={desiredCsvFields}
                customButton={
                  <Button appearance="primary" intent="success" style={{ backgroundColor: '#167C51' }}>
                    Export
                  </Button>
                }
              />
            </div>
          )}
        </div>
      </div>

      {isLoadingQuery && (
        <div className="bg-transparent">
          <div className="text-center card-body">
            <p className="text-muted">Loading inventory...</p>
          </div>
        </div>
      )}

      {!isLoadingQuery && (
        <div className="table-responsive table-container-inventory">
          <table {...getTableProps()} className="table table-sm table-hover table-nowrap card-table">
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={`header-group-${index}`}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps({
                        style: {
                          maxWidth: column.maxWidth,
                          backgroundColor: '#FFFFFF',
                          fontSize: preferredFontSizes.general,
                        },
                      })}
                      key={`inventory-column-header-${index}`}
                      className="sticky-header"
                    >
                      <div style={{ textAlign: 'left' }}>
                        <div style={hasContentBelowHeader(column) ? {} : { paddingBottom: '3.2em' }}>
                          <span {...column.getSortByToggleProps()} style={{ color: '#6D6D6D' }}>
                            {column.render('Header')}
                            {/* Add a sort direction indicator */}
                            {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                          </span>
                        </div>
                        <div>{getContentBelowHeader(column)}</div>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="list font-size-base">
              {rows.map((row, rowIndex) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} style={{ backgroundColor: rowIndex % 2 === 0 ? '#F9F9F9' : '' }} key={`inventory-table-${rowIndex}`}>
                    {row.cells.map((cell, cellIndex) => {
                      return (
                        <td
                          {...cell.getCellProps({ style: { padding: 0 } })}
                          onClick={() => handleCellClick(cell)}
                          style={{
                            cursor: COLUMN_HEADERS_THAT_ALLOW_FOR_POINTER_CURSOR.includes(cell.column.Header) ? 'pointer' : 'default',
                            color: '#6D6D6D',
                            fontSize: preferredFontSizes.general,
                          }}
                          key={`inventory-table-${rowIndex}-${cellIndex}`}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                    <td />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      {selectedLotForInventoryRecordModal && (
        <InventoryRecordModal
          showInventoryRecordModal={showInventoryRecordModal}
          makeInventoryRecordModalVisible={() => setShowInventoryRecordModal(true)}
          hideInventoryRecordModal={resetLocalLotVariables}
          internalLotId={selectedInternalLotId}
          selectedLot={selectedLotForInventoryRecordModal}
          externalLotId={selectedExternalLotId}
          productName={selectedProductName}
          productSku={selectedProductSku}
          fc={fcConfig}
          newAdjustmentSuccess={newAdjustmentSuccess}
          inventoryRecordStatus={selectedInventoryRecordStatus}
        />
      )}

      {selectedExternalLotId && selectedProductName && selectedInternalLotId && showNewAdjustment && (
        <CreateOrEditAdjustmentModal
          showModal={showNewAdjustment}
          changelogs={selectedLotForInventoryRecordModal.changelogs}
          hideModalFunction={resetLocalLotVariables}
          internalLotId={selectedInternalLotId}
          externalLotId={selectedExternalLotId}
          productName={selectedProductName}
          fc={fcConfig}
          backAction={resetLocalLotVariables}
          successFunction={newAdjustmentSuccess}
          successFunctionMessage="Successfully created new adjustment"
        />
      )}
    </div>
  );
};

InventoryTable.propTypes = {
  date: PropTypes.string,
  fcId: PropTypes.string,
  fcConfig: PropTypes.object,
};

InventoryTable.defaultProps = {
  date: '',
  fcId: '',
  fcConfig: {},
};

export default InventoryTable;
