/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { ArrowRepeat } from 'react-bootstrap-icons';
import { Dialog, toaster } from 'evergreen-ui';
import { ADMIN_URL, ENVIRONMENT_NAME, STICKY_URL } from '../../config';
import { cancelStickySubscription, getRelatedSubscriptionOrders } from '../../services/OrderManagementService';

export const ViewSubscription = ({ order, queryCache }) => {
  const [showCancelSubModal, setShowCancelSubModal] = useState(false);
  const [showPreviousOrdersModal, setShowPreviousOrdersModal] = useState(false);
  const [relatedSubscriptionOrderIds, setRelatedSubscriptionOrderIds] = useState([]);

  const { subscription } = order;
  const { duration, frequency, source, subscriptionNumber, initialSubscriptionOrderNumber, tierSku, ongoing } = subscription;
  const initialSubscriptionDate = subscription.initialSubscriptionDate
    ? DateTime.fromISO(subscription.initialSubscriptionDate).toLocaleString()
    : 'None recorded';
  const initialOrderNumberLink = initialSubscriptionOrderNumber ? (
    <Link to={{ pathname: `/orders/${initialSubscriptionOrderNumber}` }} target="_blank">
      {subscription.initialSubscriptionOrderNumber}
    </Link>
  ) : null;
  const subNumberLink =
    source === 'sticky' ? (
      <a
        style={{ padding: 0 }}
        href={`${STICKY_URL}/admin/orders.php?show_details=show_details&show_folder=view_all&fromPost=1&act=&sequence=1&show_by_id=${subscription.subscriptionNumber}`}
        target="_blank"
        className="btn btn-link"
        rel="noreferrer"
      >
        {subscriptionNumber}
      </a>
    ) : (
      <a style={{ padding: 0 }} href={`${ADMIN_URL}/subscriptions/${subscriptionNumber}`} target="_blank" rel="noreferrer">
        {subscriptionNumber}
      </a>
    );
  const previousOrdersButton = (
    <button style={{ padding: 0 }} type="button" className="btn btn-link" onClick={() => setShowPreviousOrdersModal(true)}>
      Open
    </button>
  );

  useEffect(() => {
    if (!relatedSubscriptionOrderIds.length) {
      const initializeSubOrders = async () => {
        const response = await getRelatedSubscriptionOrders(order.orderId);

        if (response.success) {
          setRelatedSubscriptionOrderIds(response.orderIds);
        } else {
          console.error('Bad response for getting related sub orders', response);
        }
      };

      initializeSubOrders();
    }
  }, []);

  const keysToValues = {
    Duration: duration,
    Frequency: frequency,
    'Initial Subscription Date': initialSubscriptionDate,
    'Initial Order Number': initialOrderNumberLink,
    Type: ongoing ? 'Recurring' : 'Prepaid',
    Source: source,
    'Subscription Number': subNumberLink,
    'Tier SKU': tierSku,
    'Previous Orders': previousOrdersButton,
  };

  const isPrepaid = order.totals && order.totals[order.totals.length - 1].orderTotal === 0 && ongoing === false;

  const showSuccessMessage = () => toaster.success(`Subscription successfully deleted for ${order.orderId}`);

  const showFailureMessage = () => toaster.danger('Subscription could not be cancelled. Please try again.');

  const cancelSubscription = async () => {
    const response = await cancelStickySubscription(order.orderId);
    if (response.success) {
      setShowCancelSubModal(false);
      queryCache.invalidateQueries(['orders', order.orderId]);
      showSuccessMessage();
    } else {
      showFailureMessage();
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title">
          <ArrowRepeat size="1.5em" className="mr-2" /> <span>Subscription</span>
        </h4>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table className="table">
            <tbody>
              {Object.keys(keysToValues)
                .filter((key) => (typeof keysToValues[key] === 'string' ? keysToValues[key].length > 0 : keysToValues[key] != null))
                .map((key, index) => (
                  <tr key={`subscriptioninfo-${index}`}>
                    <td className="px-0 py-1 border-bottom">{key}</td>
                    <td className="px-0 py-1 text-right text-right border-bottom">{keysToValues[key]}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {ENVIRONMENT_NAME !== 'prod' && subscription.source === 'sticky' && !subscription.isCancelled && (
          <div>
            <button className="btn btn-danger mr-2" onClick={() => setShowCancelSubModal(true)}>
              Cancel Subscription
            </button>
          </div>
        )}
      </div>
      <Dialog
        isShown={showCancelSubModal}
        title="Cancel Subscription"
        onCloseComplete={() => {
          setShowCancelSubModal(false);
        }}
        confirmLabel="Confirm"
        onConfirm={cancelSubscription}
      >
        <div>
          {isPrepaid && (
            <div className="mt-0">This is a prepaid order. If you'd like to refund, please go to the original order: {initialOrderNumberLink}</div>
          )}
          <div className={isPrepaid ? 'mt-3' : 'mt-0'}>Are you sure you'd like to cancel the subscription?</div>
        </div>
      </Dialog>

      <Dialog
        isShown={showPreviousOrdersModal}
        title="Related Subscription Orders"
        onCloseComplete={() => setShowPreviousOrdersModal(false)}
        footer={<></>}
      >
        {relatedSubscriptionOrderIds.map((orderId) => (
          <div key={`subscriptionLink${orderId}`}>
            <Link to={{ pathname: `/orders/${orderId}` }} target="_blank">
              {orderId}
            </Link>
          </div>
        ))}
      </Dialog>
    </div>
  );
};
