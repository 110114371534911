/* eslint-disable no-console */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { TextInputField, Table, Spinner, Button, Select } from 'evergreen-ui';
import 'react-datepicker/dist/react-datepicker.css';
import { getScheduleByDeliveryId } from '../../../services/AggregateService';

const DAYS = [
  { name: 'Mon', value: 0 },
  { name: 'Tue', value: 1 },
  { name: 'Wed', value: 2 },
  { name: 'Thur', value: 3 },
  { name: 'Fri', value: 4 },
  { name: 'Sat', value: 5 },
  { name: 'Sun', value: 6 },
];

const SchedulesTable = ({ selectedRow, deliveryArea, onSchedulesChange }) => {
  const [isFetchingDeliverySchedule, setIsFetchingDeliverySchedule] = useState(false);
  const [schedules, setSchedules] = useState([]);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      setIsFetchingDeliverySchedule(true);
      try {
        const { schedules } = await getScheduleByDeliveryId(selectedRow.id);
        if (isMounted) {
          const s = schedules.map((area) => {
            const startTime = new Date();
            startTime.setHours(area.starthour);
            startTime.setMinutes(0);
            startTime.setSeconds(0);

            const endTime = new Date(startTime.getTime() + area.duration * 60 * 60 * 1000);

            const formattedStartTime = startTime.toTimeString().slice(0, 5);
            const formattedEndTime = endTime.toTimeString().slice(0, 5);
            return {
              id: area.rule,
              dayofweek: area.dayofweek,
              startTime: formattedStartTime,
              endTime: formattedEndTime,
            };
          });
          setSchedules(s);
          onSchedulesChange(s);
        }
      } catch (error) {
        console.error('Error fetching the delivery areas:', error);
      } finally {
        if (isMounted) {
          setIsFetchingDeliverySchedule(false);
        }
      }
    };

    if (selectedRow && selectedRow.id) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, [selectedRow]);

  useEffect(() => {
    onSchedulesChange(schedules);
  }, [schedules]);

  const handleRemoveRow = (index) => {
    const updatedScheds = schedules.filter((_, i) => i !== index);
    setSchedules(updatedScheds);
  };

  const handleScheduleChange = (index, name, value) => {
    const updatedScheds = schedules.map((sched, i) => {
      if (i === index) {
        const updatedSchedule = { ...sched, [name]: value };

        const startTime = name === 'startTime' ? value : sched.startTime || '00:00';
        const endTime = name === 'endTime' ? value : sched.endTime || '00:00';

        const startMinutes = parseInt(startTime.split(':')[0], 10) * 60 + parseInt(startTime.split(':')[1], 10);
        const endMinutes = parseInt(endTime.split(':')[0], 10) * 60 + parseInt(endTime.split(':')[1], 10);

        if (endMinutes < startMinutes) {
          updatedSchedule.endTime = startTime;
        }

        return updatedSchedule;
      }
      return sched;
    });
    setSchedules(updatedScheds);
  };

  const handleAddRow = () => {
    const newRow = {
      dayOfWeek: 0,
      startTime: 0,
      endTime: 0,
    };
    setSchedules([...schedules, newRow]);
  };

  const memoizedDays = useMemo(
    () => DAYS.map((day) => (
      <option key={day.value} value={day.value}>
        {day.name}
      </option>
    )),
    [],
  );

  return (
    <>
      <Table.Body width="100%" style={{ border: '1px solid #E8E8E8' }}>
        <Table.Head>
          <Table.TextCell style={{ textAlign: 'center' }} flexBasis={120} flexShrink={0} flexGrow={0}>
            Day
          </Table.TextCell>
          <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0}>
            Start Time
          </Table.TextCell>
          <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0}>
            End Time
          </Table.TextCell>
          <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0}>
            Cutoff Time
          </Table.TextCell>
          <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0}>
            Prepped At Time
          </Table.TextCell>
          <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0}>
            Action
          </Table.TextCell>
        </Table.Head>
        {!isFetchingDeliverySchedule && (
          <>
            {schedules.map((area, index) => {
              return (
                <Table.Row key={area.id + index}>
                  <Table.TextCell
                    style={{ background: '#E8E8E8', textAlign: 'center' }}
                    flexBasis={120}
                    flexShrink={0}
                    flexGrow={0}
                  >
                    <Select
                      name="dayofweek"
                      value={area.dayofweek}
                      onChange={(e) => handleScheduleChange(index, 'dayofweek', e.target.value)}
                    >
                      <option value="" disabled>Select Day</option>
                      {memoizedDays}
                    </Select>
                  </Table.TextCell>
                  <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0} style={{ paddingTop: 15 }}>
                    <TextInputField
                      label=""
                      type="time"
                      name="startTime"
                      value={area.startTime || '00:00'}
                      onChange={(e) => handleScheduleChange(index, 'startTime', e.target.value)}
                    />
                  </Table.TextCell>
                  <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0} style={{ paddingTop: 15 }}>
                    <TextInputField
                      label=""
                      type="time"
                      name="endTime"
                      value={area.endTime || '00:00'}
                      onChange={(e) => handleScheduleChange(index, 'endTime', e.target.value)}
                    />
                  </Table.TextCell>
                  <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0}>
                    {deliveryArea.cutoff}
                  </Table.TextCell>
                  <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0}>
                    {deliveryArea.travel_time}
                  </Table.TextCell>
                  <Table.TextCell
                    flexBasis={150}
                    flexShrink={0}
                    flexGrow={0}
                    onClick={() => handleRemoveRow(index)}
                  >
                    <span style={{ color: '#E05340', textDecoration: 'underline', cursor: 'pointer' }}>
                      Remove schedule
                    </span>
                  </Table.TextCell>
                </Table.Row>
              );
            })}
          </>
        )}
      </Table.Body>
      {isFetchingDeliverySchedule ? (
        <Spinner />
      ) : (
        <Button type="button" onClick={handleAddRow} marginTop={16}>
          Add schedule
        </Button>
      )}
    </>
  );
};

SchedulesTable.propTypes = {
  selectedRow: PropTypes.object,
  onSchedulesChange: PropTypes.func.isRequired,
  deliveryArea: PropTypes.object.isRequired,
};

SchedulesTable.defaultProps = {
  selectedRow: { id: 0 },
};

export default SchedulesTable;
