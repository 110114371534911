/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { Envelope } from 'react-bootstrap-icons';
import { NavLink } from 'react-router-dom';
import background from '../img/covers/Rosemary_Carousel_v2.jpg';
import Email from './common/Email';
import TextInput from './common/TextInput';
import ChangePassword from './auth/ChangePassword';

const Authentication = ({ setUser, setTempUser, tempUser }) => {
  const [username, setUsername] = useState('');
  const [validUsername, setValidUsername] = useState(false);
  const [password, setPassword] = useState('');
  const [validPassword, setValidPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loggingIn, setLoggingIn] = useState(false);

  const changeInput = (event) => {
    const { target } = event;
    const { value, name } = target;
    switch (name) {
      case 'password':
        setValidPassword(target.valid);
        setPassword(value);
        break;
      case 'username':
        setValidUsername(target.valid);
        setUsername(value);
        break;
      default:
        break;
    }
  };

  const signIn = async (event) => {
    event.preventDefault();
    if (!validUsername || !validPassword) {
      return;
    }
    try {
      setLoggingIn(true);
      const user = await Auth.signIn(username, password);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setTempUser(user);
      } else {
        setUser(user);
      }
    } catch (error) {
      setErrorMessage(error.message);
      setTimeout(() => setErrorMessage(''), 3000);
    } finally {
      setLoggingIn(false);
    }
  };

  const validatePassword = (val) => val !== '' && val.length >= 8;

  if (tempUser.username) {
    return <ChangePassword tempUser={tempUser} setUser={setUser} />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-5 col-lg-6 col-xl-4 vh-100 px-lg-6 py-5 overflow-auto">
          <h1 className="display-4 text-center mb-3">Sign in</h1>

          <p className="text-center mt-5 mb-2">Got an urbanstems.com email address?</p>

          <button tabIndex="1" type="button" className="btn btn-white btn-block" onClick={() => Auth.federatedSignIn({ provider: 'Google' })}>
            <span className="fe mr-3">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" className="abcRioButtonSvg">
                <g>
                  <path
                    fill="#EA4335"
                    d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                  />
                  <path
                    fill="#4285F4"
                    d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                  />
                  <path
                    fill="#FBBC05"
                    d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                  />
                  <path
                    fill="#34A853"
                    d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                  />
                  <path fill="none" d="M0 0h48v48H0z" />
                </g>
              </svg>
            </span>{' '}
            Sign In with Google
          </button>

          <p className="text-center mt-5 mb-2">Otherwise, sign in here:</p>

          <form>
            <div className="form-group">
              <Email onChange={changeInput} value={username} tabIndex={2} />
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                </div>
                <div className="col-auto">
                  <NavLink exact to="/forgotPassword">
                    <span className="form-text small text-muted">Forgot password?</span>
                  </NavLink>
                </div>
              </div>
              <TextInput
                id="password"
                onChange={changeInput}
                value={password}
                name="password"
                type="password"
                tabIndex={3}
                validate={validatePassword}
              />
            </div>
            <button type="button" className="btn btn-lg btn-block btn-primary mb-3" onClick={signIn} disabled={loggingIn} tabIndex="4">
              Sign in
            </button>
            <p className="text-center">
              <NavLink exact to="/resetPassword">
                <small className="text-muted text-center">Login with reset code</small>
              </NavLink>
            </p>
            <p className="text-center">
              <small className="text-muted text-center">
                Don&apos;t have an account yet? <Envelope /> People Ops.
              </small>
            </p>
            <div className="text-danger">{errorMessage}</div>
          </form>
        </div>
        <div className="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block p-0">
          <div className="bg-cover vh-100" style={{ backgroundImage: `url(${background})` }} />
        </div>
      </div>
    </div>
  );
};

Authentication.propTypes = {
  setUser: PropTypes.func.isRequired,
  setTempUser: PropTypes.func.isRequired,
  tempUser: PropTypes.object,
};

Authentication.defaultProps = {
  tempUser: {},
};

export default Authentication;
