/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import Select from 'react-select';
import { matchSorter } from 'match-sorter';
import { useAsyncDebounce } from 'react-table';
import { TextInput, SelectMenu as EvergreenSelectMenu, Button, ChevronDownIcon } from 'evergreen-ui';

export const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{' '}
      <input
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: '1.1rem',
          border: '0',
        }}
      />
    </span>
  );
};

export const DefaultColumnFilter = ({ column: { filterValue, preFilteredRows, setFilter } }) => {
  const count = preFilteredRows.length;

  return (
    <textarea
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
};

export const TextInputColumnFilter = (props = {}) => {
  const { minWidth, maxWidth, fontSize } = props;
  // eslint-disable-next-line react/display-name
  return ({ column: { filterValue, setFilter } }) => (
    <TextInput
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      fontSize={fontSize}
      className="react-table-usable-filter"
      placeholder="Search"
      maxWidth={maxWidth}
      minWidth={minWidth}
    />
  );
};

const getOptionsForSelectColumnFilter = ({ preFilteredRows, id }, { colonDelimitedFields = [], includeAllOption = true, allOptionLabel = 'All' }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const options = React.useMemo(() => {
    const options = new Map();
    if (includeAllOption) {
      options.set('', { value: '', label: allOptionLabel });
    }
    preFilteredRows.forEach((row) => {
      const vals = `${row.values[id]}`;
      const valsWithoutQuantities = vals.replace(/(\d+)x\s/g, '');
      if (valsWithoutQuantities !== 'null' && valsWithoutQuantities !== '') {
        if (colonDelimitedFields.includes(id)) {
          valsWithoutQuantities.split(';').forEach((val) => {
            options.set(val, { value: val, label: val });
          });
        } else {
          valsWithoutQuantities.split(',').forEach((val) => {
            options.set(val, { value: val, label: val });
          });
        }
      }
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return options;
};

export const SelectColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
  const colonDelimitedFields = ['exceptionReason', 'exceptionType', 'sku', 'fulfillmentDate', 'deliveryDate'];
  const options = getOptionsForSelectColumnFilter({ preFilteredRows, id }, { colonDelimitedFields, includeAllOption: true, allOptionLabel: 'All' });

  if (options.length > 2) {
    // Render a multi-select box
    return (
      <div className="table-filter-container">
        <Select
          value={filterValue && filterValue.value}
          onChange={(e) => {
            setFilter(e?.value);
          }}
          options={options}
          // eslint-disable-next-line react/jsx-boolean-value
          isClearable={true}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
        />
      </div>
    );
  }

  return <div />;
};

export const EvergreenSelectFilter = (props) => {
  const { minWidth, maxWidth, fontSize } = props;

  // eslint-disable-next-line react/display-name, no-confusing-arrow
  return ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
    const options = getOptionsForSelectColumnFilter(
      { preFilteredRows, id },
      { colonDelimitedFields: [], includeAllOption: true, allOptionLabel: 'Clear' },
    );

    const tooFewOptions = 2;
    const onlyOneRealOptionExists = options.length === tooFewOptions;
    return (
      <div>
        <EvergreenSelectMenu
          title={id}
          options={options}
          selected={filterValue}
          onSelect={(item) => (item.value.length === 0 ? setFilter(null) : setFilter(item.value))}
          minWidth={minWidth}
          maxWidth={maxWidth}
        >
          <Button
            fontSize={fontSize}
            style={{ padding: '4px' }}
            disabled={onlyOneRealOptionExists}
            className="react-table-usable-filter"
            minWidth={minWidth}
            maxWidth={maxWidth}
          >
            {onlyOneRealOptionExists ? options[tooFewOptions - 1].value : filterValue || 'Select'}
            {options.length > tooFewOptions ? <ChevronDownIcon /> : <span />}
          </Button>
        </EvergreenSelectMenu>
      </div>
    );
  };
};

export const MultiSelectFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Map();
    preFilteredRows.forEach((row) => {
      const vals = `${row.values[id]}`;
      const valsWithoutQuantities = vals.replace(/(\d+)x\s/g, '');
      if (id === 'exceptionReason') {
        valsWithoutQuantities.split(';').forEach((val) => {
          options.set(val, { value: val, label: val });
        });
      } else if (id !== 'fulfillmentDate' && id !== 'deliveryDate') {
        valsWithoutQuantities.split(',').forEach((val) => {
          options.set(val, { value: val, label: val });
        });
      } else if (vals !== 'null') {
        options.set(vals, { value: vals, label: vals });
      }
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  const handleMultiSelect = (e) => {
    const values = e && e.map((v) => v.value);
    setFilter(values && values.length ? values : undefined);
  };

  if (options.length >= 2) {
    // Render a multi-select box
    return (
      <div className="table-filter-container">
        <Select
          value={filterValue && filterValue.value}
          isMulti
          onChange={handleMultiSelect}
          options={options}
          // eslint-disable-next-line react/jsx-boolean-value
          isClearable={true}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
        />
      </div>
    );
  }
  return <div />;
};
export const fuzzyTextFilterFn = (rows, id, filterValue) => {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
};

export const multiLineTextFilterFn = (rows, id, filterValue) => {
  const filterValues = String(filterValue)
    .toLowerCase()
    .split('\n')
    .filter((f) => f.trim().length);
  return rows.filter((row) => {
    const rowValue = row.values[id];
    if (rowValue === undefined) return true;
    const lcaseRowValue = String(rowValue).toLowerCase();
    for (const fVal of filterValues) {
      if (lcaseRowValue.includes(fVal)) return true;
    }
    return false;
  });
};

export const fcIdFilterFn = (rows, columnIds, filterValue) => {
  return rows.filter((row) => filterValue.includes(row.values.deliveryAreaName));
};
// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;
