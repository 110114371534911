/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from 'react';
import { Paragraph, FilePicker, Spinner } from 'evergreen-ui';
import { CSVLink } from 'react-csv';
import { NavLink, Link, useParams, useHistory } from 'react-router-dom';
import { useTable } from 'react-table';

import { LookupContext } from '../../context/lookupContext';
import { getBulkUploadResponse, ingestBulkOrder } from '../../services/OrderManagementService';
import Modal from '../common/Modal';
import { BULK_AUTO_FOLLOW_THRESHOLD, BULK_SLACK_CHANNEL } from '../../config';
import PageHeader from '../nav/PageHeader';

const RETRY_LIMIT = 10;

const BulkOrderForm = () => {
  const history = useHistory();

  const { id: requestId } = useParams();

  const { fulfillmentCenters } = useContext(LookupContext);
  const [bulkFile, setBulkFile] = useState(null);
  const [tableOrders, setTableOrders] = useState([]);
  const [badOrderMessages, setBadOrderMessages] = useState([]);

  const [showFilePicker, setShowFilePicker] = useState(true);
  const [showTable, setShowTable] = useState(false);
  const [showPreviewButton, setShowPreviewButton] = useState(false);
  const [showIngestOrderButton, setShowIngestOrderButton] = useState(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [showResendMessage, setShowResendMessage] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isPreview, setIsPreview] = useState(true);
  const [grandTotal, setGrandTotal] = useState(0);

  const [showFileProcessingMessage, setShowFileProcessingMessage] = useState(false);
  const [requestIdForMessage, setRequestIdForMessage] = useState(requestId);
  const [showRequestIdDoesNotExist, setShowRequestIdDoesNotExist] = useState(false);

  const [displayRetryCount, setDisplayRetryCount] = useState(0);

  const [csvData, setCsvData] = useState(null);

  function timeout(delayInSeconds) {
    return new Promise((res) => setTimeout(res, delayInSeconds * 1000));
  }

  const getBulkUploadResponseAndApply = async () => {
    if (requestId) {
      setShowFilePicker(false);
      setRequestIdForMessage(requestId);

      let retryCount = 0;

      const resetProcessingVars = () => {
        setShowFileProcessingMessage(false);
        setDisplayRetryCount(0);
      };

      while (retryCount < RETRY_LIMIT) {
        const { data: callResponse, success } = await getBulkUploadResponse(requestId);
        if (success) {
          const { data, isPreview: isPreviewFromCall, isProcessing } = callResponse;

          setIsPreview(isPreviewFromCall);

          if (isProcessing) {
            setShowFileProcessingMessage(true);
            await timeout((retryCount + 1) * 5);

            retryCount++;
            setDisplayRetryCount(retryCount);
          } else {
            if (isPreviewFromCall) {
              processPreviewData(data);
            } else {
              processIngestionData(data);
            }

            resetProcessingVars();
            break;
          }
        } else {
          console.error('Error with bulk upload response', callResponse);
          setShowRequestIdDoesNotExist(true);

          resetProcessingVars();
          break;
        }
      }
    }
  };

  useEffect(() => {
    if (requestId) {
      getBulkUploadResponseAndApply();
    } else {
      reset();
    }
  }, [requestId]);

  const shouldAutoFollow = (recordCount) => recordCount <= Number(BULK_AUTO_FOLLOW_THRESHOLD);

  const processPreviewData = (data) => {
    handleResponseData(data, true);

    setShowIngestOrderButton(true);
  };

  const processIngestionData = (data) => {
    handleResponseData(data, false);
  };

  const getUrlExtensionForRequestId = (reqId) => `/orders/bulk/${reqId}`;

  const ingestWithPreview = async () => {
    setShowTable(false);
    setShowPreviewButton(false);
    setBadOrderMessages([]);
    setShowResendMessage(false);
    setIsLoading(true);
    setIsPreview(true);
    setCsvData(null);
    new Promise((resolve, reject) =>
      ingestBulkOrder(bulkFile)
        .then((res) => {
          if (res.success) {
            const { recordCount, requestId: reqId } = res.data;

            if (shouldAutoFollow(recordCount)) {
              history.push(getUrlExtensionForRequestId(reqId));
            } else {
              setShowFileProcessingMessage(true);
              setRequestIdForMessage(reqId);
            }
          } else {
            throw Error('Not a successful bulk order call', res);
          }
        })
        .catch((err) => {
          console.error('got an error for bulk order', err);
          setShowResendMessage(true);
          setShowFilePicker(true);
          setShowPreviewButton(true);
        })
        .finally(() => {
          setIsLoading(false);
        }),
    );
  };

  const ingestCompletely = async () => {
    setShowTable(false);
    setShowPreviewButton(false);
    setShowIngestOrderButton(false);
    setShowResendMessage(false);
    setIsLoading(true);
    setIsPreview(false);
    setCsvData(null);
    new Promise((resolve, reject) =>
      ingestBulkOrder(null, requestId)
        .then((res) => {
          const { recordCount, requestId: reqId } = res.data;

          if (shouldAutoFollow(recordCount)) {
            if (requestId) {
              getBulkUploadResponseAndApply();
            } else {
              history.push(getUrlExtensionForRequestId(reqId));
            }
          } else {
            setShowFileProcessingMessage(true);
            setRequestIdForMessage(reqId);
          }
        })
        .catch((err) => {
          console.error('got an error for bulk order', err);
          setShowResendMessage(true);
          setShowPreviewButton(true);
        })
        .finally(() => {
          setIsLoading(false);
        }),
    );
  };

  const handleResponseData = (data, isPreview) => {
    setIsPreview(isPreview);

    const orderRows = formatAcceptableOrdersForTableAndCsv(data.acceptableOrders, isPreview);
    setTableOrders(orderRows);

    const badMessages = data.badOrders.map((badOrder) => badOrder.ingestionExceptionReason);
    setBadOrderMessages(badMessages);

    setShowTable(true);
  };

  const formatUsingHardcodedLineBreaks = ({ value }) => {
    return <div dangerouslySetInnerHTML={{ __html: value }} />;
  };

  const orderIdCell = ({ value: orderId }) => {
    return orderId !== undefined ? (
      <Link to={{ pathname: `/orders/${orderId}` }} target="_blank">
        {orderId}
      </Link>
    ) : (
      <div>Not yet ingested; no ID</div>
    );
  };

  const makeRowForCsv = (csvRowData) => {
    return [
      csvRowData.orderId,
      csvRowData.suborderId,
      csvRowData.lineItemName,
      csvRowData.lineItemSku,
      csvRowData.lineItemQty,
      String(csvRowData.unitPrice),
      String(csvRowData.discount),
      String(csvRowData.shipping),
      String(csvRowData.tax),
      String(csvRowData.price),
      String(csvRowData.totalPrice),
      csvRowData.notecard,
    ];
  };

  const formatAcceptableOrdersForTableAndCsv = (acceptableOrders, isPreview) => {
    const newCsvRows = [];
    const addRowToCsv = (csvRow) => {
      newCsvRows.push(makeRowForCsv(csvRow));
    };

    const titleRow = {
      orderId: 'Order ID',
      suborderId: 'Suborder ID',
      lineItemName: 'Line Item Name',
      lineItemSku: 'Line Item SKU',
      lineItemQty: 'Line Item Quantity',
      unitPrice: 'Unit Price',
      discount: 'Discount',
      shipping: 'Shipping',
      tax: 'Tax',
      price: 'Price',
      totalPrice: 'Total Price With Tax',
      notecard: 'Notecard',
    };

    addRowToCsv(titleRow);

    let localGrandTotal = 0;
    const orderRows = [];

    const totalsRow = {
      orderId: 'TOTALS',
      suborderId: '',
      lineItemName: '',
      lineItemSku: '',
      lineItemQty: '',
      unitPrice: '',
      discount: 0,
      shipping: 0,
      tax: 0,
      price: 0,
      totalPrice: 0,
      notecard: '',
    };

    for (const order of acceptableOrders) {
      const { customer } = order;
      const orderRow = {};
      orderRow.customerInfo =
        customer !== undefined
          ? `Account Name: ${customer.parentCustomerName}<br />
        Name: ${customer.firstName} ${customer.lastName}<br />
        Email: ${customer.email}<br />
        Phone: ${customer.phone}<br />
        Zipcode: ${customer.zipCode}`
          : 'No customer info available';

      const recipient = order.suborders && order.suborders.length && order.suborders[0].recipient ? order.suborders[0].recipient : undefined;
      orderRow.recipientInfo =
        recipient !== undefined
          ? `Name: ${recipient.firstName} ${recipient.lastName} <br />
      Address: ${recipient.address1} ${recipient.address2} ${recipient.city}, ${recipient.state} ${recipient.zipCode} <br />
      Email: ${recipient.email} <br />
      Phone: ${recipient.phone} <br />
      Location Type: ${recipient.locationType} <br />`
          : 'No recipient info available';

      const orderTotals = order.totals && order.totals.length ? order.totals[order.totals.length - 1] : undefined;

      const { shippingCost } = orderTotals;

      orderRow.totalsInfo =
        orderTotals !== undefined
          ? `Item Total: ${orderTotals.itemTotal}<br />
          Discounted Item Total: ${orderTotals.itemTotal - orderTotals.discountTotal}<br />
      Tax Total: ${orderTotals.taxTotal}<br />
      Shipping Total: ${shippingCost}<br />
      Order Total: ${orderTotals.orderTotal}`
          : 'No order totals available.';

      localGrandTotal += orderTotals.orderTotal !== undefined ? orderTotals.orderTotal : 0;

      const transaction = order.transactions && order.transactions.length ? order.transactions[0] : undefined;
      orderRow.transactionInfo =
        transaction !== undefined
          ? `Payment Type: ${transaction.paymentType}<br />
      Transaction ID: ${transaction.transactionId}<br />
      Brand: ${transaction.brand}<br />
      Amount: ${transaction.amount}`
          : 'No transaction ingested';

      const orderId = !isPreview ? order.orderId : customer ? customer.email : 'Not ingested';

      const shippingTotalPrice = shippingCost + orderTotals.shippingTaxAmount;

      const shippingRow = {
        orderId,
        suborderId: 'Shipping Totals',
        lineItemName: '',
        lineItemSku: '',
        lineItemQty: '',
        unitPrice: '',
        discount: orderTotals.shippingDiscount,
        shipping: shippingCost,
        tax: orderTotals.shippingTaxAmount,
        price: shippingCost,
        totalPrice: shippingTotalPrice,
        notecard: '',
      };

      totalsRow.discount += orderTotals.shippingDiscount;
      totalsRow.tax += shippingCost;
      totalsRow.shipping += orderTotals.shippingTaxAmount;
      totalsRow.price += shippingCost;
      totalsRow.totalPrice += shippingTotalPrice;

      addRowToCsv(shippingRow);

      const { suborders } = order;
      let suborderInfoString = '';
      if (suborders && suborders.length) {
        suborderInfoString += '<ul> ';
        let notecard = '';
        for (let i = 0; i < suborders.length; i++) {
          const suborder = suborders[i];

          suborderInfoString += `<li>SUBORDER ${i + 1}:
          <ul><li>FC: ${fulfillmentCenters[suborder.fcId].name}</ li>
          <li>Fulfillment Date: ${suborder.fulfillmentDate}</ li>`;

          const getSaleLineItemData = (suborder, fulfillableLineItem, fieldName) =>
            suborder.saleLineItems ? suborder.saleLineItems[fulfillableLineItem.saleLineItemIndex][fieldName] : fulfillableLineItem[fieldName];

          for (let j = 0; j < suborder.lineItems.length; j++) {
            const lineItem = suborder.lineItems[j];

            const discount = getSaleLineItemData(suborder, lineItem, 'discountAmount');
            const taxRate = getSaleLineItemData(suborder, lineItem, 'taxRate');
            const taxAmount = getSaleLineItemData(suborder, lineItem, 'taxAmount');

            suborderInfoString += `<li>Line Item ${j + 1}: ${lineItem.name}
            <ul><li>SKU: ${lineItem.sku}</ li>
            <li>Quantity: ${lineItem.quantity}</ li>
            <li>Unit Price: ${lineItem.unitPrice}</ li>
            <li>Discount Amount: ${discount}</ li>
            <li>Tax Rate: ${taxRate}</ li>
            <li>Tax Amount: ${taxAmount}</ li>
            <li>Price: ${lineItem.price}</ li>
            </li>
            </ul>`;

            if (!isPreview && suborder.inException && suborder.exceptionsList && suborder.exceptionsList.length) {
              const reason = suborder.exceptionsList[suborder.exceptionsList.length - 1].reason;
              suborderInfoString += `Exception reason: ${reason}<br />`;
            }
            suborderInfoString += '</li>';

            const totalPrice = lineItem.price + taxAmount;

            notecard = suborder.message;

            const csvRow = {
              orderId,
              suborderId: !isPreview ? suborder.suborderId : 'No suborder ID',
              lineItemName: lineItem.name,
              lineItemSku: lineItem.sku,
              lineItemQty: lineItem.quantity,
              unitPrice: lineItem.unitPrice,
              discount,
              shipping: 0,
              tax: taxAmount,
              price: lineItem.price,
              totalPrice,
              notecard: suborder.message,
            };

            addRowToCsv(csvRow);

            totalsRow.discount += discount;
            totalsRow.tax += taxAmount;
            totalsRow.price += lineItem.price;
            totalsRow.totalPrice += totalPrice;
          }

          suborderInfoString += '</ul> <br />';
        }

        suborderInfoString += '</ul></li>';
      } else {
        suborderInfoString = 'No suborders available';
      }
      orderRow.suborderInfo = suborderInfoString;

      if (!isPreview) {
        orderRow.orderId = order.orderId;
      }

      orderRows.push(orderRow);
    }

    localGrandTotal = Math.round(localGrandTotal * 100) / 100;
    setGrandTotal(localGrandTotal);

    addRowToCsv(totalsRow);

    setCsvData(newCsvRows);

    return orderRows;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Recipient',
        accessor: 'recipientInfo',
        Cell: formatUsingHardcodedLineBreaks,
      },
      {
        Header: 'Order Totals',
        accessor: 'totalsInfo',
        Cell: formatUsingHardcodedLineBreaks,
      },
      {
        Header: 'Suborders',
        accessor: 'suborderInfo',
        Cell: formatUsingHardcodedLineBreaks,
      },
      {
        Header: 'Transactions',
        accessor: 'transactionInfo',
        Cell: formatUsingHardcodedLineBreaks,
      },
      {
        Header: 'Customer',
        accessor: 'customerInfo',
        Cell: formatUsingHardcodedLineBreaks,
      },
      {
        Header: 'Order ID',
        accessor: 'orderId',
        Cell: orderIdCell,
      },
    ],
    [],
  );

  const defaultColumn = React.useMemo(() => ({}), []);

  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state: {},
  } = useTable({ columns, data: tableOrders, defaultColumn, initialState: {} });

  const reset = () => {
    setBulkFile(null);
    setTableOrders([]);
    setBadOrderMessages([]);
    setShowFilePicker(true);
    setShowTable(false);
    setShowPreviewButton(false);
    setShowIngestOrderButton(false);
    setShowModalConfirmation(false);
    setShowResetModal(false);
    setShowResendMessage(false);
    setIsLoading(false);
    setIsPreview(true);
    setGrandTotal(0);
  };

  const onPreviewSubmit = (e) => {
    e.preventDefault();
    setShowFilePicker(false);
    ingestWithPreview();
  };

  const onResetData = (e) => {
    e.preventDefault();
    if (showResendMessage) {
      reset();
    } else {
      setShowResetModal(true);
    }
  };

  const onIngestOrderButton = (e) => {
    e.preventDefault();
    setShowModalConfirmation(true);
  };

  const onBulkFileInput = (files) => {
    setShowPreviewButton(true);
    setShowTable(false);
    setBulkFile(files);
  };

  const cancelIngestion = (e) => {
    e.preventDefault();
    setShowModalConfirmation(false);
  };

  const confirmIngestion = (e) => {
    e.preventDefault();
    setShowModalConfirmation(false);
    ingestCompletely();
  };

  const cancelReset = (e) => {
    e.preventDefault();
    setShowResetModal(false);
  };

  const confirmReset = (e) => {
    e.preventDefault();
    setShowResetModal(false);
    reset();
  };

  return (
    <>
      <PageHeader category="Orders" title="Upload Bulk Order Form" />
      <div className="container-fluid">
        {showFilePicker && (
          <div className="form-group">
            <FilePicker onChange={onBulkFileInput} placeholder="Attach Bulk Order Ingestion File here..." />
          </div>
        )}

        {/* {!showFilePicker && !isLoading &&
            <>
              <button type="submit" onClick={onResetData} className="btn btn-block btn-danger">Reset Data and Allow for Upload of New File</button>
            </>} */}

        {showPreviewButton && (
          <>
            <button type="submit" onClick={onPreviewSubmit} className="btn btn-block btn-primary">
              Upload File to Preview
            </button>
            <hr className="mt-5 mb-5" />
          </>
        )}

        <Modal title="Ingest Orders" onCancel={cancelIngestion} onConfirm={confirmIngestion} visible={showModalConfirmation}>
          <div>Are you sure you want to ingest the previewed orders?</div>
        </Modal>

        <Modal title="Reset Bulk Upload Data" onCancel={cancelReset} onConfirm={confirmReset} visible={showResetModal}>
          <div>Are you sure you want to reset your data?</div>
        </Modal>

        {showTable && (
          <>
            <div>Grand Total: {grandTotal}</div>
            <hr className="mt-5 mb-5" />
            {csvData && (
              <div>
                <CSVLink data={csvData} filename={`${requestId}-${isPreview ? 'preview' : 'ingested'}.csv`}>
                  Export to CSV
                </CSVLink>
                <hr className="mt-5 mb-5" />{' '}
              </div>
            )}
            {isPreview && (
              <>
                <h2>Bulk Order Ingestion Preview</h2>
                <br />
              </>
            )}
            {!isPreview && (
              <>
                <h2>Bulk Order Ingested Orders</h2>
                <br />
              </>
            )}
            <div className="">
              <table className="table table-sm table-hover table-nowrap">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                          <div>
                            <span>
                              <div>{column.render('Header')}</div>
                            </span>
                          </div>
                        </th>
                      ))}
                      <th></th>
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()} className="list font-size-base">
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr style={i == 0 ? {} : { borderTop: '2px solid' }} {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                        })}
                        <td></td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <hr className="mb-5" />
          </>
        )}

        {showTable && badOrderMessages.length > 0 && (
          <>
            <hr className="mt-5 mb-5" />
            <div>
              <p className="text-danger mt-4">Bad Order Input Information; the following will not be ingested:</p>
              <ul>
                {badOrderMessages.map((message) => (
                  <li className="text-danger" key={`bad-order-${badOrderMessages.indexOf(message)}`}>
                    {message}
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}

        {showIngestOrderButton && (
          <>
            <button type="submit" onClick={onIngestOrderButton} className="btn btn-block btn-primary">
              Ingest Orders
            </button>
          </>
        )}

        {showResendMessage && (
          <>
            <h2 className="text-danger">
              Was not able to process input file. Please ensure it is a .csv file of the appropriate format and has at least one order included and
              try again.
            </h2>
          </>
        )}

        {isLoading && (
          <>
            <h2>
              Processing file, please wait...
              <Spinner />
            </h2>
          </>
        )}

        {showFileProcessingMessage && (
          <>
            <Paragraph size={500}>
              File for bulk upload request ID{' '}
              <NavLink to={{ pathname: getUrlExtensionForRequestId(requestIdForMessage) }} target="_blank">
                {requestIdForMessage}
              </NavLink>{' '}
              is being {isPreview ? 'processed for preview' : 'ingested'}.
            </Paragraph>
            {requestId && <Paragraph size={500}>This page will attempt to reload when the request is ready.</Paragraph>}
            <Paragraph size={500}>An alert will also post in #{BULK_SLACK_CHANNEL}, in case you need to close this page.</Paragraph>
          </>
        )}

        {showFileProcessingMessage && displayRetryCount > 0 && requestId && (
          <>
            <hr className="mt-5 mb-5" />
            <Paragraph size={500}>
              Tried to get information {displayRetryCount} times already.{' '}
              {displayRetryCount < RETRY_LIMIT && <span> Will retry upto {RETRY_LIMIT} times. </span>}
            </Paragraph>
            {displayRetryCount < RETRY_LIMIT && <Spinner />}
          </>
        )}

        {showRequestIdDoesNotExist && (
          <>
            <Paragraph size={500}>Request ID {requestId} does not exist as the request file could not be found for it.</Paragraph>
            <Paragraph size={500}>Please contact the dev team if you think this is a mistake.</Paragraph>
          </>
        )}
      </div>
    </>
  );
};

export default BulkOrderForm;
