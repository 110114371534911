/* eslint-disable implicit-arrow-linebreak */
export const roles = {
  DELIVERY_MANAGER: 'DeliveryManager',
  CARE_ASSOCIATE: 'CareAssociate',
  CARE_MANAGER: 'CareManager',
  OPS_ASSOCIATE: 'OpsAssociate',
  OPS_MANAGER: 'OpsManager',
  CARE_OPS_MANAGER: 'CareOpsManager',
  INVENTORY_EDITOR: 'InventoryEditor',
  INVENTORY_VIEWER: 'InventoryViewer',
  OUTBOUNDDELIVERY_VIEWER: 'OutboundDeliveryViewer',
  OUTBOUNDDELIVERY_EDITOR: 'OutboundDeliveryEditor',
};

const isRole = (userGroups, roleName) => {
  if (!userGroups || !userGroups.length) {
    return false;
  }

  return userGroups.includes(roleName);
};

const isDeliveryManager = (userGroups) => isRole(userGroups, roles.DELIVERY_MANAGER);
const isCareAssociate = (userGroups) => isRole(userGroups, roles.CARE_ASSOCIATE);
const isCareManager = (userGroups) => isRole(userGroups, roles.CARE_MANAGER); // this one for payments
const isOpsAssociate = (userGroups) => isRole(userGroups, roles.OPS_ASSOCIATE);
const isOpsManager = (userGroups) => isRole(userGroups, roles.OPS_MANAGER);
const isCareOpsManager = (userGroups) => isRole(userGroups, roles.CARE_OPS_MANAGER);
const isInventoryEditor = (userGroups) => isRole(userGroups, roles.INVENTORY_EDITOR);
const isInventoryViewer = (userGroups) => isRole(userGroups, roles.INVENTORY_VIEWER);
const isOutboundDeliveryViewer = (userGroups) => isRole(userGroups, roles.OUTBOUNDDELIVERY_VIEWER);
const isOutboundDeliveryEditor = (userGroups) => isRole(userGroups, roles.OUTBOUNDDELIVERY_EDITOR);

export const roleChecks = {
  canUpdate: (userGroups) => isOpsManager(userGroups) || isCareAssociate(userGroups) || isCareManager(userGroups) || isOpsAssociate(userGroups),
  canFulfill: (userGroups) => isOpsManager(userGroups) || isCareAssociate(userGroups) || isCareManager(userGroups) || isOpsAssociate(userGroups),
  canRefulfillInGuidedFulfillment: (userGroups) => isOpsManager(userGroups),
  canDeliver: (userGroups) => isDeliveryManager(userGroups),
  // Order related Roles below
  canAddOrUpdateOrders: (userGroups) => isOpsManager(userGroups) || isCareAssociate(userGroups) || isCareManager(userGroups),
  canRefundOrIssueCreditsOnDirectlyToOrder: (userGroups) => isCareManager(userGroups),
  canUpdateSuborders: (userGroups) => isOpsManager(userGroups) || isCareAssociate(userGroups) || isCareManager(userGroups),
  canUpdateLineItems: (userGroups) => isOpsManager(userGroups) || isCareAssociate(userGroups) || isCareManager(userGroups),
  canUpgradeLineItems: (userGroups) => isCareOpsManager(userGroups),
  canUpdateCustomers: (userGroups) => isOpsManager(userGroups) || isCareAssociate(userGroups) || isCareManager(userGroups),
  canUpdateRecipients: (userGroups) => isOpsManager(userGroups) || isCareAssociate(userGroups) || isCareManager(userGroups),
  canUpdateOrderMessages: (userGroups) =>
    isOpsManager(userGroups) || isCareAssociate(userGroups) || isCareManager(userGroups) || isOpsAssociate(userGroups),
  canSelectNewProductOption: (userGroups) => isCareManager(userGroups) || isOpsManager(userGroups),
  canRunBulkSuborderAction: (userGroups) => isCareManager(userGroups) || isOpsManager(userGroups),
  canRunBulkBatchAction: (userGroups) => isCareManager(userGroups) || isOpsManager(userGroups),
  canFixExceptions: (userGroups) =>
    isCareAssociate(userGroups) || isCareManager(userGroups) || isCareOpsManager(userGroups) || isOpsManager(userGroups),
  // Delivery Roles Below
  canReprintDeliveryLabel: (userGroups) =>
    isOpsManager(userGroups) || isCareAssociate(userGroups) || isCareManager(userGroups) || isOpsAssociate(userGroups),
  canChangeDeliveryInDeliveryComponent: (userGroups) => isOpsManager(userGroups) || isCareAssociate(userGroups) || isCareManager(userGroups),
  canUpdateDeliveryInUpdateDeliveryComponent: (userGroups) => isCareManager(userGroups) || isOpsManager(userGroups),
  canSelectOtherDeliveryAreas: (userGroups) =>
    isDeliveryManager(userGroups) ||
    isCareManager(userGroups) ||
    isOpsAssociate(userGroups) ||
    isOpsManager(userGroups) ||
    isCareOpsManager(userGroups),
  canSetAnySuborderStatus: (userGroups) => isCareManager(userGroups) || isOpsManager(userGroups),
  canViewFulfillableProductsFromProductList: (userGroups) => isCareOpsManager(userGroups),
  canViewChargeOrderButton: (userGroups) => isCareAssociate(userGroups) || isCareManager(userGroups),
  canChangeAmountWhileChargingOrder: (userGroups) => isCareManager(userGroups),
  canBulkRemoveException: (userGroups) => isOpsManager(userGroups) || isCareOpsManager(userGroups),
  canRedistributeSuborders: (userGroups) => isCareOpsManager(userGroups),
  canAddAdditionalCareResolutionInORW: (userGroups) => isCareManager(userGroups),
  canOverrideAddressesInRecipient: (userGroups) => isCareManager(userGroups) || isCareOpsManager(userGroups),
  // Product roles below
  canEditInventory: (userGroups) => isInventoryEditor(userGroups),
  canViewInventory: (userGroups) => isInventoryEditor(userGroups) || isInventoryViewer(userGroups),
  canViewOutboundDelivery: (userGroups) => isOutboundDeliveryViewer(userGroups),
  canEditOutboundDelivery: (userGroups) => isOutboundDeliveryEditor(userGroups),
};
