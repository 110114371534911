import { Button, DownloadIcon } from 'evergreen-ui';
import { DateTime } from 'luxon';
import React from 'react';
import { CSVLink } from 'react-csv';
import PropTypes from 'prop-types';

const ExportDataToCsv = ({ filePrefix, customButton = null, csvData }) => {
  return csvData && csvData.length ? (
    <CSVLink filename={`${filePrefix} ${DateTime.now().toISO()}.csv`} data={csvData}>
      {customButton || (
        <Button iconAfter={DownloadIcon} appearance="primary">
          Export
        </Button>
      )}
    </CSVLink>
  ) : (
    <div />
  );
};

ExportDataToCsv.propTypes = {
  filePrefix: PropTypes.string.isRequired,
  customButton: PropTypes.element,
  csvData: PropTypes.array.isRequired,
};

ExportDataToCsv.defaultProps = {
  customButton: null,
};

export default ExportDataToCsv;
