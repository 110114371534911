import React from 'react';
import { NavLink } from 'react-router-dom';
import { Spinner as EvergreenSpinner, Pane } from 'evergreen-ui';
import PropTypes from 'prop-types';
import * as Icon from 'react-bootstrap-icons';
import { useIsFetching } from 'react-query';

import { roleChecks } from '../../utilities/Role';
import { ReactComponent as Logo } from '../../img/urbanstems-icon.svg';
import { ReactComponent as ProductIcon } from '../../img/icons/navbar/products.svg';
import { ReactComponent as OutboundDeliveryIcon } from '../../img/icons/navbar/outbound-delivery.svg';
import { ENVIRONMENT_NAME, INVENTORY_TABLE_FEATURE_FLAG, DELIVERYAREA_TABLE_FEATURE_FLAG, DELIVERYWINDOWS_TABLE_FEATURE_FLAG } from '../../config';

import Spinner from '../common/Spinner';

const NavBar = ({
  urlPathname,
  fcId,
  loggedInUser,
  signOutFunction,
  fetchedSuborderExceptions,
  userGroups,
  suborderExceptions,
  suborderExceptionsForFc,
}) => {
  const NAVBAR_CLASSES = {
    ICON_CLASS: 'col-sm-2 pr-1 ml-2',
    HEADER_CLASS: 'navbar-nav col-sm-9 pl-2 pr-0',
    SUBHEADER_CLASS: 'nav-subtext-items g-0',
  };

  return (
    <nav className="navbar navbar-vertical navbar-vertical-sm fixed-left navbar-expand-md navbar-light" id="sidebarSmall">
      <div className="container-fluid">
        <button
          className="navbar-toggler collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#sidebarSmallCollapse"
          aria-controls="sidebarSmallCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <GlobalLoadingIndicator />

        <div className="navbar-collapse collapse nav-text pr-0" id="sidebarSmallCollapse">
          <hr className="mt-0 mb-3 navbar-divider" />

          {roleChecks.canAddOrUpdateOrders(userGroups) && (
            <>
              <div className="row pb-1">
                <Icon.FolderPlus className={NAVBAR_CLASSES.ICON_CLASS} />

                <div className={NAVBAR_CLASSES.HEADER_CLASS}>
                  <h4 className="nav-text">Create</h4>
                </div>
                <ul className={NAVBAR_CLASSES.SUBHEADER_CLASS}>
                  <li className="navbar-subtext-list-item">
                    <NavLink
                      className={`nav-text nav-item-hover ${urlPathname === '/orders/new' ? 'nav-item-selected' : ''}`}
                      exact
                      to="/orders/new"
                      id="sidebarCreateOrder"
                      title="Create New Order"
                    >
                      <span>New Order</span>
                    </NavLink>
                  </li>
                  <li className="navbar-subtext-list-item">
                    <NavLink
                      className={`nav-text nav-item-hover ${urlPathname === '/orders/bulk' ? 'nav-item-selected' : ''}`}
                      exact
                      to="/orders/bulk"
                      id="sidebarUploadBulkOrder"
                      title="Bulk Orders"
                    >
                      <span>Bulk Orders</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
              <hr className="mt-0 mb-3 navbar-divider" />
            </>
          )}

          <div className="row pb-1">
            <Icon.Binoculars className={NAVBAR_CLASSES.ICON_CLASS} />
            <div className={NAVBAR_CLASSES.HEADER_CLASS}>
              <h4 className="nav-text">Find</h4>
            </div>
            <ul className={NAVBAR_CLASSES.SUBHEADER_CLASS}>
              <li className="navbar-subtext-list-item">
                <NavLink
                  className={`nav-text nav-item-hover ${urlPathname === '/orders' ? 'nav-item-selected' : ''}`}
                  exact
                  to="/orders"
                  id="sidebarOrderSearch"
                  title="Find Orders"
                >
                  <span>Orders</span>
                </NavLink>
              </li>
            </ul>
          </div>
          <hr className="mt-0 mb-3 navbar-divider" />

          {roleChecks.canFixExceptions(userGroups) && (
            <>
              <div className="row pb-1">
                <Icon.ExclamationTriangle className={NAVBAR_CLASSES.ICON_CLASS} />
                <div className={NAVBAR_CLASSES.HEADER_CLASS}>
                  <h4 className="nav-text">Fix Exceptions</h4>
                </div>
                <ul className={NAVBAR_CLASSES.SUBHEADER_CLASS}>
                  {userGroups.includes('FCAll') && (
                    <li className="navbar-subtext-list-item">
                      <NavLink
                        className={`nav-text nav-exceptions nav-item-hover ${urlPathname === '/exceptions' ? 'nav-item-selected' : ''}`}
                        exact
                        to="/exceptions"
                        id="sidebarExceptions"
                        title="Fix Exceptions"
                      >
                        <span>
                          All
                          {fetchedSuborderExceptions ? (
                            ` (${suborderExceptions.length})`
                          ) : (
                            <Pane style={{ display: 'inline-block' }}>
                              <EvergreenSpinner styles={{ marginLeft: 4, display: 'inline-block' }} size={12} />
                            </Pane>
                          )}
                        </span>
                      </NavLink>
                    </li>
                  )}
                  <li className="navbar-subtext-list-item">
                    <NavLink
                      className={`nav-text nav-exceptions nav-item-hover ${urlPathname === `/exceptions/${fcId}` ? 'nav-item-selected' : ''}`}
                      exact
                      to={`/exceptions/${fcId}`}
                      id="sidebarExceptions"
                      title="Fix Exceptions in FC"
                    >
                      <span>
                        In FC
                        {fetchedSuborderExceptions ? (
                          ` (${suborderExceptionsForFc.length})`
                        ) : (
                          <Pane style={{ display: 'inline-block' }}>
                            <EvergreenSpinner styles={{ marginLeft: 4 }} size={12} />
                          </Pane>
                        )}
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
              <hr className="my-3 navbar-divider" />
            </>
          )}

          {roleChecks.canFulfill(userGroups) && (
            <>
              <div className="row pb-1">
                <Icon.BoxSeam className={NAVBAR_CLASSES.ICON_CLASS} />
                <div className={NAVBAR_CLASSES.HEADER_CLASS}>
                  <h4 className="nav-text">Fulfill</h4>
                </div>
                <ul className={NAVBAR_CLASSES.SUBHEADER_CLASS}>
                  <li className="navbar-subtext-list-item">
                    <NavLink
                      className={`nav-text nav-item-hover ${urlPathname === '/batches' ? 'nav-item-selected' : ''}`}
                      exact
                      to="/batches"
                      id="sidebarBatch"
                      title="Select &amp; Batch"
                    >
                      <span>Select &amp; Batch</span>
                    </NavLink>
                  </li>
                  <li className="navbar-subtext-list-item">
                    <NavLink
                      className={`nav-text nav-item-hover ${urlPathname === '/fulfill' ? 'nav-item-selected' : ''}`}
                      exact
                      to="/fulfill"
                      id="sidebarFulfill"
                      title="Fulfill"
                    >
                      <span>Fulfill</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
              <hr className="my-3 navbar-divider" />
            </>
          )}

          {roleChecks.canViewInventory(userGroups) && INVENTORY_TABLE_FEATURE_FLAG && (
            <>
              <div className="row pb-1">
                <ProductIcon className={NAVBAR_CLASSES.ICON_CLASS} />
                <div className={NAVBAR_CLASSES.HEADER_CLASS}>
                  <h4 className="nav-text">Products</h4>
                </div>
                <ul className={NAVBAR_CLASSES.SUBHEADER_CLASS}>
                  <li className="navbar-subtext-list-item">
                    <NavLink
                      className={`nav-text nav-item-hover ${urlPathname === '/inventory' ? 'nav-item-selected' : ''}`}
                      exact
                      to="/inventory"
                      id="sidebarBatch"
                      title="Inventory"
                    >
                      <span>Inventory</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
              <hr className="my-3 navbar-divider" />
            </>
          )}

          {roleChecks.canViewOutboundDelivery(userGroups) && (
          <>
            <div className="row pb-1">
              <OutboundDeliveryIcon className={NAVBAR_CLASSES.ICON_CLASS} />
              <div className={NAVBAR_CLASSES.HEADER_CLASS}>
                <h4 className="nav-text">Outbound Delivery</h4>
              </div>
              <ul className={NAVBAR_CLASSES.SUBHEADER_CLASS}>
                {DELIVERYAREA_TABLE_FEATURE_FLAG && (
                <li className="navbar-subtext-list-item">
                  <NavLink
                    className={`nav-text nav-item-hover ${urlPathname === '/delivery-area' ? 'nav-item-selected' : ''}`}
                    exact
                    to="/delivery-area"
                    id="sidebarBatch"
                    title="Delivery Area"
                  >
                    <span>Area</span>
                  </NavLink>
                </li>
                )}
                {DELIVERYWINDOWS_TABLE_FEATURE_FLAG && (
                <li className="navbar-subtext-list-item">
                  <NavLink
                    className={`nav-text nav-item-hover ${urlPathname === '/delivery-windows' ? 'nav-item-selected' : ''}`}
                    exact
                    to="/delivery-windows"
                    id="sidebarBatch"
                    title="Delivery Window"
                  >
                    <span>Windows</span>
                  </NavLink>
                </li>
                )}
              </ul>
            </div>
            <hr className="my-3 navbar-divider" />
          </>
          )}

          <div className="mt-auto" />

          <div className={NAVBAR_CLASSES.ICON_CLASS} id="sidebarSmallUser" style={{ position: 'absolute', bottom: 20 }}>
            <div className="dropright">
              <div className="nav-text" style={{ width: '135px', textOverflow: 'ellipsis', whitespace: 'nowrap', overflow: 'hidden' }}>
                {loggedInUser.email}
              </div>

              <div className="mt-2" style={{ cursor: 'pointer' }} role="button" tabIndex={0} onKeyDown={signOutFunction} onClick={signOutFunction}>
                <Icon.BoxArrowRight size="1.2em" />
                Sign out
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

const GlobalLoadingIndicator = () => {
  const renderLogo = () => {
    return (
      <>
        <a className="navbar-brand pl-1" href="/">
          <Logo className="mx-auto navbar-brand-img ml-2" style={{ marginTop: '-8px' }} alt="..." fill="#203a56" /> Dash
        </a>
        {ENVIRONMENT_NAME !== 'prod' && (
          <h5 className="pl-1" style={{ color: '#203a56' }}>
            {ENVIRONMENT_NAME} env
          </h5>
        )}
      </>
    );
  };
  const isFetching = useIsFetching();

  return isFetching ? <Spinner /> : renderLogo();
};

NavBar.propTypes = {
  urlPathname: PropTypes.string,
  fcId: PropTypes.string,
  loggedInUser: PropTypes.object.isRequired,
  signOutFunction: PropTypes.func.isRequired,
  fetchedSuborderExceptions: PropTypes.array,
  userGroups: PropTypes.arrayOf(PropTypes.string),
  suborderExceptions: PropTypes.array,
  suborderExceptionsForFc: PropTypes.array,
};

NavBar.defaultProps = {
  userGroups: [],
  fetchedSuborderExceptions: [],
  suborderExceptions: [],
  suborderExceptionsForFc: [],
  urlPathname: '',
  fcId: '',
};

export default NavBar;
