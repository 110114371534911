import React, { useState } from 'react';
import StepWizard from 'react-step-wizard';
import Nav from './Nav';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Summary from './Summary';

const SuborderIssueWizard = ({ order, suborder, closeModal, allowRedelivery }) => {
  const [state, updateState] = useState({
    issueInEntirePackage: null,
    lineItemsWithIssue: [],
    suborderLevelReasons: [],
    needsRefundOrCredits: false,
    needsRedelivery: false,
    creditAmountToCustomer: 0.0,
    creditAmountToRecipient: 0.0,
    creditCustomerEmail: '',
    creditRecipientEmail: '',
    refundAmount: 0.0,
    redeliverySuborder: null,
  });

  const [stepThreeState, setStepThreeState] = useState({
    issuingToCustomer: false,
    issuingToRecipient: false,
    issuingAccountCredits: false,
    issuingRefund: false,
    lineItemTotalWasSelected: false,
    grandTotalWasSelected: false,
    customPercentageWasSelected: false,
    customDollarAmountWasSelected: false,
    percentageAmount: 0,
    amountForEntireStep: 0,
  });

  const [stepFourState, setStepFourState] = useState({
    selectedSkus: [],
    skuToUpgradeMap: {},
    additionalLineItems: [],
    editingRecipient: false,
    productOptions: [],
    deliveryDate: null,
    showBadAddressErrorMessage: false,
  });

  const updateAction = (payload) => updateState({ ...state, ...payload });

  const updateStepThreeState = (payload) => setStepThreeState({ ...stepThreeState, ...payload });

  const updateStepFourState = (payload) => setStepFourState({ ...stepFourState, ...payload });

  return (
    <StepWizard isLazyMount transitions={{}} nav={<Nav state={state} closeModal={closeModal} />}>
      <Step1
        order={order}
        suborder={suborder}
        state={state}
        stepName="Select"
        updateAction={updateAction}
        closeModal={closeModal}
        updateStepFourState={updateStepFourState}
      />
      <Step2
        suborder={suborder}
        state={state}
        stepName="Choose Remedy"
        updateAction={updateAction}
        updateStepThreeState={updateStepThreeState}
        allowRedelivery={allowRedelivery}
      />
      {state.needsRefundOrCredits && (
        <Step3
          suborder={suborder}
          state={state}
          stepName="Refund/Credit"
          updateAction={updateAction}
          order={order}
          stepThreeState={stepThreeState}
          updateStepThreeState={updateStepThreeState}
        />
      )}
      {state.needsRedelivery && (
        <Step4
          suborder={suborder}
          state={state}
          stepName="Redeliver"
          updateAction={updateAction}
          stepFourState={stepFourState}
          updateStepFourState={updateStepFourState}
        />
      )}
      <Summary order={order} suborder={suborder} state={state} stepName="Summary" closeModal={closeModal} stepThreeState={stepThreeState} />
    </StepWizard>
  );
};

export default SuborderIssueWizard;
