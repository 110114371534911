import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import SearchInput from './common/forms/SearchInput';
import PageHeader from './nav/PageHeader';

const Order = () => {
  const emailRegex = new RegExp(
    [
      '^(([^<>()[\\]\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\.,;:\\s@"]+)*)',
      '|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.',
      '[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+',
      '[a-zA-Z]{2,}))$',
    ].join(''),
  );

  const [orderId, setOrderId] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const history = useHistory();

  const { handleSubmit, register, errors } = useForm();
  const { handleSubmit: submitCustomerForm, register: registerCustomerForm, errors: customerFormErrors } = useForm();

  const fetchOrder = async () => {
    history.push(`/orders/${orderId}`);
  };

  const fetchCustomer = async () => {
    history.push(`/customers/${customerEmail}`);
  };

  const onOrderIdChange = (e) => setOrderId(e.target.value);
  const onCustomerEmailChange = (e) => setCustomerEmail(e.target.value);

  return (
    <>
      <PageHeader category="Care" title="Find Orders" />
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-6">
            <form className="mb-4" onSubmit={handleSubmit(fetchOrder)}>
              <SearchInput
                label="By Order Id"
                name="orderId"
                searchLabel="Find"
                placeholder="Order id"
                register={register({ required: 'required' })}
                errors={errors}
                autoFocus
                value={orderId}
                onChange={onOrderIdChange}
              />
            </form>
          </div>
          <div className="col-12 col-sm-6">
            <form className="mb-4" onSubmit={submitCustomerForm(fetchCustomer)}>
              <SearchInput
                label="By Customer Email"
                name="customerEmail"
                searchLabel="Find"
                placeholder="Customer email address"
                register={registerCustomerForm({
                  pattern: {
                    value: emailRegex,
                    message: 'invalid email',
                  },
                  required: 'required',
                })}
                errors={customerFormErrors}
                value={customerEmail}
                onChange={onCustomerEmailChange}
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Order;
