import React, { useEffect, useState } from 'react';
import { Dialog, toaster } from 'evergreen-ui';
import BraintreeDropIn from '../integration/BraintreeDropIn';
import { getBraintreeToken, chargeBraintreePayment } from '../../services/OrderManagementService';
import { getOutstandingBalance, outstandingBalanceDisplay } from './helpers/getOutstandingBalance';

const ChargeOrderForm = ({ order, showChargeOrder, setShowChargeOrder, queryCache, canManuallySetChargeAmount }) => {
  const grandTotal = order.paymentSummary.orderTotal;
  const outstandingBalance = getOutstandingBalance({ paymentSummary: order.paymentSummary });

  const defaultChargeableAmount = outstandingBalance ?? 0;

  const [chargeAmount, setChargeAmount] = useState(defaultChargeableAmount);
  const [braintreeToken, setBraintreeToken] = useState(null);
  const [paymentNonce, setPaymentNonce] = useState(null);
  const [failedMessage, setFailedMessage] = useState(null);

  const getAndSetBraintreeToken = async () => {
    const anyBraintreeTransaction = order.transactions
      ? order.transactions.find((t) => t.paymentType === 'Braintree' && t.transactionId !== 'PENDING')
      : null;
    const transactionId = anyBraintreeTransaction ? anyBraintreeTransaction.transactionId : null;
    const btTokenResponse = await getBraintreeToken(transactionId);
    setBraintreeToken(btTokenResponse.token);
  };

  useEffect(() => {
    if (!braintreeToken) {
      getAndSetBraintreeToken();
    }
  }, []);

  const onPaymentMethodConfirmation = (paymentNonce) => {
    setFailedMessage(null);
    setPaymentNonce(paymentNonce);
  };

  const handleChargeOrder = async () => {
    const responseForCharging = await chargeBraintreePayment(chargeAmount, paymentNonce, order.orderId);
    if (
      responseForCharging.success &&
      responseForCharging.data &&
      responseForCharging.data.transactionResult &&
      responseForCharging.data.transactionResult.success
    ) {
      setFailedMessage(null);
      queryCache.invalidateQueries(['orders', order.orderId]);
      setShowChargeOrder(false);
      toaster.success(`Charged order ${order.orderId} a total of $${chargeAmount} successfully. This will now show up in the transaction summary.`);
    } else {
      setFailedMessage('Could not register payment. Please try again or change the payment method.');
    }
  };

  const ensureNumberBetween = (val, min, max) => parseInt(val) && Number(val) > min && Number(val) <= max;

  const onAmountInputChange = (event) => {
    const { value } = event.target;
    let asNum = Number(value);
    if (asNum > grandTotal) {
      asNum = grandTotal;
    }
    if (asNum <= 0) {
      asNum = 0.01;
    }
    setChargeAmount(asNum);
  };

  return (
    <Dialog
      isShown={showChargeOrder}
      title="Charge Order"
      onCloseComplete={() => {
        setChargeAmount(defaultChargeableAmount);
        setFailedMessage(null);
        setShowChargeOrder(false);
        setPaymentNonce(null);
      }}
      confirmLabel="Confirm"
      onConfirm={handleChargeOrder}
      isConfirmDisabled={!(chargeAmount && chargeAmount > 0 && paymentNonce)}
      shouldCloseOnOverlayClick={false}
    >
      <div className="row">
        <div className="col-auto">
          Grand Total : ${grandTotal} <br />
          Charge Amount : ${chargeAmount.toString()}
        </div>
      </div>
      {chargeAmount !== outstandingBalance && (
        <div className="row">
          <div className="mt-4 alert alert-info" role="alert">
            Your charge amount (${chargeAmount}) differs from the outstanding balance ({outstandingBalanceDisplay(outstandingBalance)}).
          </div>
        </div>
      )}
      {chargeAmount >= 1 && (
        <div className="row">
          <BraintreeDropIn braintreeToken={braintreeToken} show={braintreeToken !== null} onPaymentMethodConfirmation={onPaymentMethodConfirmation} />
        </div>
      )}
      {failedMessage && (
        <div className="row">
          <div className="mt-4 alert alert-danger" role="alert">
            {failedMessage}
          </div>
        </div>
      )}
      {chargeAmount <= 0 && (
        <div className="row">
          <div className="mt-4 alert alert-danger" role="alert">
            The charged amount is currently below the minimum of $0.01 so it cannot be charged.
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default ChargeOrderForm;
