import React from 'react';
import { Dialog } from 'evergreen-ui';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-cycle
import SuborderIssueWizard from './SuborderIssueWizard';
import { LARGER_DIALOG_COMMON_PROPS } from '../common/constants';

const SuborderIssueWizardModal = ({ suborder, visible, closeModal, order, allowRedelivery }) => {
  return (
    <Dialog
      isShown={visible}
      overlayProps={{ style: { paddingLeft: '150px' } }}
      {...LARGER_DIALOG_COMMON_PROPS}
      topOffset="10px"
      onCloseComplete={closeModal}
      hasFooter={false}
      shouldCloseOnOverlayClick={false}
      hasHeader={false}
      preventBodyScrolling
    >
      <SuborderIssueWizard suborder={suborder} closeModal={closeModal} order={order} allowRedelivery={allowRedelivery} />
    </Dialog>
  );
};

SuborderIssueWizardModal.propTypes = {
  suborder: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  allowRedelivery: PropTypes.bool.isRequired,
};

export default SuborderIssueWizardModal;
