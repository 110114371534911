import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTable, useGroupBy, useSortBy, useExpanded } from 'react-table';
import { LookupContext } from '../../../context/lookupContext';

const SubordersByTransportMode = ({ suborders, date, fcId }) => {
  const [data, setData] = React.useState([]);

  const { deliveryAreas } = useContext(LookupContext);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {

    const getIdealTransportModeForWhenDeliveryAreaToggleIsOff = (transportMode) => {
      if (transportMode.toLowerCase().includes('express')) {
        return 'Express';
      } else if (transportMode.toLowerCase().includes('ground')) {
        return 'Ground';
      }
      return transportMode;
    };

    const setDataWithCapacity = async () => {

      let data = [];
      for (const transportMode of Object.values(transportModeGroups)) {
        data.push({
          transportMode: transportMode.transportMode,
          type: transportMode.type,
          count: transportMode.suborders.size,
        });
      }

      setData(data);
    };

    let transportModeGroups = {};

    suborders.forEach(s => {
      const { delivery } = s;
      const { areaId } = delivery;

      const transportMode = deliveryAreas[areaId] ? deliveryAreas[areaId].transportMode : `Unknown transport mode: Delivery Area ${areaId}`;

      if (transportModeGroups[transportMode]) {
        transportModeGroups[transportMode].suborders.add(s.suborderId);
      } else {
        transportModeGroups[transportMode] = { suborders: new Set([s.suborderId]) };
      }

      const type = capitalizeFirstLetter(getIdealTransportModeForWhenDeliveryAreaToggleIsOff(transportMode));
      transportModeGroups[transportMode].type = type;
      transportModeGroups[transportMode].transportMode = transportMode;
    });

    setDataWithCapacity();

  }, [deliveryAreas, suborders, date, fcId]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Transport Mode',
        accessor: 'transportMode',
        canGroupBy: false,
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Count',
        accessor: 'count',
        canGroupBy: false,
        // Aggregate the sum of all orders
        aggregate: (leafValues) => leafValues.reduce((acc, next) => acc + Number(next), 0),
        Aggregated: ({ value }) => `${value} (total)`,
      },
    ],
    []
  );

  const defaultColumn = React.useMemo(() => ({}), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    expandAllSubComponents,
    toggleAllRowsExpanded,
    state: { groupBy, expanded },
  } = useTable({
    columns,
    data,
    defaultColumn,
    autoResetExpanded: false,
    initialState: {
      groupBy: ['type'],
      sortBy: [
        {
          id: 'type',
          desc: false,
        },
        {
          id: 'transportMode',
          desc: false,
        },
      ],
    },
  },
  useGroupBy,
  useSortBy,
  useExpanded
  );

  return (<>
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title">
          Suborders by Transport Mode
        </h4>
      </div>
      <div className="table-responsive mb-0">
        <table {...getTableProps()} className="table table-sm table-hover card-table">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>
                    <div className="d-flex align-items-center">
                      <span>
                        {column.render('Header')}
                      </span>
                    </div>
                  </th>

                ))}
                <th></th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="list font-size-base">
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, i) => {
                    //print cell and row
                    return (
                      <td key={i}>
                        {cell.isGrouped ? (
                          // If it's a grouped cell, add an expander and row count
                          <>
                            <div> {cell.render('Cell')} ({row.subRows.length}) </div>
                            <span className="btn btn-info btn-sm ml-2 py-0" {...row.getToggleRowExpandedProps()}>
                              {row.isExpanded ? 'Hide expanded' : 'Expand'}
                            </span>
                          </>
                        ) : cell.isAggregated ? (
                          // If the cell is aggregated, use the Aggregated
                          cell.render('Aggregated')
                        ) : cell.isPlaceholder ? null : (
                          // Otherwise, just render the regular cell
                          cell.render('Cell')
                        )}
                      </td>
                    );
                  })}
                  <td></td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  </>);
};

SubordersByTransportMode.propTypes = {
  suborders: PropTypes.array,
};

export default SubordersByTransportMode;
