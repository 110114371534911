import axios from 'axios';
import React, { useContext, useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Dialog } from 'evergreen-ui';
import { ProgressBar, Step } from 'react-step-progress-bar';
import { completeBatch } from '../../utilities/BatchActions';
import { ORDER_MANAGEMENT_SERVICE_BASE_URL } from '../../config';
import { AuthContext } from '../../context/authContext';
import BatchSuborder from './BatchSuborder';
import 'react-step-progress-bar/styles.css';
import sortSubordersByBatchIndex from '../batching/helpers/sortSubordersByBatchIndex';
import { getSuborderStatusMap, updateBatchStatus } from '../../services/OrderManagementService';
import { roleChecks } from '../../utilities/Role';

const NEGATIVE_INDEX_FOR_BATCH = -1;

const BatchSuborders = () => {
  const { batchId } = useParams();
  const [successMessage, setSuccessMessage] = useState('');
  const [batch, setBatch] = useState(null);
  const [completed, setCompleted] = useState(false);
  const [isFetchingBatch, setIsFetchingBatch] = useState(false);
  const [currentBatchSuborderIndex, setCurrentBatchSuborderIndex] = useState(NEGATIVE_INDEX_FOR_BATCH);
  const [currentBatchMaxSuborderIndex, setCurrentBatchMaxSuborderIndex] = useState(NEGATIVE_INDEX_FOR_BATCH);
  const [haveSetCurrentIndexOnce, setHaveSetCurrentIndexOnce] = useState(false);

  const [loading, setLoading] = useState(true);

  const showRefulfillModal = batch && batch.status === 'Completed';

  const { userGroups } = useContext(AuthContext);
  const canRefulfill = roleChecks.canRefulfillInGuidedFulfillment(userGroups);
  const history = useHistory();

  const fetchBatch = async () => {
    if (!isFetchingBatch) {
      setIsFetchingBatch(true);
      const { data } = await axios.get(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/batches/${batchId}`);
      const batchInResponse = data.data.batch;

      sortSubordersByBatchIndex(batchInResponse);

      setIsFetchingBatch(false);
      setCompleted(false);
      setBatch(batchInResponse);
    }
  };

  const updateBatchToBeAtTable = async () => updateBatchStatus(batchId, 'AtTable');

  const initActions = () => {
    const setCurrentIndex = () => {
      setHaveSetCurrentIndexOnce(true);
      // If picking up a batch in progress find the last suborder with status SuborderPrinted
      let indexToNavigateTo = 0;
      const suborderStatusMap = getSuborderStatusMap();
      const packedStatusValue = suborderStatusMap.Packed;

      for (let suborderIndex = 0; suborderIndex < batch.suborders.length; suborderIndex += 1) {
        const suborder = batch.suborders[suborderIndex];

        const suborderStatusValue = suborderStatusMap[suborder.status];
        if (suborderStatusValue < packedStatusValue && !suborder.inException) {
          indexToNavigateTo = suborderIndex;
          break;
        }
      }

      setCurrentBatchSuborderIndex(indexToNavigateTo);
      setCurrentBatchMaxSuborderIndex(indexToNavigateTo);

      if (indexToNavigateTo <= batch.suborders.length - 1) {
        updateBatchToBeAtTable();
      }
    };

    if (batch) {
      setLoading(false);

      if (!showRefulfillModal && !haveSetCurrentIndexOnce) {
        setCurrentIndex();
      }
    }
  };

  const refulfillActions = async () => {
    setLoading(true);
    await updateBatchToBeAtTable();
    await fetchBatch();
    initActions();
  };

  const batchSuborderDone = () => {
    fetchBatch();
    const newIndex = currentBatchSuborderIndex + 1;
    setCurrentBatchSuborderIndex(newIndex);
    if (newIndex > currentBatchMaxSuborderIndex) {
      setCurrentBatchMaxSuborderIndex(newIndex);
    }

    if (newIndex === batch.suborders.length) {
      setCompleted(true);
    }
  };

  useEffect(() => {
    if (!batch && !completed) {
      fetchBatch();
    }
  }, [batch]);

  const { status, error } = useQuery(['batch', batchId], fetchBatch, {
    retry: false,
  });

  useEffect(initActions, [batch]);

  if (!loading && batch.status === 'Completed') {
    return (
      <Dialog
        isShown={batch && showRefulfillModal}
        title="Batch Is Already Fulfilled And Completed"
        onCloseComplete={() => {
          history.goBack();
        }}
        shouldCloseOnEscapePress={false}
        shouldCloseOnOverlayClick={false}
        hasFooter={false}
      >
        <div>
          <div className="mb-4">Batch Id {batchId} has already been fulfilled and completed</div>
          {canRefulfill && <div className="mb-4">Confirm you want to re-fulfill the batch?</div>}
          <div className="mb-4">
            {canRefulfill && (
              <button className="btn btn-primary mr-2" type="submit" onClick={refulfillActions}>
                Confirm
              </button>
            )}
            <button
              className="btn btn-secondary"
              type="button"
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Dialog>
    );
  }

  if (status === 'loading') {
    return (
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="card bg-transparent">
              <div className="card-body text-center">
                <p className="text-muted">Loading batch...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (status === 'error') {
    return (
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="card bg-transparent">
              <div className="card-body text-center">
                <p className="text-muted">Error: {error.message}</p>
                <Link to="/fulfill" className="btn btn-primary">
                  Back
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!batch || !batch.suborders || !batch.suborders.length) {
    return (
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12">
            {successMessage && <div className="alert alert-success">{successMessage}</div>}
            <Link to="/fulfill" className="btn btn-primary">
              Next Batch
            </Link>
          </div>
        </div>
      </div>
    );
  }

  if (currentBatchSuborderIndex === batch.suborders.length) {
    completeBatch(batchId, null).then(() => {
      setCompleted(true);
      setBatch(null);
      setSuccessMessage('Batch completed');
      setTimeout(() => setSuccessMessage(null), 3000);
    });
    return (
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12">
            {successMessage && <div className="alert alert-success">{successMessage}</div>}
            <Link to="/fulfill" className="btn btn-primary">
              Next Batch
            </Link>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="container-fluid">
      <header className="footer w-100 py-3 bg-light">
        <div className="container-fluid">
          <ProgressBar percent={(currentBatchSuborderIndex / (batch.suborders.length - 1)) * 100}>
            {batch.suborders.map((suborder) => (
              <Step key={suborder.suborderId}>
                {({ accomplished, index }) => (
                  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                  <div
                    className={`indexedStep ${accomplished || currentBatchMaxSuborderIndex > index ? 'accomplished' : null}`}
                    onClick={currentBatchMaxSuborderIndex >= index ? () => setCurrentBatchSuborderIndex(index) : () => {}}
                    onKeyDown={() => {}}
                    style={{ cursor: 'pointer' }}
                  >
                    {index + 1}
                  </div>
                )}
              </Step>
            ))}
          </ProgressBar>
        </div>
      </header>
      <div className="row justify-content-center">
        {currentBatchMaxSuborderIndex > NEGATIVE_INDEX_FOR_BATCH && currentBatchSuborderIndex > NEGATIVE_INDEX_FOR_BATCH && (
          <BatchSuborder
            suborder={batch.suborders[currentBatchSuborderIndex]}
            slotMapping={batch.subordersMap[batch.suborders[currentBatchSuborderIndex].suborderId]}
            onDone={batchSuborderDone}
            isFinalSuborderInBatch={currentBatchSuborderIndex === batch.suborders.length - 1}
          />
        )}
      </div>
    </div>
  );
};

export default BatchSuborders;
