export const tableSlots = {
  'FLRL-V-00016': 'V1', // Glass Vase
  'FLRL-V-00015': 'V2', // Large Glass Vase
  'FLRL-V-00001': 'V3', // The Gold Tegan Vase
  'FLRL-V-00006': 'V4', // White Tegan Vase
  'FLRL-V-00008': 'V5', // The Charcoal Vase
  'FLRL-V-00012': 'V6', // The White Monaco Vase
  'FLRL-V-00051': 'V7', // Tobago Vase
  'FLRL-V-00014': 'V8', // Zara Vase
  'FLRL-V-00031': 'V9', // White Hobnail Vase
  'FLRL-V-00029': 'V10', // Large Brooklyn Vase
  'FLRL-V-00028': 'V11', // Small Brooklyn Vase
  'FLRL-V-00049': 'V12', // The West Palm Vase
  'FLRL-V-00025': 'V13', // Lincoln Vase
  'FLRL-V-00022': 'V14', // Habitat Vase
  'FLRL-V-00048': 'V16', // The Palm Vase
  'FLRL-V-00053': 'V17', // Speckled Stoneware Copen Vase
  'FLRL-V-00046': 'V18', // Large Eleanor Bud Vase
  'FLRL-V-00045': 'V19', // Medium Eleanor Bud Vase
  'FLRL-V-00044': 'V20', // Small Eleanor Bud Vase
  'FLRL-V-00055': 'V21', // Everest Vase
  'FLRL-V-00056': 'V22', // Loren Glass Vase
  'FLRL-V-00054': 'V23', // Hannover vase
  'FLRL-V-00058': 'V24', // Potion Vase
  'FLRL-V-00040': 'V25', // The Grey Modern Kendall Vase
  'FLRL-V-00039': 'V26', // Small Cafu Vase
  'FLRL-V-00041': 'V27', // Green Roseland Vase
  'FLRL-V-00026': 'V28', // DIY Bud Vase Large
  'FLRL-V-00027': 'V29', // DIY Bud Vase Medium
  'FLRL-V-00057': 'V30', // Pink Roseland Vase
  'FLRL-V-00024': 'V31', // The Kino Vase
  'FLRL-V-00063': 'V32', // Prosper Votive Vase
  'FLRL-V-00052': 'V33', // White Modern Vase
  'FLRL-V-00062': 'V34', // Pink Kendall Vase
  'FLRL-V-00018': 'V35', // The Dusk Vase
  'FLRL-V-00071': 'V36', // Chromo Vase
  'FLRL-V-00073': 'V37', // Cocktail Carafe Vase
  'FLRL-V-00070': 'V38', // Small Gold Tegan Vase
  'FLRL-V-00033': 'V39', // Reese Vase
  'FLRL-V-00030': 'V40', // Pier Vase
  'FLRL-V-00064': 'V41', // The Larson Vase
  'FLRL-V-00065': 'V42', // Small White Orchid Vase
  'FLRL-V-00423': 'V45', // The Bellini Vase
  'FLRL-V-00047': 'V46', // The Waterfall Vase
  'FLRL-V-00438': 'V41', // The Serena Vase
  'FLRL-V-00050': 'V42', // The Aria Vase
  'FLRL-V-00420': 'V42', // The Amore Vase
  'FLRL-V-00627': 'V43', // Charcoal Artisan Fringe Vase
  'FLRL-V-00626': 'V44', // White Artisan Slender Vase
  'FLRL-V-00032': 'V45', // Editor Vase
  'FLRL-V-00043': 'V46', // Clear Hobnail Vase
  'FLRL-V-00021': 'V47', // The Marble Vase
  'FLRL-V-00019': 'V48', // The Elixir Vase
  'FLRL-V-00587': 'V49', // Nema Vase
  'FLRL-V-00038': 'V50', // The Blakely Vase
  'FLRL-V-00989': 'V51', // Colorway Vase
  'FLRL-V-01072': 'V52', // Sholar Middle Kingdom Vase
  'FLRL-V-00965': 'V53', // Kate Spade Park Place Rose Bowl
  'FLRL-V-00962': 'V54', // Kate Spade Charlotte Street Rose Bowl
  'FLRL-V-00060': 'V56', // Medium Tilde Budvase
  'FLRL-V-00995': 'V57',
  'FLRL-V-00042': 'V58',
  'NF-PLNTR-00032': 'V59',
  'FLRL-V-01147': 'V62', // Vermillion Vase Terracotta 9in
  'FLRL-V-01671': 'V60', // Accent Decor 5" Mirage pot, black
  'FLRL-V-01820': 'V61', // FRINGE 4.5" Bud Vase - White
  'FLRL-V-00075': 'V62', // Glass Vase - Single 4" x 8"
  'FLRL-V-00076': 'V63', // Glass Vase - Double 5" x 8"
  'FLRL-V-02362': 'V64', // Accent Decor 8" Golden Love Vase Gold
  'FLRL-V-10003': 'V65', // Accent Decor Tegan Vase 4.25" x 9" Iridescent Cosmic Vase
  'FLRL-V-10004': 'V66', // Morrigan Vase (Tan, 4" x 8")
  'FLRL-V-10005': 'V67', // Hux Vase (5" x 5.5")
  'FLRL-V-10012': 'V69', // Lavender Tegan Vase
  'FLRL-V-10016': 'V70', // Peridot Glass Vase

  'GFT-OCSN-00002': 'X1', // Birthday Box
  'GFT-OCSN-00001': 'X2', // Birthday Banner Card
  'GFT-SHR-00001': 'X3', // Pruning Shears
  'GFT-MST-00002': 'X4', // Copper Mister
  'GFT-OCSN-00006': 'X5', // The Happy Birthday Cake Lovepop Card
  'GFT-OCSN-00005': 'X6', // The Champagne Toast Lovepop Card
  'GFT-OCSN-00003': 'X7', // The Floral Birthday Lovepop Card
  'GFT-OCSN-00008': 'X8', // Happy Birthday Balloon Banner
  'GFT-OCSN-00009': 'X10', // Coterie Hooray Balloons
  'GFT-OCSN-00010': 'X11', // Coterie BABY Balloons
  'GFT-SC-00002': 'X12', // Fresh Kit
  'GFT-K-00005': 'X13', // Cocktail Multi Tool Kit
  'GFT-TOTE-00003': 'X14', // LOCAL CITY TOTE NYC
  'GFT-TOTE-00004': 'X15', // DC
  'GFT-TOTE-00002': 'X16', // BK
  'FM-NFF-00001': 'X17', // Bloom- Flowering Plant Nutrients
  'GFT-TVT-00001': 'X18', // Plant Trivet Set
  'FT-K-00006': 'X19', // Virgo Necklace - Kendra Scott
  'GFT-SHR-00002': 'X20', // Custom Shears
  'GFT-K-00007': 'X21', // Kendra Scott Libra Necklace
  'GFT-K-00008': 'X22', // The Birthday Set
  'GFT-PLNT-00001': 'X23', // Amaryllis Grow Kit
  'GFT-CNDL-00025': 'X24', // Black Pine Reed Diffuser
  'GFT-OCSN-00014': 'X25', // Airplant Ornament
  'GFT-DECO-00007': 'X26', // Pom Pom Garland
  'GFT-DECO-00005': 'X27', // LED Lights
  'GFT-DECO-00006': 'X28', // Wood Snowflake Ornament
  'GFT-OCSN-00015': 'X29', // Candy Cane Ornament
  'GFT-SC-00498': 'X30', // Claus Porto Favorito Lotion
  'GFT-CNFT-00248': 'X31', // Fancy Sprinkles Prism Powder
  'GFT-SC-00424': 'X33', // Rose Bath Bomb
  'GFT-MST-00925': 'X34', // Clear Glass Mister Branded
  'GFT-SC-00242': 'X35', // Yellow Nest Hand Cream
  'GFT-SC-00190': 'X36', // Apotheke Hand Sanitizer Trio
  'GFT-K-00194': 'X37', // Basil Grow Kit
  'GFT-PC-00195': 'X38', // Georg Jensen Watering Globe
  'GFT-SC-00288': 'X39', // Claus Porto Cerina Lotion
  'GFT-DECO-01044': 'X40', // Tomato Tarragon Tea Towel
  'FLRL-K-00599': 'X41', // Tocca Trio Crema Veloce
  'GFT-PC-00899': 'X42', // XS Self Watering Pot
  'GFT-MSTR-01383': 'X43', // Black Glass Mister
  'GFT-HOME-0900': 'X44', // Aesthetic Movement Gardening Gloves
  'GFT-PC-00909': 'X45', // Mint Grow Kit (Tumblr)
  'GFT-JWLR-21322': 'X46', // Lilac Picture Frame
  'GFT-JWLR-11323': 'X47', // Pansy Stud Earrings
  'GFT-JWLR-31324': 'X48', // Pansy Cuff
  'GFT-CARD-01544': 'X49', // Brooklinen Giftcard
  'GFT-CARE-01463': 'X50',
  'GFT-HOME-01874': 'X51',
  'GFT-HOME-01211': 'X52',
  'GFT-HOME-01210': 'X53',
  'GFT-HOME-01206': 'X54',
  'GFT-TOY-01207': 'X55',
  'GFT-HOME-02269': 'X58', // True Hue 2.0oz Votive Candle Golden Woods
  'GFT-DECO-00002': 'X60', // Fall Tablescape runner
  'GFT-DECO-00009': 'X61', // Mady Votive
  'GFT-HOME-01491': 'X62', // Joyful Dirt 3 oz. Plant Nutrients
  'GFT-HOME-01492': 'X63', // Culinary Classics Window Garden Kit 5.5x3.5x1
  'GFT-HOME-01608': 'X64', // Peach Plant Magnet,
  'GFT-HOME-01609': 'X65', // Warhol Plant Magnet
  'GFT-K-00195': 'X66', // Georg Jensen Watering Globe
  'GFT-K-00300': 'X67', // The Home Gardener Giftbox
  'GFT-K-00899': 'X68', // XS Self Watering Pot
  'GFT-K-00909': 'X69', // Mint Grow Kit (Tumblr)
  'GFT-K-01208': 'X70', // The Zest
  'GFT-K-01263': 'X71', // The Getaway
  'GFT-K-01490': 'X72', // Pollinator Protector Activity Kit
  'GFT-MSTR-01835': 'X73', // Clear Glass Mister
  'GFT-SC-01968': 'X74', // ESW Beauty S/5 Raw Juice Cleanse Mask Set
  'GFT-HOME-01533': 'X76', // S/25 1.25" Ornaments, Neutrals
  'GFT-HOME-01998': 'X77', // Corkcicle 16oz Mug, Nebula
  'GFT-SC-01751': 'X56', // Milk+Honey 3-Pack Bath Bombs
  'GFT-HOME-01726': 'X57', // Plant Good Vibes Plant Magnet - Audrey
  'GFT-HOME-01733': 'X58', // Plant Good Vibes Plant Magnet - Lennon
  'GFT-SC-01678': 'X59', // Origins Retexturizing Rose Clay Mask, 2.5oz
  'GFT-SC-01684': 'X60', // Milk+Honey Rose Quartz Face Roller
  'GFT-CARD-01387': 'X74', // Up With Paper, Trinket Bday Pop Up Card
  'GFT-CARE-02262': 'X75', // Pink Juice Moisturizer (25ml)
  'GFT-HOME-02417': 'X78', // Brooklyn Candle Studio Toasted Pumpkin Minimalist Candle
  'GFT-HOME-02551': 'X79', // Shoppe Geo Crystal Set Joy + Bliss
  'GFT-SC-10006': 'X80', // Prosperity Candle Vanilla Sugar Scrub
  'GFT-CARD-10027': 'X81', // Birthday Cake Folding Card
  'GFT-CNDL-02553': 'X82', // The Little Market Peony Votive Candle
  'GFT-CNDL-10004': 'X83', // Apotheke Sea Salt Grapefruit Tin Candle
  'GFT-CNDL-10016': 'X84', // Apotheke Hinoki Lavender Tin Candle
  'GFT-CNDL-10017': 'X85', // NEST Himalayan Salt & Rosewater Classic Candle
  'GFT-HOME-02521': 'X86', // MerSea Coconut Sugar Diffuser
  'GFT-CNDL-10026': 'X87', // Literie Peony Candle Urban Stems Branded
  'GFT-SC-10015': 'X88', // The Little Market Lavender Sugar Scrub
  'GFT-CNDL-10031': 'X90', // NEST Sunlit Neroli & Yuzu Candle

  'GFT-CNFT-00001': 'F1', // Pink Champagne Truffles
  'GFT-CNFT-00010': 'F2', // Dark Chocolate Truffles
  'GFT-CNFT-00014': 'F3', // Cheers 3pc Candy Box
  'GFT-CNFT-00013': 'F4', // Birthday Bento Box
  'GFT-CNFT-00048': 'F5', // Evergreen Custom Champagne Bear cube
  'GFT-CNFT-00049': 'F6', // Evergreen Custom 2 Pc Bento Box
  'GFT-CNFT-00056': 'F7', // Co Branded Neuhaus Exclusive
  'GFT-CNFT-00028': 'F8', // pink pineapple gummies
  'GFT-CNFT-00043': 'F9', // Watermelon Sour Gummies
  'GFT-CNFT-00044': 'F10', // Ice Cream Cones
  'GFT-CNFT-00037': 'F11', // Sour Rainbow Gummies
  'GFT-CNFT-00038': 'F12', // Avocado Toast Chocolates
  'GFT-CNFT-00041': 'F13', // Bourbon Gummy Bears
  'GFT-K-00004': 'F14', // Hot Sauce and Seasoning Bundle
  'GFT-CNFT-00036': 'F15', // Happy Thoughts Chocolates
  'GFT-CNFT-00042': 'F16', // Birthday Cake Caramels
  'GFT-CNFT-00034': 'F17', // Sugarfina Vanilla Latte Bears
  'GFT-CNFT-00046': 'F18', // Rise and Shine Choc. Box
  'GFT-CNFT-00051': 'F19', // Sugar Skulls
  'GFT-CNFT-00052': 'F20', // Wicked Sweets
  'GFT-CNFT-00053': 'F21', // Neuhaus Dark Bleu Square Box
  'GFT-CNFT-00050': 'F22', // Evergreen Custom 3 Pc Bento Box
  'GFT-CNFT-00018': 'F23', // Holiday Bento Box
  'GFT-CNFT-00054': 'F24', // Neuhaus Hot Chocolate
  'GFT-CNFT-00057': 'F25', // Blue Neuhaus Cracker
  'GFT-CNFT-00058': 'F26', // Sugarfina Cranberry Cocktail Gummies
  'GFT-CNFT-02420': 'F26', // Le Chocolat des Francais LOVE Chocolate Squares
  'GFT-CNFT-00059': 'F27', // Taza Winter Warmer Sampler
  'GFT-CNFT-00061': 'F28', // Peppermint Chocolate Bar
  'GFT-CNFT-00060': 'F29', // Green Juice Gummies
  'GFT-CNFT-00245': 'F30', // Galaxy Chocolate Truffle Set
  'GFT-CNFT-00253': 'F31', // Salted Caramel Chocolate Bar
  'GFT-CNFT-00254': 'F32', // Chocolate Covered Bacon
  'GFT-CNFT-00172': 'F33', // Birthday Cookie Bites
  'GFT-CNFT-00027': 'F34', // Green Neuhaus Bunnies
  'GFT-CNFT-00568': 'F35', // Sugarfina Butterfly Gummies
  'GFT-CNFT-00598': 'F37', // Flower Box Sugar Lips Cube
  'GFT-CNFT-00305': 'F38', // Hampton Popcorn
  'GFT-CNFT-00722': 'F39', // Lipstick Trio Chocolates
  'GFT-CNFT-00032': 'F40', // Neuhaus Exclusive Red Square Box
  'GFT-CNFT-00892': 'F41', // Sugarfina Mint Chocolate Chip Bites
  'GFT-CNFT-01386': 'F42', // Sugarfina Rainbow Bears
  'GFT-CNFT-01217': 'F43',
  'GFT-CNFT-01218': 'F44',
  'GFT-CNFT-01216': 'F45',
  'GFT-CNFT-01209': 'F46',
  'GFT-K-01349': 'F47',
  'GFT-CNFT-01223': 'F48',
  'GFT-CNFT-00298': 'F49', // Peach Tea Bears
  'GFT-CNFT-01679': 'F50', // Compartes 2-Pack Chocolate Bar Gift Box
  'GFT-CNFT-01680': 'F51', // Amborella Organics x UrbanStems Vanilla Hibiscus 3-Pack Grow Lollipops
  'GFT-CNFT-01723': 'F52', // Sugarfina Matcha Green Tea Bears
  'GFT-CNFT-01729': 'F53', // McCrea’s Family Flavor Tall Tube, White
  'GFT-CNFT-01966': 'F55', // Sugarfina Golden Truffle Eggs
  'GFT-CNFT-01687': 'F56', // Mast Mini Chocolate Bar, Milk Chocolate
  'GFT-CNFT-01987': 'F61', // Mer-Sea Wrapped Soap, Moroccan Mint
  'GFT-CNFT-02418': 'F62', // McCrea's Tapped Maple Caramel Pillow Box
  'GFT-CNFT-02450': 'F63', // Sugarfina Dark Chocolate Salted Caramel Bar
  'GFT-CNFT-02452': 'F64', // Belgian Boys Butter Cookies
  'GFT-CNFT-01964': 'F65', // BRINS S/3 Mini Jam Gift Box, Berry Cherry
  'GFT-CNFT-02455': 'F66', // Sugarfina Robin's Egg Caramels
  'GFT-CNFT-10018': 'F67', // Vinoos Rosé Wine Gummies

  'GFT-CNDL-00012': 'C1', // Secret Garden Candle
  'GFT-CNDL-00011': 'C2', // Morning Dew Candle
  'GFT-CNDL-00001': 'C3', // Bamboo Candle
  'GFT-CNDL-00015': 'C4', // Ocean Mist Candle
  'GFT-CNDL-00014': 'C5', // Velvet Pear Candle
  'GFT-CNDL-00018': 'C6', // Sweet Sunflower Candle
  'GFT-CNDL-00017': 'C7', // Citronella Sea Salt Candle
  'GFT-CNDL-00016': 'C8', // Lemongrass & Ginger NEST Candle
  'GFT-CNDL-00019': 'C9', //  Lavender Grow Candle
  'FLRL-K-00111': 'C10', // Tea Light Candles
  'GFT-CNDL-00021': 'C11', // Pumpkin Brulee Votive
  'GFT-CNDL-00026': 'C13', // Birchwood Pine Candle
  'GFT-CNDL-00024': 'C14', // Nest Holiday Votive
  'GFT-CNDL-00022': 'C15', // White Morning Dew Candle
  'GFT-CNDL-00421': 'C16', // Pink Morning Dew PL Candle
  'GFT-CNDL-00249': 'C17', // Apotheke Candy Store Candle
  'GTF-CNDL-00736': 'C18', // Crushed Mint Grow Candle
  'GFT-CNDL-00579': 'C19', // Apotheke Meyer Lemon Votive
  'GFT-CNDL-00189': 'C20', // Nest Grapefruit Candle
  'GFT-CNDL-00604': 'C21', // Apotheke 2.5oz. Votive Candle, Hinoki Lavender
  'GFT-CNDL-00294': 'C22', // Tomato Market Candle
  'GFT-CNDL-00896': 'C23', // Apotheke, 11oz, Candle, Lemon Meyer
  'GFT-CNDL-01367': 'C24', // Wild Mint and Eucalyptus Candle
  'GFT-CNDL-01486': 'C25',
  'GFT-CNDL-00023': 'C26', // Apotheke Charcoal Candle
  'GFT-CNDL-01221': 'C27',
  'GFT-CNDL-01774': 'C28', // Malin+Goetz 2.35oz Votive Candle, Vetiver
  'GFT-HOME-01782': 'C29', // Mer-Sea 3.4floz Reed Diffuser, Saltaire
  'GFT-CNDL-01488': 'C30', // Framed Florals Magnolia Bouquet 11oz Candle
  'GFT-CNDL-02321': 'C31', // Apotheke Cedarwood & Ginger 2.5oz Votive Candle
  'GFT-CNDL-01685': 'C32', // Sunday Morning Minimalist Candle
  'GFT-CNDL-10005': 'C35', // Amber Woods Tin Candle
  'GFT-CNDL-10011': 'C36', // Toasted pumpkin travel tin candle 4oz
  'GFT-CNDL-02448': 'C55', // Apotheke White Vetiver Votive Candle
  'GFT-CNDL-02450': 'C53', // NEST Festive Votive Trio
};
