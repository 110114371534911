const getIdealProductName = (productFeed, item) => {
  if (item.sku && productFeed[item.sku]) {
    const product = productFeed[item.sku];

    if (product.opsName && product.opsName.length) {
      return product.opsName;
    } else if (product.name) {
      return product.name;
    }
  }

  return item.name;
};

export default getIdealProductName;
