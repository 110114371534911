import React, { useState, useEffect } from 'react';
import { useQueryCache } from 'react-query';
import PropTypes from 'prop-types';
import { ChatLeftQuote } from 'react-bootstrap-icons';
import { toaster } from 'evergreen-ui';
import { Auth } from 'aws-amplify';
import Select from 'react-select';
import { ORDER_MANAGEMENT_SERVICE_BASE_URL } from '../../config';
import { updateSuborder } from '../../services/OrderManagementService';

const Message = ({ order, suborders, canUpdate }) => {
  const [editing, setEditing] = useState(false);
  const [notecardMessage, setNotecardMessage] = useState(suborders && suborders.length ? suborders[0].message : null);
  const [subordersToUpdate, setSubordersToUpdate] = useState(suborders && suborders.length ? suborders : null);
  const [printing, setPrinting] = useState(false);
  const [suborderToPrint, setSuborderToPrint] = useState(suborders && suborders.length ? suborders[0] : null);

  const queryCache = useQueryCache();

  let subordersToUpdateOptions = [];
  subordersToUpdateOptions = suborders.map(s => { return { value: s, label: s.suborderId } });
  subordersToUpdateOptions.push({ value: suborders, label: 'All suborders' });

  let subordersToPrintOptions = [];
  subordersToPrintOptions = suborders.map(s => { return { value: s, label: s.suborderId } });

  useEffect(() => {
    setNotecardMessage(subordersToUpdate[0].message);
  }, [subordersToUpdate]);

  useEffect(() => {
    setNotecardMessage(suborderToPrint.message);
  }, [suborderToPrint]);

  const onMessageChange = (e) => {
    const val = e.target.value;
    setNotecardMessage(val);
  };

  const onClickEdit = e => setEditing(!editing);

  const cancelEdit = e => {
    setNotecardMessage(notecardMessage);
    setSubordersToUpdate(suborders && suborders.length ? suborders : null);
    setEditing(false);
  };

  const onSuborderChange = (option) => {
    if (option.label === 'All suborders') {
      setSubordersToUpdate(option.value);
    } else {
      setSubordersToUpdate([option.value]);
    }
  };

  const onClosePrint = () => {
    setPrinting(false);
    setSuborderToPrint(suborders && suborders.length ? suborders[0] : null);
    setNotecardMessage(suborders && suborders.length ? suborders[0].message : null);
  };

  const printNotecard = async (suborder) => {
    const currentSession = await Auth.currentSession();
    const idToken = currentSession.getIdToken();
    const authToken = idToken.getJwtToken();
    const w = window.open('/notecards.html', 'massprint-note', 'location=no,status=no,scrollbars=no,menubar=no,resizable=no,width=700,height=700');
    w.orders = [suborder];
    w.apiKey = authToken;
    w.url = `${ORDER_MANAGEMENT_SERVICE_BASE_URL}`;
    w.onload = () => w.init();
    w.onafterprint = () => {
      return w.close();
    };
  };

  const saveEdit = async (e) => {
    for (const suborder of subordersToUpdate) {
      const response = await updateSuborder(suborder.suborderId, {
        message: notecardMessage,
      });
      if (response.success) {
        toaster.success(`Message successfully saved for suborder ${suborder.suborderId}`);
        setEditing(false);
      } else {
        if (response.message) {
          toaster.warning(`Failed to update message for suborder ${suborder.suborderId}: ${response.message}`, {
            duration: 10,
          });
        } else {
          toaster.warning(`Failed to update messagefor suborder ${suborder.suborderId}`);
        }
      }
    }
    queryCache.invalidateQueries(['orders', order.orderId]);
  };

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title"><ChatLeftQuote size="1.5em" className="mr-2" /> <span>Message</span></h4>
        <div>
          {!editing && !printing && canUpdate &&
            <>
              <button type="button" className="btn btn-secondary btn-sm" onClick={() => setPrinting(true)}>
                Print
              </button>
              <button type="button" className="btn btn-link" onClick={onClickEdit}>
                Edit
              </button>
            </>
          }
          {editing &&
            <div className="d-inline-flex">
              <button type="button" className="btn btn-primary mx-1" onClick={saveEdit}>
                Save
              </button>
              <button type="button" className="btn btn-secondary" onClick={cancelEdit}>
                Cancel
              </button>
            </div>
          }
          {printing &&
            <>
              <button type="button" className="btn btn-primary mx-1" onClick={() => {
                printNotecard(suborderToPrint);
                onClosePrint();
              }}>
                Print
              </button>
              <button type="button" className="btn btn-secondary" onClick={onClosePrint}>
                Cancel
              </button>
            </>}
        </div>
      </div>
      <div className="card-body">
        {editing &&
          <>
            <div className="mb-4">
              Select Suborders to Update:
              <Select
                id="selectSubordersToUpdate"
                onChange={option => onSuborderChange(option)}
                options={subordersToUpdateOptions}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                defaultValue={subordersToUpdate && subordersToUpdate.length === 1 ?
                  subordersToUpdateOptions.find(o => o.value.suborderId === subordersToUpdate[0].suborderId) :
                  subordersToUpdateOptions.find(o => o.label === 'All suborders')}
              />
            </div>
            <div className="mb-2">
              <label className="form-label text-muted">
                Message
              </label>
              <textarea value={(notecardMessage || '')} name="message" onChange={onMessageChange} className="form-control" />
            </div>
          </>}
        {!editing && !printing &&
          (<>
            <p className="mb-1">
              {notecardMessage}
            </p>
          </>)
        }
        {printing &&
          <>
            <div className="mb-4">
              Select Suborder to Print:
              <Select
                id="selectSubordersToPrint"
                onChange={option => setSuborderToPrint(option.value)}
                options={subordersToPrintOptions}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                defaultValue={subordersToPrintOptions[0]}
              />
            </div>
            <div className="mb-2">
              <label className="form-label text-muted">
                Message
              </label>
              <p className="mb-1">
                {notecardMessage}
              </p>
            </div>
          </>}
      </div>
    </div>
  );
};

Message.propTypes = {
  order: PropTypes.object.isRequired,
  suborders: PropTypes.array.isRequired,
  canUpdate: PropTypes.bool.isRequired,
};

export default Message;
