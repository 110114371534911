import React, { useState } from 'react';
import PropTypes from 'prop-types';

const FulfillableCollateral = ({ item, onChange, action }) => {
  const [completed, setCompleted] = useState(false);
  const toggle = () => setCompleted(!completed);

  return item ? (
    <div className={'card' + (completed ? ' border-2 border-primary' : '')} onClick={toggle}>
      <div className="card-header p-3">
        <div className="row align-items-center justify-content-between">
          <div className="col-auto">
            <input className="form-check-input" type="checkbox" checked={completed} onChange={onChange} />
            <span className="h4 ml-3">{item.type}</span>
          </div>
          <div className="col-auto">
            {action && (<button className="btn btn-sm btn-outline-secondary" onClick={action}>
              Reprint
            </button>)}
          </div>
        </div>
      </div>
      <div className="card-body p-3 text-center">
        <p className="small mb-3">{item.text}</p>
      </div>
    </div>
  ) : (<></>);
};

FulfillableCollateral.propTypes = {
  item: PropTypes.shape({
    type: PropTypes.string,
    text: PropTypes.string,
  }),
  onChange: PropTypes.func,
  action: PropTypes.func,
};

FulfillableCollateral.defaultProps = {
  item: null,
  onChange: () => {},
};

export default FulfillableCollateral;
