/* eslint-disable no-console */
import React from 'react';
import { toaster } from 'evergreen-ui';

const { BrowserPrint } = window;

const errorCallback = (errorMessage) => {
  console.log(`Error: ${errorMessage}`);
};

const getLocalDevices = () => new Promise((resolve, reject) => BrowserPrint.getLocalDevices(resolve, reject));

const print = (printer, data) => new Promise((resolve, reject) => printer.send(data, resolve, reject));

const printImg = (printer, data) => new Promise((resolve) => printer.sendUrl(data, resolve(data), errorCallback));

export const printZpl = (labels, onSuccess = () => {}, onFail = toaster.danger) => {
  Promise.all([getLocalDevices()])
    .then(([devices]) => {
      if (!devices || !devices.printer || devices.printer.length === 0) {
        return onFail(`
          You are trying to print a Zebra file
          but I can't find any Zebra printers!
        `);
      }

      const p = devices.printer[0];

      const inputLabels = Array.isArray(labels) ? labels.map((label) => `${label}`) : `${labels}`; // turn into literal strings so no escape characters get added

      return print(p, inputLabels).then(onSuccess);
    })
    .catch(() =>
      onFail('Something went wrong printing ZPL labels. Do you have BrowserPrint installed and running?', {
        description: <a href="https://www.zebra.com/us/en/products/software/barcode-printers/link-os/browser-print.html">Download it here</a>,
      }),
    );
};

export const printImage = (labels, onSuccess = () => {}, onFail = toaster.danger) => {
  Promise.all([getLocalDevices()])
    .then(([devices]) => {
      if (!devices || !devices.printer || devices.printer.length === 0) {
        return onFail('You are trying to print a label but there are no Zebra printers available.');
      }
      const p = devices.printer[0];

      return labels.map((label) => printImg(p, label).then(() => onSuccess()));
    })
    .catch((e) =>
      onFail(
        e || (
          <>
            Something went wrong printing ZPL labels. Do you have BrowserPrint installed and running?
            <strong>
              <a href="https://www.zebra.com/us/en/products/software/barcode-printers/link-os/browser-print.html">Download it here</a>
            </strong>
          </>
        ),
      ),
    );
};
