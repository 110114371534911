import React, { useState } from 'react';
import { Button, Table } from 'evergreen-ui';
import PropTypes from 'prop-types';

import CreateOrEditAdjustmentModal from '../../CreateOrEditAdjustmentModal';
import { NETSUITE_URL } from '../../../../../config';

const { Body, Head, TextCell } = Table;

const hrf = (adjustmentTime) => {
  const adjustedDate = new Date(adjustmentTime);
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
  };
  return adjustedDate.toLocaleString('en-US', options);
};

const Adjustments = ({ adjustments, internalLotId = '', externalLotId = '', productName = '', fc = {}, successFunction = () => {} }) => {
  const [adjustmentBeingEdited, setAdjustmentBeingEdited] = useState(null);

  const editAdjustment = (adjustment) => {
    setAdjustmentBeingEdited(adjustment);
  };

  const exitEditAdjustment = () => {
    setAdjustmentBeingEdited(null);
  };

  const titlesAndAdditionalProps = [
    { title: 'ID', additionalProps: {} },
    { title: 'Type', additionalProps: {} },
    { title: 'Quantity', additionalProps: {} },
    {
      title: 'Time Of',
      additionalProps: {
        flexBasis: 560,
        flexShrink: 0,
        flexGrow: 0,
        maxWidth: 230,
      },
    },
    { title: 'Related Record', additionalProps: {} },
    { title: 'Cleared', additionalProps: {} },
    { title: 'Action', additionalProps: {} },
  ];

  const getURL = (adj) => {
    if (adj?.netsuite_inventory_adjustment_id) {
      let service = 'invadjst';
      if (adj.netsuite_inventory_adjustment_id.includes('IR')) {
        service = 'itemrcpt';
      }
      if (adj.netsuite_inventory_adjustment_id.includes('PO')) {
        service = 'purchord';
      }

      const netsuiteUniqueIdArray = adj.netsuite_inventory_adjustment_id.split(':');
      const netsuiteId = (netsuiteUniqueIdArray.length === 3) ? netsuiteUniqueIdArray[1] : netsuiteUniqueIdArray[0];

      return (
        <a
          href={`${NETSUITE_URL}/app/accounting/transactions/${service}.nl?id=${netsuiteId}&whence=`}
          target="_blank"
          rel="noreferrer"
        >
          {adj.netsuite_inventory_adjustment_id}
        </a>
      );
    }
    if (adj.suborder_ref) {
      let orderid = adj.suborder_ref;
      if (adj.suborder_ref.includes('-')) {
        // eslint-disable-next-line prefer-destructuring
        orderid = adj.suborder_ref.split('-')[0];
      }
      return (
        <a href={`/orders/${orderid}`} target="_blank" rel="noreferrer">
          {adj.suborder_ref}
        </a>
      );
    }
    return '';
  };

  return (
    <Body style={{ border: '1px solid gray', borderRadius: 10 }}>
      <Head>
        {titlesAndAdditionalProps.map(({ title, additionalProps }, index) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <TextCell {...additionalProps} key={`adjustment-column-title-${index}`}>
            <h4 style={{ textTransform: 'capitalize' }}>{title}</h4>
          </TextCell>
        ))}
      </Head>
      <Body>
        {adjustments.map((value, index) => (
          <Table.Row key={`adjustments-row-${index}`} maxHeight={50}>
            <TextCell>{value.adjustment_id}</TextCell>
            <TextCell>{value.adjustment_type}</TextCell>
            <TextCell>{value.adjustment_quantity}</TextCell>
            <TextCell flexBasis={560} flexShrink={0} flexGrow={0} maxWidth={230}>
              {hrf(value.adjustment_time)}
            </TextCell>
            <TextCell>{getURL(value)}</TextCell>
            <TextCell>{value.for_count ? 'Yes' : 'No'}</TextCell>
            <TextCell>
              <Button intent="danger" onClick={() => editAdjustment(value)}>
                Edit
              </Button>
            </TextCell>
          </Table.Row>
        ))}
      </Body>
      {adjustmentBeingEdited && (
        <CreateOrEditAdjustmentModal
          showModal={adjustmentBeingEdited != null}
          hideModalFunction={exitEditAdjustment}
          internalLotId={internalLotId}
          externalLotId={externalLotId}
          productName={productName}
          fc={fc}
          backAction={exitEditAdjustment}
          successFunction={successFunction}
          successFunctionMessage="Successfully updated adjustment"
          isFromInventoryRecordModal
          existingAdjustment={adjustmentBeingEdited}
        />
      )}
    </Body>
  );
};

Adjustments.propTypes = {
  adjustments: PropTypes.arrayOf(PropTypes.object),
  internalLotId: PropTypes.number,
  externalLotId: PropTypes.string,
  productName: PropTypes.string,
  fc: PropTypes.object,
  successFunction: PropTypes.func.isRequired,
};

Adjustments.defaultProps = {
  adjustments: null,
  internalLotId: '',
  externalLotId: '',
  productName: '',
  fc: {},
};

export default Adjustments;
