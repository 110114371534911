import React from 'react';
import { Badge, TickIcon, CrossIcon, IconButton } from 'evergreen-ui';

const Nav = ({ state, closeModal, ...props }) => {
  let stepTitles;
  if (state.needsRefundOrCredits && state.needsRedelivery) {
    stepTitles = ['Select', 'Choose Remedy', 'Refund/Credit', 'Redeliver', 'Summary'];
  } else if (state.needsRefundOrCredits) {
    stepTitles = ['Select', 'Choose Remedy', 'Refund/Credit', 'Summary'];
  } else if (state.needsRedelivery) {
    stepTitles = ['Select', 'Choose Remedy', 'Redeliver', 'Summary'];
  } else {
    stepTitles = ['Select', 'Choose Remedy', 'Summary'];
  }
  const steps = [];
  for (let i = 1; i <= props.totalSteps; i += 1) {
    const isActive = props.currentStep === i;
    const isComplete = props.currentStep > i;
    let badge = (<Badge color='neutral' marginRight={6}>{i}</Badge>);
    if (isActive) {
      badge = (<Badge color='blue' marginRight={6}>{i}</Badge>);
    } else if (isComplete) {
      badge = (<Badge color='green' marginRight={6} paddingX={1} ><TickIcon marginBottom={4} size={14} /></Badge>);
    }
    steps.push((
      <div
        key={`step-${i}`}
        className={`wizardnav-step d-inline-block ${isActive ? 'active' : ''}`}
        // onClick={() => props.goToStep(i)}
        style={{ cursor: 'default', height: '64px', lineHeight: '64px' }}
      >{badge}<span style={{ color: isActive ? 'blue' : 'inherit' }}>{stepTitles[i - 1]}</span></div>
    ));
  }

  return (
    <div className="wizardnav d-flex justify-content-between mt-2 mb-2">
      {steps}
      <IconButton icon={CrossIcon} appearance="minimal" onClick={closeModal} />
    </div>
  );
};

export default Nav;
