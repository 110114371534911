/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://jwpsldngtvhmphcnokvckaufxy.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-xjh7aavbxbhqdhoenmq5qkmyyq",
    "aws_cognito_identity_pool_id": "us-west-2:0071f130-fb2b-4a1a-97d9-958eb7a23e94",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_HJ7WIv1s6",
    "aws_user_pools_web_client_id": "429uoek9bnnh62a2ei9mo5kjfv",
    "oauth": {
        "domain": "urbanstems-prod.auth.us-west-2.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "phone",
            "profile"
        ],
        "redirectSignIn": "https://dash.urbanstems.com,https://dash.urbstx.co",
        "redirectSignOut": "https://dash.urbanstems.com,https://dash.urbstx.co",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "GIVEN_NAME",
        "FAMILY_NAME",
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 10,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
