/* eslint-disable react/display-name */
/* eslint-disable prefer-const */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, UnlockIcon, Icon, LockIcon, toaster, Select } from 'evergreen-ui';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DeliveryTable from '../components/DeliveryTable';
import CellLastEdited from '../components/CellLastEdited';
import { MultipleColumnFilter } from '../../common/tableHelpers/MultipleColumnFilter';
import { roleChecks } from '../../../utilities/Role';
import AuditLog from '../components/AuditLog';
import { FcContext } from '../../../context/fcContext';
import { getDeliveryAreasByFc, getDeliveryWindowsByDate } from '../../../services/AggregateService';
import { createDeliveryWindow, saveDeliveryWindow, saveDeliveryWindowStatus } from '../../../services/OrderManagementService';
import { TRANSPORT_MODES } from '../../../lookups/delivery';

const convertMinutesToTime = (minutes, datetime) => {
  const date = new Date(datetime);
  date.setMinutes(date.getMinutes() - minutes);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}`;
};

const roundToNearestInterval = (value, interval) => {
  return Math.round(value / interval) * interval;
};

const TODAY_TIME = new Date();
const ENDDATE_TIME = new Date(TODAY_TIME.getTime() + 60 * 60 * 1000);

const DeliveryWindow = ({ userGroups }) => {
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  const [deliveryWindows, setDeliveryWindows] = useState([]);
  const { date, allFcs } = useContext(FcContext);
  const [inputRefs, setInputRefs] = useState({});
  const [showAuditLog, setShowAuditLog] = useState(false);
  const [deliveryAreas, setDeliveryAreas] = useState([]);
  const [lastRefreshed, setLastRefreshed] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [previousWindow, setPreviousWindow] = useState({ id: null, name: '' });
  const [inputErrors, setInputErrors] = useState({});
  const [window, setWindow] = useState(
    {
      id: '1',
      type: 'open',
      delivery_area_id: '',
      delivery_area_name: '',
      fc_id: '',
      fc_name: '',
      cutoff_lead_time_minutes: TODAY_TIME,
      prepped_at_time: TODAY_TIME,
      start_datetime: TODAY_TIME,
      end_datetime: ENDDATE_TIME,
      transport_mode: 'bike',
      capacity: '',
      updatedAt: TODAY_TIME,
    },
  );

  const validateFields = (row) => {
    const newErrors = {};

    if (!row.deliveryAreaId) {
      newErrors.deliveryAreaId = 'Delivery Area is required';
    }

    if (!row.transportMode) {
      newErrors.transportMode = 'Transport Mode is required';
    }

    if (!row.startDatetime) {
      newErrors.startDatetime = 'Start DateTime is required';
    }

    if (!row.endDatetime) {
      newErrors.endDatetime = 'End DateTime is required';
    } else if (new Date(row.startDatetime) > new Date(row.endDatetime)) {
      newErrors.endDatetime = 'End DateTime must be after or the same as Start DateTime';
    }

    if (row.cutoffLeadTimeMinutes === null || Number.isNaN(row.cutoffLeadTimeMinutes)) {
      newErrors.cutoffLeadTimeMinutes = 'Cutoff Lead Time (minutes) is required';
    } else {
      const cutoffTime = new Date(new Date(row.startDatetime).getTime() - row.cutoffLeadTimeMinutes * 60000);
      if (cutoffTime > new Date(row.startDatetime)) {
        newErrors.cutoffLeadTimeMinutes = 'Cutoff Lead Time must be before or the same as Start DateTime';
      }
    }

    if (row.preppedAtTime === null || Number.isNaN(row.preppedAtTime)) {
      newErrors.preppedAtTime = 'Prepped At Time is required';
    } else if (new Date(row.preppedAtTime) > new Date(row.startDatetime)) {
      newErrors.preppedAtTime = 'Prepped At Time must be before or the same as Start DateTime';
    }

    if (row.capacity === null || Number.isNaN(row.capacity)) {
      newErrors.capacity = 'Capacity is required';
    }
    return newErrors;
  };

  const handleSaveDeliveryWindow = async (data) => {
    setIsFetching(true);
    try {
      const window = await saveDeliveryWindow(data);
      if (!window.success) {
        toaster.danger(window?.message);
        return false;
      }
      return window.window;
    } catch (error) {
      console.error('Error :', error);
      return false;
    }
  };

  const handleCreateDeliveryWindow = async (data) => {
    setIsFetching(true);
    try {
      const window = await createDeliveryWindow(data);
      if (!window.success) {
        toaster.danger(window?.message);
        return false;
      }
      return window.window;
    } catch (error) {
      console.error('Error :', error);
      return false;
    }
  };

  const handleRefresh = async () => {
    setIsFetching(true);
    try {
      const { windows } = await getDeliveryWindowsByDate(date);
      setDeliveryWindows(windows);
      setIsFetching(false);
      setLastRefreshed(new Date().toLocaleString());
    } catch (error) {
      console.error('Error fetching the delivery windows:', error);
      setIsFetching(false);
    }
  };

  const parseTimeString = (timeString, baseDate) => {
    let timeStringValue = timeString;

    if (timeStringValue instanceof Date) {
      timeStringValue = timeStringValue.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
    }

    if (typeof timeStringValue !== 'string') {
      console.error(`Expected a string for timeString, but received: ${typeof timeStringValue}`);
      return new Date(baseDate);
    }

    const [time, modifier] = timeStringValue.split(' ');
    let [hours, minutes] = time.split(':').map(Number);

    if (modifier === 'PM' && hours < 12) {
      hours += 12;
    } else if (modifier === 'AM' && hours === 12) {
      hours = 0;
    }

    const date = new Date(baseDate);
    date.setHours(hours, minutes, 0, 0);

    return date;
  };

  const handleSave = async (row) => {
    if (editingRow === row.id) {
      const startDatetime = new Date(window.start_datetime);
      const endDatetime = new Date(window.end_datetime);

      const preppedAtTimeParsed = parseTimeString(window.prepped_at_time, startDatetime);
      const cutoffLeadTimeParsed = parseTimeString(window.cutoff_lead_time_minutes, startDatetime);

      const preppedAtDiff = startDatetime - preppedAtTimeParsed;
      const preppedAtTimeInMinutes = preppedAtDiff / (1000 * 60);

      const cutoffLeadTimeDiff = endDatetime - cutoffLeadTimeParsed;
      const cutoffLeadTimeInMinutes = cutoffLeadTimeDiff / (1000 * 60);

      const deliveryWindowPayload = {
        deliveryAreaId: window.delivery_area_id,
        startDatetime: startDatetime.toISOString(),
        endDatetime: endDatetime.toISOString(),
        cutoffLeadTimeMinutes: roundToNearestInterval(cutoffLeadTimeInMinutes, 5),
        preppedAtTime: roundToNearestInterval(preppedAtTimeInMinutes, 5),
        capacity: window.capacity,
        fcId: window.fc_id,
        transportMode: window.transport_mode,
        isActive: window.type === 'open',
      };

      const newErrors = validateFields(deliveryWindowPayload);
      if (Object.keys(newErrors).length > 0) {
        const errorMessages = Object.values(newErrors).join(', ');
        toaster.danger(`Please correct the following errors before saving: ${errorMessages}.`);
        return;
      }

      if (!row.temp) {
        deliveryWindowPayload.id = row.id;
        const savedWindow = await handleSaveDeliveryWindow(deliveryWindowPayload);
        if (!savedWindow) {
          return;
        }
        if (previousWindow.id === window.id && previousWindow.name !== window.name) {
          toaster.notify('Delivery window has been moved');
          setPreviousWindow({ id: window.id, name: window.name });
        } else {
          toaster.success('Window saved!');
        }
      } else {
        const createdWindow = await handleCreateDeliveryWindow(deliveryWindowPayload);
        if (!createdWindow) {
          return;
        }

        if (previousWindow.id === window.id && previousWindow.name !== window.name) {
          toaster.notify('Delivery window has been moved');
          setPreviousWindow({ id: window.id, name: window.name });
        } else {
          toaster.success('Window saved!');
        }
      }
      await handleRefresh();
    } else {
      toaster.danger('No row is being edited');
    }
  };

  const handleCapacityEditChange = (e, columnId, value) => {
    e.preventDefault();
    setWindow((prevWindow) => ({ ...prevWindow, [columnId]: Number(value) }));
  };

  const handleEditChange = async (columnId, value) => {
    let parsedValue = columnId === 'capacity' ? Number(value) : value;

    if (columnId === 'fc_id') {
      const { areas } = await getDeliveryAreasByFc(value);
      setDeliveryAreas(areas);
    }

    if (columnId === 'end_datetime') {
      const selectedTime = new Date(value);
      const startDate = new Date(window.start_datetime);

      const combinedEndDate = new Date(startDate);
      combinedEndDate.setHours(selectedTime.getHours());
      combinedEndDate.setMinutes(selectedTime.getMinutes());
      combinedEndDate.setSeconds(selectedTime.getSeconds());

      if (combinedEndDate < startDate) {
        setInputErrors((prevErrors) => ({
          ...prevErrors,
          end_datetime: 'End time cannot be earlier than the start time.',
        }));
        return;
      }

      setInputErrors((prevErrors) => ({
        ...prevErrors,
        end_datetime: '',
      }));

      parsedValue = combinedEndDate.toISOString();
    }

    if (columnId === 'cutoff_lead_time_minutes') {
      const cutoffDate = new Date(value);
      const startDate = new Date(window.start_datetime);

      if (cutoffDate > startDate) {
        setInputErrors((prevErrors) => ({
          ...prevErrors,
          cutoff_lead_time_minutes: 'Cutoff time cannot be later than the start time.',
        }));
        return;
      }

      setInputErrors((prevErrors) => ({
        ...prevErrors,
        cutoff_lead_time_minutes: '',
      }));

      parsedValue = cutoffDate;
    }

    if (columnId === 'prepped_at_time') {
      const preppedTime = new Date(value);
      const startDate = new Date(window.start_datetime);

      if (preppedTime > startDate) {
        setInputErrors((prevErrors) => ({
          ...prevErrors,
          prepped_at_time: 'Prep time cannot be later than the start time.',
        }));
        return;
      }

      setInputErrors((prevErrors) => ({
        ...prevErrors,
        prepped_at_time: '',
      }));

      parsedValue = preppedTime;
    }

    if (columnId === 'start_datetime') {
      const newStartDate = new Date(value);
      let o = window;

      const cutoffTime = new Date(window.cutoff_lead_time_minutes);
      if (cutoffTime > newStartDate) {
        o = {
          ...o,
          cutoff_lead_time_minutes: newStartDate,
        };
      }

      let currentEndDate = new Date(window.end_datetime);
      if (currentEndDate <= newStartDate) {
        currentEndDate = new Date(newStartDate.getTime() + 60 * 60 * 1000);
        o = {
          ...o,
          end_datetime: currentEndDate,
        };
      }

      const preppedTime = new Date(window.prepped_at_time);
      if (preppedTime > newStartDate) {
        o = {
          ...o,
          prepped_at_time: newStartDate,
        };
      }

      o = {
        ...o,
        [columnId]: parsedValue,
      };

      parsedValue = newStartDate.toISOString();
      setWindow({ ...window, ...o });
    } else {
      setWindow({ ...window, [columnId]: parsedValue });
    }

    setInputRefs((prevRefs) => ({ ...prevRefs, capacity: null }));
  };

  const closeAndOpenButton = ({ type, id }) => {
    const handleClick = async (newStatus, id) => {
      setLoadingStatus(id);
      try {
        const response = await saveDeliveryWindowStatus({ id, isActive: newStatus });
        if (response.status === 'SUCCESS') {
          toaster.success(`Window status updated to ${newStatus ? 'open' : 'close'}`);
          await handleRefresh();
        } else {
          toaster.danger('Failed to update the window status');
        }
        setLoadingStatus(false);
      } catch (error) {
        console.danger('Error updating window status:', error);
        setLoadingStatus(false);
      }
    };

    return type === 'open' ? (
      <Button isLoading={loadingStatus === id} onClick={() => handleClick(false, id)} className="button" appearance="primary" intent="danger">
        <Icon icon={LockIcon} size={12} /> &nbsp; Close
      </Button>
    ) : (
      <Button isLoading={loadingStatus === id} onClick={() => handleClick(true, id)} className="button" appearance="primary" intent="none">
        <Icon icon={UnlockIcon} size={12} style={{ marginRight: 5 }} /> &nbsp; Open
      </Button>
    );
  };

  const handleCancel = (row) => {
    if (row?.temp) {
      setDeliveryWindows((prevData) => {
        const updatedData = prevData.filter((r) => r.id !== row.id);
        setEditingRow(null);
        return updatedData;
      });
    } else {
      setEditingRow(null);
    }
  };

  const cancelButton = (row) => {
    return (
      <Button className="button" intent="none" onClick={() => handleCancel(row)}>
        Cancel
      </Button>
    );
  };

  const handleRowClick = async (row) => {
    setDeliveryWindows(() => deliveryWindows.filter((r) => !r.temp));
    const w = {
      ...row,
      prepped_at_time: convertMinutesToTime(row.prepped_at_time, row.start_datetime),
      cutoff_lead_time_minutes: convertMinutesToTime(row.cutoff_lead_time_minutes, row.end_datetime),
    };
    if (row.fc_id) {
      const { areas } = await getDeliveryAreasByFc(row.fc_id);
      setDeliveryAreas(areas);
    }

    setWindow(w);
    setEditingRow(row.id);
  };

  const handleRowKeyDown = (event, row) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleRowClick(row.id);
    } else if (event.key === 'Escape') {
      event.preventDefault();
      handleCancel(row);
    }
  };

  const memoizedTransportModes = useMemo(
    () => TRANSPORT_MODES.map((val) => <option key={val} value={val}>{val}</option>),
    [],
  );

  const multiValueFilterFn = (rows, columnIds, filterValue) => {
    return rows.filter((row) => {
      const rowValue = row.values[columnIds[0]];

      if (rowValue === null || rowValue === undefined) {
        return false;
      }

      return filterValue.some((filter) => rowValue.includes(filter));
    });
  };

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: 'Delivery Area',
        accessor: 'delivery_area_name',
        sortType: 'customSort',
        Filter: MultipleColumnFilter,
        filter: multiValueFilterFn,
        Cell: ({ value, row }) => {
          const isBeingEdited = row.original.id === editingRow;
          return isBeingEdited ? (
            <Select
              label=""
              disabled={!window.fc_id}
              key={row.original.delivery_area_id}
              onKeyDown={(e) => handleRowKeyDown(e, row.original.delivery_area_id)}
              value={window.delivery_area_id || ''}
              onChange={(e) => handleEditChange('delivery_area_id', e.target.value)}
              data-row-id={row.original.delivery_area_id}
            >
              <option value="">Select area</option>
              {deliveryAreas.map((area) => <option key={area.id} value={area.id}>{area.description}</option>)}
            </Select>
          ) : (
            <div
              onClick={() => handleRowClick(row.original)}
              onKeyDown={(e) => handleRowKeyDown(e, row.original.id)}
              data-row-id={row.original.id}
              tabIndex={0}
              role="button"
              style={{ cursor: 'pointer' }}
            >
              {value || ''}
            </div>
          );
        },
      },
      {
        Header: 'FC Location',
        accessor: 'fc_name',
        sortType: 'customSort',
        Filter: MultipleColumnFilter,
        filter: multiValueFilterFn,
        Cell: ({ value, row }) => {
          const isBeingEdited = row.original.id === editingRow;
          return isBeingEdited ? (
            <Select
              label=""
              key={row.original.fc_id}
              onKeyDown={(e) => handleRowKeyDown(e, row.original.fc_id)}
              value={window.fc_id || ''}
              onChange={(e) => handleEditChange('fc_id', e.target.value)}
              data-row-id={row.original.fc_id}
            >
              <option value="">Select fc</option>
              {allFcs.map((fc, index) => <option key={index} value={fc.value}>{fc.label}</option>)}
            </Select>
          ) : (
            <div
              onClick={() => handleRowClick(row.original)}
              onKeyDown={(e) => handleRowKeyDown(e, row.original.fc_id)}
              data-row-id={row.original.fc_id}
              tabIndex={0}
              role="button"
              style={{ cursor: 'pointer' }}
            >
              {value || ''}
            </div>
          );
        },
      },
      {
        Header: 'Transport Mode',
        accessor: 'transport_mode',
        sortType: 'customSort',
        filter: multiValueFilterFn,
        Filter: MultipleColumnFilter,
        Cell: ({ value, row }) => {
          const isBeingEdited = row.original.id === editingRow;
          return isBeingEdited ? (
            <Select
              label=""
              key={row.original.id}
              onKeyDown={(e) => handleRowKeyDown(e, row.original.id)}
              value={window.transport_mode || ''}
              onChange={(e) => handleEditChange('transport_mode', e.target.value)}
              data-row-id={row.original.id}
            >
              <option value="">Select transport mode</option>
              {memoizedTransportModes}
            </Select>
          ) : (
            <div
              onClick={() => handleRowClick(row.original)}
              onKeyDown={(e) => handleRowKeyDown(e, row.original.id)}
              data-row-id={row.original.id}
              tabIndex={0}
              role="button"
              style={{ cursor: 'pointer' }}
            >
              {value || ''}
            </div>
          );
        },
      },
      {
        Header: 'Start Date Time',
        accessor: 'start_datetime',
        sortType: 'customSort',
        Filter: MultipleColumnFilter,
        Cell: ({ value, row }) => {
          const isBeingEdited = row.original.id === editingRow;
          return isBeingEdited ? (
            <DatePicker
              key={row.original.id}
              selected={window.start_datetime ? new Date(window.start_datetime) : null}
              onKeyDown={(e) => handleRowKeyDown(e, row.original.id)}
              onChange={(date) => handleEditChange('start_datetime', date)}
              showTimeSelect
              dateFormat="Pp"
              data-row-id={row.original.id}
              placeholderText="Select start date/time"
            />
          ) : (
            <div
              onClick={() => handleRowClick(row.original)}
              onKeyDown={(e) => handleRowKeyDown(e, row.original.id)}
              data-row-id={row.original.id}
              tabIndex={0}
              role="button"
              style={{ cursor: 'pointer' }}
            >
              {new Date(value).toLocaleString() || ''}
            </div>
          );
        },
      },
      {
        Header: 'End Time',
        accessor: 'end_datetime',
        sortType: 'customSort',
        Filter: MultipleColumnFilter,
        Cell: ({ value, row }) => {
          const isBeingEdited = row.original.id === editingRow;
          return isBeingEdited ? (
            <>
              <DatePicker
                key={row.original.id}
                selected={window.end_datetime ? new Date(window.end_datetime) : null}
                onChange={(date) => handleEditChange('end_datetime', date)}
                showTimeSelect
                onKeyDown={(e) => handleRowKeyDown(e, row.original.id)}
                data-row-id={row.original.id}
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
                placeholderText="Select end time"
              />
              {inputErrors.end_datetime && (
              <div style={{ color: 'red', marginTop: '5px', fontSize: 8 }}>
                {inputErrors.end_datetime}
              </div>
              )}
            </>
          ) : (
            <div
              onClick={() => handleRowClick(row.original)}
              onKeyDown={(e) => handleRowKeyDown(e, row.original.id)}
              data-row-id={row.original.id}
              tabIndex={0}
              role="button"
              style={{ cursor: 'pointer' }}
            >
              {new Date(value).toLocaleString() || ''}
            </div>
          );
        },
      },
      {
        Header: 'Cutoff Time',
        accessor: 'cutoff_lead_time_minutes',
        sortType: 'customSort',
        Filter: MultipleColumnFilter,
        Cell: ({ value, row }) => {
          const isBeingEdited = row.original.id === editingRow;
          return isBeingEdited ? (
            <>
              <DatePicker
                selected={window.cutoff_lead_time_minutes ? new Date(window.cutoff_lead_time_minutes) : null}
                onChange={(date) => handleEditChange('cutoff_lead_time_minutes', date)}
                showTimeSelect
                dateFormat="Pp"
                placeholderText="Select cutoff time"
              />
              {inputErrors.cutoff_lead_time_minutes && (
              <div style={{ color: 'red', marginTop: '5px', fontSize: 8 }}>
                {inputErrors.cutoff_lead_time_minutes}
              </div>
              )}
            </>
          ) : (
            <div
              onClick={() => handleRowClick(row.original)}
              onKeyDown={(e) => handleRowKeyDown(e, row.original.id)}
              data-row-id={row.original.id}
              tabIndex={0}
              role="button"
              style={{ cursor: 'pointer' }}
            >
              {value || ''}
            </div>
          );
        },
      },
      {
        Header: 'Prepped At Time',
        accessor: 'prepped_at_time',
        sortType: 'customSort',
        Filter: MultipleColumnFilter,
        Cell: ({ value, row }) => {
          const isBeingEdited = row.original.id === editingRow;
          const isDate = value instanceof Date && !Number.isNaN(value.getTime());
          const isNumber = typeof value === 'number';

          return isBeingEdited ? (
            <>
              <DatePicker
                selected={window.prepped_at_time ? new Date(window.prepped_at_time) : null}
                onChange={(date) => handleEditChange('prepped_at_time', date)}
                showTimeSelect
                dateFormat="Pp"
                placeholderText="Select prep time"
              />
              {inputErrors.prepped_at_time && (
              <div style={{ color: 'red', marginTop: '5px', fontSize: 8 }}>
                {inputErrors.prepped_at_time}
              </div>
              )}
            </>
          ) : (
            <div
              onClick={() => handleRowClick(row.original)}
              onKeyDown={(e) => handleRowKeyDown(e, row.original.id)}
              data-row-id={row.original.id}
              tabIndex={0}
              role="button"
              style={{ cursor: 'pointer' }}
            >
              {isDate ? new Date(value).toLocaleString() : isNumber ? value.toFixed(2) : value || ''}
            </div>
          );
        },
      },
      {
        Header: 'Capacity',
        accessor: 'capacity',
        sortType: 'customSort',
        filter: multiValueFilterFn,
        Filter: MultipleColumnFilter,
        Cell: React.memo(({ value, row }) => {
          const isBeingEdited = row.original.id === editingRow;

          useEffect(() => {
            if (isBeingEdited) {
              if (inputRefs.capacity) {
                inputRefs.capacity.current.focus();
              }
            }
          }, [isBeingEdited]);

          return isBeingEdited ? (
            <Select
              value={window.capacity || ''}
              onChange={(e) => handleCapacityEditChange(e, 'capacity', e.target.value)}
            >
              <option value="">∞</option>
              {Array.from({ length: 100 }, (_, i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </Select>
          ) : (
            <div
              onClick={() => handleRowClick(row.original)}
              onKeyDown={(e) => handleRowKeyDown(e, row.original.id)}
              data-row-id={row.original.id}
              tabIndex={0}
              role="button"
              style={{ cursor: 'pointer' }}
            >
              {value || ''} at 0/∞
            </div>
          );
        }),
      },
      {
        Header: 'Last Edited',
        accessor: 'updatedAt',
        sortType: 'customSort',
        filter: multiValueFilterFn,
        Filter: MultipleColumnFilter,
        Cell: React.memo(({ row }) => {
          const isBeingEdited = row.original.id === editingRow;
          return isBeingEdited ? null : (
            <CellLastEdited
              setId={() => {
                setWindow(row.original);
              }}
              row={row.original}
              setShowAuditLog={setShowAuditLog}
            />
          );
        }),
      },
    ];

    if (roleChecks.canEditOutboundDelivery(userGroups)) {
      baseColumns.push({
        Header: 'Action',
        Cell: ({ row }) => {
          const isBeingEdited = row.original.id === editingRow;

          const buttonStyles = isBeingEdited
            ? {
              marginRight: 5,
              color: '#25b5e4',
              border: '1px solid #25b5e4',
              backgroundColor: 'transparent',
              transition: 'all 0.3s ease',
            }
            : { marginRight: 5 };

          return (
            <>
              <Button
                style={buttonStyles}
                intent={isBeingEdited ? undefined : 'danger'}
                data-row-id={row.original.id}
                onClick={() => (isBeingEdited ? handleSave(row.original) : handleRowClick(row.original))}
                onMouseEnter={(e) => {
                  if (isBeingEdited) {
                    e.target.style.border = '1px solid blue';
                    e.target.style.backgroundColor = 'blue';
                    e.target.style.color = 'white';
                  }
                }}
                onMouseLeave={(e) => {
                  if (isBeingEdited) {
                    e.target.style.border = '1px solid #25b5e4';
                    e.target.style.backgroundColor = 'transparent';
                    e.target.style.color = '#25b5e4';
                  }
                }}
              >
                <Icon size={12} /> {isBeingEdited ? 'Save' : 'Edit'}
              </Button>
              {isBeingEdited ? cancelButton(row.original) : closeAndOpenButton({ type: row.original.type, id: row.original.id })}
            </>
          );
        },
        disableFilters: true,
      });
    }

    return baseColumns;
  }, [userGroups, handleEditChange, handleRowKeyDown, handleRowClick, handleSave]);

  const showCreateForm = () => {
    const highestId = deliveryWindows.length > 0 ? Math.max(...deliveryWindows.map((row) => row.id)) : 0;
    const newRow = {
      ...window,
      id: highestId + 1,
      temp: true,
    };

    setEditingRow(highestId + 1);
    setDeliveryWindows((prevData) => {
      const firstOpenIndex = prevData.findIndex((row) => row.type === 'open');
      const insertIndex = firstOpenIndex !== -1 ? firstOpenIndex : 0;
      const updatedData = [...prevData.slice(0, insertIndex), newRow, ...prevData.slice(insertIndex)];
      return updatedData;
    });
  };

  const humanReadableDate = (date) => {
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    const d = new Date(date);
    return d.toLocaleDateString('en-US', options);
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      setIsFetching(true);
      try {
        const { windows } = await getDeliveryWindowsByDate(date);
        if (isMounted) {
          setDeliveryWindows(windows);
        }
      } catch (error) {
        console.error('Error fetching the delivery areas:', error);
      } finally {
        if (isMounted) {
          setIsFetching(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [date]);

  return (
    <FcContext.Consumer>
      {({ date }) => (
        <>
          <AuditLog entity="DELIVERYWINDOWS" deliveryAreaId={window.id} showAuditLog={showAuditLog} setShowAuditLog={setShowAuditLog} />
          <DeliveryTable
            isModalOpen={!!editingRow}
            title={`Delivery Windows on ${humanReadableDate(date)}`}
            kind="deliveryWindow"
            data={deliveryWindows}
            columns={columns}
            showCreateEdit={showCreateForm}
            showFcPicker={false}
            showDatePicker
            lastRefreshed={lastRefreshed}
            isRefreshFetching={isFetching}
            handleRefresh={handleRefresh}
          />
        </>
      )}
    </FcContext.Consumer>
  );
};

DeliveryWindow.propTypes = {
  userGroups: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default DeliveryWindow;
