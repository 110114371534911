import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'evergreen-ui';

const BatchCreationErrorsDialog = ({ batchCreationErrors, setBatchCreationErrors }) => {
  const onCloseBatchCreationErrorPage = () => {
    setBatchCreationErrors([]);
  };

  return (
    <Dialog
      isShown={batchCreationErrors.length > 0}
      width={880}
      title="Batch Creation Errors"
      confirmLabel="OK"
      onCloseComplete={onCloseBatchCreationErrorPage}
      onConfirm={onCloseBatchCreationErrorPage}
      hasCancel={false}
    >
      {batchCreationErrors.map((bcError, index) => (
        <li className="text-danger" key={`bcError-${index}`}>
          {bcError}
        </li>
      ))}
    </Dialog>
  );
};

BatchCreationErrorsDialog.propTypes = {
  batchCreationErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
  setBatchCreationErrors: PropTypes.func.isRequired,
};

export default BatchCreationErrorsDialog;
