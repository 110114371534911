import React, { useState, useEffect } from 'react';
import { Table, ChevronRightIcon } from 'evergreen-ui';
import PropTypes from 'prop-types';
import Adjustments from './Adjustments';
import './SummaryOfAdjustments.css';

const SummaryOfAdjustments = ({ adjustmentsSummary, adjustments, internalLotId, successFunction }) => {
  const [expandedRows, setExpandedRows] = useState([]);
  const titles = ['Type', 'Description', 'Net Claimed', 'Net Cleared'];
  const [multiple, setMultiple] = useState({});

  const toggleRowExpansion = (index) => {
    setExpandedRows((prevExpandedRows) => {
      if (prevExpandedRows.includes(index)) {
        return prevExpandedRows.filter((rowIndex) => rowIndex !== index);
      }
      return [...prevExpandedRows, index];
    });
  };

  useEffect(() => {
    const separatedData = adjustments.reduce((acc, adjustment) => {
      const type = adjustment.adjustment_type.replace(' ', '_');
      if (acc[type]) {
        acc[type].push(adjustment);
      } else {
        acc[type] = [adjustment];
      }
      return acc;
    }, {});

    setMultiple(separatedData);
  }, [adjustments]);

  return (
    <Table>
      <Table.Head>
        {titles.map((title, index) => (
          <Table.TextCell
            flexBasis={560}
            flexShrink={0}
            flexGrow={0}
            key={`summary-of-adjustments-title-${index}`}
            maxWidth={['Type', 'Description'].includes(title) ? 300 : 150}
          >
            {title}
          </Table.TextCell>
        ))}
      </Table.Head>
      <Table.Body>
        {adjustmentsSummary
          .filter((value) => {
            return adjustments.some((adj) => adj.adjustment_type === value.adjustment_type);
          })
          .map((value, index) => (
            <React.Fragment key={`adjustment-summary-row-${index}`}>
              <Table.Row
                key={`adjustment-summary-row-${index}`}
                style={{ cursor: 'pointer' }}
                maxHeight={50}
                onClick={() => toggleRowExpansion(index)}
              >
                <Table.TextCell maxWidth={300}>
                  <ChevronRightIcon className={`adjustments-list ${expandedRows.includes(index) ? 'rotate-icon' : 'rotate-back'}`} />
                  {value.adjustment_type}
                </Table.TextCell>
                <Table.TextCell maxWidth={300}>{value.description}</Table.TextCell>
                <Table.TextCell maxWidth={150}>{value.net_stock}</Table.TextCell>
                <Table.TextCell maxWidth={150}>{value.net_count}</Table.TextCell>
              </Table.Row>
              {expandedRows.includes(index) && (
                <Table.Row height="auto" background="tint1">
                  <Table.TextCell colSpan={4}>
                    <div style={{ padding: '16px' }}>
                      {multiple[value.adjustment_type.replace(' ', '_')] && multiple[value.adjustment_type.replace(' ', '_')].length ? (
                        <>
                          <p>Net claimed</p>
                          <Adjustments
                            adjustments={multiple[value.adjustment_type.replace(' ', '_')]}
                            internalLotId={internalLotId}
                            successFunction={successFunction}
                          />
                        </>
                      ) : (
                        <div style={{ textAlign: 'center', color: 'grey' }}>
                          <i>No adjustments</i>
                        </div>
                      )}
                    </div>
                  </Table.TextCell>
                </Table.Row>
              )}
            </React.Fragment>
          ))}
      </Table.Body>
    </Table>
  );
};

SummaryOfAdjustments.propTypes = {
  adjustmentsSummary: PropTypes.array,
  adjustments: PropTypes.array,
  internalLotId: PropTypes.string.isRequired,
  successFunction: PropTypes.func.isRequired,
};

SummaryOfAdjustments.defaultProps = {
  adjustmentsSummary: [],
  adjustments: [],
};

export default SummaryOfAdjustments;
