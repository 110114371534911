/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTable, useSortBy, useFilters, useRowSelect } from 'react-table';
import { useQueryCache, useQuery } from 'react-query';
import axios from 'axios';
import * as Icon from 'react-bootstrap-icons';
import { Button, Popover, Menu, Position, RepeatIcon, TrashIcon, toaster } from 'evergreen-ui';
import { DateTime } from 'luxon';
import { ORDER_MANAGEMENT_SERVICE_BASE_URL } from '../../../config';
import { FcContext } from '../../../context/fcContext';
import { LookupContext } from '../../../context/lookupContext';
import { AuthContext } from '../../../context/authContext';
import UpdateBatchStatusModal from '../common/UpdateBatchStatusModal';
import { DefaultColumnFilter, SelectColumnFilter, fuzzyTextFilterFn } from '../../common/tableHelpers/Filters';
import { deleteBatch, getDocboxLabelForBatch, getZplLabelsForBatch } from '../../../services/OrderManagementService';
import {
  downloadIPDFile,
  printBatchNotecards,
  printBatchLabels,
  printBatchWorkorder,
  printBatchOnePager,
  printUpsWorldeaseMasterInvoice,
} from '../../../utilities/BatchActions';
import IndeterminateCheckbox from '../../common/tableHelpers/IndeterminateCheckbox';
import Modal from '../../common/Modal';
import { roleChecks } from '../../../utilities/Role';
import { CompleteBatchSuborder } from '../common/CompleteBatchSuborderModal';
import { printZpl } from '../../common/printingHelpers/ZebraPrint';
import DeleteBatchModal from '../common/DeleteBatchModal';

const BatchList = ({ date, fcId }) => {
  const [data, setData] = useState([]);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [batchToUpdate, setBatchToUpdate] = useState({});
  const [batchToDelete, setBatchToDelete] = useState({});
  const [batchDeletionSuccessAlert, setBatchDeletionSuccessAlert] = useState({});
  const queryCache = useQueryCache();

  const [latestRefreshTime, setLatestRefreshTime] = useState(DateTime.now());

  const { productFeed } = useContext(LookupContext);
  const { userGroups } = useContext(AuthContext);

  const [showDeleteBatchesModal, setShowDeleteBatchesModal] = useState(false);
  const [showDeleteResult, setShowDeleteResult] = useState(false);
  const [unsuccessfulBatchDeletions, setUnsuccessfulBatchDeletions] = useState([]);

  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const [confirmCompleteShow, setConfirmCompleteShow] = useState(false);

  const [selectedBatchId, setSelectedBatchId] = useState(false);
  const [selectedBatchName, setSelectedBatchName] = useState(null);

  const fetchData = async () => {
    if (!isFetchingData) {
      setIsFetchingData(true);

      const { data } = await axios.get(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/fulfillmentCenters/${fcId}/batches/${date}`);
      const {
        data: { batches },
      } = data;
      batches.sort((a, b) => {
        return DateTime.fromISO(b.createdAt) - DateTime.fromISO(a.createdAt);
      });
      setData(batches);

      setIsFetchingData(false);
    }
  };

  const { status, error } = useQuery(['batches', { fcId, date }], fetchData, { retry: false });

  const deleteBatchAfterEffects = async () => {
    queryCache.invalidateQueries('fulfillmentSuborders');
    queryCache.invalidateQueries('batches');
  };

  const getLabelsAndPrint = async (batchId) => {
    const response = await getZplLabelsForBatch(batchId);

    if (response) {
      const {
        data: { labels },
      } = response;
      if (!labels || labels.length === 0) {
        toaster.danger('Could not generate labels for suborders. Please contact the dev team if you think this is a mistake.');
      } else {
        toaster.notify('Printing labels...');
        printZpl(labels, () => {
          queryCache.invalidateQueries('fulfillmentSuborders');
          queryCache.invalidateQueries('batches');
        });
      }
    }
  };

  const printDocboxLabel = async (batchId) => {
    const response = await getDocboxLabelForBatch(batchId);

    try {
      if (!response || !response.data || !response.data.label) {
        throw new Error('Cannot generate docbox label for batch. Please contact the dev team if you think this is a mistake.');
      }

      const { label } = response.data;

      toaster.notify('Printing labels...');

      printZpl([label]);
    } catch (e) {
      toaster.danger(e.message);
    }
  };

  const refreshBatches = async () => {
    queryCache.invalidateQueries('batches');
    setLatestRefreshTime(DateTime.now());
  };

  function getContextMenu(batchId, currStatus, batchName) {
    return (
      <FcContext.Consumer>
        {({ fcConfig }) => (
          <Popover
            position={Position.BOTTOM_LEFT}
            content={
              <Menu>
                <Menu.Group>
                  <Menu.Item>
                    <a href="#!" onClick={() => printBatchWorkorder(batchId, currStatus, productFeed, fcConfig, queryCache)}>
                      Print workorder
                    </a>
                  </Menu.Item>
                  {(fcConfig.useGuidedFulfillment || fcConfig.useBulkAndGuidedFulfillment) && (
                    <Menu.Item>
                      <Link to={{ pathname: `/fulfill/${batchId}` }}>Fulfill</Link>
                    </Menu.Item>
                  )}
                </Menu.Group>
                <Menu.Divider />
                <Menu.Group>
                  <Menu.Item>
                    <a href="#!" onClick={() => printBatchNotecards(batchId, currStatus, queryCache)}>
                      Print notecards
                    </a>
                  </Menu.Item>
                  <Menu.Item>
                    {fcConfig.useIPD && (
                      <a href="#!" onClick={() => downloadIPDFile(batchId, currStatus, queryCache)}>
                        Download FedEx IPD
                      </a>
                    )}
                    {fcConfig.isUpsWorldeaseFc && (
                      <a href="#!" onClick={() => getLabelsAndPrint(batchId)}>
                        Print ZPL labels
                      </a>
                    )}
                    {!fcConfig.useIPD && !fcConfig.isUpsWorldeaseFc && (
                      <a href="#!" onClick={() => printBatchLabels(batchId, currStatus, queryCache, fcConfig)}>
                        Print labels
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    <a href="#!" onClick={() => printBatchOnePager(batchId, currStatus, productFeed, queryCache)}>
                      Print 1-pager
                    </a>
                  </Menu.Item>
                  {fcConfig.isUpsWorldeaseFc && currStatus === 'Completed' && (
                    <Menu.Item>
                      <a href="#!" onClick={() => printDocboxLabel(batchId)}>
                        Print Docbox Label
                      </a>
                    </Menu.Item>
                  )}
                  {fcConfig.isUpsWorldeaseFc && currStatus === 'Completed' && (
                    <Menu.Item>
                      <a href="#!" onClick={() => printUpsWorldeaseMasterInvoice(batchId)}>
                        Print Master Invoice
                      </a>
                    </Menu.Item>
                  )}
                </Menu.Group>
                <Menu.Divider />
                <Menu.Group>
                  {!fcConfig.useGuidedFulfillment && ['SubordersPrinted', 'AtTable'].includes(currStatus) && (
                    <Menu.Item>
                      <a
                        href="#!"
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedBatchId(batchId);
                          setSelectedBatchName(batchName);
                          setConfirmCompleteShow(true);
                        }}
                      >
                        Complete batch
                      </a>
                    </Menu.Item>
                  )}
                  <Menu.Item disabled={!userGroups.some((u) => ['OpsManager'].indexOf(u) >= 0)} intent="warning">
                    <a href="#!" onClick={() => setBatchToUpdate({ batchId, currStatus })}>
                      Update batch status...
                    </a>
                  </Menu.Item>
                  <Menu.Item intent="danger">
                    <a href="#!" onClick={() => setBatchToDelete({ batchId, batchName })}>
                      Delete batch
                    </a>
                  </Menu.Item>
                </Menu.Group>
              </Menu>
            }
          >
            <Icon.ThreeDotsVertical className="text-muted" />
          </Popover>
        )}
      </FcContext.Consumer>
    );
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row }) => <Link to={{ pathname: `/batches/${row.original.batchId}` }}>{row.original.name}</Link>,
      },
      {
        Header: '# of Suborders',
        accessor: 'suborderCount',
        disableFilters: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: SelectColumnFilter,
        filter: 'equals',
        Cell: ({ value }) => (
          <span className={`badge ${value === 'Completed' ? 'bg-success' : 'bg-light text-dark'}`} style={{ fontSize: '1em' }}>
            {value}
          </span>
        ),
      },
      {
        Header: '',
        accessor: 'batchId',
        disableFilters: true,
        Cell: ({ row }) => {
          return getContextMenu(row.original.batchId, row.original.status, row.original.name);
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase()) : true;
        });
      },
    }),
    [],
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, toggleAllRowsSelected, initialRows } = useTable(
    { columns, data, defaultColumn, filterTypes },
    useFilters,
    useSortBy,
    useRowSelect,
    (hooks) => {
      if (roleChecks.canRunBulkBatchAction(userGroups)) {
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div className="form-check mb-n2">
                <IndeterminateCheckbox className="form-check-input list-checkbox-all" {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div className="form-check">
                <IndeterminateCheckbox className="form-check-input list-checkbox" {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
      }
    },
  );

  const selectedBatches = initialRows.filter((row) => row.isSelected).map((row) => row.original);

  const runDeleteBatchesCall = async () => {
    setIsLoadingDelete(true);
    setShowDeleteBatchesModal(false);

    const awaitingDelete = [];
    for (const batch of selectedBatches) {
      const { batchId } = batch;
      awaitingDelete.push(deleteBatch(batchId));
    }

    const deleteBatchResponses = await Promise.all(awaitingDelete);
    const newUnsuccessfulBatchDeletions = deleteBatchResponses.filter((deleteBatchResponse) => !deleteBatchResponse.success);

    setUnsuccessfulBatchDeletions(newUnsuccessfulBatchDeletions);
    setShowDeleteResult(true);
    toggleAllRowsSelected(false);
    setIsLoadingDelete(false);
    queryCache.invalidateQueries('batches');
    queryCache.invalidateQueries('fulfillmentSuborders');
  };

  const onDoneUpdate = () => {
    setConfirmCompleteShow(false);
    queryCache.invalidateQueries();
  };

  const renderSortDirection = (column) => {
    if (column.isSorted) return column.isSortedDesc ? ' 🔽' : ' 🔼';
    return '';
  };

  return (
    <FcContext.Consumer>
      {({ fcConfig }) => (
        <div className="col-12 col-md-7">
          {selectedBatchId && (
            <CompleteBatchSuborder
              visible={confirmCompleteShow}
              batchId={selectedBatchId}
              fcConfig={fcConfig}
              batchName={selectedBatchName}
              batchStatus=""
              onDone={onDoneUpdate}
            />
          )}
          <div className="card">
            <div className="card-header">
              <div>
                <h4 className="card-header-title d-inline-block">Batches</h4>
                {selectedBatches.length > 0 && (
                  <Button appearance="primary" intent="danger" marginLeft={12} iconBefore={TrashIcon} onClick={() => setShowDeleteBatchesModal(true)}>
                    Delete {selectedBatches.length} Batches
                  </Button>
                )}
              </div>
              <span style={{ display: 'inline-block', verticalAlign: 'middle', paddingRight: '0.5em', fontSize: '13px', color: 'gray' }}>
                <div>Last Refreshed</div>
                {latestRefreshTime.toFormat('h:mm:ssa')}
              </span>
              <Button iconAfter={RepeatIcon} isLoading={status === 'loading' || isLoadingDelete} onClick={() => refreshBatches()}>
                Refresh
              </Button>
            </div>
            <div className="table-responsive mb-0">
              <table {...getTableProps()} className="table table-sm table-hover card-table">
                <thead>
                  {headerGroups.map((headerGroup, headerGroupIndex) => (
                    <tr key={`batchlist-row-header-index-${headerGroupIndex}`} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, columnIndex) => (
                        <th key={`batchlist-header-index-${columnIndex}`} {...column.getHeaderProps()}>
                          <div>
                            <span {...column.getSortByToggleProps()}>
                              {column.render('Header')}
                              {renderSortDirection(column)}
                            </span>
                          </div>
                          <div>{column.canFilter ? column.render('Filter') : null}</div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                {status !== 'loading' && status !== 'error' && !isLoadingDelete && (
                  <tbody {...getTableBodyProps()} className="list font-size-base">
                    {rows.map((row, rowIndex) => {
                      prepareRow(row);
                      return (
                        <tr key={`batchlist-row-index-${rowIndex}`} {...row.getRowProps()}>
                          {row.cells.map((cell, cellIndex) => {
                            return (
                              <td key={`batchlist-cell-index-${cellIndex}`} {...cell.getCellProps()}>
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
              {status === 'loading' && (
                <div className="card-body text-center">
                  <p className="text-muted">Loading list of batches...</p>
                </div>
              )}
              {isLoadingDelete && (
                <div className="card-body text-center">
                  <p className="text-muted">Deleting selected batches...</p>
                </div>
              )}
              {status === 'error' && (
                <div className="card-body text-center">
                  <p className="text-muted">Error: {error.message}</p>
                </div>
              )}
            </div>
          </div>
          <Modal
            title="Delete Batches"
            onConfirm={runDeleteBatchesCall}
            onClose={() => setShowDeleteBatchesModal(false)}
            onCancel={() => setShowDeleteBatchesModal(false)}
            visible={showDeleteBatchesModal}
          >
            <div>Are you sure you want to delete the {selectedBatches.length} selected batches?</div>
          </Modal>
          {showDeleteResult &&
            (unsuccessfulBatchDeletions.length ? (
              <div className="list-alert alert alert-danger alert-dismissible border fade show" role="alert">
                <div className="row align-items-center">
                  <div className="col">{unsuccessfulBatchDeletions.map((batch) => batch.name).join(', ')} were not deleted.</div>
                </div>

                <button type="button" className="list-alert-close close" onClick={() => setShowDeleteResult(false)} aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            ) : (
              <div className="list-alert alert alert-success alert-dismissible border fade show" role="alert">
                <div className="row align-items-center">
                  <div className="col">All selected batches were successfully deleted.</div>
                </div>

                <button type="button" className="list-alert-close close" onClick={() => setShowDeleteResult(false)} aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            ))}
          {batchToUpdate.batchId && (
            <UpdateBatchStatusModal
              batchId={batchToUpdate.batchId}
              currStatus={batchToUpdate.currStatus}
              onClose={() => {
                setBatchToUpdate({});
                queryCache.invalidateQueries('batches');
              }}
            />
          )}
          {batchToDelete.batchId && (
            <DeleteBatchModal
              batchId={batchToDelete.batchId}
              batchName={batchToDelete.batchName}
              onClose={(response) => {
                const { batchName } = batchToDelete;
                setBatchToDelete({});
                deleteBatchAfterEffects();
                if (response.wasDeleted) {
                  setBatchDeletionSuccessAlert({
                    show: true,
                    batchName,
                  });
                }
              }}
            />
          )}
          {batchDeletionSuccessAlert.show && (
            <div className="list-alert alert alert-success alert-dismissible border fade show" role="alert">
              <div className="row align-items-center">
                <div className="col">{batchDeletionSuccessAlert.batchName} was successfully deleted.</div>
              </div>

              <button type="button" className="list-alert-close close" onClick={() => setBatchDeletionSuccessAlert({})} aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
          )}
        </div>
      )}
    </FcContext.Consumer>
  );
};

BatchList.propTypes = {
  date: PropTypes.string.isRequired,
  fcId: PropTypes.string.isRequired,
};

export default BatchList;
