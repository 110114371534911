import React, { useState } from 'react';
import getDisableDivStyle from '../helpers/getDisableDivStyle';

const IssueSelectionComponent = ({
  iconComponent = null,
  issueText,
  issueSubtext = null,
  issuePretext = null,
  isSelected,
  setIsSelected,
  selectionType = 'radio',
  allowDeselection = false,
  onSelection = () => {},
  postTextComponents = null,
  additionalWrapperClass = '',
  canClickEntireComponent = true,
  isDisabled = false,
}) => {
  const toggle = allowDeselection
    ? () => {
        if (!isSelected === true) {
          onSelection();
        }
        setIsSelected(!isSelected);
      }
    : () => {
        if (!isSelected) {
          setIsSelected(true);
          onSelection();
        }
      };

  const componentBody = () => (
    <div className="row align-items-center">
      {!isDisabled && (
        <div className="col-auto">
          <input
            className="form-check-input"
            type={selectionType}
            checked={isSelected || false}
            onChange={() => {}}
            onClick={canClickEntireComponent ? undefined : toggle}
          />
        </div>
      )}
      {iconComponent && <div className="col-auto">{iconComponent}</div>}
      <div className={issuePretext ? 'col-auto' : 'col'}>
        <div className="row">
          <div className="col" style={isDisabled ? { color: 'gray' } : {}}>
            {issuePretext && (
              <span>
                <span>{`${issuePretext} `}</span>
                <span className="font-weight-bold">{issueText}</span>
              </span>
            )}
            {!issuePretext && <h3 className="mb-0">{issueText}</h3>}
            {issueSubtext && <div style={{ color: 'gray' }}>{issueSubtext}</div>}
          </div>
        </div>
      </div>
      {isSelected && postTextComponents && <div className="col col-lg-6">{postTextComponents}</div>}
    </div>
  );

  return (
    <div
      style={isDisabled ? getDisableDivStyle() : {}}
      className={'card' + (isSelected ? ' border-2 border-primary' : '') + additionalWrapperClass}
      onClick={canClickEntireComponent ? toggle : undefined}
    >
      <div className="card-body p-3">{componentBody()}</div>
    </div>
  );
};

export default IssueSelectionComponent;
