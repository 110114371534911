import React from 'react';
import PropTypes from 'prop-types';
import { SelectMenu, Button, Icon, ChevronDownIcon } from 'evergreen-ui';

export const MultipleColumnFilter = ({
  column: { filterValue = [], setFilter, preFilteredRows, id },
}) => {
  const options = React.useMemo(() => {
    const optionsSet = new Set();
    preFilteredRows.forEach((row) => {
      const value = row.values[id];
      if (value !== undefined && value !== null) {
        const stringValue = typeof value === 'string' ? value.trim() : value.toString();
        if (stringValue !== '') {
          optionsSet.add(stringValue);
        }
      }
    });
    return [...optionsSet.values()].sort();
  }, [id, preFilteredRows]);

  const handleSelect = (item) => {
    const newFilterValue = [...filterValue, item.value];
    setFilter(newFilterValue);
  };

  const handleDeselect = (item) => {
    const newFilterValue = filterValue.filter((value) => value !== item.value);

    if (newFilterValue.length === 0) {
      setFilter(undefined);
    } else {
      setFilter(newFilterValue);
    }
  };

  const renderButtonLabel = () => {
    if (!filterValue || filterValue.length === 0) {
      return 'Select';
    }
    if (filterValue.length === 1) {
      return filterValue[0];
    }
    return `${filterValue[0]} +${filterValue.length - 1}`;
  };

  return (
    <SelectMenu
      isMultiSelect
      title="Select"
      options={options.map((option) => ({ label: option, value: option }))}
      selected={filterValue}
      onSelect={handleSelect}
      onDeselect={handleDeselect}
      position="bottom-left"
    >
      <Button marginRight={12} size="small">
        {renderButtonLabel()} <Icon style={{ marginLeft: 5 }} icon={ChevronDownIcon} size={12} />
      </Button>
    </SelectMenu>
  );
};

MultipleColumnFilter.propTypes = {
  column: PropTypes.shape({
    filterValue: PropTypes.array,
    setFilter: PropTypes.func.isRequired,
    preFilteredRows: PropTypes.arrayOf(
      PropTypes.shape({
        values: PropTypes.object.isRequired,
      }),
    ).isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
};
