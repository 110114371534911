import React, { useContext, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { Button, CrossIcon, Pane, PlusIcon } from 'evergreen-ui';
import { ArrowRight } from 'react-bootstrap-icons';
import Select from 'react-select';
import { ProgressBar, Step } from 'react-step-progress-bar';
import Flatpickr from 'react-flatpickr';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css';

import Footer from '../../../common/wizards/Footer';
import getIdealProductName from '../../helpers/getIdealProductName';
import { LookupContext } from '../../../../context/lookupContext';
import TextInput from '../../../common/TextInput';
import getInventoryForUseEffect from '../../helpers/getInventoryForUseEffect';
import { AuthContext } from '../../../../context/authContext';
import { generateNewCoords, remedySuborder, removeSuborderException } from '../../../../services/OrderManagementService';
import UpdateDelivery from '../../UpdateDelivery';
import { computeWindow } from '../../helpers/computeWindow';
import isPrimaryProduct from '../../helpers/isPrimaryProduct';

const Step2Body = ({ suborder, nextStep, pushToSuccesses }) => {
  const fontSize = '14px';
  const { deliveryAreas, productFeed } = useContext(LookupContext);
  const { userGroups } = useContext(AuthContext);

  const [delivery, setDelivery] = useState(suborder.delivery);
  const [deliveryDate, setDeliveryDate] = useState(suborder.delivery.startTime);
  const [lineItems, setLineItems] = useState(suborder.lineItems);
  const oldLineItems = suborder.lineItems.filter((lineItem) => !lineItem.isDeleted);
  const [continueDisable, setContinueDisable] = useState(true);

  const [selectedSkus, setSelectedSkus] = useState([]);
  const [skuToUpgradeMap, setSkuToUpgradeMap] = useState({});
  const [additionalLineItems, setAdditionalLineItems] = useState([]);

  const [recipient, setRecipient] = useState(suborder.recipient);
  const [newRecipientDetails, setNewRecipientDetails] = useState(suborder.recipient);
  const [editingRecipient, setEditingRecipient] = useState(false);
  const [productOptions, setProductOptions] = useState([]);
  const [showBadAddressErrorMessage, setShowBadAddressErrorMessage] = useState(false);

  const [showChangeDelivery, setShowChangeDelivery] = useState(true);

  const [argsForRemedy, setArgsForRemedy] = useState({});

  const updateArgsForRemedy = (params) => {
    setArgsForRemedy({ ...argsForRemedy, ...params });
  };

  useEffect(() => getInventoryForUseEffect({ delivery, deliveryDate, deliveryAreas, productFeed, userGroups, setProductOptions }), [deliveryDate]);

  const skuKey = (li) => `${li.sku}-${li.index}`;

  const getSanitizedLineItems = (newLineItems) => {
    const arrayToReturn = [];
    for (const lineItem of newLineItems) {
      if (lineItem.allowedTransportModes || lineItem.distributionPoints || lineItem.categories) {
        const sanitized = {
          unitPrice: 0,
          taxRate: 0,
          images: {
            large: lineItem.imageUrl,
            thumbnail: lineItem.imageThumbUrl,
          },
          quantity: 1,
          originalPrice: 0,
          price: 0,
          name: lineItem.name,
          index: -1,
          discountAmount: 0,
          taxAmount: 0,
          sku: lineItem.sku,
          isPrimaryProduct: isPrimaryProduct(lineItem.sku, productFeed),
          components: lineItem.kit,
        };
        arrayToReturn.push(sanitized);
      } else {
        arrayToReturn.push(lineItem);
      }
    }
    return arrayToReturn;
  };

  useEffect(() => {
    const olderOnes =
      selectedSkus.length > 0
        ? oldLineItems.filter((li) => selectedSkus.includes(skuKey(li)) && !Object.keys(skuToUpgradeMap).includes(skuKey(li)))
        : [];
    const lineItemsToDelete =
      selectedSkus.length > 0
        ? oldLineItems.filter((li) => selectedSkus.includes(skuKey(li)) && Object.keys(skuToUpgradeMap).includes(skuKey(li)))
        : [];
    const upgrades = Object.keys(skuToUpgradeMap)
      .map((key) => skuToUpgradeMap[key])
      .filter((upgrade) => upgrade != null);
    const additionals = additionalLineItems.filter((additional) => additional != null);
    const newLineItems = [...olderOnes, ...upgrades, ...additionals];

    const sanitized = getSanitizedLineItems(newLineItems);
    setLineItems(sanitized);

    const sanitizedDeletedLineItems = getSanitizedLineItems(lineItemsToDelete);

    updateArgsForRemedy({ lineItems: sanitized, deletedLineItems: sanitizedDeletedLineItems });
  }, [selectedSkus, skuToUpgradeMap, additionalLineItems]);

  const getSanitizedDelivery = (passedInDelivery) => {
    const copyOfDelivery = { ...passedInDelivery };
    if (copyOfDelivery.deliveryAreaName) {
      delete copyOfDelivery.deliveryAreaName;
    }
    if (copyOfDelivery.windowDescription) {
      delete copyOfDelivery.windowDescription;
    }

    return copyOfDelivery;
  };

  useEffect(() => updateArgsForRemedy({ delivery: getSanitizedDelivery(delivery), recipient }), [delivery, recipient]);

  const onLineItemCheckboxSelect = (isChecked, lineItem) => {
    if (isChecked) {
      setSelectedSkus([...selectedSkus, skuKey(lineItem)]);
    } else {
      const arr = [...selectedSkus];
      if (arr.includes(skuKey(lineItem))) {
        const indexInPrevValues = arr.indexOf(skuKey(lineItem));
        arr.splice(indexInPrevValues, 1);
        setSelectedSkus(arr);
      }
      const newSkuToUpgradeMap = { ...skuToUpgradeMap };
      delete newSkuToUpgradeMap[skuKey(lineItem)];
      setSkuToUpgradeMap(newSkuToUpgradeMap);
    }
  };

  const getLineItemDisplay = (lineItem) => `${lineItem.quantity}x ${getIdealProductName(productFeed, lineItem)}`;

  const onUpgradeClick = (sku) => {
    setSkuToUpgradeMap({
      ...skuToUpgradeMap,
      [sku]: null,
    });
  };

  const onCancelUpgradeClick = (sku) => {
    const upgradeMap = { ...skuToUpgradeMap };
    delete upgradeMap[sku];
    setSkuToUpgradeMap(upgradeMap);
  };

  const onCancelAdditionalItemClick = (index) => {
    const arr = [...additionalLineItems];
    const newArr = [];
    for (let i = 0; i < additionalLineItems.length; i++) {
      if (i !== index) {
        newArr.push(arr[i]);
      }
    }
    setAdditionalLineItems(newArr);
  };

  const onAddItemClick = () => {
    setAdditionalLineItems([...additionalLineItems, null]);
  };

  const onLineItemSelection = (oldSku, indexInAdditionalItemArray, newLineItem) => {
    if (oldSku) {
      const newSkuToUpgradeMap = { ...skuToUpgradeMap };
      newSkuToUpgradeMap[oldSku] = newLineItem;
      setSkuToUpgradeMap(newSkuToUpgradeMap);
    } else if (additionalLineItems.length > indexInAdditionalItemArray) {
      const newAdditionalLineItems = [...additionalLineItems];
      newAdditionalLineItems[indexInAdditionalItemArray] = newLineItem;
      setAdditionalLineItems(newAdditionalLineItems);
    }
  };

  const createAProductDropdown = (oldSku, indexInAdditionalItemArray) => {
    return (
      <Select
        id={`selectNewProduct-${lineItems.length}`}
        defaultValue={oldSku ? skuToUpgradeMap[oldSku] : additionalLineItems[indexInAdditionalItemArray]}
        onChange={(event) => onLineItemSelection(oldSku, indexInAdditionalItemArray, event.value)}
        options={productOptions}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
      />
    );
  };

  const onEditRecipientClick = () => setEditingRecipient(true);

  const getAddressWithCoords = async () => {
    const fieldsToCheck = ['address1', 'address2', 'state', 'city', 'zipCode'];
    const addressHasBeenChanged = fieldsToCheck.filter((field) => recipient[field] !== newRecipientDetails[field]).length > 0;
    if (addressHasBeenChanged) {
      const newCoordsResponse = await generateNewCoords(newRecipientDetails);
      if (newCoordsResponse.success && newCoordsResponse.coordinates && newCoordsResponse.coordinates.lat) {
        return {
          ...newRecipientDetails,
          coords: newCoordsResponse.coordinates,
        };
      }
      return null;
    }
    return newRecipientDetails;
  };

  const onSaveRecipientClick = async () => {
    const fullRecipient = await getAddressWithCoords();
    if (fullRecipient) {
      setRecipient(fullRecipient);
      setNewRecipientDetails(fullRecipient);
      setEditingRecipient(false);
      setShowBadAddressErrorMessage(false);
    } else {
      setShowBadAddressErrorMessage(true);
    }
  };

  const onCancelRecipientEditClick = () => {
    setNewRecipientDetails(recipient);
    setEditingRecipient(false);
  };

  const argsForUpdateDeliveryComponent = {
    deliveryDate,
    lineItems,
    window: computeWindow(delivery),
    deliveryArea: delivery.areaId,
  };

  const continueAction = async () => {
    try {
      const response = await remedySuborder(suborder.suborderId, argsForRemedy);
      if (response.success) {
        await removeSuborderException(suborder.suborderId);
        pushToSuccesses({ id: suborder.suborderId, message: 'Successfully remedied suborder' });
      } else {
        // eslint-disable-next-line no-console
        console.error('Did not successfully remedy', { message: response.message, suborder });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Did not successfully remedy', { error, suborder });
    }

    nextStep();
  };

  const skipAction = nextStep;

  return (
    <>
      <Pane minHeight={460}>
        <h2 className="mt-2 mb-2 text-center">Remedy Suborder: {suborder.suborderId}</h2>
        <div className="pb-2 text-center" style={{ fontSize }}>
          Choose what to send to the recipient and when to send it.
        </div>
        <h4 className="mt-4">When should the new package be delivered?</h4>
        <Flatpickr
          value={deliveryDate}
          onChange={(date, dateStr) => setDeliveryDate(dateStr)}
          options={{ minDate: DateTime.now().toISO() }}
          placeholder="Select delivery date..."
        />
        <h4 className="mt-4">What should we send?</h4>
        {oldLineItems.map((lineItem) => (
          <div className="m-3 ml-0" key={`${lineItem.index}key`}>
            <div className="row align-items-center">
              <div className="col-auto">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={selectedSkus.includes(skuKey(lineItem))}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    onLineItemCheckboxSelect(isChecked, lineItem);
                  }}
                />
              </div>
              <div className="col-auto">{getLineItemDisplay(lineItem)}</div>
              {!Object.keys(skuToUpgradeMap).includes(skuKey(lineItem)) && selectedSkus.includes(skuKey(lineItem)) && (
                <div className="col-auto">
                  <Button onClick={() => onUpgradeClick(skuKey(lineItem))}>Upgrade</Button>
                </div>
              )}
              {Object.keys(skuToUpgradeMap).includes(skuKey(lineItem)) && (
                <>
                  <div className="col-auto">
                    <ArrowRight size="1.5em" />
                  </div>
                  <div className="col col-lg-6">{createAProductDropdown(skuKey(lineItem), null)}</div>
                  <div className="col-auto">
                    <Button intent="danger" onClick={() => onCancelUpgradeClick(skuKey(lineItem))}>
                      Cancel
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
        {additionalLineItems.map((additionalLineItem, index) => (
          <div key={`additional-${index}`} className="mb-3 row">
            <div className="col col-lg-6">{createAProductDropdown(null, index)}</div>
            <div className="col-auto">
              <Button iconBefore={CrossIcon} intent="danger" onClick={() => onCancelAdditionalItemClick(index)}>
                Cancel Item
              </Button>
            </div>
          </div>
        ))}
        {(!additionalLineItems.length || additionalLineItems[additionalLineItems.length - 1]) && (
          <div className="row">
            <div className="col-auto">
              <Button iconBefore={PlusIcon} onClick={() => onAddItemClick()}>
                Add Item
              </Button>
            </div>
          </div>
        )}

        <h4 className="mt-4">Where should we send it?</h4>
        {!editingRecipient && (
          <>
            <div>
              {recipient.firstName} {recipient.lastName}
            </div>
            <div>
              {recipient.address1} {recipient.address2}
            </div>
            <div>
              {recipient.city}, {recipient.state} {recipient.zipCode}
            </div>
            <div className="mt-3">
              <Button onClick={() => onEditRecipientClick()}>Edit</Button>
            </div>
          </>
        )}
        {editingRecipient && (
          <>
            <div className="row">
              <div className="col-auto">
                <TextInput
                  label="First Name"
                  placeholder="Recipient First Name"
                  name="recipientFirstName"
                  value={newRecipientDetails.firstName}
                  onChange={(event) => setNewRecipientDetails({ ...newRecipientDetails, firstName: event.target.value })}
                />
              </div>
              <div className="col-auto">
                <TextInput
                  label="Last Name"
                  placeholder="Recipient Last Name"
                  name="recipientLastName"
                  value={newRecipientDetails.lastName}
                  onChange={(event) => setNewRecipientDetails({ ...newRecipientDetails, lastName: event.target.value })}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-auto">
                <TextInput
                  label="Address"
                  placeholder="Recipient Address"
                  name="recipientAddress1"
                  value={newRecipientDetails.address1}
                  onChange={(event) => setNewRecipientDetails({ ...newRecipientDetails, address1: event.target.value })}
                />
              </div>
              <div className="col-auto">
                <TextInput
                  label="Apartment #"
                  placeholder="Recipient Apt #"
                  name="recipientAddress2"
                  value={newRecipientDetails.address2}
                  onChange={(event) => setNewRecipientDetails({ ...newRecipientDetails, address2: event.target.value })}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-auto">
                <TextInput
                  label="City"
                  placeholder="Recipient City"
                  name="recipientCity"
                  value={newRecipientDetails.city}
                  onChange={(event) => setNewRecipientDetails({ ...newRecipientDetails, city: event.target.value })}
                />
              </div>
              <div className="col-auto">
                <TextInput
                  label="State"
                  placeholder="Recipient State"
                  name="recipientState"
                  value={newRecipientDetails.state}
                  onChange={(event) => setNewRecipientDetails({ ...newRecipientDetails, state: event.target.value })}
                />
              </div>
              <div className="col-auto">
                <TextInput
                  label="Zipcode"
                  placeholder="Recipient Zipcode"
                  name="recipientZipcode"
                  value={newRecipientDetails.zipCode}
                  onChange={(event) => setNewRecipientDetails({ ...newRecipientDetails, zipCode: event.target.value })}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-auto">
                <Button intent="success" onClick={onSaveRecipientClick}>
                  Save
                </Button>
              </div>
              <div className="col-auto">
                <Button intent="danger" onClick={() => onCancelRecipientEditClick()}>
                  Cancel
                </Button>
              </div>
            </div>
            {showBadAddressErrorMessage && (
              <div className="row">
                <span className="text-danger">Bad address input; could not find coordinates.</span>
              </div>
            )}
          </>
        )}
        {deliveryDate && DateTime.fromISO(deliveryDate).toMillis() > DateTime.now().toMillis() && lineItems.length > 0 && (
          <>
            <h4 className="mt-4">Fulfillment Center and Delivery Method</h4>
            <UpdateDelivery
              suborders={[suborder]}
              fcId={null}
              deliveryAreas={deliveryAreas}
              showChangeDelivery={showChangeDelivery}
              setShowChangeDelivery={setShowChangeDelivery}
              onComplete={() => {}}
              coords={recipient.coords}
              zipCode={recipient.zipCode}
              setDeliveryFields={setDelivery}
              useDialog={false}
              redeliveryArgs={argsForUpdateDeliveryComponent}
              newOrder={false}
              setDisable={setContinueDisable}
            />
          </>
        )}
      </Pane>

      <Footer
        showBack={false}
        forwardActionText="Continue"
        forwardAction={continueAction}
        disableForwardOn={continueDisable}
        hasSkipButton
        skipButtonFunctionality={skipAction}
      />
    </>
  );
};

const Step2 = ({ suborders, nextStep, pushToSuccesses }) => {
  const [suborder, setSuborder] = useState(suborders[0]);

  const suborderIds = suborders.map((suborder) => suborder.suborderId);

  const indexOfCurrentSuborder = suborderIds.indexOf(suborder.suborderId);

  const nextStepForBody = () => {
    if (indexOfCurrentSuborder === suborders.length - 1) {
      nextStep();
    } else {
      setSuborder(suborders[indexOfCurrentSuborder + 1]);
    }
  };

  const percent = (indexOfCurrentSuborder / (suborders.length - 1)) * 100;
  const progressBar = () => (
    <ProgressBar percent={percent}>
      {suborders.map((suborder, suborderIndex) => (
        <Step key={`stepinprogress-${suborder.suborderId}`}>
          {({ accomplished }) => (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div className={`indexedStep ${accomplished || indexOfCurrentSuborder > suborderIndex ? 'accomplished' : null}`}>{suborderIndex + 1}</div>
          )}
        </Step>
      ))}
    </ProgressBar>
  );

  return (
    <>
      {suborders.length > 1 && progressBar()}

      <Step2Body suborder={suborder} nextStep={nextStepForBody} pushToSuccesses={pushToSuccesses} />
    </>
  );
};

Step2Body.propTypes = {
  suborder: PropTypes.object.isRequired,
  nextStep: PropTypes.func.isRequired,
  pushToSuccesses: PropTypes.func.isRequired,
};

Step2.propTypes = {
  suborders: PropTypes.array.isRequired,
  nextStep: PropTypes.func.isRequired,
  pushToSuccesses: PropTypes.func.isRequired,
};

export default Step2;
