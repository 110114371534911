import React from 'react';
import PropTypes from 'prop-types';
import { formattedValue } from './utils';

const TableRow = ({ name, value, nameStyles, valueStyles, typeTd, promoCode, addMinus, trStyle }) => {
  const val = formattedValue(value, addMinus);

  let nameTd;
  switch (typeTd) {
    case 'promo':
      nameTd = (<td className="px-0 py-1">{name} <span className="badge bg-info">{promoCode}</span></td>);
      break;
    case 'total':
      nameTd = (
        <td className="px-0 py-1 border-top border-top-2">
          <strong className="h3">{name}</strong>
        </td>
      );
      break;
    default:
      nameTd = (<td className={`px-0 py-1 ${nameStyles}`}>{name}</td>);
      break;
  }
  return (
    <tr className={trStyle}>
      {nameTd}
      <td className={`px-0 py-1 text-right ${valueStyles}`}>{val}</td>
    </tr>
  );
};

TableRow.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  nameStyles: PropTypes.string,
  valueStyles: PropTypes.string,
  typeTd: PropTypes.string,
  promoCode: PropTypes.string,
  addMinus: PropTypes.string,
  trStyle: PropTypes.string,
};

TableRow.defaultProps = {
  nameStyles: '',
  valueStyles: '',
  typeTd: '',
  promoCode: '',
  addMinus: '',
  trStyle: '',
};

export default TableRow;
