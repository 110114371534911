/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import PageHeader from '../nav/PageHeader';

const Fulfill = () => {
  const [batchId, setBatchId] = useState('');
  const history = useHistory();

  const onChange = (e) => setBatchId(e.target.value);

  const onSubmit = (e) => {
    e.preventDefault();
    history.push(`/fulfill/${batchId}`);
  };

  return (
    <>
      <PageHeader category="Fulfillment" title="Fulfill Batch" />
      <div className="container-fluid">
        <div className="col-12">
          <form className="mb-4" onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="batchIdString" className="form-label">
                Batch Id
              </label>
              <input
                id="batchIdString"
                type="text"
                autoFocus
                className="form-control"
                placeholder="Scan Batch Barcode / Enter Batch Id"
                name="batchId"
                value={batchId}
                onChange={onChange}
              />
            </div>
            <button className="btn btn-primary" type="submit">
              Search
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Fulfill;
