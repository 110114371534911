import React from 'react';
import { CreditCard } from 'react-bootstrap-icons';

const Header = () => {
  return (
    <div className="card-header">
      <h4 className="card-header-title">
        <CreditCard size="1.5em" className="mr-2" />
        <span>Payment Summary</span>
      </h4>
    </div>
  );
};

export default Header;
