import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'evergreen-ui';

const CellAction = ({ row, showCreateEditModal }) => (
  <Button intent="danger" className="button" onClick={() => showCreateEditModal(row?.original)}>
    Edit
  </Button>
);

CellAction.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.object,
  }).isRequired,
  showCreateEditModal: PropTypes.func.isRequired,
};

export default CellAction;
