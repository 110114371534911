/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useEffect, useState } from 'react';
import { Button, Checkbox, Dialog, Label, Pane, Textarea } from 'evergreen-ui';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { DateTime } from 'luxon';
import * as Icon from 'react-bootstrap-icons';
import DatePicker from '../../common/forms/DatePicker';
import TextInput from '../../common/TextInput';
import { createAdjustment, updateAdjustment } from '../../../services/AggregateService';
import { LARGER_DIALOG_COMMON_PROPS } from '../../common/constants';
import { AuthContext } from '../../../context/authContext';
import './CreateOrEditAdjustmentModal.css';

const SALE = 'Sale';
const CARE = 'Care';
const RECONCILE_MANUAL = 'Reconcile: Manual';
const RECONCILE_EOD = 'Reconcile: EOD';
const CYCLE_COUNT = 'Cycle Count';
const DAMAGE_AND_UNUSABLE = 'Damage & Unusable';
const RECEIVING_REJECTED_OTHER = 'Receiving: Rejected: Other';
const SPOILAGE = 'Spoilage';
const DAMAGED_LOGISTICS = 'Damaged Logistics';
const MARKETING_AND_CORPORATE = 'Marketing & Corporate';

const BUFFER_HOLD = 'Buffer: Hold';
const BUFFER_CARE = 'Buffer: Care';
const BUFFER_QC = 'Buffer: QC'; // Do we need this?
const BUFFER_PROMOTIONS = 'Buffer: Promotions';
const BUFFER_TYPES = [BUFFER_HOLD, BUFFER_CARE, BUFFER_PROMOTIONS, BUFFER_QC];

const ADJUSTMENT_TYPES = [
  ...BUFFER_TYPES,
  SALE,
  CARE,
  RECONCILE_MANUAL,
  RECONCILE_EOD,
  CYCLE_COUNT,
  DAMAGE_AND_UNUSABLE,
  RECEIVING_REJECTED_OTHER,
  SPOILAGE,
  DAMAGED_LOGISTICS,
  MARKETING_AND_CORPORATE,
];

const PREFERRED_INPUT_SIZE = 186;

const ALREADY_CLEARED = 'Already Cleared?';

const BOOLEAN_ROW_TITLES = [ALREADY_CLEARED];

// eslint-disable-next-line consistent-return
const includeChangelogs = ({ memo, user, displayMemo }) => {
  if (memo) {
    let lastPart = '';
    if (displayMemo) {
      lastPart = `\n${displayMemo}`;
    }
    return `${memo}\n>>>${user}@${DateTime.now().toISO()}${lastPart}`;
  }
  return displayMemo;
};

const CreateOrEditAdjustmentModal = ({
  showModal,
  hideModalFunction,
  internalLotId,
  externalLotId,
  productName,
  fc,
  backAction,
  successFunction,
  successFunctionMessage,
  isFromInventoryRecordModal,
  errorFunction,
  existingAdjustment,
  changelogs,
}) => {
  const { user } = useContext(AuthContext);
  const isCreate = existingAdjustment == null;
  const adjustmentId = existingAdjustment?.adjustment_id;

  const today = new Date();
  const DEFAULT_ADJUSTMENT_TYPE = ADJUSTMENT_TYPES[0];
  const DEFAULT_TIME_OF_ADJUSTMENT = new Date(today.getTime() - today.getTimezoneOffset() * 60000).toISOString().split('T')[0];
  const DEFAULT_ADJUSTMENT_QUANTITY = -1;
  const DEFAULT_ADJUSTMENT_SUBORDER_NUMBER = null;
  const DEFAULT_ALREADY_CLEARED = false;
  const DEFAULT_MEMO = null;

  const checksForChanges = [];

  const [adjustmentType, setAdjustmentType] = useState(existingAdjustment?.adjustment_type ?? DEFAULT_ADJUSTMENT_TYPE);
  const adjustmentTypeChanged = isCreate || adjustmentType !== existingAdjustment?.adjustment_type;
  checksForChanges.push(adjustmentTypeChanged);

  const [timeOfAdjustment, setTimeOfAdjustment] = useState(existingAdjustment?.adjustment_time?.split('T')[0] ?? DEFAULT_TIME_OF_ADJUSTMENT);
  const timeOfAdjustmentChanged = isCreate || timeOfAdjustment !== existingAdjustment?.adjustment_time?.split('T')[0];
  checksForChanges.push(timeOfAdjustmentChanged);

  const [adjustmentQuantity, setAdjustmentQuantity] = useState(existingAdjustment?.adjustment_quantity ?? DEFAULT_ADJUSTMENT_QUANTITY);
  const adjustmentQuantityChanged = isCreate || adjustmentQuantity !== existingAdjustment?.adjustment_quantity;
  checksForChanges.push(adjustmentQuantityChanged);

  const [adjustmentSuborderNumber, setAdjustmentSuborderNumber] = useState(existingAdjustment?.suborder_ref ?? DEFAULT_ADJUSTMENT_SUBORDER_NUMBER);
  const adjustmentSuborderNumberChanged = isCreate || adjustmentSuborderNumber !== existingAdjustment?.suborder_ref;
  checksForChanges.push(adjustmentSuborderNumberChanged);

  const [lotId, setLotId] = useState(existingAdjustment?.delivery_id ?? null);
  const lotIdChanged = isCreate || lotId !== existingAdjustment?.delivery_id;
  checksForChanges.push(lotIdChanged);

  const [isAlreadyCleared, setIsAlreadyCleared] = useState(existingAdjustment?.for_count ?? DEFAULT_ALREADY_CLEARED);
  const isAlreadyClearedChanged = isCreate || isAlreadyCleared !== existingAdjustment?.for_count;
  checksForChanges.push(isAlreadyClearedChanged);
  const [memo, setMemo] = useState(existingAdjustment?.memo ?? DEFAULT_MEMO);
  const [displayMemo] = useState(memo);
  const memoChanged = isCreate || memo !== existingAdjustment?.memo;
  checksForChanges.push(memoChanged);
  const [memoErrorMessage, setMemoErrorMessage] = useState('');

  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (errorMessage) {
      setErrorMessage(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adjustmentType, timeOfAdjustment, adjustmentQuantity, adjustmentSuborderNumber, isAlreadyCleared, memo]);

  useEffect(() => {
    if (existingAdjustment && existingAdjustment.memo) {
      let updatedMemo = existingAdjustment.memo;

      // Remove the value of displayMemo from updatedMemo
      updatedMemo = updatedMemo.replace(displayMemo, '');

      setMemo(updatedMemo);
    }
  }, [existingAdjustment, displayMemo]);

  const adjustmentTypeOptions = ADJUSTMENT_TYPES.map((adjustmentType) => ({ value: adjustmentType, label: adjustmentType }));

  const labelForAlreadyClearedBoolean = isAlreadyCleared ? 'Cleared' : 'Still in FC';

  const arrayOfRowTitlesAndInputs = [
    {
      isEditOnly: true,
      title: 'Lot ID',
      subtitle: 'Only edit this if moving to another known lot',
      input: (
        <div style={{ width: PREFERRED_INPUT_SIZE }}>
          <TextInput
            name="lotNumber"
            type="text"
            value={lotId}
            onChange={(event) => {
              const { value } = event.target;
              setLotId(value);
            }}
          />
        </div>
      ),
    },
    {
      title: 'Type',
      subtitle: 'What is this adjustment for?',
      input: (
        <div style={{ width: PREFERRED_INPUT_SIZE }}>
          <Select
            id="selectAdjustmentType"
            onChange={(option) => setAdjustmentType(option.value)}
            options={adjustmentTypeOptions}
            defaultValue={adjustmentTypeOptions.find((o) => o.value === adjustmentType)}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
          />
        </div>
      ),
    },
    {
      title: 'Time Of',
      subtitle: 'Date Time This Unit Will Be Fulfilled',
      input: <DatePicker date={timeOfAdjustment} setDate={setTimeOfAdjustment} />,
    },
    {
      title: 'Quantity',
      subtitle: 'Negative Removes, Positive Adds',
      input: (
        <div style={{ width: PREFERRED_INPUT_SIZE }}>
          <TextInput
            name="adjustmentQuantity"
            type="number"
            value={adjustmentQuantity}
            onChange={(event) => {
              const { value } = event.target;

              // allow only either 1 minus or 1 plus
              if (value === '' || /^-?\+?\d*$/.test(value)) {
                setAdjustmentQuantity(value);
              }
            }}
          />
        </div>
      ),
    },
    {
      title: 'Suborder Number',
      subtitle: 'Optionally associate with suborder',
      input: (
        <div style={{ width: PREFERRED_INPUT_SIZE }}>
          <TextInput
            name="suborderNumber"
            type="text"
            value={adjustmentSuborderNumber}
            onChange={(event) => {
              const { value } = event.target;
              setAdjustmentSuborderNumber(value);
            }}
          />
        </div>
      ),
    },
    {
      title: ALREADY_CLEARED,
      input: (
        <Checkbox
          disabled={BUFFER_TYPES.includes(adjustmentType)}
          label={`${labelForAlreadyClearedBoolean}${BUFFER_TYPES.includes(adjustmentType) ? ' (Buffer must be uncleared)' : ''}`}
          checked={isAlreadyCleared}
          onChange={(e) => setIsAlreadyCleared(e.target.checked)}
          className="pb-2"
        />
      ),
    },
  ];

  const closeActions = () => {
    hideModalFunction();
    setAdjustmentType(DEFAULT_ADJUSTMENT_TYPE);
    setTimeOfAdjustment(DEFAULT_TIME_OF_ADJUSTMENT);
    setAdjustmentQuantity(DEFAULT_ADJUSTMENT_QUANTITY);
    setAdjustmentSuborderNumber(DEFAULT_ADJUSTMENT_SUBORDER_NUMBER);
    setIsAlreadyCleared(DEFAULT_ALREADY_CLEARED);
    setMemo(DEFAULT_MEMO);
  };

  const adjustmentTypeExists = adjustmentType != null;
  const timeOfAdjustmentExists = timeOfAdjustment != null;
  const adjustmentQuantityExists = adjustmentQuantity != null;
  const anythingChangedFromExisting = checksForChanges.filter((check) => check === true).length > 0;

  const checksForAllowance = [adjustmentTypeExists, timeOfAdjustmentExists, adjustmentQuantityExists, anythingChangedFromExisting];

  const allowSave = checksForAllowance.filter((check) => check === false).length === 0;
  const isConfirmDisabled = !allowSave;

  const confirmActions = async () => {
    setMemoErrorMessage('');

    if (!memo || memo.trim() === '') {
      setMemoErrorMessage('is required.');
      return;
    }

    const adjustment = {
      delivery_id: internalLotId,
      adjustment_type: adjustmentType,
      adjustment_time: timeOfAdjustment,
      adjustment_quantity: adjustmentQuantity,
      for_stock: true,
      for_count: isAlreadyCleared,
      suborder_ref: adjustmentSuborderNumber,
      memo: includeChangelogs({ memo, displayMemo, isCreate, user: `${user.given_name} ${user.family_name}` }),
    };
    let response;

    if (isCreate) {
      response = await createAdjustment(adjustment);
    } else {
      response = await updateAdjustment(adjustment, adjustmentId);
    }

    if (response.success) {
      successFunction({ isFromInventoryRecordModal, successFunctionMessage });
      closeActions();
    } else {
      errorFunction();
      const errorFromResponse = response.error?.response?.data?.error?.error?.message ?? response.error;
      setErrorMessage(`There was an error in ${isCreate ? 'creating' : 'editing'} the adjustment: ${errorFromResponse}`);
      // closeActions();
    }
  };

  const footer = (
    <div style={{ width: '1195px' }}>
      <hr className="mt-0 mb-4" />
      <div style={{ display: 'flex' }}>
        <div style={{ justifyContent: 'left' }}>
          <Button onClick={closeActions} style={{ cursor: 'pointer' }}>
            Cancel
          </Button>
        </div>
        <div style={{ justifyContent: 'right', marginLeft: 'auto' }}>
          <Button
            appearance="primary"
            onClick={isConfirmDisabled ? () => {} : confirmActions}
            style={{ backgroundColor: '#4B93EE', opacity: isConfirmDisabled ? 0.5 : 1, cursor: isConfirmDisabled ? 'default' : 'pointer' }}
          >
            {isCreate ? 'Create Adjustment' : 'Save Changes'}
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <Pane>
      <Dialog
        {...LARGER_DIALOG_COMMON_PROPS}
        overlayProps={{ style: { paddingLeft: '150px' } }}
        topOffset="30px"
        isShown={showModal}
        title="No footer"
        onCloseComplete={closeActions}
        onCancel={closeActions}
        onConfirm={confirmActions}
        hasHeader={false}
        hasClose={false}
        hasCancel={false}
        isConfirmDisabled={isConfirmDisabled}
        footer={footer}
      >
        <div className="mt-3 row align-items-center">
          <div className="col-auto" onClick={backAction} style={{ cursor: 'pointer' }}>
            <Icon.ChevronLeft size={15} className="pb-1" />
            Back
          </div>
        </div>
        <div style={{ color: '#6d6d6d' }}>
          <div className="mt-2">
            <h2 className="mb-2 text-center">{isCreate ? 'Adjustment Creation' : 'Edit Adjustment'}</h2>
          </div>
          <div className="mt-4">
            <div className="mb-2 text-center">
              Shipment {externalLotId} of {productName} at {fc.label}
            </div>
          </div>
          {!isCreate && (
            <div className="mt-0">
              <div className="mb-2 text-center">{adjustmentId}</div>
            </div>
          )}
        </div>

        <hr className="mt-4 mb-4" />
        <Pane className="p-0 adjustment-table-card card-body">
          <Pane className="card-header-blue row g-0 align-items-center" style={{ backgroundColor: '#4B93EE' }}>
            <h4 className="col-auto ml-4 card-header-title-white-text">Details</h4>
          </Pane>
          {arrayOfRowTitlesAndInputs.map(({ title, subtitle, input, isEditOnly }, index) => {
            return (
              ((isEditOnly && !isCreate) || !isEditOnly) && (
                <div key={`rowTitleAndInputForAdjustment-${index}`} style={{ color: '#6d6d6d' }}>
                  <Pane className="adjustment-card-row row g-0" maxHeight={BOOLEAN_ROW_TITLES.includes(title) ? 40 : 60}>
                    <div className="col-sm-2">
                      <h4 className="ml-4">{title}</h4>
                      {subtitle && (
                        <div className="ml-4" style={{ fontSize: 10 }}>
                          {subtitle}
                        </div>
                      )}
                    </div>
                    <div className="ml-4 col">{input}</div>
                  </Pane>
                  <hr />
                </div>
              )
            );
          })}
          <Pane className={`adjustment-card-row row g-0 ${memoErrorMessage ? 'shake' : ''}`} style={{ height: 'auto' }}>
            <div className="ml-4">
              <Label htmlFor="memo-textarea" marginBottom={4} display="block">
                <h4 style={{ color: '#6d6d6d' }}>
                  Memo <span className="memo-error">{memoErrorMessage}</span>
                </h4>
              </Label>
              <Textarea
                id="memo-textarea"
                placeholder="Write memo here"
                onChange={(e) => setMemo(e.target.value)}
                style={{ width: '50%', overflow: 'hidden', resize: 'none' }}
                value={memo}
              />
              <div style={{ whiteSpace: 'pre-wrap', marginBottom: '1em' }}>{isCreate ? changelogs : displayMemo}</div>
            </div>
          </Pane>
        </Pane>

        {errorMessage && (
          <Pane>
            <div className="ml-4" style={{ color: 'red' }}>
              {errorMessage}
            </div>
          </Pane>
        )}
      </Dialog>
    </Pane>
  );
};

CreateOrEditAdjustmentModal.propTypes = {
  showModal: PropTypes.bool,
  hideModalFunction: PropTypes.func,
  internalLotId: PropTypes.number,
  externalLotId: PropTypes.string,
  productName: PropTypes.string,
  fc: PropTypes.object,
  backAction: PropTypes.func,
  successFunction: PropTypes.func,
  errorFunction: PropTypes.func,
  existingAdjustment: PropTypes.object,
  changelogs: PropTypes.string,
  successFunctionMessage: PropTypes.string,
  isFromInventoryRecordModal: PropTypes.bool,
};

CreateOrEditAdjustmentModal.defaultProps = {
  showModal: false,
  hideModalFunction: () => {},
  internalLotId: '',
  externalLotId: '',
  productName: '',
  fc: {},
  backAction: () => {},
  successFunction: () => {},
  errorFunction: () => {},
  existingAdjustment: null,
  changelogs: '',
  successFunctionMessage: '',
  isFromInventoryRecordModal: false,
};

export default CreateOrEditAdjustmentModal;
