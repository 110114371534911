export const isSetActionType = (action) => action.type === 'set';

export const filterExceptionForFc = (fcId, exceptions) => exceptions.filter((e) => (e.fcId || '').toString() === (fcId || '').toString());

export const createSuborderExceptionCreationAction = (suborderException) => ({
  type: 'creation',
  data: suborderException,
});

export const createSuborderExceptionDeletionAction = (suborderException) => ({
  type: 'deletion',
  data: suborderException,
});

export const createSuborderExceptionSetAction = (suborderExceptions) => ({
  type: 'set',
  data: suborderExceptions,
});

export const suborderExceptionReducer = (state, action) => {
  const { type, data } = action;
  switch (type) {
    case 'creation':
      return [data, ...state];
    case 'deletion':
      return state.filter((e) => e.id !== data.id);
    case 'set':
      return data;
    default:
      break;
  }
};

export const dispatchAllSuborderException = (dispatchSuborderException, dispatchSuborderExceptionForFc, action) => {
  dispatchSuborderException(action);
  dispatchSuborderExceptionForFc(action);
};
