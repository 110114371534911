import { createProductOption } from './createProductOption';
import { roleChecks } from '../../../utilities/Role';

export function addNonHandledProductComponentsToProductOptions(productFeed, handledSkus, inventoryBySkus, userGroups, productOptions) {
  if (roleChecks.canViewFulfillableProductsFromProductList(userGroups)) {
    for (const sku of Object.keys(productFeed).filter((sku) => !handledSkus.has(sku))) {
      const product = productFeed[sku];

      const option = createProductOption({ name: product?.name, sku, price: 0 }, inventoryBySkus, userGroups, `(Ops: ${product?.opsName})`);

      if (!option) {
        continue;
      }

      productOptions.push(option);
    }
  }
}
