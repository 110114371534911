import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import { useQueryCache } from 'react-query';
import PropTypes from 'prop-types';
import { Heart } from 'react-bootstrap-icons';
import { useForm, Controller } from 'react-hook-form';
import Input from '../common/forms/Input';
import { getAreas } from '../../services/AggregateService';
import { createSuborderException, generateNewCoords, updateRecipient } from '../../services/OrderManagementService';
import { Checkbox, Dialog } from 'evergreen-ui';
import { roleChecks } from '../../utilities/Role';
import { AuthContext } from '../../context/authContext';

const Recipient = ({ order, suborders, recipient, edit, newOrder, setRecipientFields, canUpdate }) => {
  const [editing, setEditing] = useState(edit);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [subordersToUpdate, setSubordersToUpdate] = useState(suborders && suborders.length ? suborders : null);
  const [overrideAddress, setOverrideAddress] = useState(false);

  const queryCache = useQueryCache();
  const { userGroups } = useContext(AuthContext);

  const canOverrideAddressesInRecipient = roleChecks.canOverrideAddressesInRecipient(userGroups);

  const { handleSubmit, register, errors, reset, control, getValues } = useForm({ mode: 'onBlur', defaultValues: recipient });

  let subordersToUpdateOptions = [];

  const isFormDataDifferent = () => {
    const data = getValues();
    for (const prop in recipient) {
      if (recipient[prop] !== data[prop]) {
        return true; // Return true if any property mismatch is found
      }
    }
    return false; // If no mismatches are found, return false
  };

  if (!newOrder) {
    subordersToUpdateOptions = suborders.map((s) => {
      return { value: s, label: s.suborderId };
    });
    subordersToUpdateOptions.push({ value: suborders, label: 'All pre-fulfillment suborders' });
  }

  const locationTypeOptions = [
    { value: 'residential', label: 'residential' },
    { value: 'commercial', label: 'commercial' },
  ];

  const onClickEdit = (e) => setEditing(!editing);
  const cancelEdit = (e) => {
    setEditing(false);
    setSuccessMessage('');
    setErrorMessage('');
  };

  // update default form values when recipient fields change
  useEffect(() => {
    if (subordersToUpdate) {
      reset(subordersToUpdate[0].recipient);
    } else {
      reset(recipient);
    }
  }, [recipient, reset, subordersToUpdate]);

  const onSuborderChange = (option) => {
    if (option.label === 'All pre-fulfillment suborders') {
      setSubordersToUpdate(option.value);
    } else {
      setSubordersToUpdate([option.value]);
    }
  };

  const saveEdit = async (data, e) => {
    if (!overrideAddress) {
      const { success, coordinates, addressComponents } = await generateNewCoords(data);

      if (success && Object.keys(coordinates).length === 2) {
        const locality = addressComponents.find((el) => el.types.find((e) => e === 'locality'));
        const sublocality = addressComponents.find((el) => el.types.find((e) => e === 'sublocality'));
        const administrativeArea = addressComponents.find((el) => el.types.find((e) => e === 'administrative_area_level_1'));
        const postalCode = addressComponents.find((el) => el.types.find((e) => e === 'postal_code'));

        data.coords = coordinates;

        if (locality) {
          data.city = locality.short_name;
        } else if (sublocality) {
          data.city = sublocality.short_name;
        }

        if (administrativeArea) {
          data.state = administrativeArea.short_name;
        }

        if (postalCode) {
          data.zipCode = postalCode.short_name;
        }
      } else {
        setErrorMessage('Error while editing recipient address.');
        return;
      }
    }

    if (newOrder) {
      setRecipientFields({
        ...data,
      });
      setEditing(false);
    } else {
      const { areas } = await getAreas(data.coords, data.zipCode);

      for (const suborder of subordersToUpdate) {
        const { success } = await updateRecipient(suborder.suborderId, data);

        if (success) {
          setSuccessMessage('Successfully saved');
          setEditing(false);

          // Check that new area is valid
          const validArea = areas.find((area) => area.id === suborder.delivery.areaId);
          if (!validArea || validArea.fc.id !== suborder.delivery.fc.fcId) {
            await createSuborderException(suborder.suborderId, 'Updated recipient address area is invalid');
          }
        } else {
          setErrorMessage('Error saving. Please wait and try again.');
        }
      }
      queryCache.invalidateQueries(['orders', order.orderId]);
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title">
          <Heart size="1.4em" className="mr-2" /> <span>Recipient</span>
        </h4>
        <div>
          {canUpdate && (
            <button type="button" className="btn btn-link" onClick={onClickEdit}>
              Edit
            </button>
          )}
        </div>
      </div>
      <div className="card-body d-flex">
        <Dialog
          isShown={editing}
          title="Edit Recipient"
          onCloseComplete={() => {
            setEditing(false);
            setSubordersToUpdate(null);
          }}
          hasFooter={false}
          hasClose={false}
          width={880}
          preventBodyScrolling={true}
          shouldCloseOnOverlayClick={false}
        >
          <div>
            <form onSubmit={handleSubmit(saveEdit)}>
              <div>
                {!newOrder && (
                  <div className="mb-4">
                    Select Suborders to Update:
                    <Select
                      id="selectSubordersToUpdate"
                      onChange={(option) => onSuborderChange(option)}
                      options={subordersToUpdateOptions}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      menuPortalTarget={document.body}
                      defaultValue={
                        subordersToUpdate && subordersToUpdate.length === 1
                          ? subordersToUpdateOptions.find((o) => o.value.suborderId === subordersToUpdate[0].suborderId)
                          : subordersToUpdateOptions.find((o) => o.label === 'All pre-fulfillment suborders')
                      }
                    />
                  </div>
                )}
                <div className="row">
                  <div className="col-12 col-md-6 mb-3">
                    <Input register={register({ required: true })} errors={errors} name="firstName" label="First name" />
                  </div>
                  <div className="col-12 col-md-6 mb-3">
                    <Input register={register({ required: true })} errors={errors} name="lastName" label="Last name" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-3">
                    <Input register={register({ required: true })} errors={errors} name="address1" label="Address" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-8 mb-3">
                    <Input register={register()} errors={errors} name="address2" label="Address 2" />
                  </div>
                  <div className="col-12 col-md-4 mb-3">
                    <label className="mb-2">Address Type</label>
                    <Controller
                      name="locationType"
                      errors={errors}
                      control={control}
                      label="Address type"
                      register={register({ required: true })}
                      defaultValue={recipient.locationType ? locationTypeOptions.find((o) => o.value === recipient.locationType) : null}
                      render={({ onChange, value, ref }) => (
                        <Select
                          options={locationTypeOptions}
                          inputRef={ref}
                          value={locationTypeOptions.find((o) => o.value === value)}
                          onChange={(val) => onChange(val.value)}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 mb-3">
                    <Input register={register()} errors={errors} name="placeName" label="Place Name" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 mb-3">
                    <Input register={register({ required: true })} errors={errors} name="city" label="City" />
                  </div>
                  <div className="col-6 col-md-3 mb-3">
                    <Input
                      register={register({ required: true, pattern: { value: /[A-Z]{2}/, message: 'Use a 2-letter state code' } })}
                      errors={errors}
                      name="state"
                      label="State"
                    />
                  </div>
                  <div className="col-6 col-md-3 mb-3">
                    <Input
                      register={register({ required: true, pattern: { value: /\d{5}/, message: 'Invalid zipcode' } })}
                      errors={errors}
                      name="zipCode"
                      label="Zip"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 mb-3">
                    <Input
                      register={register({
                        required: true,
                        pattern: { value: /(?:\d{1}\s)?\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})/, message: 'Invalid phone number' },
                      })}
                      errors={errors}
                      name="phone"
                      label="Phone"
                    />
                  </div>
                  <div className="col-12 col-md-6 mb-3">
                    <Input
                      register={register({ required: false, pattern: { value: /^[\w+\.-]+@[\w\.-]+(\.\w{2,4}){0,1}$/, message: 'Invalid email' } })}
                      errors={errors}
                      name="email"
                      label="Email"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-3">
                    <Input register={register()} errors={errors} name="instructions" label="Special instructions" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 mb-3">
                    <Input register={register({ required: true })} errors={errors} name="coords.lat" label="Latitude" />
                  </div>
                  <div className="col-12 col-md-6 mb-3">
                    <Input register={register({ required: true })} errors={errors} name="coords.lng" label="Longitude" />
                  </div>
                </div>
              </div>
              {canOverrideAddressesInRecipient && (
                <div className="col-12 mb-3">
                  <Checkbox
                    label={'I have confirmed with the customer and Google Maps that this address entered is correct.'}
                    checked={overrideAddress}
                    onChange={(e) => setOverrideAddress(e.target.checked)}
                  />
                </div>
              )}
              <div className="row">{errorMessage && <div className="text-danger h5">{errorMessage}</div>}</div>
              <div className="d-inline-flex float-right mb-3 mt-3">
                <button type="submit" className="btn btn-primary mx-1" disabled={!isFormDataDifferent()}>
                  Save
                </button>
                <button type="button" className="btn btn-secondary" onClick={cancelEdit}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </Dialog>
        {errorMessage && <div className="text-danger h5 mr-3">{errorMessage}</div>}
        {successMessage && <div className="text-success h5 mr-3">{successMessage}</div>}
        <div className={successMessage || errorMessage ? 'col-6' : 'col-8'}>
          <h4 className="mb-1">
            {recipient?.firstName} {recipient?.lastName} <span className="badge bg-dark text-white ml-3">{recipient?.locationType}</span>
          </h4>
          <p className="mb-1">
            {recipient?.address1}
            <br />
            {recipient?.address2 && (
              <>
                {recipient.address2}
                <br />
              </>
            )}
            {recipient?.city}, {recipient?.state} {recipient?.zipCode}
          </p>
          <p className="mb-1">
            {recipient?.phone}
            <br />
            {recipient?.email && <>{recipient.email}</>}
          </p>
          {recipient?.instructions && <p className="mb-1">{recipient.instructions}</p>}
        </div>
      </div>
    </div>
  );
};

Recipient.propTypes = {
  order: PropTypes.object,
  suborders: PropTypes.array,
  recipient: PropTypes.object.isRequired,
  setOrder: PropTypes.func,
  edit: PropTypes.bool.isRequired,
  newOrder: PropTypes.bool.isRequired,
  setRecipientFields: PropTypes.func.isRequired,
  canUpdate: PropTypes.bool.isRequired,
};

export default Recipient;
