/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/button-has-type */
import React, { useState, useEffect, useContext } from 'react';
import { path } from 'ramda';
import PropTypes from 'prop-types';
import * as Icon from 'react-bootstrap-icons';
import { Dialog, Button } from 'evergreen-ui';
import { useMutation, useQueryCache } from 'react-query';
import CreatableSelect from 'react-select/creatable';
import { FcContext } from '../../context/fcContext';
import { printDeliveryLabel } from '../common/printingHelpers/PrintDeliveryLabel';
import { styleSuborderStatus } from '../common/suborderHelpers/styleSuborderStatus';
import { printNotecard } from '../common/printingHelpers/PrintNotecard';
import FulfillableComponent from './FulfillableComponent';
import FulfillableCollateral from './FulfillableCollateral';
import Delivery from '../order/Delivery';
import { createSuborderException, updateSuborder } from '../../services/OrderManagementService';

const BatchSuborder = ({ suborder, slotMapping, onDone, isFinalSuborderInBatch }) => {
  const { suborderId } = suborder;
  const cache = useQueryCache();
  const [printedForSuborderIds, setPrintedForSuborderIds] = useState([]);
  const [barcodeId, setBarcodeId] = useState('');
  const onChange = (event) => setBarcodeId(event.target.value);
  const [showCreateException, setShowCreateException] = useState(false);
  const [reason, setReason] = useState(null);
  const [showCancelButton, setShowCancelButton] = useState(false);
  const { allFcsConfig } = useContext(FcContext);

  const [loading, setLoading] = useState(false);

  const updateSuborderStatus = async (status) => {
    return updateSuborder(suborder.suborderId, { status });
  };

  const [mutateSuborderStatus] = useMutation(updateSuborderStatus);

  const printSuborderLabelAndNoteCard = () => {
    if (suborder && !suborder.inException && !printedForSuborderIds.includes(suborderId) && suborder.suborderId === suborderId) {
      setPrintedForSuborderIds([...printedForSuborderIds, suborderId]);
      printDeliveryLabel(suborder, () => mutateSuborderStatus('SuborderPrinted'), setShowCancelButton, allFcsConfig);
      printNotecard(suborder);
    }
  };

  useEffect(() => {
    if (suborder) {
      printSuborderLabelAndNoteCard();
    }
  }, [suborder]);

  const addExceptionReasons = ['Waiting for customer response'];

  useEffect(() => {
    setBarcodeId('');
  }, [mutateSuborderStatus, suborder]);

  useEffect(() => {
    window.setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [suborderId, setLoading]);

  const createException = async () => {
    await createSuborderException(suborder.suborderId, reason);

    cache.invalidateQueries(['suborders', suborder.suborderId]);

    setShowCreateException(false);
    setReason(null);
  };

  if (!suborder) {
    return null;
  }
  let slotMappingObject = {};
  if (slotMapping) {
    slotMappingObject = slotMapping.lineItems.reduce((acc, slotMapItem) => {
      return { ...acc, [slotMapItem.lineItemIndex]: slotMapItem.slot };
    }, {});
  }

  const pressNextOrConfirm = async (e) => {
    e.preventDefault();
    setLoading(true);
    await updateSuborderStatus('Packed');
    onDone();
  };

  return (
    <>
      <div className="col-12">
        <div className="header">
          <div className="header-body">
            <div className="row align-items-center">
              <div className="col-auto">
                <h1 className="header-title">{suborder.suborderId}</h1>
                <div className="d-flex align-items-baseline">
                  <div className={styleSuborderStatus(suborder.status)}>{suborder.status} </div>
                  <div className="badge bg-dark ml-3">
                    {suborder.orderClassificationInfo && suborder.orderClassificationInfo.type && suborder.orderClassificationInfo.type}
                  </div>
                  <div className="badge bg-dark ml-3">
                    {suborder.orderClassificationInfo && suborder.orderClassificationInfo.subscription && <div>Subscription</div>}
                  </div>
                </div>
              </div>
              <div className="col-auto">
                <h6 className="text-uppercase text-muted text-nowrap mb-2">Recipient Name</h6>
                <span className="h2">
                  {path(['recipient', 'firstName'], suborder)} {path(['recipient', 'lastName'], suborder)}
                </span>
              </div>
              <div className="col">
                <h6 className="text-uppercase text-muted text-nowrap mb-2">Message</h6>
                <span className="h2">{suborder.message ? `${suborder.message.substring(0, 20)}...` : '<blank>'}</span>
              </div>
              <div className="col-auto">
                {!suborder.inException && (
                  <button className="btn btn-danger" onClick={() => setShowCreateException(true)}>
                    Create Exception
                  </button>
                )}
              </div>
              <div className="col-auto">
                <form className="row row-cols-md-auto g-3 align-items-center" onSubmit={pressNextOrConfirm}>
                  <div className="col-12">
                    <div className="input-group input-group-merge input-group-reverse">
                      <input
                        type="text"
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus
                        autoComplete="off"
                        className="form-control"
                        placeholder="Scan delivery label"
                        name="batchOrderBarcodeId"
                        onChange={onChange}
                        value={barcodeId}
                      />
                      <div className="input-group-text">
                        <Icon.UpcScan size="1.5em" />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <Button height={36} appearance="primary" isLoading={loading} type="submit" disabled={loading}>
                      {isFinalSuborderInBatch ? 'Complete' : 'Next'}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="row">
          <div className="col-12 col-lg-8">
            <h6 className="text-uppercase text-muted text-nowrap mb-2">Components</h6>
            {(suborder.lineItems || []).map((lineItem, i) => {
              if (!lineItem.isDeleted) {
                const { components } = lineItem;
                if (components && components.length) {
                  return (
                    <div key={i} className="row">
                      <div className="col-12 col-md-3">
                        <div className="card card-fill p-3 align-items-center justify-content-center bg-transparent border">
                          {lineItem.images && (
                            <div className="avatar avatar-lg avatar-4by3 mb-3">
                              <img src={lineItem.images.large} alt="..." className="avatar-img rounded" />
                            </div>
                          )}
                          <h4>{lineItem.name}</h4>
                          <h4 className="mb-0">{lineItem.sku}</h4>
                        </div>
                      </div>
                      <div className="col-12 col-md-9">
                        {components.map((component, idx) => {
                          if (component.sku.indexOf('CRDISL') < 0) {
                            return (
                              <FulfillableComponent
                                item={component}
                                slotForItem={slotMappingObject[`${lineItem.index}`] || slotMappingObject[`${lineItem.index}.${idx}`]}
                                key={`${suborder.suborderId}_${lineItem.index}_${idx}`}
                              />
                            );
                          }
                        })}
                      </div>
                    </div>
                  );
                }
                if (lineItem.sku.indexOf('CRDISL') < 0) {
                  return (
                    <FulfillableComponent
                      item={lineItem}
                      slotForItem={slotMappingObject[`${lineItem.index}`]}
                      key={`${suborder.suborderId}_${lineItem.index}`}
                    />
                  );
                }
              } else {
                // eslint-disable-next-line react/jsx-key
                return <div />;
              }
            })}
          </div>
          <div className="col-12 col-lg-4">
            <h6 className="text-uppercase text-muted text-nowrap mb-2">Cards &amp; Labels</h6>
            <FulfillableCollateral
              action={() => printNotecard(suborder)}
              item={{ type: 'Notecard', text: `${suborder.message || '<blank>'}` }}
              key={`${suborder.suborderId}_notecard`}
            />
            <Delivery
              suborder={suborder}
              delivery={suborder.delivery}
              showCancel={showCancelButton}
              reprintAction={() => printDeliveryLabel(suborder, () => {}, setShowCancelButton, allFcsConfig)}
            />
          </div>
        </div>
      </div>
      <Dialog
        isShown={suborder.inException}
        hasClose={false}
        hasFooter
        hasCancel={false}
        confirmLabel="Skip to next suborder"
        onConfirm={(close) => {
          onDone();
          close();
        }}
        title="Exception"
        shouldCloseOnEscapePress={false}
        shouldCloseOnOverlayClick={false}
      >
        <p>This suborder is in exception for the following reasons:</p>
        <ul>
          {suborder.exceptionsList?.map((exception, i) => (
            <li key={i}>{exception.reason}</li>
          ))}
        </ul>
      </Dialog>
      <Dialog
        isShown={showCreateException}
        title="Confirm Create Exception"
        onCloseComplete={() => {
          setShowCreateException(false);
        }}
        confirmLabel="Confirm"
        onConfirm={() => createException()}
        isConfirmDisabled={!reason}
      >
        <CreatableSelect
          id="createExceptionReason"
          isClearable
          onChange={(option) => setReason(option?.value)}
          options={addExceptionReasons.map((reason) => {
            return { value: reason, label: reason };
          })}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
        />
      </Dialog>
    </>
  );
};

BatchSuborder.propTypes = {
  suborder: PropTypes.object.isRequired,
  slotMapping: PropTypes.object.isRequired,
  onDone: PropTypes.func.isRequired,
  isFinalSuborderInBatch: PropTypes.bool.isRequired,
};

export default BatchSuborder;
