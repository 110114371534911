/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable no-await-in-loop */
import React, { useState } from 'react';
import { toaster, Spinner, Portal, Dialog } from 'evergreen-ui';
import { useQueryCache } from 'react-query';
import PropTypes from 'prop-types';
import { removeSuborderException } from '../../../services/OrderManagementService';

const BulkRemoveException = ({ batchExceptionIds, setBatchExceptionIds, setCount, confirmation, setConfirmation, loading, setLoading }) => {
  const queryCache = useQueryCache();
  const [hasFooter, setHasFooter] = useState(true);
  const [countDisplayProgress, setCountDisplayProgress] = useState(0);

  const removeBatchExceptions = async () => {
    setLoading(true);
    setHasFooter(false);
    const countError = [];
    const countSuccess = [];
    let count = 0;
    for (const id of batchExceptionIds.values()) {
      setCountDisplayProgress(++count);
      const result = await removeSuborderException(id);
      if (!result?.success) {
        countError.push({ response: result, id });
      } else {
        countSuccess.push({ response: result, id });
      }
    }
    queryCache.invalidateQueries(['exceptions']);
    setBatchExceptionIds(new Set());
    if (!countError.length) {
      toaster.success(`Successfully removed ${batchExceptionIds.size} suborder exception${batchExceptionIds.size > 1 ? 's' : ''}`);
    }
    setLoading(false);
    setCount({
      errors: countError,
      success: countSuccess,
    });
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const RemovingInProgress = () => (
    <div style={{ textAlign: 'center', marginBottom: 30 }}>
      <Spinner
        style={{
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      />{' '}
      <br />
      Removing {countDisplayProgress}/{batchExceptionIds.length}...
    </div>
  );

  return (
    <Portal>
      <Dialog
        isShown={confirmation}
        title="Remove Exception"
        onCloseComplete={() => setConfirmation(false)}
        confirmLabel="Confirm"
        hasFooter={hasFooter}
        onConfirm={() => removeBatchExceptions()}
      >
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12">
              {!loading ? <>Are you sure you want to clear exceptions from the selected suborders?</> : <RemovingInProgress />}
            </div>
          </div>
        </div>
      </Dialog>
    </Portal>
  );
};

BulkRemoveException.propTypes = {
  batchExceptionIds: PropTypes.array,
  setBatchExceptionIds: PropTypes.func,
  setCount: PropTypes.func,
  confirmation: PropTypes.bool,
  setConfirmation: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
};

BulkRemoveException.defaultProps = {
  batchExceptionIds: [],
  setBatchExceptionIds: () => {},
  setCount: () => {},
  confirmation: false,
  setConfirmation: () => {},
  loading: false,
  setLoading: () => {},
};

export default BulkRemoveException;
