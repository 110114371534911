import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { deleteBatch } from '../../../services/OrderManagementService';
import Modal from '../../common/Modal';

const DeleteBatchModal = ({ batchId, batchName, onClose }) => {
  const [show, setShow] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  const onConfirm = async () => {
    const response = await deleteBatch(batchId);
    if (response.success) {
      onClose({
        wasDeleted: response.success,
      });
      setShow(false);
    } else {
      setErrorMessage(response?.error?.response?.data?.message || 'Network error; please retry or have the dev team investigate.');
    }
  };

  return (
    <Modal
      title={`Delete Batch ${batchName}`}
      onConfirm={onConfirm}
      onClose={() => onClose({
        didNotTryToDelete: true,
      })}
      onCancel={() => onClose({
        didNotTryToDelete: true,
      })}
      visible={show}
    >
      {
        errorMessage === null ? (
          <div>Are you sure you want to delete this batch?</div>
        ) : (
          <>
            <div>
              Could not delete this batch due to the following error:
            </div>
            <div className="text-danger">
              {errorMessage}
            </div>
          </>
        )
      }
    </Modal>
  );
};

DeleteBatchModal.propTypes = {
  batchId: PropTypes.string.isRequired,
  batchName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DeleteBatchModal;
