import React, { useState, useMemo } from 'react';
import { Group, Button } from 'evergreen-ui';
import PropTypes from 'prop-types';
import './TabulatedButtons.css';

const TabulatedButtons = ({ defaultSelected = 'all', handleFilterChange, data, activeText, inactiveText }) => {
  const activeCount = useMemo(() => data.filter((item) => item.active).length, [data]);
  const inactiveCount = useMemo(() => data.filter((item) => !item.active).length, [data]);
  const allCount = data.length;

  const options = useMemo(
    () => [
      { label: `View all (${allCount})`, value: 'all' },
      { label: `${activeText[0].toUpperCase() + activeText.slice(1)} Only (${activeCount})`, value: activeText },
      { label: `${inactiveText[0].toUpperCase() + inactiveText.slice(1)} Only (${inactiveCount})`, value: inactiveText },
    ],
    [allCount, activeCount, inactiveCount, activeText, inactiveText],
  );

  const [selectedValue, setSelectedValue] = useState(defaultSelected);

  const handleButtonClick = (value) => {
    setSelectedValue(value);
    if (handleFilterChange) {
      handleFilterChange(value);
    }
  };

  return (
    <Group size="small">
      {options.map(({ label, value }) => (
        <Button
          key={label}
          isActive={selectedValue === value}
          className={selectedValue === value ? 'd-a-active' : ''}
          onClick={() => handleButtonClick(value)}
        >
          {label}
        </Button>
      ))}
    </Group>
  );
};

TabulatedButtons.propTypes = {
  defaultSelected: PropTypes.string,
  handleFilterChange: PropTypes.func,
  data: PropTypes.array.isRequired,
  activeText: PropTypes.string.isRequired,
  inactiveText: PropTypes.string.isRequired,
};
TabulatedButtons.defaultProps = {
  defaultSelected: 'all',
  handleFilterChange: () => {},
};

export default TabulatedButtons;
