export function getProductSkusArrayForInventoryLookup({ productFeed, canViewFulfillableProductsFromProductList }) {
  const productFeedSkus = Object.keys(productFeed);
  if (canViewFulfillableProductsFromProductList) {
    const fulfillableProducts = [];
    for (const sku of Object.keys(productFeed)) {
      fulfillableProducts.push(sku);
    }

    return Array.from(new Set([...productFeedSkus, ...fulfillableProducts]));
  } else {
    return [...productFeedSkus];
  }
}
