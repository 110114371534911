/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSuborderException = /* GraphQL */ `
  query GetSuborderException($id: ID!) {
    getSuborderException(id: $id) {
      id
      fcId
      createdAt
      updatedAt
      deliveryDate
      fulfillmentDate
      exceptionReason
      sku
      exceptionType
      orderType
    }
  }
`;
export const listSuborderExceptions = /* GraphQL */ `
  query ListSuborderExceptions(
    $id: ID
    $filter: ModelSuborderExceptionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSuborderExceptions(id: $id, filter: $filter, limit: $limit, nextToken: $nextToken, sortDirection: $sortDirection) {
      items {
        id
        fcId
        createdAt
        updatedAt
        deliveryDate
        fulfillmentDate
        exceptionReason
        sku
        exceptionType
        orderType
      }
      nextToken
    }
  }
`;
export const suborderExceptionsByFcId = /* GraphQL */ `
  query SuborderExceptionsByFcId(
    $fcId: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelSuborderExceptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    suborderExceptionsByFcId(fcId: $fcId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fcId
        createdAt
        updatedAt
        deliveryDate
        fulfillmentDate
        exceptionReason
        sku
        exceptionType
        orderType
      }
      nextToken
    }
  }
`;
