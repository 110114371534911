/* eslint-disable no-console */
import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import Email from '../common/Email';
import PasswordWithConfirmation from '../common/PasswordWithConfirmation';

const ResetPassword = () => {
  const [username, setUsername] = useState('');
  const [validUsername, setValidUsername] = useState(false);
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [validPassword, setValidPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [changingPassword, setChangingPassword] = useState(false);
  const history = useHistory();

  const changeInput = (event) => {
    const { target } = event;
    const { value } = target;
    switch (event.target.name) {
      case 'password':
        setPassword(value);
        setValidPassword(target.valid);
        break;
      case 'username':
        setUsername(value);
        setValidUsername(target.valid);
        break;
      case 'code':
        setCode(value);
        break;
      default:
        break;
    }
  };
  const validate = () => (validUsername && validPassword && !changingPassword);

  const changePassword = async (event) => {
    event.preventDefault();
    if (!validate()) {
      return;
    }
    try {
      setChangingPassword(true);
      await Auth.forgotPasswordSubmit(username, code, password);
      setSuccessMessage('Successfully Reset! You need to log in with your new password. Redirecting you now...');
      setTimeout(() => {
        setSuccessMessage('');
        history.push('/');
      }, 3000);
    } catch (error) {
      console.error('Unable to process change password', error);
      setErrorMessage(error.message);
      setTimeout(() => setErrorMessage(''), 3000);
    } finally {
      setChangingPassword(false);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-5 col-lg-6 col-xl-4 vh-100 px-lg-6 py-5 overflow-auto">
          <h1 className="display-4 text-center mb-3">
            Reset Password
          </h1>
          <form>
            <div className="form-group">
              <Email
                onChange={changeInput}
                value={username}
                tabIndex={1}
              />
            </div>
            <div className="form-group">
              <label className="form-label">
                Code
              </label>
              <input
                type="text"
                name="code"
                className="form-control"
                onChange={changeInput}
                value={code}
                tabIndex="2"
              />
            </div>

            <PasswordWithConfirmation
              onChange={changeInput}
              tabIndex1={3}
              tabIndex2={4}
            />

            <button
              className="btn btn-lg btn-block btn-primary mb-3"
              onClick={changePassword}
              disabled={!validate()}
              tabIndex="5"
            >
              Submit
            </button>

            <div className="text-danger">
              {errorMessage}
            </div>
            <div className="text-success">
              {successMessage}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
