import React, { useState, useEffect } from 'react';
import { useQueryCache } from 'react-query';
import { Spinner, Dialog, Portal, Alert, InlineAlert, Select } from 'evergreen-ui';

import { completeBatch } from '../../../utilities/BatchActions';
import { createSuborderException, generateAndSendPld, getBatch } from '../../../services/OrderManagementService';
import { categorizeReaons } from '../../order/helpers/getReasonType';

export const CompleteBatchSuborder = ({ visible, onDone, batchId, batchName, fcConfig, batchStatus }) => {
  const queryCache = useQueryCache();
  const [completeLoading, setCompleteLoading] = useState(false);
  const [hasFooter, setHasFooter] = useState(true);
  const [completeBatchError, setCompleteBatchError] = useState(false);
  const [suborderErrorMap, setSuborderErrorMap] = useState(null);
  const [suborders, setSuborders] = useState([]);
  const [categories, setCategories] = useState({});
  const [completeBatchMessage, setCompleteBatchMessage] = useState(false);

  const getSubordersOfBatch = async () => {
    const data = await getBatch(batchId);
    const redundantSuborderIds = [];
    const simplified = [];
    await data.batch.suborders.map((suborder, subindex) => {
      return suborder.lineItems.map((lineItem, lineindex) => {
        if (!redundantSuborderIds.includes(suborder.suborderId)) {
          simplified.push({
            backgroundColor: (lineindex + subindex) % 2 === 0 ? '#e4eff5' : '#f7f8fa',
            checked: true,
            suborderId: suborder.suborderId,
            orderId: suborder.orderId,
            name: suborder.lineItems ? suborder.lineItems.map((e) => `${e.name} (x${e.quantity})`).join(',') : '',
            sku: suborder.lineItems ? suborder.lineItems.map((e) => e.sku).join(';') : '',
            reason: lineItem.reason,
            description: lineItem.description,
            errorMessage: '',
            successMessage: '',
          });
        }
        redundantSuborderIds.push(suborder.suborderId);
      });
    });
    setSuborders(simplified);
    setCompleteLoading(false);
  };

  const getAllCategoriesFetcher = async () => {
    const holder = await categorizeReaons();
    setCategories(holder);
  };

  useEffect(() => {
    setCompleteBatchError(false);
    setSuborderErrorMap(null);
    setHasFooter(true);
    getAllCategoriesFetcher();
  }, []);

  useEffect(() => {
    if (batchId) {
      setCompleteLoading(true);
      getSubordersOfBatch();
    }
  }, [visible]);

  const putExcludedSubordersInException = async () => {
    // create exception for the unchecked ones
    const mutable = suborders;
    const redundantRequest = [];
    let failed = 0;
    const urlPromises = [];

    await suborders.forEach(async (esuborder) => {
      if (!esuborder.checked && !redundantRequest.includes(esuborder.suborderId)) {
        redundantRequest.push(esuborder.suborderId);
        urlPromises.push(createSuborderException(esuborder.suborderId, esuborder.reason, esuborder.sku));
      }
    });
    const responses = await Promise.all(urlPromises);

    await responses.forEach(async (request) => {
      if (!request.success) {
        failed += 1;
      }
      await suborders.forEach(async (esuborder, index) => {
        if (request.data.suborderId === esuborder.suborderId) {
          if (request.success) {
            mutable[index].successMessage = '(added to exception)';
            mutable[index].errorMessage = '';
            setSuborders(mutable);
          } else {
            mutable[index].errorMessage = !request.data.reason ? '(reason is required)' : '(failed to add exception)';
            mutable[index].successMessage = '';
            setSuborders(mutable);
          }
        }
      });
    });

    setSuborders(mutable);

    return failed;
  };

  const runCompleteBatchActions = async () => {
    const response = await completeBatch(batchId, queryCache);

    if (response.length && !response[0].value.success) {
      // Failed
      setCompleteBatchError(response[0].value.error.message);
    } else {
      // Success
      queryCache.invalidateQueries(['batches']);
      setCompleteBatchMessage('Batch complete!');
    }
  };

  const completeBatchAndGetWhetherPageShouldRefresh = async (errors) => {
    if (errors) {
      setCompleteLoading(false);
      setHasFooter(true);
      return true;
    }

    let refreshAnswer = true;

    if (!fcConfig.isUpsWorldeaseFc) {
      await runCompleteBatchActions();
    } else {
      const submitPldResponse = await generateAndSendPld(batchId);

      const { success, data, error } = submitPldResponse;

      if (!success || !data.isCompleted) {
        // is failure
        if (error && error.message) {
          setCompleteBatchError(error.message);
        } else {
          setCompleteBatchError('Error while completing batch');
        }
        if (data) {
          if (data.suborderIdsToErrors && Object.keys(data.suborderIdsToErrors).length) {
            setSuborderErrorMap(data.suborderIdsToErrors); // if this doesn't get set, completeBatchError will show instead
          }
        }

        refreshAnswer = false;
      } else {
        // is success
        await runCompleteBatchActions();
      }
    }
    setCompleteLoading(false);

    return refreshAnswer;
  };

  const confirmComplete = async (close) => {
    setCompleteLoading(true);
    setHasFooter(false);
    const errors = fcConfig.usePickAndPass ? await putExcludedSubordersInException() : null;
    const refreshAnswer = await completeBatchAndGetWhetherPageShouldRefresh(errors);

    if (refreshAnswer) {
      setTimeout(() => {
        if (!errors) {
          setCompleteLoading(false);
          setHasFooter(true);
          setCompleteBatchError(false);
          setSuborderErrorMap(null);
          setSuborders([]);
          close();
        }
      }, 2000);
    }
  };

  const CompleteInProgress = () => (
    <div style={{ textAlign: 'center', marginBottom: 30 }}>
      <Spinner
        style={{
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      />
    </div>
  );

  const updateInclusionOrExclusionOfSuborders = async (id) => {
    setSuborders(
      suborders.map((suborder) => {
        if (suborder.suborderId === id) {
          suborder = {
            ...suborder,
            ...{ checked: !suborder.checked, successMessage: suborder.checked ? '(to be added in exception)' : '' },
          };
        }
        return suborder;
      }),
    );
  };

  const PLDtoUPS = () => {
    if (fcConfig.isUpsWorldeaseFc) {
      return (
        <Alert
          intent="none"
          title="Completing this batch will upload a PLD to UPS and will require approval from them for completion. "
          marginTop={32}
        />
      );
    }

    return null;
  };

  const handleChangeReasonOfSuborderInException = (e, suborderId) => {
    setSuborders(
      suborders.map((suborder) => {
        if (suborder.suborderId === suborderId) {
          suborder = {
            ...suborder,
            reason: e.target.value,
          };
        }
        return suborder;
      }),
    );
  };

  return (
    <Portal>
      <Dialog
        isShown={visible}
        title={`Batch ${batchName}`}
        confirmLabel="Confirm"
        onCloseComplete={onDone}
        hasFooter={hasFooter}
        onConfirm={confirmComplete}
        width="800px"
        isConfirmDisabled={batchStatus === 'Completed'}
      >
        <div className="container-fluid">
          <div className="row justify-content-center">
            {batchStatus !== 'Completed' ? (
              <div className="col-12">
                {fcConfig.usePickAndPass && (
                  <>
                    <InlineAlert intent="none" marginBottom={16}>
                      Uncheck suborders to send them to exception.
                    </InlineAlert>

                    <table style={{ width: '100%', margin: 20 }}>
                      <thead>
                        <tr>
                          <th>&nbsp;</th>
                          <th style={{ textAlign: 'left' }}>Suborder ID</th>
                          <th style={{ textAlign: 'left' }}>Products</th>
                          <th style={{ textAlign: 'left' }}>Reason *</th>
                        </tr>
                      </thead>
                      <tbody>
                        {suborders.map((suborder, suborderKey) => {
                          let counterForNextLine = 0;
                          return (
                            <React.Fragment key={`complete-batch-suborder-${suborder.suborderId}-${suborderKey}`}>
                              <tr style={{ backgroundColor: suborder.backgroundColor, marign: 5 }}>
                                <td>
                                  <input
                                    style={{ margin: 5 }}
                                    type="checkbox"
                                    checked={suborder.checked}
                                    onChange={() => updateInclusionOrExclusionOfSuborders(suborder.suborderId)}
                                  />
                                </td>
                                <td style={{ width: 260 }}>
                                  <a href={`/orders/${suborder.orderId}`} target="_blank" rel="noreferrer">
                                    {suborder.suborderId}
                                  </a>
                                </td>
                                <td style={{ width: 400 }}>
                                  {suborder.name.split(',').map((o, suborderNameKey) => {
                                    counterForNextLine += 1;
                                    if (suborder.name.split(',').length === counterForNextLine) {
                                      return <React.Fragment key={`suborder-name-${o}-${suborderNameKey}`}>{o}</React.Fragment>;
                                    }
                                    return (
                                      <React.Fragment key={`suborder-name-${o}-${suborderNameKey}`}>
                                        {o} <br />
                                      </React.Fragment>
                                    );
                                  })}{' '}
                                  <br />
                                </td>
                                <td style={{ width: 400 }}>
                                  {!suborder.checked && (
                                    <Select
                                      name="reason"
                                      value={suborder.reason}
                                      onChange={(e) => handleChangeReasonOfSuborderInException(e, suborder.suborderId)}
                                    >
                                      <option value="">Required to select</option>
                                      {Object.keys(categories).map((categoriesKey) => {
                                        return (
                                          <optgroup label={categoriesKey} key={`categories-${categoriesKey}`}>
                                            {categories[categoriesKey].map((val, categoryKey) => {
                                              return (
                                                <option key={`category-${categoryKey}`} value={val}>
                                                  {val}
                                                </option>
                                              );
                                            })}
                                          </optgroup>
                                        );
                                      })}
                                    </Select>
                                  )}
                                </td>
                              </tr>
                              <tr style={{ textAlign: 'center' }}>
                                <td colSpan="5">
                                  {suborder.successMessage && <span style={{ color: 'green', fontSize: 10 }}>{suborder.successMessage}</span>}
                                  {suborder.errorMessage && <span style={{ color: 'red', fontSize: 10 }}>{suborder.errorMessage}</span>}
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                )}
                {!completeLoading ? (
                  <>
                    {!completeBatchError ? (
                      <>
                        {completeBatchMessage ? (
                          <h1 style={{ textAlign: 'center' }}>{completeBatchMessage}</h1>
                        ) : (
                          <>
                            Are you sure you want to complete batch <b>{batchName}</b>?
                            <PLDtoUPS />
                          </>
                        )}
                      </>
                    ) : suborderErrorMap ? (
                      <div>
                        {Object.keys(suborderErrorMap).map((suborderId) => suborderErrorMap[suborderId].map((error) => (
                          <Alert intent="danger">
                            Suborder {suborderId} has the following error: {error}
                          </Alert>
                        )))}
                      </div>
                    ) : (
                      <Alert intent="danger" title={completeBatchError} />
                    )}
                  </>
                ) : (
                  <CompleteInProgress />
                )}
              </div>
            ) : (
              <span>Batch already completed!</span>
            )}
          </div>
        </div>
      </Dialog>
    </Portal>
  );
};
