import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Eye } from 'react-bootstrap-icons';
import { DateTime } from 'luxon';

const notificationTypes = {
  redelivery: 'redelivery',
  creditsIssued: 'creditsIssued',
  creditsIssuedToCustomer: 'creditsIssuedToCustomer',
  creditsIssuedToRecipient: 'creditsIssuedToRecipient',
  refund: 'refund',
};

const CareResolutionNotification = ({ order }) => {
  const [notificationsOrderedByTime, setNotificationsOrderedByTime] = useState([]);

  const convertMillisToPrefferedTimestamp = (millis) => DateTime.fromMillis(Number(millis)).toLocaleString(DateTime.DATETIME_SHORT);

  const convertNotificationObjectToText = (notificationType, notification) => {
    if (!notification) {
      return '';
    }
    const localeString = DateTime.fromISO(notification.date, { setZone: true }).toLocaleString();
    switch (notificationType) {
      case notificationTypes.redelivery:
        let redeliveryNotificationText = `Redelivery created for suborder ${notification.redeliveryOfSuborderId}: ${notification.suborderId}`;

        if (notification.redeliveryDate) {
          const redeliveryDate = DateTime.fromISO(notification.redeliveryDate, { setZone: true }).toFormat('MM/dd/yyyy');
          redeliveryNotificationText = `${redeliveryNotificationText} for delivery on ${redeliveryDate}`;
        }

        return redeliveryNotificationText;
      case notificationTypes.creditsIssued:
        return `Credits issued for order ${order.orderId}: $${notification.amount}`;
      case notificationTypes.creditsIssuedToCustomer:
        return `Credits issued to customer for order ${order.orderId}: $${notification.amount}`;
      case notificationTypes.creditsIssuedToRecipient:
        return `Credits issued to recipient for order ${order.orderId}: $${notification.amount}`;
      case notificationTypes.refund:
        return `Refund issued for order ${order.orderId}: $${notification.amount}`;
      default:
        return `${notificationType} happened on ${localeString}`;
    }
  };

  useEffect(() => {
    const mapOfMillisToNotifications = {};
    const newNotificationsOrderedByTime = [];
    if (order && order.careResolutionNotifications) {
      for (const notificationType of Object.keys(order.careResolutionNotifications)) {
        const notificationsOfCurrentType = order.careResolutionNotifications[notificationType];
        for (const notification of notificationsOfCurrentType) {
          const millis = DateTime.fromISO(notification.date, { setZone: true }).toMillis();
          const stringified = convertNotificationObjectToText(notificationType, notification);

          let arr = [];
          if (mapOfMillisToNotifications[millis]) {
            arr = mapOfMillisToNotifications[millis];
          }
          arr.push({ suborderId: notification.suborderId, text: stringified });
          mapOfMillisToNotifications[millis] = arr;
        }
      }

      const milliArray = Object.keys(mapOfMillisToNotifications);
      milliArray.sort((m1, m2) => m1 - m2);
      milliArray.forEach((milli) => {
        const arrOfNotifStrings = mapOfMillisToNotifications[milli];
        for (const notification of arrOfNotifStrings) {
          newNotificationsOrderedByTime.push({
            timestamp: convertMillisToPrefferedTimestamp(milli),
            text: notification.text,
            rowId: newNotificationsOrderedByTime.length,
            suborderId: notification.suborderId,
          });
        }
      });
    }

    setNotificationsOrderedByTime(newNotificationsOrderedByTime);
  }, [order]);

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title">
          <Eye size="1.4em" className="mr-2" /> <span>Care Notifications</span>
        </h4>
      </div>
      <div className="card-body d-flex">
        <div>
          {notificationsOrderedByTime.map((notification) => (
            <div className="row" key={notification.rowId}>
              <div className="col-3">{notification.timestamp}</div>
              <div className="col-9">
                {notification.suborderId ? <a href={`#${notification.suborderId}`}>{notification.text}</a> : notification.text}
              </div>
              <hr className="mt-1 mb-1" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

CareResolutionNotification.propTypes = {
  order: PropTypes.object,
};

export default CareResolutionNotification;
