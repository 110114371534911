import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { descend, sort, prop } from 'ramda';
import { useTable } from 'react-table';
import ReactToPrint from 'react-to-print';
import { LookupContext } from '../../../context/lookupContext';
import { getSuborderStatusMap, useGetFCSuborders } from '../../../services/OrderManagementService';
import OrderCount from './OrderCount';
import SubordersByTransportMode from './SubordersByTransportMode';

const DailySummary = ({ date, fcId, fcConfig }) => {
  const [data, setData] = React.useState([]);
  const { productFeed } = useContext(LookupContext);
  const componentRef = useRef();

  const suborderStatusMap = getSuborderStatusMap();

  const PRE_FULFILLMENT = 'prefulfillment';
  const IN_FULFILLMENT = 'infulfillment';
  const COMPLETED = 'completed';
  // these will force devs to make updates to this page accordingly; shouldn't be dynamically decided. such counts will still get added to totals
  const NOT_ACCOUNTED_FOR = 'notAccountedFor';

  const PRE_FULFILLMENT_LESS_THAN = 20;
  const INFULFILLMENT_LESS_THAN = 100;
  const COMPLETED_UNTIL = 120;

  const getWhichCountAStatusBelongsTo = (suborderStatus) => {
    // rules as per DASH-257
    const statusNum = suborderStatusMap[suborderStatus];
    if (statusNum < PRE_FULFILLMENT_LESS_THAN) {
      return PRE_FULFILLMENT;
    }
    if (statusNum < INFULFILLMENT_LESS_THAN) {
      return IN_FULFILLMENT;
    }
    if (statusNum <= COMPLETED_UNTIL) {
      return COMPLETED;
    }
    return NOT_ACCOUNTED_FOR;
  };

  const addToSkuCountMap = (countMap, sku, newCount) => {
    countMap[sku] = (countMap[sku] || 0) + newCount;
  };

  const { status, error, data: suborders } = useGetFCSuborders(fcId, date);

  const getPageMargins = () => {
    return '@page { margin: 2rem !important; } @media print { td,th {padding: 0.5rem !important;} }';
  };

  useEffect(() => {
    function computeProducts(lineItems) {
      const skuQuantities = {};
      lineItems.forEach((lineItem) => {
        const { sku, quantity, components } = lineItem;
        if (!lineItem.isDeleted) {
          if (components && components.length) {
            components.forEach((component) => {
              const { sku: subSku, quantity: subQuantity } = component;
              skuQuantities[subSku] = (skuQuantities[subSku] || 0) + subQuantity * quantity;
            });
          } else {
            skuQuantities[sku] = (skuQuantities[sku] || 0) + quantity;
          }
        }
      });
      return skuQuantities;
    }

    const prefulfillmentCounts = {};
    const infulfillmentCounts = {};
    const completedCounts = {};

    const productCounts = (suborders || []).reduce((acc, suborder) => {
      if (suborder.status.toLowerCase() !== 'cancelled') {
        const quantities = computeProducts(suborder.lineItems);
        (Object.keys(quantities) || []).forEach((sku) => {
          const skuCount = quantities[sku];
          addToSkuCountMap(acc, sku, skuCount);

          const countDecision = getWhichCountAStatusBelongsTo(suborder.status);
          if (countDecision === PRE_FULFILLMENT) {
            addToSkuCountMap(prefulfillmentCounts, sku, skuCount);
          } else if (countDecision === IN_FULFILLMENT) {
            addToSkuCountMap(infulfillmentCounts, sku, skuCount);
          } else if (countDecision === COMPLETED) {
            addToSkuCountMap(completedCounts, sku, skuCount);
          }
        });
      }

      return acc;
    }, {});

    const primaryComponents = [];
    const otherComponents = [];
    Object.keys(productCounts).forEach((key) => {
      const matchingProduct = productFeed[key];
      const name = matchingProduct?.opsName || '';
      const sku = key;
      const productIdentifier = `${name}<br />${sku}`;
      const totalCount = productCounts[key];
      const prefulfillmentCount = prefulfillmentCounts[key] || 0;
      const infulfillmentCount = infulfillmentCounts[key] || 0;
      const completedCount = completedCounts[key] || 0;
      const component = {
        name,
        sku,
        total: totalCount,
        prefulfillmentCount,
        infulfillmentCount,
        completedCount,
        productIdentifier,
      };

      switch (matchingProduct?.classification) {
        case 'Floral - Live':
        case 'Floral - Dried, Wreaths & Home':
        case 'Plants':
          primaryComponents.push(component);
          break;
        case 'Vases & Pots':
        case 'Food & Drink':
        case 'Misc Addon':
        default:
          otherComponents.push(component);
          break;
      }
    });
    const byCount = descend(prop('count'));
    setData([...sort(byCount, primaryComponents), ...sort(byCount, otherComponents)]);
  }, [productFeed, suborders]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Product',
        accessor: 'productIdentifier',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => {
          return <div dangerouslySetInnerHTML={{ __html: value }} />;
        },
      },
      {
        Header: 'Pre-Fulfillment',
        accessor: 'prefulfillmentCount',
      },
      {
        Header: 'In Fulfillment',
        accessor: 'infulfillmentCount',
      },
      {
        Header: 'Completed',
        accessor: 'completedCount',
      },
      {
        Header: 'Totals',
        accessor: 'total',
      },
    ],
    [],
  );

  const defaultColumn = React.useMemo(() => ({}), []);

  const useTableVariables = { columns, data, defaultColumn };

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(useTableVariables);

  const hideableColumnIds = ['prefulfillmentCount', 'infulfillmentCount', 'completedCount'];

  const getCssClassForHidingColumnForPrinting = (column) => (hideableColumnIds.includes(column.id) ? 'd-print-none' : '');

  return status === 'loading' ? (
    <div className="bg-transparent card">
      <div className="text-center card-body">
        <p className="text-muted">Loading suborders...</p>
      </div>
    </div>
  ) : status === 'error' ? (
    <div className="bg-transparent card">
      <div className="text-center card-body">
        <p className="text-muted">Error: {error.message}</p>
      </div>
    </div>
  ) : (
    <div className="col-12 col-md-5">
      {fcConfig.showWindows ? (
        <OrderCount suborders={suborders} date={date} fcId={fcId} isLocalFc={fcConfig.showWindows} />
      ) : (
        <SubordersByTransportMode suborders={suborders} date={date} fcId={fcId} />
      )}

      <div className="card" ref={componentRef}>
        <style>{getPageMargins()}</style>
        <div className="card-header">
          <h4 className="card-header-title">
            Daily Summary <span className="d-none d-print-inline">- {date}</span>
          </h4>
          <ReactToPrint trigger={() => <button className="btn btn-link d-print-none">Print</button>} content={() => componentRef.current} />
        </div>

        <div className="mb-0">
          <table {...getTableProps()} className="table table-sm table-hover card-table table-small-sizing">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} className={getCssClassForHidingColumnForPrinting(column)}>
                      <div>
                        <span>{column.render('Header')}</span>
                      </div>
                    </th>
                  ))}
                  <th />
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="list font-size-sm">
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} className={getCssClassForHidingColumnForPrinting(cell.column)}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                    <td />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

DailySummary.propTypes = {
  date: PropTypes.string,
  fcId: PropTypes.string,
};

export default DailySummary;
