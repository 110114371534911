/* eslint-disable react/button-has-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useContext, useState } from 'react';
import { ChevronDownIcon, Menu, Popover, Portal, Position, Spinner } from 'evergreen-ui';
import PropTypes from 'prop-types';
import { roleChecks } from '../../../utilities/Role';
import { AuthContext } from '../../../context/authContext';
import BulkRemoveException from './BulkRemoveException';
import BulkRedistributeSubordersWizard from './redistribute-suborders/BulkRedistributeSubordersWizard';

const BulkExceptionActionPopup = ({ batchExceptionIds, setBatchExceptionIds, setCount }) => {
  const { userGroups } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [showConfirmationClearingModal, setShowConfirmationClearingModal] = useState(false);
  const [showRedistributeWizard, setShowRedistributeWizard] = useState(false);

  const clearExceptionIds = () => setBatchExceptionIds(new Set());

  const setShowConfirmationClearingModalWithClose = (val) => {
    setShowConfirmationClearingModal(val);
    if (!val) {
      clearExceptionIds();
    }
  };

  const setShowRedistributeWizardWithClose = (val) => {
    setShowRedistributeWizard(val);
    if (!val) {
      clearExceptionIds();
    }
  };

  return (
    <Portal>
      <div style={{ zIndex: 1 }} className="list-alert alert alert-dark alert-dismissible border fade show" role="alert">
        <div className="row align-items-center">
          <div className="col">
            <div className="form-check">
              <input className="form-check-input" id="listAlertCheckbox" type="checkbox" disabled checked />
              <label className="form-check-label text-white" htmlFor="listAlertCheckbox">
                <span className="list-alert-count">{batchExceptionIds.length}</span> suborder exception {batchExceptionIds.size > 0 ? '(s)' : ''}
              </label>
            </div>
          </div>
          <div className="col-auto mr-n3">
            {!loading ? ( // eslint-disable-next-line
              <div>
                <Popover
                  position={Position.BOTTOM_LEFT}
                  content={
                    <Menu>
                      <Menu.Group>
                        <Menu.Item disabled={!roleChecks.canBulkRemoveException(userGroups)} onSelect={() => setShowConfirmationClearingModal(true)}>
                          Remove Exceptions
                        </Menu.Item>
                        <Menu.Item disabled={!roleChecks.canRedistributeSuborders(userGroups)} onSelect={() => setShowRedistributeWizard(true)}>
                          Redistribute Suborders
                        </Menu.Item>
                      </Menu.Group>
                    </Menu>
                  }
                >
                  <button className="btn btn-sm btn-link text-white" role="button" aria-haspopup="true" aria-expanded="false">
                    Bulk Actions <ChevronDownIcon />
                  </button>
                </Popover>
                {showRedistributeWizard && (
                  <BulkRedistributeSubordersWizard
                    visible={showRedistributeWizard}
                    suborderIds={Array.from(batchExceptionIds)}
                    closeModal={() => setShowRedistributeWizardWithClose(false)}
                  />
                )}
              </div>
            ) : (
              <Spinner size={16} />
            )}
          </div>
        </div>
        <button type="button" className="list-alert-close close" aria-label="Close">
          <span aria-hidden="true" onClick={clearExceptionIds}>
            x
          </span>
        </button>
      </div>
      {showConfirmationClearingModal && (
        <BulkRemoveException
          batchExceptionIds={batchExceptionIds}
          setBatchExceptionIds={setBatchExceptionIds}
          setCount={setCount}
          loading={loading}
          setLoading={setLoading}
          confirmation={showConfirmationClearingModal}
          setConfirmation={setShowConfirmationClearingModalWithClose}
        />
      )}
    </Portal>
  );
};

BulkExceptionActionPopup.propTypes = {
  batchExceptionIds: PropTypes.array,
  setBatchExceptionIds: PropTypes.func,
  setCount: PropTypes.func,
};

BulkExceptionActionPopup.defaultProps = {
  batchExceptionIds: [],
  setBatchExceptionIds: () => {},
  setCount: () => {},
};

export default BulkExceptionActionPopup;
