import React from 'react';

import { FcContext } from '../../../context/fcContext';
import PageHeader from '../../nav/PageHeader';

import SelectAndBatchTable from './SelectAndBatchTable';
import BatchList from './BatchList';
import DailySummary from './DailySummary';

const SelectAndBatchScreen = () => {
  return (
    <FcContext.Consumer>
      {({ fcId, fcConfig, date }) => (
        <>
          <PageHeader category="Fulfillment" title="Select &amp; Batch" showDatePicker showFcPicker />
          <div className="container-fluid mt-n6">
            <div className="row justify-content-center">
              <div className="col-12">
                <SelectAndBatchTable date={date} fcId={fcId} fcConfig={fcConfig} />
              </div>
            </div>
            <div className="row justify-content-center">
              <BatchList date={date} fcId={fcId} />
              <DailySummary date={date} fcId={fcId} fcConfig={fcConfig} />
            </div>
          </div>
        </>
      )}
    </FcContext.Consumer>
  );
};

export default SelectAndBatchScreen;
