import { getRefundedTotal } from "./getRefundedTotal";
import { rollUpTransactions } from "./rollUpTransactions";

export const getAdjustedPaymentTotal = ({ transactions }) => {
  const alreadyRefunded = getRefundedTotal({ transactions });

  const alreadyPaid = rollUpTransactions({ transactions, transactionType: 'payment' });

  return alreadyPaid - alreadyRefunded;
};
