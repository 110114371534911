/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { BRAINTREE_API, BRAINTREE_MERCHANT_ID, AFFIRM_API } from '../../config';
import { formattedValue } from './utils';
import { getOutstandingBalance, outstandingBalanceDisplay } from '../order/helpers/getOutstandingBalance';

const getBrainTreeTransactionUrl = (id) => {
  return `${BRAINTREE_API}/merchants/${BRAINTREE_MERCHANT_ID}/transactions/${id}`;
};

const Transactions = ({ order, transactions, setShowChargeOrder }) => {
  const REFUND = 'REFUND';
  const PENDING = 'PENDING';
  const PAYMENT = 'PAYMENT';
  const BRAINTREE_TEXT = 'Braintree';
  const AFFIRM = 'AFFIRM';
  const AFFIRM_TEXT = 'Affirm';
  const SETTLED = 'settled';

  const getTypePayment = (source, id) => {
    return source?.toUpperCase() === AFFIRM
      ? {
        text: AFFIRM_TEXT,
        link: `${AFFIRM_API}/dashboard/charges/${id}`,
      }
      : {
        text: BRAINTREE_TEXT,
        link: getBrainTreeTransactionUrl(id),
      };
  };

  const getRefundTransaction = (transaction) => {
    const { type, id, status, source } = transaction;
    if (type.toUpperCase() === REFUND) {
      const typePayment = getTypePayment(source, id);

      const displayStatus = status === SETTLED ? status.toLowerCase() : 'Pending';
      return (
        <tr>
          <td className="px-0 py-1 border-top">
            <strong>Refund {displayStatus} </strong>
            {id !== 'PENDING' ? (
              <a href={typePayment.link} target="_blank" rel="noopener noreferrer">
                {typePayment.text} {id}
              </a>
            ) : (
              ''
            )}
          </td>
          <td className="px-0 py-1 text-right" />
        </tr>
      );
    }
    return null;
  };

  const getBrandAndPanTruncation = (transaction) => {
    const { name, amount, type } = transaction;
    const addMinus = type.toUpperCase() === REFUND || type.toUpperCase() === PENDING ? '-' : '';
    const total = formattedValue(amount, addMinus);
    return (
      <tr>
        <td className="px-0 py-1 border-top">{name}</td>
        <td className="px-0 py-1 text-right border-top">{total}</td>
      </tr>
    );
  };

  const getDateRefundPending = (transaction) => {
    const { type, id, date } = transaction;
    const dateRequested = type.toUpperCase() === REFUND && id?.toUpperCase() === PENDING;

    return (
      <tr>
        <td className="px-0 py-0 border-top-0">
          {dateRequested && <em> Date Requested: </em>}
          {DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_FULL)}
        </td>
        <td className="px-0 py-1 text-right border-top-0" />
      </tr>
    );
  };

  const getTotalRefundRow = (transaction) => {
    const { type, totalAfterRefund } = transaction;
    if (type.toUpperCase() === REFUND && typeof totalAfterRefund === 'number') {
      const total = formattedValue(totalAfterRefund);
      return (
        <tr>
          <td className="px-0 py-1 border-top border-top-2">
            <strong>Total</strong>
          </td>
          <td className="px-0 py-1 border-top border-top-2 text-right">
            <strong>{total}</strong>
          </td>
        </tr>
      );
    }
    return null;
  };

  const getPaymentRow = (transaction) => {
    const { type, id, source } = transaction;
    if (type?.toUpperCase() === PAYMENT) {
      const typePayment = getTypePayment(source, id);

      return (
        <tr>
          <td className="px-0 py-1">
            <a href={typePayment.link} target="_blank" rel="noopener noreferrer">
              {typePayment.text} {id}
            </a>
          </td>
          <td className="px-0 py-1 text-right" />
        </tr>
      );
    }
    return null;
  };

  const outstandingBalance = getOutstandingBalance({ paymentSummary: order.paymentSummary });

  if (transactions && transactions.length) {
    const braintreeTransactionList = transactions.map((transaction, i) => (
      <tbody className="border-top-space" key={`transaction-body-${i}`}>
        {getRefundTransaction(transaction)}
        {getBrandAndPanTruncation(transaction)}
        {getDateRefundPending(transaction)}
        {getTotalRefundRow(transaction)}
        {getPaymentRow(transaction)}
      </tbody>
    ));

    return (
      <>
        {braintreeTransactionList}
        <tbody>
          <tr>
            <td className="px-0 py-1 border-top border-top-2">
              <strong className="h3">Outstanding Balance</strong>
            </td>
            <td className="px-0 py-1 text-right border-top border-top-2">
              <span className="h3">{outstandingBalanceDisplay(outstandingBalance)}</span>
            </td>
          </tr>
          {!!(outstandingBalance && outstandingBalance > 0) && (
            <tr>
              <td colSpan="2" align="center">
                <button
                  type="button"
                  className="btn btn-success mr-2"
                  onClick={() => {
                    setShowChargeOrder(true);
                  }}
                >
                  Charge Order
                </button>
              </td>
            </tr>
          )}
        </tbody>
      </>
    );
  }
  return null;
};

Transactions.propTypes = {
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number.isRequired,
      date: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      source: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      totalAfterRefund: PropTypes.number,
    }),
  ).isRequired,
  order: PropTypes.shape({
    paymentSummary: PropTypes.object.isRequired,
  }).isRequired,
  setShowChargeOrder: PropTypes.func.isRequired,
};

export default Transactions;
