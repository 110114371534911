import React from 'react';
import { Dialog, Button } from 'evergreen-ui';
import PropTypes from 'prop-types';
import InventoryRecordContent from './InventoryRecordContent';
import { LARGER_DIALOG_COMMON_PROPS } from '../../../common/constants';

const InventoryRecordModal = ({
  showInventoryRecordModal,
  makeInventoryRecordModalVisible,
  hideInventoryRecordModal,
  selectedLot,
  fc = {},
  // eslint-disable-next-line no-unused-vars
  newAdjustmentSuccess = (object) => {},
}) => {
  const completeCreatingOrEditingAdjustment = ({ isFromInventoryRecordModal, successFunctionMessage }) => {
    newAdjustmentSuccess({ isFromInventoryRecordModal, successFunctionMessage });
  };

  return (
    <Dialog
      {...LARGER_DIALOG_COMMON_PROPS}
      topOffset="30px"
      isShown={showInventoryRecordModal}
      title="No footer"
      onCloseComplete={() => hideInventoryRecordModal(false)}
      hasHeader={false}
      hasClose={false}
      hasFooter={false}
      overlayProps={{ style: { paddingLeft: '150px' } }}
    >
      <InventoryRecordContent
        makeInventoryRecordModalVisible={makeInventoryRecordModalVisible}
        selectedLot={selectedLot}
        fc={fc}
        completeCreatingOrEditingAdjustment={completeCreatingOrEditingAdjustment}
        showCopyLinkIcon
      />
      <div style={{ margin: 20, textAlign: 'center' }}>
        <Button style={{ width: 150 }} onClick={() => hideInventoryRecordModal(false)}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
};

InventoryRecordModal.propTypes = {
  showInventoryRecordModal: PropTypes.bool,
  makeInventoryRecordModalVisible: PropTypes.func,
  hideInventoryRecordModal: PropTypes.func,
  selectedLot: PropTypes.object,
  fc: PropTypes.object,
  newAdjustmentSuccess: PropTypes.func,
};

InventoryRecordModal.defaultProps = {
  showInventoryRecordModal: false,
  makeInventoryRecordModalVisible: false,
  hideInventoryRecordModal: () => {},
  selectedLot: {},
  fc: {},
  newAdjustmentSuccess: () => {},
};

export default InventoryRecordModal;
