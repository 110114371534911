import React from 'react';
import PropTypes from 'prop-types';
import Flatpickr from 'react-flatpickr';
import { DateTime } from 'luxon';

const BestOptionDatePicker = ({ bestOptionDate, setBestOptionDate }) => {
  return (
    <Flatpickr
      className="form-control"
      value={bestOptionDate}
      onChange={(date, datestr) => setBestOptionDate(datestr)}
      options={{ minDate: DateTime.now().toISO() }}
      placeholder="Select Date For Best Option"
    />
  );
};

BestOptionDatePicker.propTypes = {
  bestOptionDate: PropTypes.string.isRequired,
  setBestOptionDate: PropTypes.func.isRequired,
};

export default BestOptionDatePicker;
