import React from 'react';

export default function SearchInput({ register, errors, name, label, searchLabel, ...rest }) {
  return (
    <>
      <div className="form-group">
        <label htmlFor={name} className="form-label">{label}</label>
        <div className="input-group">
          <input name={name} type="text" ref={register} className={'form-control ' + (errors[name] ? 'is-invalid' : '')} {...rest} />
          {errors[name] && <div className="invalid-feedback">{errors[name].message}</div>}
          <button type="submit" className="btn btn-primary">{searchLabel}</button>
        </div>
      </div>
    </>
  );
}
