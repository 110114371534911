/* This sorts IN-PLACE
 * Reorder suborders based on batchIndex in subordersMap
 */
export default function sortSubordersByBatchIndex(batch) {
  batch.suborders.sort((a, b) => {
    const indexA = batch.subordersMap[a.suborderId]?.batchIndex;
    const indexB = batch.subordersMap[b.suborderId]?.batchIndex;

    if (indexA === undefined || indexB === undefined) {
      return 0;
    }

    if (indexA < indexB) {
      return -1;
    }
    if (indexA > indexB) {
      return 1;
    }

    return 0;
  });
}
