import React from 'react';

export default function Input({ register, errors, name, label, ...rest }) {
  return (
    <>
      <label htmlFor={name} className="form-label">{label}</label>
      <input name={name} ref={register} className={'form-control ' + (errors[name] ? 'is-invalid' : '')} {...rest} />
      {errors[name] && <div className="invalid-feedback">{errors[name].message || `${label} is required`}</div>}
    </>
  );
}