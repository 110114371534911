import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Truck, Bicycle } from 'react-bootstrap-icons';
import { Dialog } from 'evergreen-ui';
import Spinner from '../common/Spinner';
import { createDelivery, cancelDelivery, getCustomerTrackingLink } from '../../services/OrderManagementService';
import { LookupContext } from '../../context/lookupContext';
import { AuthContext } from '../../context/authContext';
import { FcContext } from '../../context/fcContext';
import { roleChecks } from '../../utilities/Role';
import DeliveryDetails from './DeliveryDetails';
import UpdateDelivery from './UpdateDelivery';
import { computeWindow } from './helpers/computeWindow';
import { printOnePager } from '../../utilities/Printing';
import { applyOpsNamesToLineItemsAndComponentsInSuborder } from '../../utilities/OpsNames';

const Delivery = ({ order, suborder, delivery, setOrder, queryCache, showCancel, reprintAction }) => {
  const [errorMessage, setErrorMessage] = useState(delivery?.transportVendorDelivery?.errorMessage);
  const [updatedDelivery, setUpdatedDelivery] = useState(delivery);
  const [successMessage, setSuccessMessage] = useState('');
  const [showChangeDelivery, setShowChangeDelivery] = useState(false);
  const [showOverrideOnfleetAddressModal, setShowOverrideOnfleetAddressModal] = useState(false);
  const [customerTrackingLink, setCustomerTrackingLink] = useState(null);
  const [renderSpinner, setRenderSpinner] = useState(false);

  const { allFcs } = useContext(FcContext);
  const deliveryFc = allFcs.find((fc) => Number(fc.value) === delivery?.fc?.fcId);
  const { productFeed } = useContext(LookupContext);
  const { userGroups } = useContext(AuthContext);

  const recipient = suborder?.recipient;
  const recipientAddress = `${recipient?.address1 || ''} ${recipient?.address2 || ''} ${recipient?.city || ''} ${recipient?.state || ''} ${
    recipient?.zipCode || ''
  }`;
  const onfleetSubmittedAddressIsEquivalentToRecipientAddress =
    updatedDelivery?.transportVendorDelivery?.onfleetError?.submittedAddress === recipientAddress;

  const editableDeliveryStatuses = ['Received', 'Batched', 'BatchWorkorderPrinted', 'Picking', 'PickedAndQCd', 'AtTable', 'NotecardPrinted'];
  const reprintableDeliveryStatuses = [
    'SuborderPrinted',
    'Packed',
    'BatchCompleted',
    'OnPallet',
    'AwaitingPickup',
    'LeftFulfillmentCenter',
    'InTransit',
  ];

  // Temporarily declare FC ids here, pull from config in the future
  const soCalFcId = 45;
  const galleriaFcId = 47;

  const isDeliveryActive = () => {
    if (delivery && delivery.transportVendorDelivery) {
      const transportVendorDeliveryName = delivery.transportVendorDelivery.name.toLowerCase();
      switch (transportVendorDeliveryName) {
        case 'easypost':
        case 'ups_worldease':
          return !!updatedDelivery.transportVendorDelivery.easypostShipmentId;
        case 'onfleet':
          return !!updatedDelivery.transportVendorDelivery.onfleetTaskId;
        case 'golocal':
          return !!updatedDelivery.transportVendorDelivery.deliveryId;
        default:
          return false;
      }
    }
    return false;
  };

  useEffect(() => {
    const { transportVendorDelivery } = delivery;

    const allowedToAttemptCustomerTrackingLinkFetch =
      transportVendorDelivery.name && (transportVendorDelivery.trackingCode || transportVendorDelivery.onfleetTaskId);

    const setTrackingUrlFromOmsInCaseOfInexistence = async () => {
      const { customerTrackingLink: linkFromRoute } = await getCustomerTrackingLink(suborder.suborderId);

      if (linkFromRoute && linkFromRoute.length) {
        setCustomerTrackingLink(linkFromRoute);
        queryCache.invalidateQueries(['orders', suborder.orderId]);
      }
    };

    if (transportVendorDelivery.customerTrackingLink) {
      setCustomerTrackingLink(transportVendorDelivery.customerTrackingLink);
    } else if (allowedToAttemptCustomerTrackingLinkFetch) {
      setTrackingUrlFromOmsInCaseOfInexistence();
    }
  }, [delivery.transportVendorDelivery, suborder, delivery, queryCache]);

  const cancelSuborderDelivery = async () => {
    setErrorMessage(null);
    setSuccessMessage('');
    const { success, delivery: updatedDelivery } = await cancelDelivery(suborder.suborderId);

    if (success) {
      setSuccessMessage('Successfully cancelled');
      if (order) {
        const suborders = order.suborders.map((sb) => {
          if (sb.suborderId === suborder.suborderId) {
            return { ...suborder, delivery: { ...updatedDelivery.updatedDelivery } };
          }
          return sb;
        });
        setOrder({ ...order, suborders });
      }
    } else {
      setErrorMessage('Error cancelling');
    }
  };

  const createSuborderDelivery = async (overrideServiceErrors = false) => {
    setErrorMessage(null);
    setSuccessMessage('');
    setRenderSpinner(true);
    setShowOverrideOnfleetAddressModal(false);

    const { success, delivery: updatedDelivery } = await createDelivery(suborder.suborderId, overrideServiceErrors);
    setUpdatedDelivery(updatedDelivery);
    setRenderSpinner(false);

    if (success) {
      setSuccessMessage('Successfully created');
      if (order) {
        const suborders = order.suborders.map((sb) => {
          if (sb.suborderId === suborder.suborderId) {
            return { ...suborder, delivery: { ...updatedDelivery } };
          }
          return sb;
        });
        setOrder({ ...order, suborders });
      }
    } else {
      setErrorMessage(updatedDelivery?.transportVendorDelivery?.errorMessage);
    }
  };

  const renderLabelButton = () => {
    const hasPermissionToReprint = roleChecks.canReprintDeliveryLabel(userGroups);

    const isUpsWorldeaseDelivery = delivery.transportMode === 'ups_worldease' || delivery.transportMode === 'ups_worldease_saver';

    if (isUpsWorldeaseDelivery) {
      const worldeaseLabelButton = (text) => (
        <div className="mt-3 px-3" style={{ textAlign: 'center' }}>
          <button type="button" className="btn btn-secondary btn-sm" style={{ margin: 10 }} onClick={reprintAction}>
            {text}
          </button>
        </div>
      );

      if (delivery && delivery.transportVendorDelivery) {
        const { upsReferenceNumber } = delivery.transportVendorDelivery;
        if (hasPermissionToReprint) {
          if (upsReferenceNumber && upsReferenceNumber.length > 0) {
            // label has been printed before
            return worldeaseLabelButton('Reprint Label');
          }
          // label has not been printed at least once
          return worldeaseLabelButton('Create Label');
        }
      }
    } else if (hasPermissionToReprint && reprintAction && reprintableDeliveryStatuses.includes(suborder.status)) {
      return (
        <div className="mt-3 px-3" style={{ textAlign: 'center' }}>
          <button type="button" className="btn btn-secondary btn-sm" style={{ margin: 10 }} onClick={reprintAction}>
            Reprint Label
          </button>
          {(delivery?.fc?.fcId === soCalFcId || delivery?.fc?.fcId === galleriaFcId) && (
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              style={{ margin: 10 }}
              onClick={() => {
                applyOpsNamesToLineItemsAndComponentsInSuborder(suborder, productFeed);
                printOnePager([suborder], order.customer);
              }}
            >
              Reprint 1 Pager
            </button>
          )}
        </div>
      );
    } else if (editableDeliveryStatuses.includes(suborder.status)) {
      if (isDeliveryActive() || showCancel) {
        if (roleChecks.canChangeDeliveryInDeliveryComponent(userGroups) && delivery.transportVendorDelivery.name !== 'fedex_ipd') {
          return (
            <div className="mt-3 px-3" style={{ textAlign: 'center' }}>
              <button type="button" className="btn btn-secondary btn-sm" onClick={() => cancelSuborderDelivery()}>
                {delivery.transport === 'mail' ? 'Cancel Label' : `Cancel ${delivery.transportVendorDelivery.name} Job`}
              </button>
            </div>
          );
        }
      } else if (delivery.transportVendorDelivery.name !== 'fedex_ipd') {
        return (
          <div className="mt-3 px-3 border-top" style={{ textAlign: 'center' }}>
            <button
              type="button"
              className="btn btn-secondary btn-sm mt-4"
              onClick={
                onfleetSubmittedAddressIsEquivalentToRecipientAddress
                  ? () => setShowOverrideOnfleetAddressModal(true)
                  : () => createSuborderDelivery(false)
              }
            >
              {delivery.transport === 'mail' ? 'Create Label' : `Push to ${delivery.transportVendorDelivery.name}`}
            </button>
          </div>
        );
      } else {
        return '';
      }
    }
    return '';
  };

  const suborderData = (suborderObject) => {
    return {
      suborderId: suborderObject.suborderId,
      deliveryArea: delivery.areaId,
      status: suborderObject.status,
      lineItems: suborderObject.lineItems,
      window: computeWindow(delivery),
      startTime: delivery.startTime,
    };
  };

  const getTrackingLinkUrlElement = () => {
    return customerTrackingLink ? (
      <a href={customerTrackingLink} target="_blank" rel="noreferrer" className="btn btn-sm btn-outline-secondary">
        View Tracking
      </a>
    ) : (
      <div />
    );
  };

  return (
    <LookupContext.Consumer>
      {({ deliveryAreas }) => (
        <div className="card">
          <div className="card-header p-3">
            <h4 className="card-header-title">
              {!delivery.transport || delivery.transport === 'mail' ? (
                <Truck size="1.5em" className="mr-2" />
              ) : (
                <Bicycle size="1.5em" className="mr-2" />
              )}
              <span>Delivery Details</span>
            </h4>
            <div>
              {editableDeliveryStatuses.includes(suborder.status) && roleChecks.canChangeDeliveryInDeliveryComponent(userGroups) && (
                <button type="button" className="btn btn-link" onClick={() => setShowChangeDelivery(true)}>
                  Edit
                </button>
              )}
            </div>
          </div>
          <div className="card-body pt-0 pr-0 pl-0">
            <DeliveryDetails delivery={delivery} deliveryAreas={deliveryAreas} deliveryFc={deliveryFc} />

            {!renderSpinner && renderLabelButton()}
            {renderSpinner && (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Spinner />
              </div>
            )}
            {errorMessage && (
              <div className="text text-danger mt-3 mb-0 px-3" style={{ overflowWrap: 'anywhere' }}>
                {errorMessage}
                {updatedDelivery?.transportVendorDelivery?.onfleetError?.suggestedAddress ? (
                  <div>
                    The suggested address is {updatedDelivery?.transportVendorDelivery?.onfleetError?.suggestedAddress}. Please either update the
                    recipient address or Push To Onfleet with an override as necessary.
                  </div>
                ) : (
                  <span />
                )}
              </div>
            )}
            {showOverrideOnfleetAddressModal && (
              <Dialog
                isShown={showOverrideOnfleetAddressModal}
                title="Override Onfleet Errors"
                onCloseComplete={() => setShowOverrideOnfleetAddressModal(false)}
                onCancel={() => setShowOverrideOnfleetAddressModal(false)}
                hasCancel
                confirmLabel="Confirm"
                hasFooter
                onConfirm={() => createSuborderDelivery(true)}
              >
                <div className="container-fluid">
                  <div className="row justify-content-center">
                    <div className="col-12">Are you sure you want to override Onfleet Address errors and create a new label?</div>
                  </div>
                </div>
              </Dialog>
            )}
            {successMessage && <div className="text text-success mt-3 mb-0 px-3">{successMessage}</div>}
            {(isDeliveryActive() || showCancel) &&
              delivery.transportVendorDelivery &&
              ['easypost', 'ups_worldease'].includes(delivery.transportVendorDelivery.name) && (
                <div className="row g-0 px-3 mt-4 align-items-center justify-content-between">
                  <div className="col-auto">
                    {delivery.transportVendorDelivery.name === 'easypost' && (
                      <a
                        href={delivery.transportVendorDelivery.labelUrlPng ?? delivery.transportVendorDelivery.easypostLabelUrl}
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn-sm btn-outline-secondary"
                      >
                        View Label
                      </a>
                    )}
                  </div>

                  <div className="col-auto">{getTrackingLinkUrlElement()}</div>
                </div>
            )}
            {(isDeliveryActive() || showCancel) &&
              delivery.transportVendorDelivery &&
              delivery.transportVendorDelivery.name.toLowerCase() === 'onfleet' && (
                <div className="row px-3 mt-3 align-items-center justify-content-center" style={{ textAlign: 'center' }}>
                  <div className="col-auto">{getTrackingLinkUrlElement()}</div>
                </div>
            )}
          </div>
          {suborder && showChangeDelivery && (
            <UpdateDelivery
              suborders={[suborderData(suborder)]}
              fcId={delivery.fc?.fcId}
              deliveryAreas={deliveryAreas}
              showChangeDelivery={showChangeDelivery}
              setShowChangeDelivery={setShowChangeDelivery}
              onComplete={() => {
                queryCache.invalidateQueries(['orders', suborder.orderId]);
                setShowChangeDelivery(false);
              }}
              coords={suborder.recipient.coords}
              zipCode={suborder.recipient.zipCode}
              newOrder={false}
              setDeliveryFields={() => {}}
            />
          )}
        </div>
      )}
    </LookupContext.Consumer>
  );
};

Delivery.propTypes = {
  delivery: PropTypes.object.isRequired,
  suborder: PropTypes.object.isRequired,
  order: PropTypes.object,
  setOrder: PropTypes.func,
  reprintAction: PropTypes.func.isRequired,
  queryCache: PropTypes.object,
  showCancel: PropTypes.bool,
};

Delivery.defaultProps = {
  order: {},
  setOrder: (order) => order,
  queryCache: {},
  showCancel: false,
};

export default Delivery;
