import { DateTime } from 'luxon';

/**
 * Format the date to 'MMM d' (e.g., 'Apr 30')
 */
function formatDateString(dateString) {
  const parsedDate = DateTime.fromISO(dateString);

  const formattedDate = parsedDate.toFormat('MMM d yyyy');

  return formattedDate;
}

export default formatDateString;
