import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Recipient from './Recipient';
import Customer from './Customer';
import UpdateDelivery from './UpdateDelivery';
import { createNewOrder } from '../../services/OrderManagementService';
import { LookupContext } from '../../context/lookupContext';
import PageHeader from '../nav/PageHeader';
import DeliveryDetails from './DeliveryDetails';

const NewOrder = () => {
  const [page, setPage] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null);
  const [customerFields, setCustomerFields] = useState({
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    createdAt: null,
    modifiedAt: null,
    customerId: null,
  });
  const [recipientFields, setRecipientFields] = useState({
    firstName: null,
    lastName: null,
    address1: null,
    address2: null,
    city: null,
    state: null,
    zipCode: null,
    phone: null,
    locationType: null,
    instructions: null,
    placeName: null,
    email: null,
    coords: {
      lat: 0,
      lng: 0,
    },
  });
  const [deliveryFields, setDeliveryFields] = useState({
    areaId: null,
    startTime: null,
    orderFulfilledBy: null,
    windowDuration: null,
    transport: null,
    transportMode: null,
    fc: {
      fcId: null,
      timezone: null,
      company: null,
      address1: null,
      address2: null,
      city: null,
      state: null,
      zipCode: null,
      phone: null,
    },
    transportVendorDelivery: {
      name: null,
    },
  });
  const [showChangeDelivery, setShowChangeDelivery] = useState(true);
  const [enableNext, setEnableNext] = useState(false);

  const history = useHistory();

  const { deliveryAreas } = useContext(LookupContext);

  const pages = ['customer', 'recipient', 'delivery'];

  useEffect(() => {
    if (page === 0) {
      setEnableNext(!!(customerFields.firstName && customerFields.lastName && customerFields.email && customerFields.phone));
    } else if (page === 1) {
      setEnableNext(
        !!(
          recipientFields.firstName &&
          recipientFields.lastName &&
          recipientFields.address1 &&
          recipientFields.city &&
          recipientFields.state &&
          recipientFields.zipCode &&
          recipientFields.phone &&
          recipientFields.locationType &&
          recipientFields.coords
        ),
      );
    }
  }, [customerFields, recipientFields, page]);

  const nextPage = () => {
    setPage((prev) => prev + 1);
  };

  const previousPage = () => {
    setPage((prev) => prev - 1);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const orderFields = {
      customer: customerFields,
      recipient: recipientFields,
      delivery: deliveryFields,
    };

    const result = await createNewOrder(orderFields);

    // if success navigate to /order/newOrderId
    if (result.success) {
      const { orderId } = result.order;
      history.push(`/orders/${orderId}`);
    } else {
      // on fail display error message
      setErrorMessage('Could not create order');
    }
  };

  return (
    <>
      <PageHeader category="Orders" title="Create New Order" />
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="header">
            {errorMessage && (
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            )}
            <div className="form-container">
              {page === 0 && (
                <Customer
                  order={null}
                  suborder={null}
                  customer={customerFields}
                  setOrder={() => {}}
                  edit
                  newOrder
                  setCustomerFields={setCustomerFields}
                  canUpdate
                />
              )}

              {page === 1 && (
                <Recipient
                  order={null}
                  suborders={null}
                  recipient={recipientFields}
                  setOrder={() => {}}
                  edit
                  newOrder
                  setRecipientFields={setRecipientFields}
                  canUpdate
                />
              )}

              {page === 2 && (
                <div>
                  <DeliveryDetails
                    delivery={deliveryFields}
                    deliveryAreas={deliveryAreas}
                    deliveryFc={null}
                    showChangeDelivery={showChangeDelivery}
                    setShowChangeDelivery={setShowChangeDelivery}
                    newOrder
                  />

                  <UpdateDelivery
                    suborders={null}
                    fcId={null}
                    deliveryAreas={deliveryAreas}
                    showChangeDelivery={showChangeDelivery}
                    setShowChangeDelivery={setShowChangeDelivery}
                    onComplete={() => {}}
                    coords={recipientFields.coords}
                    zipCode={recipientFields.zipCode}
                    newOrder
                    setDeliveryFields={setDeliveryFields}
                  />
                </div>
              )}
            </div>

            <div className="d-inline-flex m-4">
              {page > 0 && (
                <button type="button" className="btn btn-secondary" onClick={previousPage}>
                  Back
                </button>
              )}
              {page < pages.length - 1 ? (
                <button type="button" className="btn btn-primary mx-1" onClick={nextPage} disabled={!enableNext}>
                  Next
                </button>
              ) : (
                <button type="button" className="btn btn-warning mx-1" onClick={onSubmit}>
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewOrder;
