import { DateTime } from 'luxon';

const getLatestCareResolution = (order) => {
  if (order) {
    const { careResolutionNotifications } = order;
    if (careResolutionNotifications) {
      const resolutions = Object.values(careResolutionNotifications).flat();
      if (resolutions.length) {
        resolutions.sort((a, b) => {
          const dateA = DateTime.fromISO(a.date);
          const dateB = DateTime.fromISO(b.date);

          if (dateA < dateB) {
            return 1; // Return a positive value to move 'a' to a later index
          } else if (dateA > dateB) {
            return -1; // Return a negative value to move 'a' to an earlier index
          } else {
            return 0; // Dates are equal
          }
        });

        return resolutions[0];
      }
    }
  }

  return null;
};

export default getLatestCareResolution;
