import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import Fulfill from '../fulfill/Fulfill';
import BatchSuborders from '../fulfill/BatchSuborders';
import Order from '../Order';
import SuborderExceptionQueue from '../order/exceptions/SuborderExceptionQueue';
import SelectAndBatchScreen from '../batching/create';
import ForgotPassword from '../auth/ForgotPassword';
import ViewOrder from '../order/ViewOrder';
import { roleChecks } from '../../utilities/Role';
import ViewBatch from '../batching/view';
import NewOrder from '../order/NewOrder';
import ViewCustomer from '../customers/ViewCustomer';
import BulkOrderForm from '../order/BulkOrderForm';
import Inventory from '../products/inventory/Inventory';
import DeliveryArea from '../outboundDelivery/deliveryArea/DeliveryArea';
import DeliveryWindows from '../outboundDelivery/deliveryWindows/DeliveryWindows';
import Spinner from '../common/Spinner';
import InventoryRecordPage from '../products/inventory/InventoryRecordPage';

const ProtectedRouter = ({ apiClient, userGroups, suborderExceptions, suborderExceptionsForFc, isRefreshingOMSLookups, fetchException }) => {
  const loadingIndicator = (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <Spinner />
      <div> Loading data... </div>
    </div>
  );

  return !isRefreshingOMSLookups ? (
    <>
      {roleChecks.canAddOrUpdateOrders(userGroups) && <Route exact path="/" component={Order} />}
      {!roleChecks.canAddOrUpdateOrders(userGroups) && roleChecks.canFulfill(userGroups) && <Route exact path="/" component={Fulfill} />}
      <Route exact path="/forgotPassword" component={ForgotPassword} />
      {roleChecks.canUpdate(userGroups) && (
        <>
          <Switch>
            <Route exact path="/orders" component={Order} />
            <Route path="/orders/new" component={NewOrder} />
            <Route exact path="/orders/bulk" component={BulkOrderForm} />
            <Route exact path="/orders/bulk/:id" component={BulkOrderForm} />
            <Route path="/orders/:id" component={ViewOrder} />
          </Switch>

          <Route
            exact
            path="/exceptions"
            render={() => <SuborderExceptionQueue fetchException={fetchException} apiClient={apiClient} suborderExceptions={suborderExceptions} />}
          />
          <Route
            exact
            path="/exceptions/:fcId"
            render={() => <SuborderExceptionQueue apiClient={apiClient} suborderExceptions={suborderExceptionsForFc} />}
          />

          <Route exact path="/customers/:id" component={ViewCustomer} />
        </>
      )}

      {roleChecks.canFulfill(userGroups) && (
        <>
          <Route exact path="/fulfill" component={Fulfill} />
          <Route exact path="/fulfill/:batchId" component={BatchSuborders} />
          <Route exact path="/batches" component={SelectAndBatchScreen} />
          <Route exact path="/batches/:batchId" component={ViewBatch} />
        </>
      )}

      {roleChecks.canViewInventory(userGroups) && (
        <>
          <Route exact path="/inventory" component={Inventory} />
          <Route exact path="/inventory/:id" component={InventoryRecordPage} />
        </>
      )}
      {roleChecks.canViewOutboundDelivery(userGroups) && (
        <>
          <Route exact path="/delivery-area" render={(props) => <DeliveryArea {...props} userGroups={userGroups} />} />
          <Route exact path="/delivery-windows" render={(props) => <DeliveryWindows {...props} userGroups={userGroups} />} />
        </>
      )}
    </>
  ) : (
    loadingIndicator
  );
};

ProtectedRouter.propTypes = {
  userGroups: PropTypes.arrayOf(PropTypes.string),
  apiClient: PropTypes.shape({ query: PropTypes.func.isRequired }),
  suborderExceptions: PropTypes.array,
  suborderExceptionsForFc: PropTypes.array,
  isRefreshingOMSLookups: PropTypes.bool,
  fetchException: PropTypes.func,
};

ProtectedRouter.defaultProps = {
  userGroups: [],
  apiClient: null,
  suborderExceptions: [],
  suborderExceptionsForFc: [],
  isRefreshingOMSLookups: false,
  fetchException: () => { },
};

export default ProtectedRouter;
