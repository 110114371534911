/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useReducer, useMemo, useLayoutEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import axios from 'axios';
import gql from 'graphql-tag';
import { path, intersection } from 'ramda';
import Amplify, { Auth, Hub, Logger, Storage } from 'aws-amplify';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

import './App.scss';
import { FcContext } from './context/fcContext';
import { LookupContext } from './context/lookupContext';
import { AuthContext } from './context/authContext';
import awsconfig from './aws-exports';
import Authentication from './components/Authentication';
import ResetPassword from './components/auth/ResetPassword';
import ProtectedRouter from './components/nav/ProtectedRouter';
import NavBar from './components/nav/NavBar';
import ForgotPassword from './components/auth/ForgotPassword';
import { roleChecks } from './utilities/Role';

import { CALLBACK_URL, ENVIRONMENT_NAME, ORDER_MANAGEMENT_SERVICE_BASE_URL, NON_PROD_ENVIRONMENT_COLOR } from './config';
import * as subscriptions from './graphql/subscriptions';
import * as queries from './graphql/queries';
import {
  isSetActionType,
  filterExceptionForFc,
  createSuborderExceptionCreationAction,
  createSuborderExceptionDeletionAction,
  createSuborderExceptionSetAction,
  suborderExceptionReducer,
  dispatchAllSuborderException,
} from './reducers/SuborderExceptionReducer';
import fulfillmentCenters from './lookups/fulfillmentCenters.json';
import { tableSlots } from './lookups/table-slots';
import { getAllDeliveryAreas, getProductFeedArray } from './services/OrderManagementService';

const primaryPrefixes = ['FLRL-B', 'FLRL-K', 'FLRL-D', 'FLRL-T', 'NF-K', 'GFT-K', 'GFT-B', 'NF-PLNT'];

const mapProductFeedFromArray = (pFeedArray) => {
  // eslint-disable-next-line
  const updatedProductFeedArray = pFeedArray.map((product) => {
    const isPrimary = primaryPrefixes.some((prefix) => product.sku.startsWith(prefix));

    const foundProduct = {
      ...product,
      isPrimary,
      allowedTransportModes: product.allowedTransportModes ? product.allowedTransportModes.split(',') : [],
      distributionPoints: product.distributionPoints ? product.distributionPoints.split(',') : [],
    };

    return foundProduct;
  });

  const mappedBySku = Object.assign({}, ...updatedProductFeedArray.map((product) => ({ [product.sku]: product })));

  return mappedBySku;
};

awsconfig.oauth.redirectSignIn = CALLBACK_URL;
awsconfig.oauth.redirectSignOut = CALLBACK_URL;

Amplify.configure(awsconfig);
Auth.configure(awsconfig);
Storage.configure({ level: 'private' });

const App = () => {
  const log = new Logger('App');
  const { urlPathname } = useLocation();

  const fcConfig = useMemo(
    () => ({
      1: {
        label: 'DC Dungeon',
        optgroup: 'UrbanStems',
        useCompactLocalLabels: true,
        showWindows: true,
        useGuidedFulfillment: true,
        roles: ['FCUrbanstems', 'FCDc'],
        batchOptions: { defaultMinSize: 1 },
      },
      2: {
        label: 'Manhattan Parlour',
        optgroup: 'UrbanStems',
        useCompactLocalLabels: true,
        showWindows: true,
        useGuidedFulfillment: true,
        useZplForCdlInGuidedFulfillment: true,
        roles: ['FCUrbanstems', 'FCMan'],
        batchOptions: { defaultMinSize: 1 },
      },
      4: {
        label: 'Baltimore Depot',
        optgroup: 'UrbanStems',
        useBulkAndGuidedFulfillment: true,
        subOrderPageSize: 40,
        useZplForCdlInGuidedFulfillment: true,
        roles: ['FCUrbanstems', 'FCHub'],
        usePickAndPass: true,
      },
      9: { label: 'USA Bouquet - Miami', optgroup: 'USA Bouquet', subOrderPageSize: 40, roles: ['FCUsab', 'FCUsabMia'] },
      10: { label: 'USA Bouquet - Atlanta', optgroup: 'USA Bouquet', roles: ['FCUsab', 'FCUsabAtl'] },
      11: { label: 'USA Bouquet - Chicago', optgroup: 'USA Bouquet', useBulkAndGuidedFulfillment: true, roles: ['FCUsab', 'FCUsabChi'] },
      13: { label: 'USA Bouquet - Los Angeles', optgroup: 'USA Bouquet', roles: ['FCUsab', 'FCUsabLa'] },
      29: { label: 'Magic Flowers', optgroup: 'Farm Direct', isUpsWorldeaseFc: true, roles: ['FCMagic'] },
      30: { label: 'Elite NJ', optgroup: 'Elite', roles: ['FCElite', 'FCEliteNj'], useCustomOnePagerForCdl: true },
      41: { label: 'Elite DAL', optgroup: 'Elite', roles: ['FCElite', 'FCEliteDal'] },
      42: { label: 'Elite MIA', optgroup: 'Elite', roles: ['FCElite', 'FCEliteMia'] },
      47: { label: 'Galleria KY', optgroup: 'Galleria', roles: ['FCGalleriaKY'] },
      50: { label: 'Royal Flowers', optgroup: 'Farm Direct', isUpsWorldeaseFc: true, roles: ['FCRoyal'] },
      51: { label: 'Agroganadera Espinosa', optgroup: 'Farm Direct', isUpsWorldeaseFc: true, roles: ['FCAgrogana'] },
      52: { label: 'Elite Farm Colombia', optgroup: 'Farm Direct', isUpsWorldeaseFc: true, roles: ['FCElite', 'FCEliteFarm'] },
      55: { label: 'Elite CA', optgroup: 'Elite', roles: ['FCElite', 'FCEliteCa'] },
      56: { label: 'PPC FL', optgroup: 'PPC', roles: ['FCPpc', 'FCPpcFl'] },
      57: { label: 'PPC CA', optgroup: 'PPC', roles: ['FCPpc', 'FCPpcCa'] },
      58: { label: 'Galleria CA', optgroup: 'Galleria', roles: ['FCGalleriaCA'], useBulkAndGuidedFulfillment: true, usePickAndPass: true },
      59: { label: 'Color Orchids VA', optgroup: 'Color Orchids', roles: ['FCColorOrchidsVA', 'FCColorOrchids'] },
      60: { label: 'Color Orchids TX', optgroup: 'Color Orchids', roles: ['FCColorOrchidsTX', 'FCColorOrchids'] },
      63: { label: 'The Magnolia FC', optgroup: 'Magnolia', roles: ['FCMagnolia'] },
      64: { label: 'Resendiz Brothers FC', optgroup: 'Resendiz Brothers', roles: ['FCResendizBrothers'] },
      65: { label: 'Elite Boston FC', optgroup: 'Elite', roles: ['FCElite', 'FCEliteBos'] },
    }),
    [],
  );

  const today = new Date();
  const [fcId, setFcId] = useState();
  const [date, setDate] = useState(new Date(today.getTime() - today.getTimezoneOffset() * 60000).toISOString().split('T')[0]);
  const [user, setUser] = useState();
  const [tempUser, setTempUser] = useState({});
  const [userGroups, setUserGroups] = useState([]);
  const [allAuthorizedFcs, setAllAuthorizedFcs] = useState([]);
  const [groupedFcs, setGroupedFcs] = useState([]);
  const [apiClient, setApiClient] = useState(null);
  const [suborderExceptions, dispatchSuborderException] = useReducer(suborderExceptionReducer, []);
  const [suborderExceptionsForFc, dispatchSuborderExceptionForFc] = useReducer((state, action) => {
    if (isSetActionType(action)) {
      return suborderExceptionReducer(state, action);
    }
    if (fcId && fcId.toString() === (action.data.fcId || '').toString()) {
      return suborderExceptionReducer(state, action);
    }
    return state;
  }, []);
  const [suborderExceptionCreationSubscription, setSuborderExceptionCreationSubscription] = useState(null);
  const [suborderExceptionDeletionSubscription, setSuborderExceptionDeletionSubscription] = useState(null);
  const [fetchedSuborderExceptions, setFetchedSuborderExceptions] = useState(false);
  const [suborderExceptionSubscribed, setSuborderExceptionSubscribed] = useState(false);
  const [productFeed, setProductFeed] = useState({});
  const [deliveryAreas, setDeliveryAreas] = useState({});
  const [isRefreshingOMSLookups, setIsRefreshingOMSLookups] = useState(false);

  const fetchProductFeedArrayAndSaveMap = async () => {
    const response = await getProductFeedArray();
    if (response && response.data && response.data.productFeedArray) {
      const { productFeedArray } = response.data;
      const mapped = mapProductFeedFromArray(productFeedArray);
      setProductFeed(mapped);
    }
  };

  const fetchAllDeliveryAreas = async () => {
    const response = await getAllDeliveryAreas();
    if (response && response.data && response.data.deliveryAreas) {
      const { deliveryAreas: allDeliveryAreas } = response.data;
      setDeliveryAreas(allDeliveryAreas);
    }
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      log.error('error signing out: ', error);
    }
  };

  useLayoutEffect(() => {
    if (ENVIRONMENT_NAME !== 'prod') document.body.style.backgroundColor = NON_PROD_ENVIRONMENT_COLOR;
  }, []);

  useEffect(() => {
    const getUser = async () => {
      try {
        const currentSession = await Auth.currentSession();
        const idToken = currentSession.getIdToken();
        axios.interceptors.request.use((axiosConfig) => {
          return new Promise((resolve, reject) => {
            Auth.currentSession()
              .then((session) => {
                const updatedAxiosConfig = { ...axiosConfig };
                // Only get/refresh/send Authorization header when interacting with OMS
                if (!axiosConfig.url.startsWith(ORDER_MANAGEMENT_SERVICE_BASE_URL)) {
                  resolve(updatedAxiosConfig);
                } else {
                  const idTokenExpire = session.getIdToken().getExpiration();
                  const refreshToken = session.getRefreshToken();
                  const currentTimeSeconds = Math.round(+new Date() / 1000);
                  if (idTokenExpire < currentTimeSeconds) {
                    Auth.currentAuthenticatedUser().then((res) => {
                      res.refreshSession(refreshToken, (err, data) => {
                        if (err) {
                          Auth.signOut();
                        } else {
                          updatedAxiosConfig.headers.Authorization = `Bearer ${data.getIdToken().getJwtToken()}`;
                          resolve(updatedAxiosConfig);
                        }
                      });
                    });
                  } else {
                    updatedAxiosConfig.headers.Authorization = `Bearer ${session.getIdToken().getJwtToken()}`;
                    resolve(updatedAxiosConfig);
                  }
                }
              })
              .catch(() => {
                // No logged-in user: don't set auth header
                reject(axiosConfig);
              });
          });
        });
        const client = new AWSAppSyncClient(
          {
            url: awsconfig.aws_appsync_graphqlEndpoint,
            region: awsconfig.aws_appsync_region,
            auth: {
              type: AUTH_TYPE.API_KEY,
              apiKey: awsconfig.aws_appsync_apiKey,
            },
            disableOffline: true,
          },
          {
            defaultOptions: {
              watchQuery: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'ignore',
              },
              query: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
              },
            },
          },
        );
        const userPayload = idToken.decodePayload();
        setUser(userPayload);
        if (userPayload) {
          setUserGroups(userPayload['cognito:groups']);
        }
        setApiClient(await client.hydrated());
      } catch (e) {
        log.warn('Not signed in');
      }
    };

    const getUserThenRunOtherActions = async () => {
      await getUser();

      setIsRefreshingOMSLookups(true);

      const asyncableActions = [];
      asyncableActions.push(fetchProductFeedArrayAndSaveMap());
      asyncableActions.push(fetchAllDeliveryAreas());

      await Promise.all(asyncableActions);

      setIsRefreshingOMSLookups(false);
    };

    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUserThenRunOtherActions();
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'forgotPassword_failure':
          log.error('Forgot password failure', data);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
        default:
          log.error('Sign in failure', data);
          break;
      }
    });

    getUserThenRunOtherActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchException = () => {
    if (!roleChecks.canUpdate(userGroups) || !apiClient) {
      return;
    }

    const fetchSuborderExceptions = async (token) => {
      const gqlQuery = { query: gql(queries.listSuborderExceptions) };

      if (token) {
        gqlQuery.variables = { nextToken: token };
      }

      const data = await apiClient.query(gqlQuery);

      // eslint-disable-next-line
      const exceptions = (path(['data', 'listSuborderExceptions', 'items'], data) || []).filter((e) => !e._deleted);
      const nextToken = path(['data', 'listSuborderExceptions', 'nextToken'], data) || '';
      return { exceptions, nextToken };
    };

    const setSuborderExceptions = () => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        const allExceptions = [];
        let token = null;

        try {
          do {
            // eslint-disable-next-line no-await-in-loop
            const { exceptions, nextToken } = await fetchSuborderExceptions(token);
            allExceptions.push(...exceptions);
            token = nextToken;
          } while (token);

          const action = createSuborderExceptionSetAction(allExceptions);
          dispatchAllSuborderException(dispatchSuborderException, dispatchSuborderExceptionForFc, action);
          setFetchedSuborderExceptions(true);
          resolve(); // Resolve the promise when done
        } catch (error) {
          reject(error); // Reject the promise if there's an error
        }
      });
    };

    // eslint-disable-next-line consistent-return
    return setSuborderExceptions();
  };

  // fetch exceptions
  useEffect(() => {
    fetchException();
  }, [userGroups, apiClient, fcId]);

  // listen to fc id change
  useEffect(
    () => dispatchSuborderExceptionForFc(createSuborderExceptionSetAction(filterExceptionForFc(fcId, suborderExceptions))),
    [fcId, suborderExceptions],
  );

  // listen to suborder exception activity
  useEffect(() => {
    if (!roleChecks.canUpdate(userGroups) || !apiClient || suborderExceptionSubscribed || !fetchedSuborderExceptions) {
      return;
    }

    // subscribe to creation
    const creationSubscription = apiClient.subscribe({ query: gql(subscriptions.onCreateSuborderException) }).subscribe({
      next: (data) => {
        const suborderException = path(['data', 'onCreateSuborderException'], data);
        const action = createSuborderExceptionCreationAction(suborderException);
        dispatchAllSuborderException(dispatchSuborderException, dispatchSuborderExceptionForFc, action);
      },
      error: (err) => {
        log.warn('Error while processing suborder exception creation subscription event', err);
      },
    });
    setSuborderExceptionCreationSubscription(creationSubscription);

    // subscribe to deletion
    const deletionSubscription = apiClient.subscribe({ query: gql(subscriptions.onDeleteSuborderException) }).subscribe({
      next: (data) => {
        const suborderException = path(['data', 'onDeleteSuborderException'], data);
        const action = createSuborderExceptionDeletionAction(suborderException);
        dispatchAllSuborderException(dispatchSuborderException, dispatchSuborderExceptionForFc, action);
      },
      error: (err) => {
        log.warn('Error while processing suborder exception deletion subcription event', err);
      },
    });
    setSuborderExceptionDeletionSubscription(deletionSubscription);
    setSuborderExceptionSubscribed(true);

    // eslint-disable-next-line
    return () => {
      if (suborderExceptionCreationSubscription) {
        suborderExceptionCreationSubscription.unsubscribe();
      }
      if (suborderExceptionDeletionSubscription) {
        suborderExceptionDeletionSubscription.unsubscribe();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userGroups,
    apiClient,
    suborderExceptionCreationSubscription,
    suborderExceptions,
    fetchedSuborderExceptions,
    suborderExceptionSubscribed,
    suborderExceptionDeletionSubscription,
  ]);

  useEffect(() => {
    if (userGroups && userGroups.length) {
      const allFcs = Object.keys(fcConfig).map((key) => {
        return { optgroup: fcConfig[key].optgroup, value: key, label: fcConfig[key].label };
      });

      const authFilter = (fc) => {
        const fcRoles = fcConfig[fc.value]?.roles || [];
        if (fcRoles && fcRoles.length) {
          return intersection(userGroups, fcRoles).length > 0;
        }
        return false;
      };

      const authed = userGroups.indexOf('FCAll') > -1 ? allFcs : allFcs.filter(authFilter);
      setAllAuthorizedFcs(authed);

      // Which FC to set by default
      setFcId(authed[0].value);

      if (userGroups.includes('FCUrbanstems')) {
        if (userGroups.indexOf('FCDc') > -1) {
          setFcId('1');
        } else if (userGroups.indexOf('FCMan') > -1) {
          setFcId('2');
        } else {
          setFcId('4');
        }
      }
      const groupedFcsObject = {};
      for (const fc of authed) {
        const { optgroup } = fc;
        if (!groupedFcsObject[optgroup]) {
          groupedFcsObject[optgroup] = { label: optgroup, options: [] };
        }
        groupedFcsObject[optgroup].options.push({ label: fc.label, value: fc.value });
      }
      const groupedFcs = Object.values(groupedFcsObject);
      setGroupedFcs(groupedFcs);
    } else {
      setAllAuthorizedFcs([]);
      setGroupedFcs([]);
      setFcId(null);
    }
  }, [fcConfig, userGroups]);

  const authContextValue = useMemo(
    () => ({
      user,
      userGroups,
    }),
    [user, userGroups],
  );

  const lookupContextValue = useMemo(() => ({ tableSlots, deliveryAreas, productFeed, fulfillmentCenters }), [deliveryAreas, productFeed]);

  const fcContextValue = useMemo(
    () => ({ fcId, setFcId, groupedFcs, allFcs: allAuthorizedFcs, allFcsConfig: fcConfig, fcConfig: fcConfig[fcId] || {}, date, setDate }),
    [fcId, allAuthorizedFcs, date, fcConfig, groupedFcs],
  );

  return user ? (
    <AuthContext.Provider value={authContextValue}>
      <LookupContext.Provider value={lookupContextValue}>
        <FcContext.Provider value={fcContextValue}>
          <NavBar
            urlPathname={urlPathname}
            fcId={fcId}
            loggedInUser={user}
            userGroups={userGroups}
            suborderExceptions={suborderExceptions}
            suborderExceptionsForFc={suborderExceptionsForFc}
            signOutFunction={signOut}
          />
          <div className="main-content">
            <Switch>
              <ProtectedRouter
                userGroups={userGroups}
                apiClient={apiClient}
                suborderExceptions={suborderExceptions}
                fetchException={fetchException}
                suborderExceptionsForFc={suborderExceptionsForFc}
                isRefreshingOMSLookups={isRefreshingOMSLookups}
              />
            </Switch>
          </div>
          <ReactQueryDevtools initialIsOpen position="bottom-right" />
        </FcContext.Provider>
      </LookupContext.Provider>
    </AuthContext.Provider>
  ) : (
    <Switch>
      <Route exact path="/forgotPassword" component={ForgotPassword} />
      <Route exact path="/resetPassword" render={(props) => <ResetPassword {...props} setUser={setUser} tempUser={tempUser} />} />
      <Route render={(props) => <Authentication {...props} setUser={setUser} setTempUser={setTempUser} tempUser={tempUser} />} />
    </Switch>
  );
};

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const AppContainer = () => (
  <Router>
    <ReactQueryCacheProvider queryCache={queryCache}>
      <App />
    </ReactQueryCacheProvider>
  </Router>
);

export default AppContainer;
