import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import TableRow from './TableRow';
import LineItem from './LineItem';
import Transactions from './Transactions';

const PaymentSummary = ({ order, summary, canChargeOrder, setShowChargeOrder }) => {
  const getShippingDiscount = () => {
    if (summary.shippingDiscount) {
      return (
        <TableRow name="Shipping Discount" value={summary.shippingDiscount} />
      );
    }
    return null;
  };
  const getCreditsUsed = () => {
    if (summary.creditUsed) {
      return (
        <TableRow name="Credits Used" value={summary.creditUsed} />
      );
    }
    return null;
  };

  const getPromo = () => {
    if (summary.promoApplied) {
      return (
        <TableRow name="Promo Total" value={summary.promo.amount} typeTd="promo" promoCode={summary.promo.code} addMinus="-" />
      );
    }

    return null;
  };

  const getDiscounts = () => {
    if (summary.productDiscountTotal) {
      return (
        <TableRow name="Promo Total" value={summary.productDiscountTotal} typeTd="promo" addMinus="-" />
      );
    }

    return null;
  };

  const getPromoAndDiscounts = () => {
    if (summary.promoApplied || summary.productDiscountTotal) {
      return (
        <>
          {getPromo()}
          {getDiscounts()}
          <TableRow
            name="Subtotal - Discount"
            value={summary.subtotalMinusProductDiscounts}
            nameStyles="bold-text border-top border-top-2 bold-text"
            valueStyles="border-top border-top-2 bold-text"
            typeTd="total"
            trStyle="border-bottom-space"
          />
        </>
      );
    }
    return null;
  };

  return (
    <div className="card">
      <Header />
      <div className="card-body">
        <div className="table-responsive">
          <table className="table" style={{ marginBottom: 0 }}>
            <tbody>
              <LineItem products={summary.products} />

              <TableRow
                name="Subtotal"
                value={summary.subtotal}
                nameStyles="bold-text border-top border-top-2 bold-text"
                valueStyles="border-top border-top-2 bold-text"
                typeTd="total"
                trStyle="border-bottom-space"
              />

              {getPromoAndDiscounts()}
              <TableRow name="Shipping Cost" value={summary.shippingAndHandling} />

              {getShippingDiscount()}
              <TableRow name="Taxes" value={summary.salesTax} />
              {getCreditsUsed()}

              <TableRow
                name="Order Total"
                value={summary.orderTotal}
                nameStyles="bold-text border-top border-top-2 bold-text"
                valueStyles="border-top border-top-2 bold-text"
                typeTd="total"
                trStyle="border-bottom-space"
              />
            </tbody>

            <Transactions
              transactions={summary.transactions}
              order={order}
              canChargeOrder={canChargeOrder}
              setShowChargeOrder={setShowChargeOrder}
            />
          </table>
        </div>
      </div>
    </div>
  );
};

PaymentSummary.propTypes = {
  summary: PropTypes.shape({
    products: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        amount: PropTypes.number.isRequired,
        sku: PropTypes.string.isRequired,
        discount: PropTypes.number,
      }),
    ),
    promoApplied: PropTypes.bool.isRequired,
    promo: PropTypes.shape({
      amount: PropTypes.number,
      code: PropTypes.string,
    }),
    subtotal: PropTypes.number.isRequired,
    subtotalMinusProductDiscounts: PropTypes.number.isRequired,
    shippingAndHandling: PropTypes.number.isRequired,
    shippingDiscount: PropTypes.number,
    productDiscountTotal: PropTypes.number.isRequired,
    salesTax: PropTypes.number.isRequired,
    creditUsed: PropTypes.number.isRequired,
    orderTotal: PropTypes.number.isRequired,
    transactions: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        date: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        source: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        totalAfterRefund: PropTypes.number,
      }),
    ),
  }).isRequired,
};

export default PaymentSummary;
