export const addExceptionReasons = [
  {
    label: 'Fulfillment Issue',
    options: [
      { value: 'Notecard not printing', label: 'Notecard not printing', categoryLabel: 'Fulfillment Issue' },
      { value: 'Label error', label: 'Label error', categoryLabel: 'Fulfillment Issue' },
      { value: 'Address formatting issue', label: 'Address formatting issue', categoryLabel: 'Fulfillment Issue' },
    ],
  },
  {
    label: 'Forced upgrade',
    options: [
      { value: 'Product not received by FC', label: 'Product not received by FC', categoryLabel: 'Forced upgrade' },
      { value: 'QC rejection', label: 'QC rejection', categoryLabel: 'Forced upgrade' },
      { value: 'Unknown inventory discrepancy', label: 'Unknown inventory discrepancy', categoryLabel: 'Forced upgrade' },
      { value: 'Vendor Shortage', label: 'Vendor Shortage', categoryLabel: 'Forced upgrade' },
      { value: 'Did not claim inventory', label: 'Did not claim inventory', categoryLabel: 'Forced upgrade' },
      { value: 'Assigned to unavailable preloads', label: 'Assigned to unavailable preloads', categoryLabel: 'Forced upgrade' },
      { value: 'Internal request', label: 'Internal request', categoryLabel: 'Forced upgrade' },
    ],
  },
  {
    label: 'Other',
    options: [
      { value: 'Awaiting customer response', label: 'Awaiting customer response', categoryLabel: 'Other' },
      { value: 'Could not claim inventory', label: 'Could not claim inventory', categoryLabel: 'Other' },
      { value: 'Existing exception', label: 'Existing exception', categoryLabel: 'Other' },
      { value: 'New exception', label: 'New exception', categoryLabel: 'Other' },
      { value: 'Redelivery creation', label: 'Redelivery creation', categoryLabel: 'Other' },
      { value: 'No line items.', label: 'No line items.', categoryLabel: 'Other' },
    ],
  },
];

export const redeliveryReasons = [
  {
    label: 'Delivery issue',
    options: [
      { label: 'Delivered late', value: 'Delivered late', categoryLabel: 'Delivery issue' },
      { label: 'Delivered too early', value: 'Delivered too early', categoryLabel: 'Delivery issue' },
      { label: 'Delivered but not received/stolen', value: 'Delivered but not received/stolen', categoryLabel: 'Delivery issue' },
      { label: 'Recipient unavailable to receive', value: 'Recipient unavailable to receive', categoryLabel: 'Delivery issue' },
      { label: 'Incorrect address', value: 'Incorrect address', categoryLabel: 'Delivery issue' },
      { label: 'Order not tracking', value: 'Order not tracking', categoryLabel: 'Delivery issue' },
      {
        label: 'Could not unclaim and reclaim inventory during delivery window change',
        value: 'Could not unclaim and reclaim inventory during delivery window change',
        categoryLabel: 'Delivery issue',
      },
    ],
  },
  {
    label: 'Fulfillment issue',
    options: [
      { label: 'Missing note', value: 'Missing note', categoryLabel: 'Fulfillment issue' },
      { label: 'Wrong note', value: 'Wrong note', categoryLabel: 'Fulfillment issue' },
      { label: 'Order not fulfilled', value: 'Order not fulfilled', categoryLabel: 'Fulfillment issue' },
    ],
  },
  {
    label: 'Tech issue',
    options: [
      { label: "Website Error / CX couldn't place order", value: "Website Error / CX couldn't place order", categoryLabel: 'Tech issue' },
      { label: 'Promo Code', value: 'Promo Code', categoryLabel: 'Tech issue' },
    ],
  },
];

export const lineItemRedeliveryReasons = [
  {
    label: 'Fulfillment issue',
    options: [
      { label: 'Missing item', value: 'Missing item', categoryLabel: 'Fulfillment issue' },
      { label: 'Incorrect item', value: 'Incorrect item', categoryLabel: 'Fulfillment issue' },
      { label: 'Forced upgrade, CX unhappy', value: 'Forced upgrade, CX unhappy', categoryLabel: 'Fulfillment issue' },
    ],
  },
  {
    label: 'Quality issue',
    options: [
      { label: 'Brown or bruised petals or plant', value: 'Brown or bruised petals or plant', categoryLabel: 'Quality issue' },
      { label: 'Short vase life/wilted too quickly', value: 'Short vase life/wilted too quickly', categoryLabel: 'Quality issue' },
      { label: 'Droopy/dehydrated upon arrival', value: 'Droopy/dehydrated upon arrival', categoryLabel: 'Quality issue' },
      { label: 'Not as pictured online', value: 'Not as pictured online', categoryLabel: 'Quality issue' },
      { label: 'Broken stems - floral/plant', value: 'Broken stems - floral/plant', categoryLabel: 'Quality issue' },
      { label: 'Too small/poor value for money', value: 'Too small/poor value for money', categoryLabel: 'Quality issue' },
      { label: 'Damaged add-on', value: 'Damaged add-on', categoryLabel: 'Quality issue' },
      { label: 'Damaged vase/pot', value: 'Damaged vase/pot', categoryLabel: 'Quality issue' },
      { label: 'Frozen flowers/plant', value: 'Frozen flowers/plant', categoryLabel: 'Quality issue' },
      { label: 'Moldy or rotten flowers', value: 'Moldy or rotten flowers', categoryLabel: 'Quality issue' },
    ],
  },
];

export const updateLineItemReasons = [
  {
    label: 'Forced upgrade',
    options: [
      { label: 'Product not received by FC', value: 'Product not received by FC', categoryLabel: 'Forced upgrade' },
      { label: 'QC rejection', value: 'QC rejection', categoryLabel: 'Forced upgrade' },
      { label: 'Unknown inventory discrepancy', value: 'Unknown inventory discrepancy', categoryLabel: 'Forced upgrade' },
      { label: 'Vendor Shortage', value: 'Vendor Shortage', categoryLabel: 'Forced upgrade' },
      { label: 'Did not claim inventory', value: 'Did not claim inventory', categoryLabel: 'Forced upgrade' },
      { label: 'Assigned to unavailable preloads', value: 'Assigned to unavailable preloads', categoryLabel: 'Forced upgrade' },
      { label: 'Internal request', value: 'Internal request', categoryLabel: 'Forced upgrade' },
    ],
  },
  {
    label: 'Customer requested order update',
    options: [
      { label: 'Item no longer needed', value: 'Item no longer needed', categoryLabel: 'Customer requested order update' },
      { label: 'Accidentally added too many items', value: 'Accidentally added too many items', categoryLabel: 'Customer requested order update' },
      { label: 'Order Cancellation', value: 'Order Cancellation', categoryLabel: 'Customer requested order update' },
    ],
  },
];

export const refundLineItemReasons = [
  {
    label: 'Fulfillment issue',
    options: [
      { label: 'Missing item', value: 'Missing item', categoryLabel: 'Fulfillment issue' },
      { label: 'Incorrect item', value: 'Incorrect item', categoryLabel: 'Fulfillment issue' },
      { label: 'Forced upgrade, CX unhappy', value: 'Forced upgrade, CX unhappy', categoryLabel: 'Fulfillment issue' },
    ],
  },
  {
    label: 'Quality issue',
    options: [
      { label: 'Brown or bruised petals or plant', value: 'Brown or bruised petals or plant', categoryLabel: 'Quality issue' },
      { label: 'Short vase life/wilted too quickly', value: 'Short vase life/wilted too quickly', categoryLabel: 'Quality issue' },
      { label: 'Droopy/dehydrated upon arrival', value: 'Droopy/dehydrated upon arrival', categoryLabel: 'Quality issue' },
      { label: 'Not as pictured online', value: 'Not as pictured online', categoryLabel: 'Quality issue' },
      { label: 'Broken stems - floral/plant', value: 'Broken stems - floral/plant', categoryLabel: 'Quality issue' },
      { label: 'Too small/poor value for money', value: 'Too small/poor value for money', categoryLabel: 'Quality issue' },
      { label: 'Damaged add-on', value: 'Damaged add-on', categoryLabel: 'Quality issue' },
      { label: 'Damaged vase/pot', value: 'Damaged vase/pot', categoryLabel: 'Quality issue' },
      { label: 'Frozen flowers/plant', value: 'Frozen flowers/plant', categoryLabel: 'Quality issue' },
      { label: 'Moldy or rotten flowers', value: 'Moldy or rotten flowers', categoryLabel: 'Quality issue' },
    ],
  },
  {
    label: 'Forced upgrade',
    options: [
      { value: 'Product not received by FC', label: 'Product not received by FC', categoryLabel: 'Forced upgrade' },
      { value: 'QC rejection', label: 'QC rejection', categoryLabel: 'Forced upgrade' },
      { value: 'Unknown inventory discrepancy', label: 'Unknown inventory discrepancy', categoryLabel: 'Forced upgrade' },
      { value: 'Vendor Shortage', label: 'Vendor Shortage', categoryLabel: 'Forced upgrade' },
      { value: 'Did not claim inventory', label: 'Did not claim inventory', categoryLabel: 'Forced upgrade' },
      { value: 'Assigned to unavailable preloads', label: 'Assigned to unavailable preloads', categoryLabel: 'Forced upgrade' },
      { value: 'Internal request', label: 'Internal request', categoryLabel: 'Forced upgrade' },
    ],
  },
  {
    label: 'Customer requested order update',
    options: [
      { label: 'Item no longer needed', value: 'Item no longer needed', categoryLabel: 'Customer requested order update' },
      { label: 'Accidentally added too many items', value: 'Accidentally added too many items', categoryLabel: 'Customer requested order update' },
    ],
  },
];

export const creditReasons = [
  ...refundLineItemReasons,
  {
    label: 'Marketing',
    options: [{ label: 'Marketing Spend', value: 'Marketing Spend', categoryLabel: 'Marketing' }],
  },
];

export const addLineItemReasons = [
  ...updateLineItemReasons,
  {
    label: 'Redelivery resolution',
    options: [{ label: 'Redelivery resolution', value: 'Redelivery resolution', categoryLabel: 'Redelivery resolution' }],
  },
];
export const cancelReasons = [
  {
    label: 'Customer requested order update',
    options: [
      { value: 'Item no longer needed', label: 'Item no longer needed', categoryLabel: 'Customer requested order update' },
      { value: 'Accidentally added too many items', label: 'Accidentally added too many items', categoryLabel: 'Customer requested order update' },
      { value: 'Order Cancellation', label: 'Order Cancellation', categoryLabel: 'Customer requested order update' },
    ],
  },
];

export const categorizeReaons = async () => {
  const allReasons = await getAllReasons();
  let categories = {};

  await allReasons.map((reason) => {
    if (categories[reason.categoryLabel]) {
      categories[reason.categoryLabel].push(reason.value);
    } else {
      categories = {
        ...categories,
        [reason.categoryLabel]: [reason.value],
      };
    }
  });

  return categories;
};

export const getAllReasons = async () => {
  const allReasons = [];

  addExceptionReasons.forEach((reason, i) => {
    reason.options.forEach((option) => {
      allReasons.push(option);
    });
  });
  redeliveryReasons.forEach((reason) => {
    reason.options.forEach((option) => {
      allReasons.push(option);
    });
  });
  lineItemRedeliveryReasons.forEach((reason) => {
    reason.options.forEach((option) => {
      allReasons.push(option);
    });
  });
  refundLineItemReasons.forEach((reason) => {
    reason.options.forEach((option) => {
      allReasons.push(option);
    });
  });
  addLineItemReasons.forEach((reason) => {
    reason.options.forEach((option) => {
      allReasons.push(option);
    });
  });
  cancelReasons.forEach((reason) => {
    reason.options.forEach((option) => {
      allReasons.push(option);
    });
  });

  let uniqueReasons = [];

  await allReasons.map((reason) =>
    uniqueReasons.filter((a) => a.label == reason.label && a.categoryLabel == reason.categoryLabel).length > 0 ? null : uniqueReasons.push(reason),
  );

  return uniqueReasons;
};

export const getReasonType = async (reason) => {
  const allReasons = await getAllReasons();
  const found = allReasons.filter((uniqueReason) => uniqueReason.label.includes(reason));

  let response = 'Other';
  if (found.length && found[0].categoryLabel) {
    response = found[0].categoryLabel;
  }

  return response;
};
