export const TRANSPORT_MODES = [
  'bike',
  'car',
  'ground',
  'express',
  'ground_2',
  'ground_3',
  'ground_4',
  'express_2',
  'express_2_ca',
  'express_extended',
  'fedex_ipd',
  'ground_weekend_home',
  'express_extended_plants',
  'express_extended_dried_ca',
  'ups_worldease',
  'ups_worldease_saver',
  'ground_5',
];

export const CARRIER = [
  'easypost',
  'onfleet',
  'fedex',
  'ups',
  'golocal',
];

export const DELIVERYMECHANISM = [
  'onFleet',
  'easypost',
  'usabouquets',
  'nationwide',
];
