import React, { useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { Dialog } from 'evergreen-ui';
import { updateBatchStatus } from '../../../services/OrderManagementService';

const UpdateBatchStatusModal = ({ batchId, onClose }) => {
  const [newStatus, setNewStatus] = useState(null);

  const statuses = [
    { value: 'Created', label: 'Created' },
    { value: 'WorkorderPrinted', label: 'Workorder Printed' },
    { value: 'AtTable', label: 'At Table' },
    { value: 'SubordersPrinted', label: 'Suborders Printed' },
  ];

  return (
    <Dialog
      isShown
      title="Update Batch Status"
      onCloseComplete={onClose}
      confirmLabel="Confirm"
      onConfirm={async (close) => {
        await updateBatchStatus(batchId, newStatus);
        close();
      }}
    >
      <div>
        <div className="mb-4">
          New Batch Status:
          <Select
            id="selectBatchStatus"
            onChange={(event) => setNewStatus(event.value)}
            options={statuses}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
          />
        </div>
      </div>
    </Dialog>
  );
};

UpdateBatchStatusModal.propTypes = {
  batchId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default UpdateBatchStatusModal;
