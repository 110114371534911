import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { CreditCard } from 'react-bootstrap-icons';
import { DateTime } from 'luxon';
import { BRAINTREE_API, BRAINTREE_MERCHANT_ID, AFFIRM_API } from '../../config.js';
import { LookupContext } from '../../context/lookupContext';
import { roleChecks } from '../../utilities/Role';
import { AuthContext } from '../../context/authContext';
import { getOutstandingBalance, outstandingBalanceDisplay } from './helpers/getOutstandingBalance';
import getIdealProductName from './helpers/getIdealProductName.js';

const TransactionSummary = ({ order, totals, orderTransactions, suborders, canChargeOrder, setShowChargeOrder }) => {
  const [transactions, setTransactions] = useState(null);
  const { productFeed } = useContext(LookupContext);
  const { userGroups } = useContext(AuthContext);

  const shouldBeShown = (value) => {
    return value !== undefined && value !== null && value > 0;
  };

  useEffect(() => {
    // sort transactions by date in ascending order (older dates first)
    const sortTransactions = orderTransactions;

    if (sortTransactions) {
      if (sortTransactions.length && sortTransactions.length > 1) {
        sortTransactions.sort((a, b) => {
          const date1 = DateTime.fromISO(a.transactionDate);
          const date2 = DateTime.fromISO(b.transactionDate);

          if (date1 > date2) {
            return 1;
          }

          if (date1 < date2) {
            return -1;
          }

          if (date1.equals(date2)) {
            return 0;
          }
        });
      }

      // calculate total after refund for each refund transaction
      let totalAfterRefunds = totals.orderTotal;

      const transactionsWithTotals = sortTransactions.map((transaction) => {
        if (transaction.transactionType === 'refund') {
          const total = totalAfterRefunds - transaction.amount;
          totalAfterRefunds -= transaction.amount;
          return { ...transaction, totalAfterRefund: total };
        }
        return transaction;
      });

      setTransactions(transactionsWithTotals);
    }
  }, [orderTransactions, totals.orderTotal]);

  const getLineItemName = (lineItem) => {
    const product = lineItem.product ? lineItem.product : lineItem;
    return getIdealProductName(productFeed, product);
  };

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title">
          <CreditCard size="1.5em" className="mr-2" /> <span>Payment Summary</span>
        </h4>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table className="table">
            <tbody>
              {suborders
                .filter((suborder) => !suborder.redeliveryOfSuborderId)
                .map((suborder) => (suborder.saleLineItems ? suborder.saleLineItems : suborder.lineItems).map((lineItem) => (
                  <tr key={`ts-${suborder.suborderId}-lineitem-${lineItem.index}`}>
                    <td className={`px-0 py-1${lineItem.price < 0 ? ' text-decoration-line-through' : ''}`}>{getLineItemName(lineItem)}</td>
                    <td className={`px-0 py-1 text-right text-right${lineItem.price < 0 ? ' text-decoration-line-through' : ''}`}>
                      ${lineItem.price}
                    </td>
                  </tr>
                )))}
              <tr>
                <td className="px-0 py-1">Item total</td>
                <td className="px-0 py-1 text-right">${totals.itemTotal}</td>
              </tr>
              <tr>
                <td className="px-0 py-1 border-top border-top-2">
                  <strong>Subtotal</strong>
                </td>
                <td className="px-0 py-1 text-right border-top border-top-2">
                  <strong>${totals.subtotal}</strong>
                </td>
              </tr>
              <tr>
                <td className="px-0 py-1">Discounts</td>
                <td className="px-0 py-1 text-right">-${totals.discountTotal}</td>
              </tr>
              <tr>
                <td className="px-0 py-1">Shipping cost</td>
                <td className="px-0 py-1 text-right">${totals.shippingCost}</td>
              </tr>
              {shouldBeShown(totals.promoTotal) && (
                <tr>
                  <td className="px-0 py-1">
                    Promo total <span className="badge bg-info">{totals.promoCode}</span>
                  </td>
                  <td className="px-0 py-1 text-right">-${totals.promoTotal}</td>
                </tr>
              )}
              <tr>
                <td className="px-0 py-1">Taxes</td>
                <td className="px-0 py-1 text-right">${totals.taxTotal}</td>
              </tr>
              {shouldBeShown(totals.creditUsed) && (
                <tr>
                  <td className="px-0 py-1">Credits used</td>
                  <td className="px-0 py-1 text-right">-${totals.creditUsed}</td>
                </tr>
              )}
              <tr>
                <td className="px-0 py-1 border-top border-top-2">
                  <strong className="h3">Payment total</strong>
                </td>
                <td colSpan="2" className="px-0 py-1 text-right border-top border-top-2">
                  <span className="h3">${totals.orderTotal}</span>
                </td>
              </tr>
            </tbody>
            {transactions &&
              transactions.map((transaction, i) => (
                <tbody key={i}>
                  {transaction.transactionType === 'refund' && (
                    <tr>
                      {transaction.transactionId === 'PENDING' ? (
                        <td className="px-0 py-1 border-top">
                          <strong> Pending Refund</strong>
                        </td>
                      ) : (
                        <td className="px-0 py-1 border-top">
                          <strong> Refund</strong>
                        </td>
                      )}
                      <td className="px-0 py-1 text-right" />
                    </tr>
                  )}
                  <tr>
                    {transaction.brand && transaction.panTruncation ? (
                      <td className="px-0 py-1 border-top">{transaction.brand + transaction.panTruncation}</td>
                    ) : (
                      <td className="px-0 py-1 border-top">{transaction.paymentType}</td>
                    )}
                    <td className="px-0 py-1 text-right border-top">${transaction.amount}</td>
                  </tr>
                  <tr>
                    <td className="px-0 py-0 border-top-0">
                      {transaction.transactionType === 'refund' && transaction.transactionId === 'PENDING' && <em> Date Requested: </em>}
                      {DateTime.fromISO(transaction.transactionDate).toLocaleString(DateTime.DATETIME_FULL)}
                    </td>
                    <td className="px-0 py-1 text-right border-top-0" />
                  </tr>
                  {transaction.transactionType === 'refund' && typeof transaction.totalAfterRefund === 'number' && (
                    <tr>
                      <td className="px-0 py-1 border-top border-top-2">
                        <strong>Total</strong>
                      </td>
                      <td className="px-0 py-1 text-right border-top border-top-2">
                        <strong>${transaction.totalAfterRefund.toFixed(2)}</strong>
                      </td>
                    </tr>
                  )}
                  <tr>
                    {transaction.transactionType === 'payment' && transaction.type && (
                      <>
                        <td className="px-0 py-1">
                          {transaction.type === 'Braintree' && (
                            <a
                              href={`${BRAINTREE_API}/merchants/${BRAINTREE_MERCHANT_ID}/transactions/${transaction.transactionId}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Braintree {transaction.transactionId}
                            </a>
                          )}
                          {transaction.type === 'Affirm' && (
                            <a href={`${AFFIRM_API}/dashboard/charges/${transaction.transactionId}`} target="_blank" rel="noopener noreferrer">
                              Affirm {transaction.transactionId}
                            </a>
                          )}
                        </td>
                        <td className="px-0 py-1 text-right" />
                      </>
                    )}
                  </tr>
                </tbody>
              ))}
            <tbody>
              <tr>
                <td className="px-0 py-1 border-top border-top-2">
                  <strong className="h3">Outstanding Balance</strong>
                </td>
                <td className="px-0 py-1 text-right border-top border-top-2">
                  <span className="h3">{outstandingBalanceDisplay(getOutstandingBalance({ paymentSummary: order.paymentSummary }))}</span>
                </td>
              </tr>
              <tr>
                <td colSpan="2" align="center">
                  {roleChecks.canRefundOrIssueCreditsOnDirectlyToOrder(userGroups) && (
                    <>
                      {roleChecks.canViewChargeOrderButton(userGroups) && canChargeOrder && (
                        <button
                          className="mr-2 btn btn-success"
                          onClick={() => {
                            setShowChargeOrder(true);
                          }}
                        >
                          Charge Order
                        </button>
                      )}
                    </>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

TransactionSummary.propTypes = {
  totals: PropTypes.object.isRequired,
  orderTransactions: PropTypes.array.isRequired,
};

export default TransactionSummary;
