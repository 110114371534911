import React, { useContext, useEffect, useState } from 'react';
import Flatpickr from 'react-flatpickr';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css';
import { DateTime } from 'luxon';
import { Button, PlusIcon, CrossIcon } from 'evergreen-ui';
import * as Icon from 'react-bootstrap-icons';
import Select from 'react-select';
import Footer from '../../common/wizards/Footer';
import TextInput from '../../common/TextInput';
import { LookupContext } from '../../../context/lookupContext';
import { AuthContext } from '../../../context/authContext';
// eslint-disable-next-line import/no-cycle
import UpdateDelivery from '../UpdateDelivery';
import { computeWindow } from '../helpers/computeWindow';
import { generateNewCoords } from '../../../services/OrderManagementService';
import getIdealProductName from '../helpers/getIdealProductName';
import getInventoryForUseEffect from '../helpers/getInventoryForUseEffect';
import isPrimaryProduct from '../helpers/isPrimaryProduct';

const Step4 = ({ suborder, state, updateAction, stepFourState, updateStepFourState, ...props }) => {
  const { deliveryAreas, productFeed } = useContext(LookupContext);
  const { userGroups } = useContext(AuthContext);
  const [redeliverySuborder, setRedeliverySuborder] = useState(state.redeliverySuborder);
  const [lineItems, setLineItems] = useState(redeliverySuborder.lineItems);
  const [delivery, setDelivery] = useState(redeliverySuborder.delivery);
  const [deliveryDate, setDeliveryDate] = useState(stepFourState.deliveryDate || DateTime.now().plus({ days: 1 }).toISODate());
  const [recipient, setRecipient] = useState(redeliverySuborder.recipient);
  const [showBadAddressErrorMessage, setShowBadAddressErrorMessage] = useState(stepFourState.showBadAddressErrorMessage);

  const [newRecipientDetails, setNewRecipientDetails] = useState(redeliverySuborder.recipient);

  const [selectedSkus, setSelectedSkus] = useState(stepFourState.selectedSkus);
  const [skuToUpgradeMap, setSkuToUpgradeMap] = useState(stepFourState.skuToUpgradeMap);
  const [additionalLineItems, setAdditionalLineItems] = useState(stepFourState.additionalLineItems);
  const [editingRecipient, setEditingRecipient] = useState(stepFourState.editingRecipient);
  const [productOptions, setProductOptions] = useState(stepFourState.productOptions);

  const [showChangeDelivery, setShowChangeDelivery] = useState(true);

  const [updateDeliveryDisable, setUpdateDeliveryDisable] = useState(true);

  const oldLineItems =
    state.lineItemsWithIssue.length === 0 ? suborder.lineItems.filter((lineItem) => !lineItem.isDeleted) : state.lineItemsWithIssue;

  const skuKey = (li) => `${li.sku}-${li.index}`;

  useEffect(() => {
    updateStepFourState({
      selectedSkus,
      skuToUpgradeMap,
      additionalLineItems,
      editingRecipient,
      productOptions,
      deliveryDate,
      showBadAddressErrorMessage,
    });
  }, [selectedSkus, skuToUpgradeMap, additionalLineItems, editingRecipient, productOptions, deliveryDate, showBadAddressErrorMessage]);

  const getSanitizedLineItems = (newLineItems) => {
    const arrayToReturn = [];
    for (const lineItem of newLineItems) {
      if (lineItem.allowedTransportModes || lineItem.distributionPoints || lineItem.categories) {
        const sanitized = {
          unitPrice: 0,
          taxRate: 0,
          images: {
            large: lineItem.imageUrl,
            thumbnail: lineItem.imageThumbUrl,
          },
          quantity: 1,
          originalPrice: 0,
          price: 0,
          name: lineItem.name,
          index: -1,
          discountAmount: 0,
          taxAmount: 0,
          sku: lineItem.sku,
          isPrimaryProduct: isPrimary(lineItem),
          components: lineItem.kit,
        };
        arrayToReturn.push(sanitized);
      } else {
        arrayToReturn.push(lineItem);
      }
    }
    return arrayToReturn;
  };

  useEffect(() => {
    const olderOnes =
      selectedSkus.length > 0
        ? oldLineItems.filter((li) => selectedSkus.includes(skuKey(li)) && !Object.keys(skuToUpgradeMap).includes(skuKey(li)))
        : [];
    const lineItemsToDelete =
      selectedSkus.length > 0
        ? oldLineItems.filter((li) => selectedSkus.includes(skuKey(li)) && Object.keys(skuToUpgradeMap).includes(skuKey(li)))
        : [];
    const upgrades = Object.keys(skuToUpgradeMap)
      .map((key) => skuToUpgradeMap[key])
      .filter((upgrade) => upgrade != null);
    const additionals = additionalLineItems.filter((additional) => additional != null);
    const newLineItems = [...olderOnes, ...upgrades, ...additionals];

    const sanitized = getSanitizedLineItems(newLineItems);
    setLineItems(sanitized);

    const sanitizedDeletedLineItems = getSanitizedLineItems(lineItemsToDelete);

    updateRedeliverySuborder({ lineItems: sanitized, deletedLineItems: sanitizedDeletedLineItems });
  }, [selectedSkus, skuToUpgradeMap, additionalLineItems]);

  const isPrimary = (lineItem) => isPrimaryProduct(lineItem.sku, productFeed);

  // updates
  const updateDelivery = (json) => {
    setDelivery(json);
    updateRedeliverySuborder({ delivery: json });
  };
  const updateRedeliverySuborder = (json) => {
    const newRedeliverySuborder = { ...redeliverySuborder, ...json };
    setRedeliverySuborder(newRedeliverySuborder);
    updateAction({ redeliverySuborder: newRedeliverySuborder });
  };

  useEffect(() => updateRedeliverySuborder({ delivery }), [delivery]);
  useEffect(() => updateRedeliverySuborder({ recipient }), [recipient]);

  const getLineItemDisplay = (lineItem) => `${lineItem.quantity}x ${getIdealProductName(productFeed, lineItem)}`;

  const onLineItemCheckboxSelect = (isChecked, lineItem) => {
    if (isChecked) {
      setSelectedSkus([...selectedSkus, skuKey(lineItem)]);
    } else {
      const arr = [...selectedSkus];
      if (arr.includes(skuKey(lineItem))) {
        const indexInPrevValues = arr.indexOf(skuKey(lineItem));
        arr.splice(indexInPrevValues, 1);
        setSelectedSkus(arr);
      }
      const newSkuToUpgradeMap = { ...skuToUpgradeMap };
      delete newSkuToUpgradeMap[skuKey(lineItem)];
      setSkuToUpgradeMap(newSkuToUpgradeMap);
    }
  };

  const onLineItemSelection = (oldSku, indexInAdditionalItemArray, newLineItem) => {
    if (oldSku) {
      const newSkuToUpgradeMap = { ...skuToUpgradeMap };
      newSkuToUpgradeMap[oldSku] = newLineItem;
      setSkuToUpgradeMap(newSkuToUpgradeMap);
    } else if (additionalLineItems.length > indexInAdditionalItemArray) {
      const newAdditionalLineItems = [...additionalLineItems];
      newAdditionalLineItems[indexInAdditionalItemArray] = newLineItem;
      setAdditionalLineItems(newAdditionalLineItems);
    }
  };

  const createAProductDropdown = (oldSku, indexInAdditionalItemArray) => {
    return (
      <Select
        id={`selectNewProduct-${lineItems.length}`}
        defaultValue={oldSku ? skuToUpgradeMap[oldSku] : additionalLineItems[indexInAdditionalItemArray]}
        onChange={(event) => onLineItemSelection(oldSku, indexInAdditionalItemArray, event.value)}
        options={productOptions}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
      />
    );
  };

  const onUpgradeClick = (sku) => {
    setSkuToUpgradeMap({
      ...skuToUpgradeMap,
      [sku]: null,
    });
  };

  const onCancelUpgradeClick = (sku) => {
    const upgradeMap = { ...skuToUpgradeMap };
    delete upgradeMap[sku];
    setSkuToUpgradeMap(upgradeMap);
  };

  const onEditRecipientClick = () => setEditingRecipient(true);

  const getAddressWithCoords = async () => {
    const fieldsToCheck = ['address1', 'address2', 'state', 'city', 'zipCode'];
    const addressHasBeenChanged = fieldsToCheck.filter((field) => recipient[field] !== newRecipientDetails[field]).length > 0;
    if (addressHasBeenChanged) {
      const newCoordsResponse = await generateNewCoords(newRecipientDetails);
      if (newCoordsResponse.success && newCoordsResponse.coordinates && newCoordsResponse.coordinates.lat) {
        return {
          ...newRecipientDetails,
          coords: newCoordsResponse.coordinates,
        };
      }
      return null;
    }
    return newRecipientDetails;
  };

  const onSaveRecipientClick = async () => {
    const fullRecipient = await getAddressWithCoords();
    if (fullRecipient) {
      setRecipient(fullRecipient);
      setNewRecipientDetails(fullRecipient);
      setEditingRecipient(false);
      setShowBadAddressErrorMessage(false);
    } else {
      setShowBadAddressErrorMessage(true);
    }
  };

  const onCancelRecipientEditClick = () => {
    setNewRecipientDetails(recipient);
    setEditingRecipient(false);
  };

  const onAddItemClick = () => {
    setAdditionalLineItems([...additionalLineItems, null]);
  };

  const onCancelAdditionalItemClick = (index) => {
    const arr = [...additionalLineItems];
    const newArr = [];
    for (let i = 0; i < additionalLineItems.length; i++) {
      if (i !== index) {
        newArr.push(arr[i]);
      }
    }
    setAdditionalLineItems(newArr);
  };

  useEffect(() => getInventoryForUseEffect({ delivery, deliveryDate, deliveryAreas, productFeed, userGroups, setProductOptions }), [deliveryDate]);

  const argsForUpdateDeliveryComponent = {
    deliveryDate,
    lineItems,
    window: computeWindow(delivery),
    deliveryArea: delivery.areaId,
  };

  return (
    <>
      <h2 className="mb-2 text-center">Redeliver</h2>
      <p className="mb-4 text-center text-muted">Choose what to send to the recipient and when to send it.</p>
      <h4 className="mt-4">When should the new package be delivered?</h4>
      <Flatpickr
        value={deliveryDate}
        onChange={(date, dateStr) => setDeliveryDate(dateStr)}
        options={{ minDate: DateTime.now().toISO() }}
        placeholder="Select delivery date..."
      />
      <h4 className="mt-4">What should we send?</h4>
      {oldLineItems.map((lineItem) => (
        <div className="m-3 ml-0" key={`${lineItem.index}key`}>
          <div className="row align-items-center">
            <div className="col-auto">
              <input
                className="form-check-input"
                type="checkbox"
                checked={selectedSkus.includes(skuKey(lineItem))}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  onLineItemCheckboxSelect(isChecked, lineItem);
                }}
              />
            </div>
            <div className="col-auto">{getLineItemDisplay(lineItem)}</div>
            {!Object.keys(skuToUpgradeMap).includes(skuKey(lineItem)) && selectedSkus.includes(skuKey(lineItem)) && (
              <div className="col-auto">
                <Button onClick={() => onUpgradeClick(skuKey(lineItem))}>Upgrade</Button>
              </div>
            )}
            {Object.keys(skuToUpgradeMap).includes(skuKey(lineItem)) && (
              <>
                <div className="col-auto">
                  <Icon.ArrowRight size="1.5em" />
                </div>
                <div className="col col-lg-6">{createAProductDropdown(skuKey(lineItem), null)}</div>
                <div className="col-auto">
                  <Button intent="danger" onClick={() => onCancelUpgradeClick(skuKey(lineItem))}>
                    Cancel
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      ))}
      {additionalLineItems.map((additionalLineItem, index) => (
        <div key={`additional-${index}`} className="mb-3 row">
          <div className="col col-lg-6">{createAProductDropdown(null, index)}</div>
          <div className="col-auto">
            <Button iconBefore={CrossIcon} intent="danger" onClick={() => onCancelAdditionalItemClick(index)}>
              Cancel Item
            </Button>
          </div>
        </div>
      ))}
      {(!additionalLineItems.length || additionalLineItems[additionalLineItems.length - 1]) && (
        <div className="row">
          <div className="col-auto">
            <Button iconBefore={PlusIcon} onClick={() => onAddItemClick()}>
              Add Item
            </Button>
          </div>
        </div>
      )}

      <h4 className="mt-4">Where should we send it?</h4>
      {!editingRecipient && (
        <>
          <div>
            {recipient.firstName} {recipient.lastName}
          </div>
          <div>
            {recipient.address1} {recipient.address2}
          </div>
          <div>
            {recipient.city}, {recipient.state} {recipient.zipCode}
          </div>
          <div className="mt-3">
            <Button onClick={() => onEditRecipientClick()}>Edit</Button>
          </div>
        </>
      )}
      {editingRecipient && (
        <>
          <div className="row">
            <div className="col-auto">
              <TextInput
                label="First Name"
                placeholder="Recipient First Name"
                name="recipientFirstName"
                value={newRecipientDetails.firstName}
                onChange={(event) => setNewRecipientDetails({ ...newRecipientDetails, firstName: event.target.value })}
              />
            </div>
            <div className="col-auto">
              <TextInput
                label="Last Name"
                placeholder="Recipient Last Name"
                name="recipientLastName"
                value={newRecipientDetails.lastName}
                onChange={(event) => setNewRecipientDetails({ ...newRecipientDetails, lastName: event.target.value })}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-auto">
              <TextInput
                label="Address"
                placeholder="Recipient Address"
                name="recipientAddress1"
                value={newRecipientDetails.address1}
                onChange={(event) => setNewRecipientDetails({ ...newRecipientDetails, address1: event.target.value })}
              />
            </div>
            <div className="col-auto">
              <TextInput
                label="Apartment #"
                placeholder="Recipient Apt #"
                name="recipientAddress2"
                value={newRecipientDetails.address2}
                onChange={(event) => setNewRecipientDetails({ ...newRecipientDetails, address2: event.target.value })}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-auto">
              <TextInput
                label="City"
                placeholder="Recipient City"
                name="recipientCity"
                value={newRecipientDetails.city}
                onChange={(event) => setNewRecipientDetails({ ...newRecipientDetails, city: event.target.value })}
              />
            </div>
            <div className="col-auto">
              <TextInput
                label="State"
                placeholder="Recipient State"
                name="recipientState"
                value={newRecipientDetails.state}
                onChange={(event) => setNewRecipientDetails({ ...newRecipientDetails, state: event.target.value })}
              />
            </div>
            <div className="col-auto">
              <TextInput
                label="Zipcode"
                placeholder="Recipient Zipcode"
                name="recipientZipcode"
                value={newRecipientDetails.zipCode}
                onChange={(event) => setNewRecipientDetails({ ...newRecipientDetails, zipCode: event.target.value })}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-auto">
              <Button intent="success" onClick={onSaveRecipientClick}>
                Save
              </Button>
            </div>
            <div className="col-auto">
              <Button intent="danger" onClick={() => onCancelRecipientEditClick()}>
                Cancel
              </Button>
            </div>
          </div>
          {showBadAddressErrorMessage && (
            <div className="row">
              <span className="text-danger">Bad address input; could not find coordinates.</span>
            </div>
          )}
        </>
      )}
      {deliveryDate && DateTime.fromISO(deliveryDate).toMillis() > DateTime.now().toMillis() && lineItems.length > 0 && (
        <>
          <h4 className="mt-4">Fulfillment Center and Delivery Method</h4>
          <UpdateDelivery
            suborders={[suborder]}
            fcId={null}
            deliveryAreas={deliveryAreas}
            showChangeDelivery={showChangeDelivery}
            setShowChangeDelivery={setShowChangeDelivery}
            onComplete={() => {}}
            coords={recipient.coords}
            zipCode={recipient.zipCode}
            setDeliveryFields={updateDelivery}
            useDialog={false}
            redeliveryArgs={argsForUpdateDeliveryComponent}
            newOrder={false}
            setDisable={setUpdateDeliveryDisable}
          />
        </>
      )}
      <Footer
        backActionText="Back"
        backAction={props.previousStep}
        forwardActionText="Continue"
        forwardAction={props.nextStep}
        disableForwardOn={
          updateDeliveryDisable || !deliveryDate || !(delivery && delivery.fc && delivery.startTime) || editingRecipient || lineItems.length === 0
        }
      />
    </>
  );
};

export default Step4;
