/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Dialog } from 'evergreen-ui';

import { getAuditLog } from '../../../services/OrderManagementService';
import AuditLogEntryDetailsExpanded from '../../auditLog/AuditLogEntryDetailsExpanded';

const AuditLog = ({ entity, deliveryAreaId, showAuditLog, setShowAuditLog }) => {
  const [auditLog, setAuditLog] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [errorMessage] = useState(null);
  const [entityOptions, setEntityOptions] = useState([]);
  const [selectEntity, setSelectEntity] = useState('suborder-123');
  const [expand, setExpand] = useState(null);

  useEffect(() => {
    let isMounted = true;
    setIsFetching(true);

    const fetchData = async () => {
      try {
        const logs = await getAuditLog(entity, deliveryAreaId);
        if (isMounted) {
          setAuditLog(logs.auditLogEntries);
        }
      } catch (error) {
        console.error('Error fetching the logs:', error);
      } finally {
        if (isMounted) {
          setIsFetching(false);
        }
      }
    };

    if (showAuditLog) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, [showAuditLog]);

  useEffect(() => {
    const options = [
      { value: 'suborder-123', label: 'SUBORDER 123' },
    ];
    setEntityOptions(options);
  }, []);

  useEffect(() => {
    if (entityOptions) {
      if (selectEntity) {
        setSelectEntity(selectEntity);
      } else {
        setSelectEntity('suborder-123');
      }
    }
  }, [selectEntity, entityOptions]);

  return (
    <Dialog
      isShown={!!showAuditLog}
      title="Audit Log"
      onCloseComplete={() => {
        setSelectEntity('suborder-123');
        setExpand(null);
        setShowAuditLog(false);
      }}
      hasClose={false}
      confirmLabel="Close"
      hasCancel={false}
      shouldCloseOnOverlayClick={false}
      width="1000px"
    >
      <div>
        {errorMessage && (
          <div className="alert alert-danger" role="alert">
            {errorMessage}
          </div>
        )}
        <div className="table-responsive">
          <table className="table table-sm table-hover card-table">
            <thead>
              <tr>
                <th>Entity</th>
                <th>Timestamp</th>
                <th>Performed By</th>
                <th>Event</th>
              </tr>
            </thead>
            <tbody className="list font-size-base">
              {isFetching ? (
                <tr>
                  <td colSpan="4">Fetching...</td>
                </tr>
              ) : (
                auditLog && auditLog.length > 0 ? (
                  auditLog.map((e, i) => (
                    <React.Fragment key={i}>
                      <tr className="cursor-pointer">
                        <td>
                          <span className="text-break">{e.entityType}</span> <span>{e.entityId}</span>
                        </td>
                        <td>
                          <span className="text-break">{DateTime.fromISO(e.timestamp).toLocaleString(DateTime.DATETIME_SHORT)}</span>
                        </td>
                        <td>
                          <span>{e.performedBy}</span>
                        </td>
                        <td className="d-flex flex-column align-items-start">
                          <span className="text-break">{e.actionPerformed}</span>
                          {expand !== i && (
                          <a href="#!" onClick={() => setExpand(i)} className="btn btn-link" style={{ fontSize: '13px' }}>
                            See full details
                          </a>
                          )}
                        </td>
                      </tr>
                      {expand === i && <AuditLogEntryDetailsExpanded index={i} expand={expand} setExpand={setExpand} data={e.actionData} />}
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">No audit log data available</td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Dialog>
  );
};

AuditLog.propTypes = {
  entity: PropTypes.string.isRequired,
  deliveryAreaId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showAuditLog: PropTypes.bool.isRequired,
  setShowAuditLog: PropTypes.func.isRequired,
};

AuditLog.defaultProps = {
  deliveryAreaId: null,
};

export default AuditLog;
