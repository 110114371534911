import { DateTime } from 'luxon';
import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useTable, useFilters, useSortBy } from 'react-table';
import PropTypes from 'prop-types';
import { useParams, Link } from 'react-router-dom';
import { Alert, Button, RepeatIcon, Pane, Spinner } from 'evergreen-ui';

import { FcContext } from '../../../context/fcContext';
import { DefaultColumnFilter, SelectColumnFilter, fuzzyTextFilterFn, multiLineTextFilterFn } from '../../common/tableHelpers/Filters';
import PageHeader from '../../nav/PageHeader';
import ExportReactTableToCsv from '../../common/csvExports/ExportReactTableToCsv';

import BulkExceptionActionPopup from './BulkExceptionActionPopup';

const SuborderExceptionQueue = ({ suborderExceptions, fetchException }) => {
  const [data, setData] = useState([]);
  const [showMore, setShowMore] = useState(3);
  const [count, setCount] = useState({
    errors: 0,
    success: 0,
  });
  const [batchExceptionIds, setBatchExceptionIds] = useState(new Set());
  const { fcId } = useParams();
  const { allFcs } = useContext(FcContext);
  // eslint-disable-next-line no-unused-vars
  const [isLoadingQuery, setIsLoadingQuery] = useState(false);
  const [latestRefreshTime, setLatestRefreshTime] = useState(DateTime.now());
  const [loader, setLoader] = useState(false);
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    // sort by oldest fulfillment date first, null dates at the end
    const sortedExceptions = JSON.parse(JSON.stringify(suborderExceptions));
    sortedExceptions.sort((a, b) => {
      if (a.fulfillmentDate === b.fulfillmentDate) {
        return 0;
      }

      if (!a.fulfillmentDate) {
        return 1;
      }

      if (!b.fulfillmentDate) {
        return -1;
      }

      const aDateTime = DateTime.fromISO(a.fulfillmentDate);
      const bDateTime = DateTime.fromISO(b.fulfillmentDate);

      if (aDateTime < bDateTime) {
        return -1;
      }

      if (aDateTime > bDateTime) {
        return 1;
      }

      return 0;
    });

    sortedExceptions.forEach((e) => {
      e.deliveryDate = e.deliveryDate ? DateTime.fromISO(e.deliveryDate).toLocaleString(DateTime.DATETIME_SHORT) : e.deliveryDate;
      e.fulfillmentDate = e.fulfillmentDate ? DateTime.fromISO(e.fulfillmentDate).toLocaleString(DateTime.DATETIME_SHORT) : e.fulfillmentDate;
      e.fcId = allFcs.find((fc) => fc.value === e.fcId.toString())?.label ? allFcs.find((fc) => fc.value === e.fcId.toString())?.label : e.fcId;
    });

    setData(sortedExceptions);
  }, [allFcs, suborderExceptions]);

  const cellWithLineBreaks = ({ value }) => {
    const lineBreaked = value && value.split(';').join('<br />');
    // eslint-disable-next-line react/no-danger
    return <div dangerouslySetInnerHTML={{ __html: lineBreaked }} />;
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Suborder',
        accessor: 'id',
        filter: 'multiLineText',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => {
          // eslint-disable-next-line react/prop-types
          const splitVal = value.split('-');
          const orderId = splitVal.shift();
          return (
            <>
              <Link to={{ pathname: `/orders/${orderId}` }}>{orderId}</Link>-{splitVal.join('-')}
            </>
          );
        },
      },
      {
        Header: 'Order Type',
        accessor: 'orderType',
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: 'FC',
        accessor: 'fcId',
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: 'Fulfillment Date',
        accessor: 'fulfillmentDate',
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: 'Delivery Date',
        accessor: 'deliveryDate',
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: 'Exception Type',
        accessor: 'exceptionType',
        Filter: SelectColumnFilter,
        filter: 'reasonText',
        // eslint-disable-next-line react/prop-types
        Cell: cellWithLineBreaks,
      },
      {
        Header: 'SKU',
        accessor: 'sku',
        Filter: SelectColumnFilter,
        filter: 'reasonText',
        Cell: cellWithLineBreaks,
      },
      {
        Header: 'Reason',
        accessor: 'exceptionReason',
        Filter: SelectColumnFilter,
        filter: 'reasonText',
        Cell: cellWithLineBreaks,
      },
    ],
    [],
  );

  function handleCheckReception(itemKey) {
    const newSelectedItems = new Set(batchExceptionIds);
    if (!newSelectedItems.has(itemKey)) {
      newSelectedItems.add(itemKey);
    } else {
      newSelectedItems.delete(itemKey);
    }
    setBatchExceptionIds(newSelectedItems);
  }

  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      multiLineText: multiLineTextFilterFn,
      reasonText: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          const reasonArr = rowValue ? rowValue.split(';') : [];
          const includesReason = !!reasonArr.filter((p) => p.includes(filterValue)).length;
          return includesReason;
        });
      },
    }),
    [],
  );

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    setAllFilters,
    state: { filters: tableFilters },
    data: tableData,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: {
        filters,
      },
    },
    useFilters,
    useSortBy,
  );

  useEffect(() => {
    setFilters(tableFilters);
  }, [tableFilters]);

  const rowData = rows.map((row) => {
    prepareRow(row);
    return row.values;
  });

  const desiredCsvFields = [
    {
      key: 'id',
      columnName: 'Suborder',
    },
    {
      key: 'orderType',
      columnName: 'Order Type',
    },
    {
      key: 'fcId',
      columnName: 'Fulfillment Center',
    },
    {
      key: 'fulfillmentDate',
      columnName: 'Fulfillment Date',
    },
    {
      key: 'deliveryDate',
      columnName: 'Delivery Date',
    },
    {
      key: 'exceptionType',
      columnName: 'Exception Type',
    },
    {
      key: 'sku',
      columnName: 'SKU',
    },
    {
      key: 'exceptionReason',
      columnName: 'Reason',
    },
  ];

  const RemoveBulkExceptionError = () => (
    <Alert intent="danger" title={`${count.errors.length} suborder${count.errors.length > 1 ? 's' : ''} exception weren't removed`}>
      <ul>
        {count.errors.slice(0, showMore).map((data, key) => (
          <li key={key}>{data.id}</li>
        ))}
      </ul>
      {count.errors.length > 3 && (
        <Button onClick={() => setShowMore(showMore > 3 ? 3 : count.errors.length)} appearance="minimal">
          Show {showMore > 3 ? 'less' : 'more'}
        </Button>
      )}
    </Alert>
  );

  const refreshExceptionSuborders = async () => {
    setLoader(true);
    await fetchException();
    setLoader(false);
    setAllFilters(filters);
    setLatestRefreshTime(DateTime.now());
  };

  return (
    <FcContext.Consumer>
      {({ fcConfig }) => {
        const fcName = fcConfig?.label || fcId;
        const fcTitleSuffix = fcId ? ` for ${fcName}` : '';
        const pageTitle = `Suborder Exceptions${fcTitleSuffix}`;
        return (
          <>
            {!!batchExceptionIds.size && (
              <BulkExceptionActionPopup batchExceptionIds={batchExceptionIds} setBatchExceptionIds={setBatchExceptionIds} setCount={setCount} />
            )}
            <PageHeader category="Exceptions" title={pageTitle} />
            <div className="container-fluid mt-n6">
              <div className="card">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col" />
                    {rowData && rowData.length > 0 && (
                      <div className="col-auto">
                        <ExportReactTableToCsv
                          filePrefix="exception-queue"
                          fcConfig={fcConfig}
                          rowData={rowData}
                          tableData={tableData}
                          idKey="id"
                          desiredFields={desiredCsvFields}
                        />
                      </div>
                    )}
                    <div className="col-auto">
                      <span style={{ display: 'inline-block', verticalAlign: 'middle', paddingRight: '0.5em', fontSize: '13px', color: 'gray' }}>
                        <div>Last Refreshed</div>
                        {latestRefreshTime.toFormat('h:mm:ssa')}
                      </span>
                      <Button iconAfter={RepeatIcon} isLoading={isLoadingQuery} onClick={() => refreshExceptionSuborders()}>
                        Refresh
                      </Button>
                    </div>
                  </div>
                </div>
                {!!count.errors.length && <RemoveBulkExceptionError />}
                <div className="table-responsive">
                  <table className="table table-sm table-hover table-nowrap card-table">
                    <thead>
                      {headerGroups.map((headerGroup, index) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={`header-group-${index}`}>
                          <th />
                          {headerGroup.headers.map((column, headerGroupHeaderIndex) => (
                            <th {...column.getHeaderProps()} key={`header-group-header-${index}-${headerGroupHeaderIndex}`}>
                              <div>
                                <span>
                                  <div>
                                    <span {...column.getSortByToggleProps()}>{column.render('Header')}</span>
                                  </div>
                                </span>
                              </div>
                              <div>{column.canFilter ? column.render('Filter') : null}</div>
                            </th>
                          ))}
                          <th />
                        </tr>
                      ))}
                    </thead>
                    {!loader ? (
                      <tbody {...getTableBodyProps()} className="list font-size-base">
                        {rows.map((row, rowIndex) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()} key={`row-${rowIndex}`}>
                              <td style={{ width: '5%' }}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={batchExceptionIds.has(row.original.id)}
                                  onChange={() => handleCheckReception(row.original.id)}
                                />
                              </td>
                              {row.cells.map((cell, cellIndex) => {
                                return (
                                  <td {...cell.getCellProps()} key={`cell-${rowIndex}-${cellIndex}`}>
                                    {cell.render('Cell')}
                                  </td>
                                );
                              })}
                              <td />
                            </tr>
                          );
                        })}
                      </tbody>
                    ) : (
                      <tbody className="list font-size-base">
                        <tr>
                          <td colSpan={8} style={{ textAlign: 'center' }}>
                            <Pane>
                              <Spinner marginX="auto" marginY={120} />
                            </Pane>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </>
        );
      }}
    </FcContext.Consumer>
  );
};

SuborderExceptionQueue.propTypes = {
  suborderExceptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      suborderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fcId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  fetchException: PropTypes.func,
};

SuborderExceptionQueue.defaultProps = {
  suborderExceptions: [],
  fetchException: () => {},
};

export default SuborderExceptionQueue;
