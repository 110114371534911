import React, { useState } from 'react';
import { Dialog, Pane, TextInput } from 'evergreen-ui';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { creditReasons } from '../../order/helpers/getReasonType';
import { issueCreditsToCustomer, updateCreditById } from '../../../services/OrderManagementService';

const PREFERRED_INPUT_SIZE = 280;

const MAX_ALLOWED_CREDIT_AMOUNT = 1000.0;
const ZERO = 0.0;

const IssueOrEditCreditsModal = ({ credit, customerEmail, closeModal, successActions }) => {
  const { id: creditId } = credit;
  const isIssuing = creditId === undefined;

  const [creditToPassToBackend, setCreditToPassToBackend] = useState(credit);
  const [customErrorMessage, setCustomErrorMessage] = useState(null);

  const differenceBetweenCreditAndRemaining = credit.credit_amount - credit.remaining_amount;

  const creditAmountExceedsMaxAllowed = creditToPassToBackend.credit_amount > MAX_ALLOWED_CREDIT_AMOUNT;
  const creditAmountIsLessThanOrEqualToZero = creditToPassToBackend.credit_amount <= ZERO;
  const creditReasonDoesNotExist = creditToPassToBackend.reason == null;

  const getDefaultValueForReason = () => {
    if (!credit.reason) {
      return null;
    }

    const splitByColon = credit.reason.split(':').map((partOfArray) => partOfArray.trim());
    if (splitByColon.length !== 2) {
      return null;
    }

    const [categoryLabel, reasonValue] = splitByColon;

    const flattenedCreditReasonOptions = Object.values(creditReasons)
      .map((creditReasonGrouping) => creditReasonGrouping.options)
      .flat();

    for (const reason of flattenedCreditReasonOptions) {
      if (reason.categoryLabel.toLowerCase() === categoryLabel.toLowerCase() && reason.value.toLowerCase() === reasonValue.toLowerCase()) {
        return reason;
      }
    }

    return null;
  };

  const arrayOfRowTitlesAndInputs = [
    {
      title: 'Credits Issued Amount',
      input: (
        <div style={{ width: PREFERRED_INPUT_SIZE }}>
          <TextInput
            name="creditAmount"
            type="number"
            value={creditToPassToBackend.credit_amount || 0}
            onChange={(event) => {
              const { value } = event.target;
              const asNum = Number(value);
              setCreditToPassToBackend({
                ...creditToPassToBackend,
                credit_amount: asNum,
                remaining_amount: asNum - differenceBetweenCreditAndRemaining,
              });
            }}
          />
        </div>
      ),
    },
    {
      title: 'Remaining Amount',
      input: <div style={{ width: PREFERRED_INPUT_SIZE }}>{creditToPassToBackend.remaining_amount}</div>,
    },
    {
      title: 'Reason',
      input: (
        <div style={{ width: PREFERRED_INPUT_SIZE }}>
          <ReactSelect
            id="selectReasonForIssuingCreditSuborder"
            onChange={(option) => setCreditToPassToBackend({ ...creditToPassToBackend, reason: `${option.categoryLabel}: ${option.value}` })}
            options={creditReasons}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            defaultValue={getDefaultValueForReason()}
          />
        </div>
      ),
    },
  ];

  const renderErrorMessage = (errorMessage) => <div style={{ color: 'red' }}>{errorMessage}</div>;

  const confirmActions = async () => {
    let response;
    if (isIssuing) {
      response = await issueCreditsToCustomer(
        customerEmail,
        creditToPassToBackend.credit_amount,
        creditToPassToBackend.reason,
        creditToPassToBackend.tag,
      );
    } else {
      response = await updateCreditById(
        creditId,
        creditToPassToBackend.credit_amount,
        creditToPassToBackend.remaining_amount,
        creditToPassToBackend.reason,
        creditToPassToBackend.tag,
      );
    }

    const { success } = response;

    if (success) {
      successActions();
      closeModal();
    } else {
      setCustomErrorMessage(`Could not ${isIssuing ? 'issue' : 'edit'} credit as required due to failure in backend.`);
    }
  };

  return (
    <Dialog
      isShown
      title={isIssuing ? `Issue Credits for ${customerEmail}` : `Edit Credit ${creditId} for ${customerEmail}`}
      onCloseComplete={closeModal}
      onCancel={closeModal}
      isConfirmDisabled={creditAmountExceedsMaxAllowed || creditAmountIsLessThanOrEqualToZero || creditReasonDoesNotExist}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
      onConfirm={confirmActions}
    >
      {arrayOfRowTitlesAndInputs.map(({ title, subtitle, input }, index) => {
        return (
          <div key={`rowTitleAndInputForAdjustment-${index}`} style={{ color: '#6d6d6d' }}>
            <Pane className="adjustment-card-row row g-0">
              <div className="col-sm-2">
                <h4 className="ml-4">{title}</h4>
                {subtitle && (
                  <div className="ml-4" style={{ fontSize: 10 }}>
                    {subtitle}
                  </div>
                )}
              </div>
              <div className="col ml-4">{input}</div>
            </Pane>
            <hr />
          </div>
        );
      })}
      <Pane>
        {creditAmountExceedsMaxAllowed && renderErrorMessage(`Credit amount cannot exceed past ${MAX_ALLOWED_CREDIT_AMOUNT}`)}
        {creditAmountIsLessThanOrEqualToZero && renderErrorMessage('Credit Amount cannot be less than or equal to zero.')}
        {creditReasonDoesNotExist && renderErrorMessage('Credit needs a reason.')}
        {customErrorMessage && renderErrorMessage(customErrorMessage)}
      </Pane>
    </Dialog>
  );
};

IssueOrEditCreditsModal.propTypes = {
  credit: PropTypes.object,
  customerEmail: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  successActions: PropTypes.func.isRequired,
};

IssueOrEditCreditsModal.defaultProps = {
  credit: {
    credit_amount: 0.0,
    remaining_amount: 0.0,
    tag: 'other',
  },
};

export default IssueOrEditCreditsModal;
