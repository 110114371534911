import React from 'react';

import { FcContext } from '../../../context/fcContext';
import PageHeader from '../../nav/PageHeader';

import InventoryTable from './InventoryTable';

const Inventory = () => {
  return (
    <FcContext.Consumer>
      {({ fcId, fcConfig, date }) => (
        <>
          <PageHeader category="Products" title="Inventory at FC" showDatePicker showFcPicker />
          <div className="container-fluid mt-n6">
            <div className="row justify-content-center">
              <div className="col-12 p-0">
                <InventoryTable date={date} fcId={fcId} fcConfig={fcConfig} />
              </div>
            </div>
          </div>
        </>
      )}
    </FcContext.Consumer>
  );
};

export default Inventory;
