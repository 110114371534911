import React from 'react';
import PropTypes from 'prop-types';
import Footer from '../../../common/wizards/Footer';
import ExportDataToCsv from '../../../common/csvExports/ExportDataToCsv';

const EXPORT_SECTION_HEIGHT = 40;
const HEIGHT_OF_SUMMARY_SECTION = 260;
const MARGIN_OF_SUMMARY_SECTION = 20;

const FILLER_HEIGHT = 105;

const convertSuborderRedistributeObjToCsvFriendly = (suborderRedistributeObj, actionMessage) => [suborderRedistributeObj.id, actionMessage];

const convertSuborderMovedObjToCsvFriendly = (suborderRedistributeObj) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  convertSuborderRedistributeObjToCsvFriendly(suborderRedistributeObj, suborderRedistributeObj.message);

const convertSuborderUnableToBeMovedObjToCsvFriendly = (suborderRedistributeObj) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  convertSuborderRedistributeObjToCsvFriendly(suborderRedistributeObj, 'Unable To Move');

const Summary = ({ subordersMoved, subordersUnableToBeMoved, closeModal }) => {
  const csvData = [
    ['Suborder ID', 'Action Message'],
    ...subordersMoved.map(convertSuborderMovedObjToCsvFriendly),
    ...subordersUnableToBeMoved.map(convertSuborderUnableToBeMovedObjToCsvFriendly),
  ];

  const showDataForSuborders = (suborderList, title) => (
    <div style={{ height: HEIGHT_OF_SUMMARY_SECTION, margin: MARGIN_OF_SUMMARY_SECTION }}>
      <div>
        {title}: {suborderList.length}
      </div>
      <div className="card" style={{ height: HEIGHT_OF_SUMMARY_SECTION, overflow: 'auto' }}>
        <div className="m-3">
          {suborderList.map((suborderListObj) => (
            <div key={`suborder-list-obj-${suborderListObj.id}`}>
              {suborderListObj.id}: {suborderListObj.message}
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <h2 className="mb-2 text-center">Bulk Redistribute Suborders</h2>
      <div style={{ height: EXPORT_SECTION_HEIGHT, display: 'grid', justifyContent: 'end', paddingRight: MARGIN_OF_SUMMARY_SECTION }}>
        <ExportDataToCsv filePrefix="Redistribute results " csvData={csvData} />
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: 'auto auto', height: HEIGHT_OF_SUMMARY_SECTION + MARGIN_OF_SUMMARY_SECTION * 2 }}>
        {showDataForSuborders(subordersMoved, 'Suborders Updated')}
        {showDataForSuborders(subordersUnableToBeMoved, 'Suborders Not Actioned')}
      </div>
      <div style={{ height: FILLER_HEIGHT }} />
      <Footer showBack={false} forwardActionText="Complete" forwardAction={closeModal} />
    </>
  );
};

Summary.propTypes = {
  subordersMoved: PropTypes.array.isRequired,
  subordersUnableToBeMoved: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default Summary;
