/* eslint-disable no-console */
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';
import { Popover, Menu, Position, Dialog, Group, Button } from 'evergreen-ui';
import { useForm } from 'react-hook-form';
import { useRowSelect, useTable } from 'react-table';
import { AuthContext } from '../../../context/authContext';
import { LookupContext } from '../../../context/lookupContext';
import Input from '../../common/forms/Input';
import { createBatch } from '../../../services/OrderManagementService';
import UpdateDelivery from '../../order/UpdateDelivery';
import RedistributeDate from '../../order/RedistributeDate';
import UpgradeLineItem from '../../order/UpgradeLineItem';
import { roleChecks } from '../../../utilities/Role';
import BatchUpdateModal from '../update/BatchUpdateModal';
import BatchRemoveSuborderModal from '../update/BatchRemoveSuborderModal';
import IndeterminateCheckbox from '../../common/tableHelpers/IndeterminateCheckbox';
import getIdealProductName from '../../order/helpers/getIdealProductName';
import Progress from '../../common/Progress';

const batchFields = {
  STATUS: 'STATUS',
  DELIVERY: 'DELIVERY',
  UPGRADE_LINEITEM: 'UPGRADE_LINEITEM',
  REMOVE_SUBORDER: 'REMOVE_SUBORDER',
  REDISTRIBUTE: 'REDISTRIBUTE',
};

const batchingStrategies = {
  BASIC: 'Basic',
  PRIMARY_SKU: 'Primary SKU',
  VASE: 'Vase',
  ADDON: 'Addon',
  PRIMARY_SKU_AND_VASE: 'Primary SKU + Vase',
  PRIMARY_SKU_AND_ADDON: 'Primary SKU + Addon',
  PRIMARY_SKU_ADDON_AND_VASE: 'Primary SKU + Addon + Vase',
};

const batchSizes = {
  DEFAULT_MIN: 5,
  DEFAULT_MAX: 40,
  GUIDED_FULFILLMENT_MAX: 40,
  ARBITRARILY_HIGH_MAX: 100000,
};

const miscVariables = {
  VASE_PREFIX: 'FLRL-V',
  MULTIPLE_SKU_DIVIDER: ':',
};

const multipleRenditionLineItemCriteria = {
  NAME_INCLUSIONS: {
    double: 'double',
    triple: 'triple',
  },
  EXPECTED_QUANTITIES: {
    double: 2,
    triple: 3,
  },
  SKU_PREFIXES: ['FLRL-K', 'FLRL-D', 'FLRL-T'],
};

const addonClassifications = ['Misc Addon', 'Food & Drink'];

const BulkSuborderActionModal = ({
  fcId,
  batchId,
  selectedRowIds,
  selectedFlatRows,
  toggleAllRowsSelected,
  queryCache,
  deliveryAreas,
  fulfillmentDate,
  fcConfig,
  setBatchCreationErrors,
}) => {
  const defaultMinBatch =
    fcConfig.batchOptions && fcConfig.batchOptions.defaultMinSize ? fcConfig.batchOptions.defaultMinSize : batchSizes.DEFAULT_MIN;
  const defaultMaxBatch =
    fcConfig.batchOptions && fcConfig.batchOptions.defaultMaxSize ? fcConfig.batchOptions.defaultMaxSize : batchSizes.DEFAULT_MAX;

  const [showCreateBatch, setShowCreateBatch] = useState(false);
  const [batchingStrategy, setBatchingStrategy] = useState(batchingStrategies.BASIC);
  const [showUpdateBatch, setShowUpdateBatch] = useState(false);
  const [showRemoveSuborder, setShowRemoveSuborder] = useState(false);
  const [showUpdateDelivery, setShowUpdateDelivery] = useState(false);
  const [showUpgradeLineItem, setShowUpgradeLineItem] = useState(false);
  const [showRedistribute, setShowRedistribute] = useState(false);
  const [batchField, setBatchField] = useState('');
  const unselectAll = () => toggleAllRowsSelected(false);
  const { userGroups } = useContext(AuthContext);
  const { productFeed } = useContext(LookupContext);
  const [minBatchSize, setMinBatchSize] = useState(defaultMinBatch);
  const [maxBatchSize, setMaxBatchSize] = useState(defaultMaxBatch);
  const [selectableBatches, setSelectableBatches] = useState([]);
  const [batchPrefix, setBatchPrefix] = useState('Batch');
  const [isLoadingWhileCreating, setIsLoadingWhileCreating] = useState(false);
  const [batchSavingCounter, setBatchSavingCounter] = useState(0);

  const addElementToSet = (expectedSet, elementToAdd) => {
    if (!expectedSet.includes(elementToAdd)) {
      expectedSet.push(elementToAdd);
    }
  };

  const isAMultipleRenditionOfASingleLineItem = (lineItem) => {
    const hasExpectedSku = multipleRenditionLineItemCriteria.SKU_PREFIXES.filter((skuPrefix) => lineItem.sku.startsWith(skuPrefix)).length === 1;
    const hasOneComponent = lineItem.components && lineItem.components.length === 1;

    if (hasExpectedSku && hasOneComponent) {
      const isDouble = lineItem.name.toLowerCase().includes(multipleRenditionLineItemCriteria.NAME_INCLUSIONS.double);
      const isTriple = lineItem.name.toLowerCase().includes(multipleRenditionLineItemCriteria.NAME_INCLUSIONS.triple);
      if (isDouble) {
        return lineItem.components[0].quantity === multipleRenditionLineItemCriteria.EXPECTED_QUANTITIES.double;
      } if (isTriple) {
        return lineItem.components[0].quantity === multipleRenditionLineItemCriteria.EXPECTED_QUANTITIES.triple;
      }
    }

    return false;
  };

  const isEcommBundle = (lineItem) => lineItem.components && !isAMultipleRenditionOfASingleLineItem(lineItem);
  const convertLineItemToFriendlyProductString = (lineItem) => `${getIdealProductName(productFeed, lineItem)}, ${lineItem.sku}`;

  const getProductsFromSuborder = (suborder) => {
    const uniqueProducts = [];
    if (suborder.lineItems) {
      for (const lineItem of suborder.lineItems) {
        if (isEcommBundle(lineItem)) {
          for (const component of lineItem.components) {
            const productString = convertLineItemToFriendlyProductString(component);
            addElementToSet(uniqueProducts, productString);
          }
        } else {
          const productString = convertLineItemToFriendlyProductString(lineItem);
          addElementToSet(uniqueProducts, productString);
        }
      }
    }
    return uniqueProducts;
  };

  const formSuperSkuFromMultipleProducts = (focusProducts) => {
    let multipleProductSku = '';
    for (const fProduct of focusProducts) {
      const { sku } = fProduct;
      multipleProductSku += multipleProductSku.length > 0 ? `${miscVariables.MULTIPLE_SKU_DIVIDER}${sku}` : sku;
    }
    return multipleProductSku;
  };

  const batchWithMultipleProductStrategy = (suborders, windowNameOrEmptyString, focusProductsFinder, numFocusProducts) => {
    const skusToBatches = {};
    for (const suborder of suborders) {
      const focusProducts = focusProductsFinder(suborder);
      if (focusProducts.length === numFocusProducts) {
        const superSku = formSuperSkuFromMultipleProducts(focusProducts);
        const skuBatches = skusToBatches[superSku] ? skusToBatches[superSku] : [];
        let currentBatch = skuBatches.length ? skuBatches[skuBatches.length - 1] : undefined;
        const currentProducts = getProductsFromSuborder(suborder);

        if (!currentBatch || currentBatch.suborders.length === maxBatchSize) {
          currentBatch = {
            suborders: [suborder],
            products: currentProducts,
            focusProducts,
          };
          skuBatches.push(currentBatch);
        } else {
          currentBatch.suborders.push(suborder);
          for (const productString of currentProducts) {
            addElementToSet(currentBatch.products, productString);
          }
        }

        if (!skusToBatches[superSku]) {
          skusToBatches[superSku] = skuBatches;
        }
      }
    }

    const newSelectableBatches = [];
    for (const sku of Object.keys(skusToBatches)) {
      const skuBatches = skusToBatches[sku];
      const needsNumberOnName = skuBatches.length > 1 && skuBatches.filter((skuBatch) => skuBatch.suborders.length >= minBatchSize).length > 1;
      for (let i = 0; i < skuBatches.length; i++) {
        const currentBatch = skuBatches[i];

        if (currentBatch.suborders.length >= minBatchSize) {
          const productName = currentBatch.focusProducts.map((product) => getIdealProductName(productFeed, product)).join(' + ');
          const productNameWithSpace = `${productName} `;
          const newSelectableBatch = {
            ...currentBatch,
            name: `${batchPrefix} ${productNameWithSpace}${windowNameOrEmptyString}${needsNumberOnName ? i + 1 : ''}`,
            index: i,
          };
          newSelectableBatches.push(newSelectableBatch);
        }
      }
    }

    return newSelectableBatches;
  };

  const batchWithBasicStrategy = (suborders, windowNameOrEmptyString) => {
    const newSelectableBatches = [];
    for (let suborderIndex = 0; suborderIndex < suborders.length; suborderIndex += 1) {
      const currentBatchIndex = Math.floor(suborderIndex / maxBatchSize);
      let currentBatch = newSelectableBatches[currentBatchIndex];
      const currentSuborder = suborders[suborderIndex];
      const currentProducts = getProductsFromSuborder(currentSuborder);

      if (!currentBatch) {
        currentBatch = {
          suborders: [currentSuborder],
          products: currentProducts,
          name: `${batchPrefix} ${windowNameOrEmptyString}${currentBatchIndex + 1}`,
          index: currentBatchIndex,
        };
        newSelectableBatches.push(currentBatch);
      } else {
        currentBatch.suborders.push(currentSuborder);
        for (const productString of currentProducts) {
          addElementToSet(currentBatch.products, productString);
        }
      }
    }
    return newSelectableBatches;
  };

  const batchWithSingleProductStrategy = (suborders, windowNameOrEmptyString, focusProductFinder) => batchWithMultipleProductStrategy(
    suborders,
    windowNameOrEmptyString,
    (suborder) => {
      const focusProduct = focusProductFinder(suborder);
      return focusProduct ? [focusProduct] : [];
    },
    1,
  );

  const isAddon = (lineItem) => {
    const product = productFeed[lineItem.sku];
    return (
      product &&
      product.classification &&
      addonClassifications.includes(product.classification)
    );
  };

  const productFinderWithPredicate = (suborder, predicate) => {
    if (!suborder.lineItems) return undefined;

    for (const lineItem of suborder.lineItems) {
      if (isEcommBundle(lineItem)) {
        for (const component of lineItem.components) {
          if (predicate(component)) return component;
        }
      } else if (predicate(lineItem)) return lineItem;
    }

    return undefined;
  };

  const getAddon = (suborder) => productFinderWithPredicate(suborder, isAddon);
  const isVase = (lineItem) => lineItem.sku.startsWith(miscVariables.VASE_PREFIX);
  const getVase = (suborder) => productFinderWithPredicate(suborder, isVase);
  const isPrimary = (lineItem) => !isVase(lineItem) && !isAddon(lineItem);
  const getPrimaryLineItem = (suborder) => productFinderWithPredicate(suborder, isPrimary);

  const batchWithPrimarySkuAndVaseStrategy = (suborders, windowNameOrEmptyString) => batchWithMultipleProductStrategy(
    suborders,
    windowNameOrEmptyString,
    (suborder) => {
      const primaryProduct = getPrimaryLineItem(suborder);
      const vase = getVase(suborder);
      return primaryProduct !== undefined && vase !== undefined ? [primaryProduct, vase] : [];
    },
    2,
  );
  const batchWithPrimarySkuStrategy = (suborders, windowNameOrEmptyString) => batchWithSingleProductStrategy(
    suborders,
    windowNameOrEmptyString,
    getPrimaryLineItem,
  );

  const batchWithVaseStrategy = (suborders, windowNameOrEmptyString) => batchWithSingleProductStrategy(
    suborders,
    windowNameOrEmptyString,
    (suborder) => {
      const addOn = getAddon(suborder);
      // As per ruleset, if there is both add-on and vase, we don't want to batch
      return addOn ? undefined : getVase(suborder);
    },
  );

  const batchWithAddonStrategy = (suborders, windowNameOrEmptyString) => batchWithSingleProductStrategy(
    suborders,
    windowNameOrEmptyString,
    (suborder) => {
      const vase = getVase(suborder);
      // As per ruleset, if there is both add-on and vase, we don't want to batch
      return vase ? undefined : getAddon(suborder);
    },
  );

  const batchWithPrimarySkuAndAddonStrategy = (suborders, windowNameOrEmptyString) => batchWithMultipleProductStrategy(
    suborders,
    windowNameOrEmptyString,
    (suborder) => {
      const primaryProduct = getPrimaryLineItem(suborder);
      const addon = getAddon(suborder);
      return primaryProduct !== undefined && addon !== undefined ? [primaryProduct, addon] : [];
    },
    2,
  );

  const batchWithPrimarySkuVaseAndAddonStrategy = (suborders, windowNameOrEmptyString) => batchWithMultipleProductStrategy(
    suborders,
    windowNameOrEmptyString,
    (suborder) => {
      const primaryProduct = getPrimaryLineItem(suborder);
      const vase = getVase(suborder);
      const addon = getAddon(suborder);
      return primaryProduct !== undefined && vase !== undefined && addon !== undefined ? [primaryProduct, vase, addon] : [];
    },
    3,
  );
  const setUpBatchVariables = () => {
    const suborders = selectedFlatRows.map((flatRow) => flatRow.original);

    if (suborders.length && minBatchSize && maxBatchSize && minBatchSize < maxBatchSize && suborders.length >= minBatchSize) {
      const windowNameOrEmptyString = fcConfig.showWindows ? `${suborders[0].window} ` : '';
      let newSelectableBatches;

      switch (batchingStrategy) {
        case batchingStrategies.BASIC:
          newSelectableBatches = batchWithBasicStrategy(suborders, windowNameOrEmptyString);
          break;
        case batchingStrategies.PRIMARY_SKU:
          newSelectableBatches = batchWithPrimarySkuStrategy(suborders, windowNameOrEmptyString);
          break;
        case batchingStrategies.VASE:
          newSelectableBatches = batchWithVaseStrategy(suborders, windowNameOrEmptyString);
          break;
        case batchingStrategies.ADDON:
          newSelectableBatches = batchWithAddonStrategy(suborders, windowNameOrEmptyString);
          break;
        case batchingStrategies.PRIMARY_SKU_AND_VASE:
          newSelectableBatches = batchWithPrimarySkuAndVaseStrategy(suborders, windowNameOrEmptyString);
          break;
        case batchingStrategies.PRIMARY_SKU_AND_ADDON:
          newSelectableBatches = batchWithPrimarySkuAndAddonStrategy(suborders, windowNameOrEmptyString);
          break;
        case batchingStrategies.PRIMARY_SKU_ADDON_AND_VASE:
          newSelectableBatches = batchWithPrimarySkuVaseAndAddonStrategy(suborders, windowNameOrEmptyString);
          break;
        default:
          console.error('There is an unhandled batching strategy', batchingStrategy);
          newSelectableBatches = [];
          break;
      }

      while (true) {
        if (
          minBatchSize &&
          newSelectableBatches.length > 0 &&
          (newSelectableBatches[newSelectableBatches.length - 1].suborders.length < minBatchSize ||
            maxBatchSize < newSelectableBatches[newSelectableBatches.length - 1].suborders.length)
        ) {
          newSelectableBatches.pop();
        } else {
          break;
        }
      }
      for (const selectableBatch of newSelectableBatches) {
        selectableBatch.productsIdentifier = selectableBatch.products.length > 1 ? 'various' : selectableBatch.products[0];
        selectableBatch.numSuborders = selectableBatch.suborders.length;
      }

      setSelectableBatches(newSelectableBatches);
    } else {
      setSelectableBatches([]);
    }
  };

  useEffect(setUpBatchVariables, [minBatchSize, maxBatchSize, selectedFlatRows, batchPrefix, batchingStrategy]);

  const onDoneUpdate = () => {
    setShowUpdateBatch(false);
    setShowUpdateDelivery(false);
    setShowUpgradeLineItem(false);
    setShowRemoveSuborder(false);
    unselectAll();
    queryCache.invalidateQueries();
  };

  const onUpdateDeliveryDoneUpdate = () => {
    unselectAll();
    queryCache.invalidateQueries();
  };

  const createSuborder = (value) => {
    let newSuborder = null;
    switch (batchField) {
      case batchFields.STATUS:
        newSuborder = { status: value };
        break;
      case batchFields.DELIVERY:
        newSuborder = {
          delivery: {
            startTime: value[0].toISOString(),
          },
        };
        break;
      default:
        break;
    }
    return newSuborder;
  };

  const onFieldSelect = (fieldName) => {
    setBatchField(fieldName);

    switch (fieldName) {
      case batchFields.STATUS:
        setShowUpdateBatch(true);
        break;
      case batchFields.DELIVERY:
        setShowUpdateDelivery(true);
        break;
      case batchFields.UPGRADE_LINEITEM:
        setShowUpgradeLineItem(true);
        break;
      case batchFields.REDISTRIBUTE:
        setShowRedistribute(true);
        break;
      case batchFields.REMOVE_SUBORDER:
        setShowRemoveSuborder(true);
        break;
      default:
        console.error(`${fieldName} not found`);
        break;
    }
  };

  const { register, errors } = useForm({ mode: 'onBlur', defaultValues: { batchPrefix, minBatchSize, maxBatchSize } });

  const createBatchAndGetErrors = async (name, fcId, subordersArr) => {
    const result = await createBatch(name, fcId, subordersArr);
    const errors = [];
    if (result.success === false && result.message) {
      errors.push(`Batch ${name} with ${subordersArr.length} suborders could not be created due to ${result.message}`);
    }

    if (result.success === true) {
      for (const suborderId of Object.keys(result.subordersAdded)) {
        if (result.subordersAdded[suborderId] != true) {
          errors.push(
            `Batch ${name} (${batchId ?? 'NO ID'}) could not add the suborder ${suborderId} due to this error: ${result.subordersAdded[suborderId]}`,
          );
        }
      }
    }

    return errors;
  };

  const batchColumns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Products',
        accessor: 'productsIdentifier',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row, value }) => (value !== 'various' ? (
          <span>{value}</span>
        ) : (
          <Tooltip
            arrow="true"
            position="left"
            html={
              <ul>
                {row.original.products.map((product, i) => (
                  <li key={i}>{product}</li>
                ))}
              </ul>
                }
          >
            <span>{value}</span>
          </Tooltip>
        )),
      },
      {
        Header: 'Size',
        accessor: 'numSuborders',
      },
    ],
    [],
  );

  const defaultBatchColumn = React.useMemo(() => ({}), []);

  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    initialRows,
    toggleAllRowsSelected: toggleAllBatchRowsSelected,
    state: {},
  } = useTable({ columns: batchColumns, data: selectableBatches, defaultColumn: defaultBatchColumn, initialState: {} }, useRowSelect, (hooks) => {
    hooks.visibleColumns.push((columns) => [
      // Let's make a column for selection
      {
        id: 'selection',
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div className="form-check mb-n2">
            <IndeterminateCheckbox className="form-check-input list-checkbox-all" {...getToggleAllRowsSelectedProps()} />
            <label className="form-check-label" />
          </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => (
          <div className="form-check">
            <IndeterminateCheckbox className="form-check-input list-checkbox" {...row.getToggleRowSelectedProps()} />
            <label className="form-check-label" />
          </div>
        ),
      },
      ...columns,
    ]);
  });

  const batchesToBeSaved = initialRows.filter((row) => row.isSelected).map((row) => row.original);

  const progressPercent = (batchSavingCounter / batchesToBeSaved.length) * 100;

  const resetBatchStateVars = () => {
    setMinBatchSize(defaultMinBatch);
    setMaxBatchSize(defaultMaxBatch);
    setBatchPrefix('Batch');
    setBatchingStrategy(batchingStrategies.BASIC);
    setUpBatchVariables();
    setIsLoadingWhileCreating(false);
    setBatchSavingCounter(0);
  };

  const closeBatchModalActions = () => {
    setShowCreateBatch(false);
    resetBatchStateVars();
  };

  const saveBatches = async () => {
    setIsLoadingWhileCreating(true);

    const errors = [];

    let counter = 0;

    for (const batch of batchesToBeSaved) {
      const errorsForBatch = await createBatchAndGetErrors(batch.name, fcId, batch.suborders);
      errors.push(...errorsForBatch);
      counter++;

      setBatchSavingCounter(counter);
    }

    setIsLoadingWhileCreating(false);
    setShowCreateBatch(false);

    if (errors.length && setBatchCreationErrors) {
      setBatchCreationErrors(errors);
    }

    unselectAll();
    await queryCache.invalidateQueries('fulfillmentSuborders');
    await queryCache.invalidateQueries('batches');

    closeBatchModalActions();
  };

  useEffect(() => {
    toggleAllBatchRowsSelected(true);
  }, [selectableBatches]);

  const getButtonInBatchingStrategyGroup = (key) => {
    const actualStrategy = batchingStrategies[key];
    return (
      <Button
        key={actualStrategy}
        isActive={batchingStrategy === actualStrategy}
        onClick={(e) => {
          e.preventDefault();
          setBatchingStrategy(actualStrategy);
        }}
      >
        {actualStrategy}
      </Button>
    );
  };

  const selectedLength = Object.keys(selectedRowIds).length;
  return (
    <div className={`list-alert alert alert-dark alert-dismissible border fade ${selectedLength && 'show'}`} role="alert">
      <div className="row align-items-center">
        <div className="col">
          <div className="form-check">
            <input className="form-check-input" id="listAlertCheckbox" type="checkbox" checked disabled />
            <label className="text-white form-check-label" htmlFor="listAlertCheckbox">
              <span className="list-alert-count">{selectedLength}</span> suborder(s)
            </label>
          </div>
        </div>
        <div className="col-auto mr-n3">
          <BatchUpdateModal
            visible={showUpdateBatch}
            onDone={onDoneUpdate}
            createSuborder={createSuborder}
            fieldName={batchField}
            suborders={(selectedFlatRows || []).map((r) => r.original)}
            fcId={fcId}
          />
          <BatchRemoveSuborderModal
            visible={showRemoveSuborder}
            onDone={onDoneUpdate}
            batchId={batchId}
            suborders={(selectedFlatRows || []).map((r) => r.original)}
          />
          <UpdateDelivery
            suborders={(selectedFlatRows || []).map((r) => r.original)}
            fcId={Number(fcId)}
            deliveryAreas={deliveryAreas}
            showChangeDelivery={showUpdateDelivery}
            setShowChangeDelivery={setShowUpdateDelivery}
            onComplete={onUpdateDeliveryDoneUpdate}
            coords={null}
            zipCode={null}
            newOrder={false}
            setDeliveryFields={() => {}}
          />

          {showUpgradeLineItem && (
            <UpgradeLineItem
              suborderDetails={(selectedFlatRows || []).map((r) => r.original)}
              oldLineItem={null}
              fcId={Number(fcId)}
              fulfillmentDate={fulfillmentDate}
              showUpgradeLineItem={showUpgradeLineItem}
              setShowUpgradeLineItem={setShowUpgradeLineItem}
              onComplete={onUpdateDeliveryDoneUpdate}
            />
          )}
          {showRedistribute && (
            <RedistributeDate
              suborders={(selectedFlatRows || []).map((r) => r.original)}
              showChangeDelivery={showRedistribute}
              setShowChangeDelivery={setShowRedistribute}
              onComplete={onUpdateDeliveryDoneUpdate}
            />
          )}
          <Popover
            position={Position.BOTTOM_LEFT}
            content={
              <Menu>
                <Menu.Group>
                  <Menu.Item disabled={!roleChecks.canRunBulkSuborderAction(userGroups)} onSelect={() => onFieldSelect(batchFields.STATUS)}>
                    Update Status
                  </Menu.Item>
                  <Menu.Item disabled={!roleChecks.canRunBulkSuborderAction(userGroups)} onSelect={() => onFieldSelect(batchFields.DELIVERY)}>
                    Change Delivery
                  </Menu.Item>
                  {roleChecks.canRedistributeSuborders(userGroups) && (
                    <Menu.Item disabled={!roleChecks.canRunBulkSuborderAction(userGroups)} onSelect={() => onFieldSelect(batchFields.REDISTRIBUTE)}>
                      Redistribute to Date
                    </Menu.Item>
                  )}
                  <Menu.Item disabled={!roleChecks.canRunBulkSuborderAction(userGroups)} onSelect={() => onFieldSelect(batchFields.UPGRADE_LINEITEM)}>
                    Forced Upgrade Line Item
                  </Menu.Item>
                  {batchId && (
                    <Menu.Item
                      disabled={!roleChecks.canRunBulkSuborderAction(userGroups)}
                      onSelect={() => onFieldSelect(batchFields.REMOVE_SUBORDER)}
                    >
                      Remove from Batch
                    </Menu.Item>
                  )}
                </Menu.Group>
              </Menu>
            }
          >
            <button className="text-white btn btn-sm btn-link dropdown-toggle">Bulk Actions</button>
          </Popover>
        </div>
        {!batchId && (
          <div className="col-auto mr-n3">
            <Dialog
              isShown={showCreateBatch}
              width={880}
              title="Create Batches"
              confirmLabel="Create Selected Batches"
              onCloseComplete={closeBatchModalActions}
              onCancel={closeBatchModalActions}
              onConfirm={saveBatches}
              isConfirmDisabled={
                !batchesToBeSaved ||
                batchesToBeSaved.length === 0 ||
                selectableBatches.length < 1 ||
                !minBatchSize ||
                !maxBatchSize ||
                maxBatchSize <= minBatchSize ||
                isLoadingWhileCreating
              }
            >
              {!isLoadingWhileCreating ? (
                <form>
                  <div className="mb-3 row">
                    <div className="col col-sm-6">
                      <Input
                        register={register({ required: true })}
                        errors={errors}
                        name="batchPrefix"
                        label="Batch Prefix"
                        value={batchPrefix}
                        onChange={(e) => setBatchPrefix(e.target.value)}
                      />
                    </div>
                    <div className="col col-sm-3">
                      <Input
                        register={register({
                          required: true,
                          min: 1,
                          valueAsNumber: true,
                          validate: {
                            lessThanMax: (v) => !maxBatchSize || parseInt(v) < parseInt(maxBatchSize) || 'Min must be less than max',
                            positive: (v) => v > 0 || 'Min should be larger than zero',
                          },
                        })}
                        type="number"
                        errors={errors}
                        name="minBatchSize"
                        label="Min Size"
                        value={minBatchSize}
                        onChange={(e) => setMinBatchSize(e.target.value)}
                        min="1"
                        max={(maxBatchSize ? parseInt(maxBatchSize) : batchSizes.DEFAULT_MAX) - 1}
                      />
                    </div>
                    <div className="col col-sm-3">
                      <Input
                        register={register({
                          required: true,
                          min: 1,
                          valueAsNumber: true,
                          validate: {
                            largerThanMin: (v) => !minBatchSize || parseInt(v) > parseInt(minBatchSize) || 'Max must be greater than min',
                            positive: (v) => v > 0 || 'Max should be larger than zero',
                          },
                        })}
                        type="number"
                        errors={errors}
                        name="maxBatchSize"
                        label="Max Size"
                        value={maxBatchSize}
                        onChange={(e) => setMaxBatchSize(e.target.value)}
                        min={(minBatchSize ? parseInt(minBatchSize) : 1) + 1}
                        max={fcConfig.useGuidedFulfillment ? batchSizes.GUIDED_FULFILLMENT_MAX : batchSizes.ARBITRARILY_HIGH_MAX}
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="mb-3">
                      <label>Auto Batching Strategy</label>
                    </div>
                    <Group>{Object.keys(batchingStrategies).map(getButtonInBatchingStrategyGroup)}</Group>
                  </div>
                  <div className="mb-3">Batches to Create</div>
                  <div className="table-responsive">
                    {/* Prototype table, based on output of a react-table table we have  elsewhere */}
                    <table role="table" className="table table-sm table-hover table-nowrap card-table table-small-sizing">
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th {...column.getHeaderProps()}>
                                <div>
                                  <span>
                                    <div>{column.render('Header')}</div>
                                  </span>
                                </div>
                              </th>
                            ))}
                            <th />
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()} className="list font-size-base">
                        {rows.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                              })}
                              <td />
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </form>
              ) : (
                <div style={{ alignItems: 'center', textAlign: 'center', marginBottom: 30 }}>
                  {batchesToBeSaved != null && batchesToBeSaved.length > 0 && <Progress percentage={progressPercent} />}
                  <div>Creating batches...</div>
                </div>
              )}
            </Dialog>
            <button className="btn btn-sm btn-white-20" onClick={() => setShowCreateBatch(true)}>
              Create Batches
            </button>
          </div>
        )}
      </div>

      <button type="button" className="list-alert-close close" onClick={unselectAll} aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    </div>
  );
};

BulkSuborderActionModal.propTypes = {
  fcId: PropTypes.string.isRequired,
  selectedRowIds: PropTypes.object.isRequired,
  selectedFlatRows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      index: PropTypes.number,
      isSelected: PropTypes.bool,
      original: PropTypes.shape({
        batchId: PropTypes.string,
        deliveryArea: PropTypes.number,
        lineItems: PropTypes.array,
        products: PropTypes.string,
        sku: PropTypes.arrayOf(PropTypes.string),
        status: PropTypes.string,
        suborderId: PropTypes.string,
        type: PropTypes.string,
        window: PropTypes.string,
      }),
    }),
  ).isRequired,
  toggleAllRowsSelected: PropTypes.func.isRequired,
  queryCache: PropTypes.shape({
    queries: PropTypes.object,
    queriesArray: PropTypes.array,
  }).isRequired,
  batchId: PropTypes.string,
  setBatchCreationErrors: PropTypes.func,
};

export default BulkSuborderActionModal;
