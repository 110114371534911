import { Button, DownloadIcon } from 'evergreen-ui';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import PropTypes from 'prop-types';

function createOrUpdateCsvDataFromReactTable({ rowData, tableData, idKey, setCsvData, desiredFields, stateReset }) {
  if (!tableData.length) {
    if (stateReset) {
      stateReset();
    }
    return;
  }

  const tableDataMappedBySuborderId = new Map();
  tableData.filter((entry) => entry !== undefined).forEach((entry) => tableDataMappedBySuborderId.set(entry[idKey], entry));

  const csvData = [];

  for (const row of rowData) {
    const csvRow = {};

    const wholeRow = tableDataMappedBySuborderId.get(row[idKey]);

    for (const field of desiredFields) {
      const { key, columnName } = field;

      let valueToPopulate = '';
      if (wholeRow[key]) {
        valueToPopulate = wholeRow[key];
      }
      if (typeof valueToPopulate === 'string') {
        valueToPopulate = valueToPopulate.replace(/"/g, '""');
      }
      csvRow[columnName] = valueToPopulate;
    }

    csvData.push(csvRow);
  }

  setCsvData(csvData);
}

const ExportReactTableToCsv = ({ filePrefix, fcConfig, rowData, tableData, idKey, desiredFields, customButton = null }) => {
  const [workingOnPopulatingCsvData, setWorkingOnPopulatingCsvData] = useState(false);
  const [csvData, setCsvData] = useState([]);

  const populateCsvDataIfPossible = () => {
    if (!workingOnPopulatingCsvData) {
      // this variable helps check whether we are updating the csv data variable
      setWorkingOnPopulatingCsvData(true);
      createOrUpdateCsvDataFromReactTable({
        rowData,
        tableData,
        idKey,
        setCsvData,
        desiredFields,
        stateReset: () => setWorkingOnPopulatingCsvData(false),
      });
    }
  };

  if (rowData.length !== csvData.length) {
    // the length check helps measure the first time we try to populate, and any changes while workingOnPopulatingCsvData === false
    populateCsvDataIfPossible();
  }

  useEffect(() => {
    setWorkingOnPopulatingCsvData(false);
    populateCsvDataIfPossible(); // one last check after update, in case the row data has been updated in the meantime
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvData]);

  return csvData && csvData.length ? (
    <CSVLink
      filename={`${filePrefix}${fcConfig && fcConfig.label ?
        `-${fcConfig.label.toLowerCase().replace(' ', '-')}` : ''}-${DateTime.now().toISO()}.csv`}
      data={csvData}
    >
      {customButton || (
        <Button iconAfter={DownloadIcon} appearance="primary">
          Export
        </Button>
      )}
    </CSVLink>
  ) : (
    <div />
  );
};

ExportReactTableToCsv.propTypes = {
  filePrefix: PropTypes.string,
  fcConfig: PropTypes.object,
  rowData: PropTypes.array,
  tableData: PropTypes.array,
  idKey: PropTypes.string,
  desiredFields: PropTypes.array,
  customButton: PropTypes.element,
};

ExportReactTableToCsv.defaultProps = {
  filePrefix: '',
  fcConfig: {},
  rowData: [],
  tableData: [],
  idKey: '',
  desiredFields: [],
  customButton: null,
};

export default ExportReactTableToCsv;
