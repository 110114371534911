import { roleChecks } from '../../../utilities/Role';

export const createProductOption = (product, inventoryBySku, userGroups, opsName) => {
  let isDisabled = false;
  let quantity = 0;

  if (inventoryBySku) {
    if (inventoryBySku[product.sku]) {
      quantity = inventoryBySku[product.sku];

      // if stock is 0 AND product is a kit, check kit components, and list the lowest inventory / quantity
    } else if (product.kit && product.kit.length) {
      const componentInventory = [];
      product.kit.forEach((component) => {
        if (inventoryBySku[component.sku]) {
          componentInventory.push(Math.floor(inventoryBySku[component.sku] / component.quantity));
        } else {
          componentInventory.push(0);
        }
      });

      if (!Math.min(...componentInventory)) {
        quantity = 0;
        // disable selecting product option with 0 inventory if user is not CareManager or OpsManager
        isDisabled = !roleChecks.canSelectNewProductOption(userGroups);
      } else {
        quantity = Math.min(...componentInventory);
      }
    } else {
      quantity = 0;
      // disable selecting product option with 0 inventory if user is not CareManager or OpsManager
      isDisabled = !roleChecks.canSelectNewProductOption(userGroups);
    }
  } else {
    quantity = '???';
  }

  const label = `${product.name} ${opsName} ${product.sku} (${quantity})`;

  if (quantity === '???' || !quantity) {
    return false;
  }

  return { value: product, label, isDisabled, quantity };
};
