/* eslint-disable no-console */
import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Dialog, TextInputField, Button, ChevronLeftIcon, Select } from 'evergreen-ui';
import SchedulesTable from './SchedulesTable';
import 'react-datepicker/dist/react-datepicker.css';
import './CreateEditDeliveryModal.css';
import { CARRIER, DELIVERYMECHANISM, TRANSPORT_MODES } from '../../../lookups/delivery';

const INITIAL_STATE_CREATE_FORM = {
  active: true,
  description: '',
  carrier: '',
  delivery_type: 'ondemand',
  deliveryMechanism: '',
  transportMode: '',
  priority: 0,
  onfleet_team_id: '',
  delivery_fee: '0.00',
  tax_rate: '0.00',
  distributionPoint: 1,
  travel_time: 0,
  cutoff: 0,
};

const CreateEditDeliveryModal = ({
  isModalOpen,
  onRequestClose,
  selectedRow,
  downloadGeoJsonMap,
  handleCreateUpdate,
  isUpdating,
  onSchedulesChange,
}) => {
  const modalContentRef = useRef(null);
  const [deliveryArea, setDeliveryArea] = useState(INITIAL_STATE_CREATE_FORM);

  let createNew = true;
  if (selectedRow) {
    createNew = false;
  }

  useEffect(() => {
    if (isModalOpen && modalContentRef.current) {
      modalContentRef.current.scrollTo(0, 0);
    }
    if (selectedRow) {
      setDeliveryArea(selectedRow);
    }
  }, [isModalOpen]);
  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setDeliveryArea((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  }, [setDeliveryArea]);

  const memoizedTransportModes = useMemo(
    () => TRANSPORT_MODES.map((val) => <option key={val} value={val}>{val}</option>),
    [],
  );

  const memoizedCarriers = useMemo(
    () => CARRIER.map((val, index) => <option key={val + index} value={val}>{val}</option>),
    [],
  );

  const memoizedDeliveryMechanisms = useMemo(
    () => DELIVERYMECHANISM.map((val) => <option key={val} value={val}>{val}</option>),
    [],
  );

  return (
    <Dialog isShown={isModalOpen} onCloseComplete={onRequestClose} confirmLabel="Save Changes" hasFooter={false} width="90%" hasClose={false}>
      <div className="header" ref={modalContentRef}>
        <div className="display-flex">
          <div
            className="tab-buttons display-flex-col-35"
            style={{ textAlign: 'left', cursor: 'pointer' }}
            onClick={() => onRequestClose()}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ' || e.key === 'Escape' || e.key === 'Delete') {
                onRequestClose();
              }
            }}
            role="button"
            tabIndex={0}
          >
            <ChevronLeftIcon /> Back
          </div>
          <div className="refresh-section display-flex-col-35" style={{ textAlign: 'center' }} />
          <div className="tab-buttons display-flex-col-35" />
        </div>
        <div className="display-flex">
          <div className="tab-buttons display-flex-col-35" style={{ textAlign: 'left' }} />
          <div className="refresh-section display-flex-col-35" style={{ textAlign: 'center' }}>
            <h1 style={{ color: '#6D6D6D' }}>{!createNew ? `Edit ${selectedRow?.description}` : 'Create Delivery Area'}</h1>
          </div>
          <div className="tab-buttons display-flex-col-35" />
        </div>
        <div className="display-flex">
          <div className="tab-buttons display-flex-col-35" />
          {!createNew &&
            <div className="tab-buttons display-flex-col-35" style={{ textAlign: 'center' }}>
              Map ID: {selectedRow?.id || 'N/A'}
            </div>}
          <div className="refresh-section display-flex-col-35" style={{ textAlign: 'right' }}>
            {(!createNew && selectedRow.id) &&
            <Button
              onClick={(e) => downloadGeoJsonMap(selectedRow.id, e)}
              iconBefore="download"
            >
              Download (.json)
            </Button>}
          </div>
        </div>
      </div>
      <hr style={{ height: 1, color: '#E8E8E8', marginBottom: 40 }} />
      <div className="edit-delivery-modal">
        <form className="details-form" style={{ border: '1px black solid' }}>
          <div className="section-header" style={{ background: selectedRow?.active ? '#fff7cc' : '#dcdcdc' }}>
            Details
          </div>

          <div className="display-flex" style={{ textAlign: 'left', borderBottom: '1px solid #E8E8E8', marginBottom: 10 }}>
            <div className="tab-buttons display-flex-col-20">
              <span style={{ color: '#6D6D6D', fontWeight: 'bold' }}>Name</span>
              <br />
              <span style={{ color: '#6D6D6D' }}>What is this delivery area called?</span>
            </div>
            <div className="tab-buttons display-flex-col-40" style={{ textAlign: 'center' }}>
              <TextInputField label="" width="100%" name="description" value={deliveryArea.description} onChange={handleChange} />
            </div>
            <div className="tab-buttons display-flex-col-40" />
          </div>

          <div className="display-flex" style={{ textAlign: 'left', borderBottom: '1px solid #E8E8E8', marginBottom: 10 }}>
            <div className="tab-buttons display-flex-col-20">
              <span style={{ color: '#6D6D6D', fontWeight: 'bold' }}>Transport Delivery Platform</span>
            </div>
            <div className="tab-buttons display-flex-col-40" style={{ textAlign: 'left', marginBottom: 15 }}>
              <Select name="deliveryMechanism" width="100%" value={deliveryArea.deliveryMechanism} onChange={handleChange}>
                <option value="N/A" placeholder="Please select" />
                {memoizedDeliveryMechanisms}
              </Select>
            </div>
            <div className="tab-buttons display-flex-col-40" />
          </div>

          <div className="display-flex" style={{ textAlign: 'left', borderBottom: '1px solid #E8E8E8', marginBottom: 10 }}>
            <div className="tab-buttons display-flex-col-20">
              <span style={{ color: '#6D6D6D', fontWeight: 'bold' }}>Carrier</span>
            </div>
            <div className="tab-buttons display-flex-col-40" style={{ textAlign: 'left', marginBottom: 15 }}>
              <Select name="carrier" width="100%" value={deliveryArea.carrier} onChange={handleChange}>
                <option value="N/A" placeholder="Please select" />
                {memoizedCarriers}
              </Select>
            </div>
            <div className="tab-buttons display-flex-col-40" />
          </div>

          <div className="display-flex" style={{ textAlign: 'left', borderBottom: '1px solid #E8E8E8', marginBottom: 10 }}>
            <div className="tab-buttons display-flex-col-20">
              <span style={{ color: '#6D6D6D', fontWeight: 'bold' }}>Transport Mode</span>
            </div>
            <div className="tab-buttons display-flex-col-40" style={{ textAlign: 'left', marginBottom: 15 }}>
              <Select name="transportMode" width="100%" value={deliveryArea.transportMode} onChange={handleChange}>
                <option value="N/A" placeholder="Please select" />
                {memoizedTransportModes}
              </Select>
            </div>
            <div className="tab-buttons display-flex-col-40" />
          </div>

          <div className="display-flex" style={{ textAlign: 'left', borderBottom: '1px solid #E8E8E8', marginBottom: 10 }}>
            <div className="tab-buttons display-flex-col-20">
              <span style={{ color: '#6D6D6D', fontWeight: 'bold' }}>Cutoff Time</span>
            </div>
            <div className="tab-buttons display-flex-col-40" style={{ textAlign: 'left', marginBottom: 15 }}>
              <TextInputField
                label=""
                name="cutoff"
                placeholder="120"
                value={deliveryArea.cutoff}
                onChange={handleChange}
              />
            </div>
            <div className="tab-buttons display-flex-col-40" />
          </div>

          <div className="display-flex" style={{ textAlign: 'left', borderBottom: '1px solid #E8E8E8', marginBottom: 10 }}>
            <div className="tab-buttons display-flex-col-20">
              <span style={{ color: '#6D6D6D', fontWeight: 'bold' }}>Prepped Time</span>
            </div>
            <div className="tab-buttons display-flex-col-40" style={{ textAlign: 'left', marginBottom: 15 }}>
              <TextInputField
                label=""
                placeholder="120"
                name="travel_time"
                value={deliveryArea.travel_time}
                onChange={handleChange}
              />
            </div>
            <div className="tab-buttons display-flex-col-40" />
          </div>

          <div className="display-flex" style={{ textAlign: 'left', borderBottom: '1px solid #E8E8E8', marginBottom: 10 }}>
            <div className="tab-buttons display-flex-col-20" style={{ marginBottom: 20 }}>
              <span style={{ color: '#6D6D6D', fontWeight: 'bold' }}>Integration ID</span>
              <br />
              <span style={{ color: '#6D6D6D' }}>
                Developer-supplied value to be able to connect with the transport delivery platform and carrier
              </span>
            </div>
            <div className="tab-buttons display-flex-col-40" style={{ textAlign: 'center' }}>
              <TextInputField label="" width="100%" name="onfleet_team_id" value={deliveryArea.onfleet_team_id} onChange={handleChange} />
            </div>
            <div className="tab-buttons display-flex-col-40" />
          </div>

          <div className="display-flex" style={{ textAlign: 'left', borderBottom: '1px solid #E8E8E8', marginBottom: 10 }}>
            <div className="tab-buttons display-flex-col-20" style={{ marginBottom: 20 }}>
              <span style={{ color: '#6D6D6D', fontWeight: 'bold' }}>Priority</span>
              <br />
              <span style={{ color: '#6D6D6D' }}>
                What network wide priority should this delivery area have? Note that a lower number means higher priority
              </span>
            </div>
            <div className="tab-buttons display-flex-col-40" style={{ textAlign: 'center' }}>
              <TextInputField
                label=""
                name="priority"
                type="number"
                placeholder="0"
                value={deliveryArea.priority}
                onChange={handleChange}
              />
            </div>
            <div className="tab-buttons display-flex-col-40" />
          </div>

          <div className="display-flex" style={{ textAlign: 'left', marginBottom: 25 }}>
            <div className="tab-buttons display-flex-col-100">
              <span style={{ color: '#6D6D6D', fontWeight: 'bold' }}>Delivery Area Schedules</span>
            </div>
          </div>
          <div className="display-flex" style={{ textAlign: 'center', marginBottom: 10 }}>
            <div className="tab-buttons display-flex-col-100">
              <SchedulesTable
                onSchedulesChange={onSchedulesChange}
                selectedRow={selectedRow}
                deliveryArea={deliveryArea}
              />
            </div>
          </div>
        </form>
      </div>
      <hr style={{ height: 1, color: '#E8E8E8', marginTop: 50 }} />
      <div className="display-flex" style={{ marginBottom: 50, marginTop: 40 }}>
        <div className="display-flex-col-50" style={{ textAlign: 'left' }}>
          <Button onClick={onRequestClose}>Cancel</Button>
        </div>
        <div className="display-flex-col-50" style={{ textAlign: 'right' }}>
          <Button
            isLoading={isUpdating}
            appearance="primary"
            onClick={() => handleCreateUpdate(deliveryArea)}
          >
            {createNew ? 'Create' : 'Save changes'}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

CreateEditDeliveryModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  selectedRow: PropTypes.shape({
    description: PropTypes.string,
    delivery_type: PropTypes.string,
    deliveryMechanism: PropTypes.string,
    transportMode: PropTypes.string,
    carrier: PropTypes.string,
    onfleet_team_id: PropTypes.string,
    priority: PropTypes.number,
    id: PropTypes.number,
    active: PropTypes.bool,
    delivery_fee: PropTypes.string,
    tax_rate: PropTypes.string,
    distributionPoint: PropTypes.number,
    travel_time: PropTypes.number,
    cutoff: PropTypes.number,
  }),
  isUpdating: PropTypes.bool.isRequired,
  downloadGeoJsonMap: PropTypes.func.isRequired,
  handleCreateUpdate: PropTypes.func.isRequired,
  onSchedulesChange: PropTypes.func.isRequired,
};

CreateEditDeliveryModal.defaultProps = {
  selectedRow: {
    description: '',
    delivery_type: '',
    deliveryMechanism: '',
    carrier: '',
    transportMode: '',
    onfleet_team_id: '',
    priority: 0,
    id: 0,
    active: true,
    delivery_fee: '0.00',
    tax_rate: '0.00',
    distributionPoint: 1,
    travel_time: 0,
  },
};

export default CreateEditDeliveryModal;
