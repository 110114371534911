import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Email = ({ onChange, tabIndex, value, name }) => {
  const [validate, setValidate] = useState(false);
  const validateValue = val => (val !== '' && /\S+@\S+\.\S+/.test(val));
  const generateEmailClass = () => {
    if (!validate) {
      return '';
    }

    if (!validateValue(value)) {
      return 'is-invalid';
    }
  };

  const onEmailChange = (event) => {
    event.target.valid = validateValue(event.target.value);
    onChange(event);
  };

  const onFocus = () => setValidate(true);
  return (
    <>
      <label className="form-label">
        Email Address
      </label>
      <input
        type="email"
        name={name}
        className={`form-control ${generateEmailClass()}`}
        placeholder="name@address.com"
        onChange={onEmailChange}
        value={value}
        tabIndex={tabIndex}
        onFocus={onFocus}
        required
      />
    </>
  );
};

Email.propTypes = {
  onChange: PropTypes.func,
  tabIndex: PropTypes.number,
  value: PropTypes.string,
  name: PropTypes.string,
};

Email.defaultProps = {
  onChange: () => {},
  tabIndex: 0,
  value: '',
  name: 'username',
};

export default Email;
