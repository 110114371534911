import React, { useEffect, useState } from 'react';
import Footer from '../../common/wizards/Footer';
import * as Icon from 'react-bootstrap-icons';
import IssueSelectionComponent from './IssueSelectionComponent';

const Step2 = ({ suborder, state, updateAction, updateStepThreeState, allowRedelivery, ...props }) => {
  const [needsRefundOrCredits, setNeedsRefundOrCredits] = useState(state.needsRefundOrCredits);
  const [needsRedelivery, setNeedsRedelivery] = useState(state.needsRedelivery);

  useEffect(() => {
    updateAction({ needsRefundOrCredits });
  }, [needsRefundOrCredits]);

  useEffect(() => {
    const redeliverySuborder = needsRedelivery // adds default behavior depending on boolean
      ? {
          suborderId: suborder.suborderId,
          recipient: { ...suborder.recipient },
          delivery: { ...suborder.delivery },
          lineItems: [],
          deletedLineItems: [],
        }
      : null;
    updateAction({ needsRedelivery, redeliverySuborder });

    if (needsRedelivery) {
      updateStepThreeState({
        issuingToCustomer: true, // default true on needs redelivery
        issuingToRecipient: false,
        issuingAccountCredits: false,
        issuingRefund: true, // default true on needs redelivery
        lineItemTotalWasSelected: false,
        grandTotalWasSelected: false,
        customPercentageWasSelected: false,
        customDollarAmountWasSelected: false,
        percentageAmount: 0,
        amountForEntireStep: 0,
      });
    } else {
      updateStepThreeState({
        // default behavior
        issuingToCustomer: false,
        issuingToRecipient: false,
        issuingAccountCredits: false,
        issuingRefund: false,
        lineItemTotalWasSelected: false,
        grandTotalWasSelected: false,
        customPercentageWasSelected: false,
        customDollarAmountWasSelected: false,
        percentageAmount: 0,
        amountForEntireStep: 0,
      });
    }
  }, [needsRedelivery]);

  const onSubmit = () => {
    props.nextStep();
  };

  return (
    <>
      <h2 className="text-center mb-2">How should we fix it?</h2>
      <p className="text-muted text-center mb-4">Choose whether we should offer money back or attempt to send another package.</p>
      <div className="row">
        <IssueSelectionComponent
          selectionType={'checkbox'}
          allowDeselection={true}
          iconComponent={<Icon.CurrencyDollar size="3em" />}
          issueText={'Issue a refund or account credits'}
          issueSubtext={'Help text here'}
          isSelected={needsRefundOrCredits === true}
          setIsSelected={setNeedsRefundOrCredits}
        />
      </div>
      <div className="row">
        <IssueSelectionComponent
          selectionType={'checkbox'}
          allowDeselection={true}
          iconComponent={<Icon.ArrowRepeat size="3em" />}
          issueText={'Redeliver items'}
          issueSubtext={allowRedelivery ? 'Resend the same items or upgrade' : 'This cannot be selected due to suborder status'}
          isSelected={needsRedelivery === true}
          setIsSelected={setNeedsRedelivery}
          isDisabled={!allowRedelivery}
        />
      </div>
      <Footer
        backActionText="Back"
        backAction={props.previousStep}
        forwardActionText="Continue"
        forwardAction={onSubmit}
        disableForwardOn={!state.needsRefundOrCredits && !state.needsRedelivery}
      />
    </>
  );
};

export default Step2;
