import React, { useState } from 'react';
import { LookupContext } from '../../context/lookupContext';
import getIdealProductName from '../order/helpers/getIdealProductName';

const FulfillableComponent = ({ item, slotForItem }) => {
  const [completed, setCompleted] = useState(false);
  const toggle = () => setCompleted(!completed);

  return item ? (
    <LookupContext.Consumer>
      {({ productFeed, tableSlots }) => (
        <div className={'card' + (completed ? ' border-2 border-primary' : '')} onClick={toggle}>
          <div className="p-3 card-body">
            <div className="row align-items-center">
              <div className="col-auto">
                <input className="form-check-input" type="checkbox" checked={completed} />
              </div>
              <div className="col-auto">
                {item.images && (
                  <div className="avatar avatar-lg avatar-4by3">
                    <img src={item.images.large} alt="..." className="rounded avatar-img" />
                  </div>
                )}
              </div>
              <div className="col">
                <div className="row">
                  <div className="col">
                    <h3 className="mb-0">
                      {item.quantity === 2 && (item.sku.indexOf('FLRL-B') === 0 || item.sku.indexOf('NF-PLNT') === 0) && 'Double '}
                      {item.quantity === 3 && (item.sku.indexOf('FLRL-B') === 0 || item.sku.indexOf('NF-PLNT') === 0) && 'Triple '}
                      {getIdealProductName(productFeed, item)}
                      {item.quantity > 1 && item.sku.indexOf('FLRL-B') === -1 && item.sku.indexOf('NF-PLNT') === -1 && ` x${item.quantity}`}
                    </h3>
                  </div>
                  <div className="col">
                    <h3 className="mb-0">{item.sku}</h3>
                  </div>
                </div>
              </div>
              <div className="col-auto">
                <div className="row">
                  <div className="col">
                    <h6 className="mb-2 text-uppercase text-muted text-nowrap">Cart Slot</h6>
                    <span className="mb-0 h4">{slotForItem || ''}</span>
                  </div>
                  <div className="col">
                    <h6 className="mb-2 text-uppercase text-muted text-nowrap">Table Slot</h6>
                    <span className="mb-0 h4">{tableSlots[item.sku] || ''}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </LookupContext.Consumer>
  ) : (
    <></>
  );
};

export default FulfillableComponent;
