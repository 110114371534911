import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Switch, Button, IconButton, toaster, DuplicateIcon } from 'evergreen-ui';
import { redistributeFulfillment } from '../../services/OrderManagementService';
import BestOptionDatePicker from './helpers/BestOptionDatePicker';

const RedistributeDate = ({ suborders, showChangeDelivery, setShowChangeDelivery, onComplete }) => {
  const [bestOptionDate, setBestOptionDate] = useState(null);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [error, setError] = useState('');
  const [listOfSuborderResponse, setListOfSuborderResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [showClose, setShowClose] = useState(false);
  const [showText, setShowText] = useState(false);

  const handleRedistributeRequest = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    setShowClose(false);

    const suborderIds = suborders.map((suborder) => suborder.suborderId);

    try {
      const res = await redistributeFulfillment(suborderIds, bestOptionDate, forceUpdate, 'delete');
      if (!res.success) {
        setError(res.error.message);
      } else {
        setShowClose(true);
        setListOfSuborderResponse(res.data);
        onComplete();
      }
      setLoading(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error', error);
      setError(error);
      setLoading(false);
    }
  };

  const copyToClipBoardAsSpreadSheetFormat = () => {
    setShowText(true);
    const header = Object.keys(listOfSuborderResponse[0]).join('\t');
    const rows = listOfSuborderResponse.map((item) => Object.values(item).join('\t'));
    const csv = `${header}\n${rows.join('\n')}`;
    navigator.clipboard.writeText(csv);
    toaster.notify('Copied to clipboard as Spread sheet format.', { duration: 2 });
    setTimeout(() => {
      setShowText(false);
    }, 3000);
  };

  const renderForm = () => (
    <>
      <div className="card-body pl-0 pt-0">
        <div className="row">
          <div className="mb-3">
            <p>For suborders: {suborders.map((suborder) => suborder.suborderId).join(',')} </p>
          </div>
        </div>
        {false && (
          <div className="row">
            <div className="mb-3">
              <span style={{ verticalAlign: 'middle' }}>Force Update </span>
              <Switch
                style={{ display: 'inline-block', verticalAlign: 'middle' }}
                checked={forceUpdate}
                onChange={(e) => setForceUpdate(e.target.checked)}
              />
            </div>
          </div>
        )}
        <BestOptionDatePicker bestOptionDate={bestOptionDate} setBestOptionDate={setBestOptionDate} />
      </div>
      {error && (
        <div>
          <p className="text-danger">{error}</p>
        </div>
      )}
      {!!listOfSuborderResponse.length && (
        <>
          <div style={{ color: 'grey' }}>
            <IconButton icon={DuplicateIcon} onClick={() => copyToClipBoardAsSpreadSheetFormat()} style={{ marginRight: 3, float: 'left' }} />
            {showText && 'Copied to clipboard as Spread sheet format!'}
          </div>
          <div className="mt-2 mx-4">
            <div>
              <ul>
                {listOfSuborderResponse.map((sub) => (
                  <li className={sub.status === 'error' ? 'text-danger' : 'text-success'} key={sub.id}>{`${sub.id} ${sub.message}`}</li>
                ))}
              </ul>
            </div>
          </div>
        </>
      )}
      <div>
        <div className="d-flex justify-content-end">
          {!showClose && (
            <Button isLoading={loading} type="submit" appearance="primary" disabled={!bestOptionDate} onClick={handleRedistributeRequest}>
              Update
            </Button>
          )}
          {showClose && (
            <Button type="submit" appearance="primary" onClick={() => setShowChangeDelivery(false)}>
              Close
            </Button>
          )}
        </div>
      </div>
    </>
  );

  return (
    <Dialog
      title="Redistribute to date"
      isShown={showChangeDelivery}
      onCloseComplete={() => setShowChangeDelivery(false)}
      hasFooter={false}
      confirmLabel="Update"
      hasClose={!loading}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
    >
      {renderForm()}
    </Dialog>
  );
};

RedistributeDate.propTypes = {
  suborders: PropTypes.array,
  showChangeDelivery: PropTypes.bool.isRequired,
  setShowChangeDelivery: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
};

RedistributeDate.defaultProps = {
  suborders: [],
  onComplete: () => {},
};

export default RedistributeDate;
