import React from 'react';
import PropTypes from 'prop-types';

const Progress = ({ percentage }) => (
  <div className="progress">
    <div style={{width: `${percentage}%`}} className="progress-bar" role="progressbar" aria-valuenow={percentage} aria-valuemin="0" aria-valuemax="100"></div>
  </div>
);

Progress.propTypes = {
  percentage: PropTypes.number.isRequired,
};

export default Progress;
