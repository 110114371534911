/* eslint-disable react/prop-types */
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTable, usePagination, useRowSelect, useFilters, useSortBy } from 'react-table';
import { useQueryCache } from 'react-query';
import { Switch, Pill, Button, RepeatIcon } from 'evergreen-ui';
import { ExclamationTriangleFill } from 'react-bootstrap-icons';
import IndeterminateCheckbox from '../../common/tableHelpers/IndeterminateCheckbox';
import {
  DefaultColumnFilter,
  SelectColumnFilter,
  fuzzyTextFilterFn,
  multiLineTextFilterFn,
  MultiSelectFilter,
  fcIdFilterFn,
} from '../../common/tableHelpers/Filters';
import { LookupContext } from '../../../context/lookupContext';
import { useGetFCSuborders } from '../../../services/OrderManagementService';
import { styleSuborderStatus } from '../../common/suborderHelpers/styleSuborderStatus';
import BulkSuborderActionModal from '../common/BulkSuborderActionModal';
import ExportReactTableToCsv from '../../common/csvExports/ExportReactTableToCsv';
import BatchCreationErrorsDialog from '../common/BatchCreationErrorsDialog';
import getProductTypeFromClassificationOrCategory from '../../helpers/getProductTypeFromClassificationOrCategory';

const SelectAndBatchTable = ({ date, fcId, fcConfig }) => {
  const [data, setData] = useState([]);
  const [stats, setStats] = useState({});
  const [readyToBatchOnlyFilter, _setReadyToBatchOnlyFilter] = useState(true);
  const queryCache = useQueryCache();
  const setReadyToBatchOnlyFilter = (val) => {
    setFilter('batchId', val ? '' : undefined);
    setFilter('inException', val ? false : undefined);
    setFilter('isCancelled', val ? false : undefined);
    setFilter('awaitingPickup', val ? false : undefined);
    setFilter('inTransit', val ? false : undefined);
    setFilter('delivered', val ? false : undefined);
    const hiddenColumns = ['batchId', 'isCancelled', 'inException', 'awaitingPickup', 'inTransit', 'delivered'];
    if (!fcConfig.showWindows) {
      hiddenColumns.push('window');
    }
    setHiddenColumns(hiddenColumns);
    _setReadyToBatchOnlyFilter(val);
  };
  const { productFeed, deliveryAreas } = useContext(LookupContext);

  const [latestRefreshTime, setLatestRefreshTime] = useState(DateTime.now());
  const [isLoadingQuery, setIsLoadingQuery] = useState(false);

  const [batchCreationErrors, setBatchCreationErrors] = useState([]);

  function computeWindow({ startTime, windowDuration }) {
    const startDateTime = DateTime.fromISO(startTime);
    const endDateTime = startDateTime.plus({ hours: windowDuration });
    return `${startDateTime.toFormat('hh:mm a')} - ${endDateTime.toFormat('hh:mm a')}`;
  }

  function calculateStats(suborders) {
    const stats = suborders.reduce((obj, suborder) => {
      if (!obj[suborder.status]) {
        obj[suborder.status] = 1;
      } else {
        obj[suborder.status]++;
      }
      return obj;
    }, {});
    stats.total = suborders.length;

    const notPrinted = ['Received', 'Batched', 'BatchWorkorderPrinted', 'Picking', 'PickedAndQCd', 'AtTable', 'NotecardPrinted'];
    let notPrintedTotal = 0;
    const printed = ['SuborderPrinted', 'Packed', 'BatchCompleted', 'OnPallet', 'AwaitingPickup', 'LeftFulfillmentCenter', 'InTransit', 'Delivered'];
    let printedTotal = 0;

    notPrinted.forEach((s) => {
      if (s in stats) {
        notPrintedTotal += stats[s];
      }
    });

    printed.forEach((s) => {
      if (s in stats) {
        printedTotal += stats[s];
      }
    });

    stats.printedTotal = printedTotal;
    stats.notPrintedTotal = notPrintedTotal;

    let inException = 0;
    suborders.forEach((s) => {
      if (s.inException) {
        inException++;
      }
    });

    stats.inException = inException;

    return stats;
  }

  const { status, error, data: suborders } = useGetFCSuborders(fcId, date);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Suborder #',
        accessor: 'suborderId',
        filter: 'multiLineText',
        Cell: ({ value, row }) => {
          const splitVal = value.split('-');
          const orderId = splitVal.shift();
          const { inException } = row.original;
          const { orderType } = row.original;
          return (
            <>
              <Link to={{ pathname: `/orders/${orderId}` }}>{orderId}</Link>-{splitVal.join('-')}
              {inException && <ExclamationTriangleFill size="1em" fill="#E63757" style={{ padding: '0 0 2px 2px' }} />}
              {orderType && <Pill className="ml-1">{orderType}</Pill>}
            </>
          );
        },
      },
      {
        Header: 'Batch',
        accessor: 'batchId',
        filter: 'equals',
      },
      {
        Header: 'Products',
        accessor: 'products',
        Filter: MultiSelectFilter,
        filter: 'productText',
        Cell: ({ value }) => {
          const lineBreaked = value.split(',').join('<br />');
          return <div dangerouslySetInnerHTML={{ __html: lineBreaked }} />;
        },
      },
      {
        Header: 'Type',
        accessor: 'type',
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: 'Window',
        accessor: 'window',
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: 'Delivery Area',
        accessor: 'deliveryAreaName',
        Filter: MultiSelectFilter,
        filter: fcIdFilterFn,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: SelectColumnFilter,
        filter: 'equals',
        Cell: ({ value }) => <span className={styleSuborderStatus(value)}>{value}</span>,
      },
      {
        Header: 'In Exception',
        accessor: 'inException',
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: 'Cancelled',
        accessor: 'isCancelled',
        filter: 'equals',
      },
      {
        Header: 'Awaiting Pickup',
        accessor: 'awaitingPickup',
        filter: 'equals',
      },
      {
        Header: 'In Transit',
        accessor: 'inTransit',
        filter: 'equals',
      },
      {
        Header: 'Delivered',
        accessor: 'delivered',
        filter: 'equals',
      },
    ],
    [],
  );

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      multiLineText: multiLineTextFilterFn,
      productText: (rows, id, filterValue) => {
        return rows.filter((row) => {
          // eslint-disable-next-line react/prop-types
          const rowValue = row.values[id];
          // eslint-disable-next-line react/prop-types
          const productArr = rowValue ? rowValue.split('\n') : [];
          let includesProduct = true;
          for (const val of filterValue) {
            const filteredArray = productArr.filter((productName) => {
              const entireProductNameSplitByCommas = productName.split(',');
              for (const productName of entireProductNameSplitByCommas) {
                const includesString = productName.includes(`x ${val}`);
                if (includesString) {
                  const productNameSplitByVal = productName.split(val);
                  if (productNameSplitByVal.length === 1) {
                    return true;
                  }
                  if (productNameSplitByVal.length === 2 && productNameSplitByVal[1] === '') {
                    return true;
                  }
                }
              }

              return false;
            });
            if (!filteredArray || !filteredArray.length) {
              includesProduct = false;
            }
          }
          return includesProduct;
        });
      },
    }),
    [],
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    pageOptions,
    gotoPage,
    toggleAllRowsSelected,
    initialRows,
    setPageSize,
    setFilter,
    setHiddenColumns,
    rows,
    data: tableData,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: {
        hiddenColumns: ['batchId', 'isCancelled', 'inException', 'awaitingPickup', 'inTransit', 'delivered'],
        filters: [
          readyToBatchOnlyFilter && { id: 'batchId', value: '' },
          readyToBatchOnlyFilter && { id: 'inException', value: false },
          readyToBatchOnlyFilter && { id: 'isCancelled', value: false },
          readyToBatchOnlyFilter && { id: 'awaitingPickup', value: false },
          readyToBatchOnlyFilter && { id: 'inTransit', value: false },
          readyToBatchOnlyFilter && { id: 'delivered', value: false },
        ],
        pageSize: fcConfig.subOrderPageSize,
      },
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div className="form-check mb-n2">
              <IndeterminateCheckbox className="form-check-input list-checkbox-all" {...getToggleAllRowsSelectedProps()} />
              <label className="form-check-label" />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div className="form-check">
              <IndeterminateCheckbox className="form-check-input list-checkbox" {...row.getToggleRowSelectedProps()} />
              <label className="form-check-label" />
            </div>
          ),
        },
        ...columns,
      ]);
    },
  );

  const rowData = rows.map((row) => {
    prepareRow(row);
    return row.values;
  });

  const desiredCsvFields = [
    {
      key: 'suborderId',
      columnName: 'Suborder #',
    },
    {
      key: 'products',
      columnName: 'Products',
    },
    {
      key: 'type',
      columnName: 'Type',
    },
    {
      key: 'deliveryAreaName',
      columnName: 'Delivery Area',
    },
    {
      key: 'status',
      columnName: 'Status',
    },
  ];

  useEffect(() => {
    setPageSize(fcConfig.subOrderPageSize || 20);
  }, [fcConfig.subOrderPageSize, setPageSize]);

  function computeProductsAndType(lineItems) {
    const onlyBulk = !fcConfig.useGuidedFulfillment && !fcConfig.useBulkAndGuidedFulfillment;
    const skuQuantities = {};
    const skuNames = {};
    const componentSkus = new Set();
    lineItems.forEach((lineItem) => {
      const { sku, quantity, name, components } = lineItem;
      if (!lineItem.isDeleted) {
        if (components && components.length && (onlyBulk || !['Double', 'Triple'].includes(name.split(' ')[0]))) {
          const product = productFeed[sku];
          const includesFloral = product?.classification === 'Floral - Live';
          components.forEach((component) => {
            const { sku: subSku, quantity: subQuantity, name: subName } = component;
            skuQuantities[subSku] = (skuQuantities[subSku] || 0) + subQuantity * quantity;
            skuNames[subSku] = subName;
            if (!includesFloral) {
              componentSkus.add(subSku);
            } // track non floral kit components
          });
        } else {
          skuQuantities[sku] = (skuQuantities[sku] || 0) + quantity;
          skuNames[sku] = name;
        }
      }
    });

    const skus = Object.keys(skuQuantities);
    const products = [];
    const types = {};
    for (const sku in skuQuantities) {
      if (sku.indexOf('CRDISL') < 0) {
        const product = productFeed[sku];
        products.push(`${skuQuantities[sku]}x ${(product && product.opsName) || skuNames[sku]}`);
        if (product) {
          const type = getProductTypeFromClassificationOrCategory(product);
          if (type) {
            types[type] = true;
          }
        }
      }
    }
    let type = 'Other';
    const typeArray = Object.keys(types);
    const sortingArray = ['Floral', 'Plant', 'Dried/Wreath', 'Vase', 'Addon', 'Other'];

    if (typeArray.length) {
      if (typeArray.length === 1 && typeArray.includes('Floral')) {
        type = `${typeArray[0]} Only`;
      } else {
        typeArray.sort((a, b) => {
          return sortingArray.indexOf(a) - sortingArray.indexOf(b);
        });
        type = typeArray.join(' & ');
      }
    }
    // remove dangling line break
    return { skus, products: products.join(','), type };
  }

  useEffect(() => {
    const translateOrderType = (orderType) => {
      if (orderType === 'b2b') {
        return 'B2B';
      } if (orderType === 'subscription') {
        return 'S';
      }

      return null;
    };

    if (productFeed && Object.keys(productFeed).length) {
      setIsLoadingQuery(false);

      const sos = (suborders || []).map((suborder) => ({
        suborderId: suborder.suborderId,
        deliveryArea: suborder.delivery.areaId,
        deliveryAreaName: deliveryAreas[suborder.delivery.areaId]?.description || suborder.delivery.areaId,
        status: suborder.status,
        isCancelled: suborder.status.toLowerCase() === 'cancelled',
        awaitingPickup: suborder.status.toLowerCase() === 'awaitingpickup',
        inTransit: suborder.status.toLowerCase() === 'intransit',
        delivered: suborder.status.toLowerCase() === 'delivered',
        lineItems: suborder.lineItems,
        window: computeWindow(suborder.delivery),
        batchId: suborder.batchId === 'undefined' || !suborder.batchId ? '' : suborder.batchId,
        inException: !!suborder.inException,
        startTime: suborder.delivery.startTime,
        orderType: translateOrderType(suborder.orderType),
        ...computeProductsAndType(suborder.lineItems),
      }));
      setData(sos);
      setStats(calculateStats(sos));
      const hiddenColumns = ['batchId', 'isCancelled', 'inException', 'awaitingPickup', 'inTransit', 'delivered'];
      if (!fcConfig.showWindows) {
        hiddenColumns.push('window');
      }
      setHiddenColumns(hiddenColumns);
    } else {
      setIsLoadingQuery(true);
    }
  }, [suborders, readyToBatchOnlyFilter, fcConfig.showWindows, setHiddenColumns, deliveryAreas, productFeed, fcConfig.useGuidedFulfillment]);

  const refreshFulfillmentSuborders = async () => {
    setIsLoadingQuery(true);
    await queryCache.invalidateQueries('fulfillmentSuborders');
    setLatestRefreshTime(DateTime.now());
    setIsLoadingQuery(false);
  };

  return (
    <div className="card">
      <div className="card-header">
        <div className="row align-items-center">
          <div className="col-auto">
            <div className="mr-4 d-inline-flex" style={{ lineHeight: '1em' }}>
              Total
              <Pill display="inline-flex" marginLeft={4}>
                {stats.total || 0}
              </Pill>
            </div>
            <div className="mr-4 d-inline-flex" style={{ lineHeight: '1em' }}>
              Printed
              <Pill display="inline-flex" marginLeft={4}>
                {stats.printedTotal || 0}
              </Pill>
            </div>
            <div className="mr-4 d-inline-flex" style={{ lineHeight: '1em' }}>
              Not Printed
              <Pill display="inline-flex" marginLeft={4}>
                {stats.notPrintedTotal || 0}
              </Pill>
            </div>
            <div className="mr-4 d-inline-flex" style={{ lineHeight: '1em' }}>
              In Exception
              <Pill display="inline-flex" marginLeft={4}>
                {stats.inException || 0}
              </Pill>
            </div>
          </div>
          <div className="col" />
          <div className="col-auto">
            <form>
              <select
                className="form-select form-select-sm form-control-flush"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize} per page
                  </option>
                ))}
              </select>
            </form>
          </div>
          <div className="col-auto">
            <Switch
              style={{ display: 'inline-block', verticalAlign: 'middle' }}
              checked={readyToBatchOnlyFilter}
              onChange={(e) => setReadyToBatchOnlyFilter(e.target.checked)}
            />
            <span style={{ display: 'inline-block', verticalAlign: 'middle', paddingLeft: '0.5em', fontSize: '13px' }}>Ready to batch only</span>
          </div>
          {rows && rows.length > 0 && (
            <div className="col-auto">
              <ExportReactTableToCsv
                filePrefix="select-and-batch"
                fcConfig={fcConfig}
                rowData={rowData}
                tableData={tableData}
                idKey="suborderId"
                desiredFields={desiredCsvFields}
              />
            </div>
          )}
          <div className="col-auto">
            <span style={{ display: 'inline-block', verticalAlign: 'middle', paddingRight: '0.5em', fontSize: '13px', color: 'gray' }}>
              <div>Last Refreshed</div>
              {latestRefreshTime.toFormat('h:mm:ssa')}
            </span>
            <Button iconAfter={RepeatIcon} isLoading={status === 'loading' || isLoadingQuery} onClick={refreshFulfillmentSuborders}>
              Refresh
            </Button>
          </div>
        </div>
      </div>

      {(status === 'loading' || isLoadingQuery) && (
        <div className="bg-transparent">
          <div className="text-center card-body">
            <p className="text-muted">Loading suborders...</p>
          </div>
        </div>
      )}

      {status === 'error' && (
        <div className="bg-transparent">
          <div className="text-center card-body">
            <p className="text-muted">Error: {error.message}</p>
          </div>
        </div>
      )}

      {status !== 'loading' && status !== 'error' && !isLoadingQuery && (
        <>
          <div className="table-responsive">
            <table {...getTableProps()} className="table table-sm table-hover table-nowrap card-table">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        <div>
                          <span {...column.getSortByToggleProps()}>
                            {column.render('Header')}
                            {/* Add a sort direction indicator */}
                            {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                          </span>
                        </div>
                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                        {/* <a className="list-sort text-muted" data-sort="item-name" href="#">Suborder #</a> */}
                      </th>
                    ))}
                    <th />
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="list font-size-base">
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                      })}
                      <td />
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="card-footer d-flex justify-content-between">
            <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
              <li className="page-item">
                <button
                  className={`page-link pl-0 pr-4 border-right fade ${canPreviousPage && 'show'}`}
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <i className="mr-1 fe fe-arrow-left" /> Prev
                </button>
              </li>
            </ul>

            <ul className="overflow-auto list-pagination pagination pagination-tabs card-pagination">
              {pageOptions.map((p, i) => (
                <li key={i} className={p === pageIndex ? 'active' : ''}>
                  <span className="page" onClick={() => gotoPage(p)}>
                    {p + 1}
                  </span>
                </li>
              ))}
            </ul>

            <ul className="list-pagination-next pagination pagination-tabs card-pagination">
              <li className="page-item">
                <button
                  className={`page-link pl-4 pr-0 border-left fade ${canNextPage && 'show'}`}
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  Next <i className="ml-1 fe fe-arrow-right" />
                </button>
              </li>
            </ul>

            <BulkSuborderActionModal
              fcId={fcId}
              selectedRowIds={selectedRowIds}
              selectedFlatRows={initialRows.filter((row) => row.isSelected)}
              toggleAllRowsSelected={toggleAllRowsSelected}
              queryCache={queryCache}
              deliveryAreas={deliveryAreas}
              fulfillmentDate={date}
              fcConfig={fcConfig}
              setBatchCreationErrors={setBatchCreationErrors}
            />

            <BatchCreationErrorsDialog batchCreationErrors={batchCreationErrors} setBatchCreationErrors={setBatchCreationErrors} />
          </div>
        </>
      )}
    </div>
  );
};

SelectAndBatchTable.propTypes = {
  date: PropTypes.string,
  fcId: PropTypes.string,
};

export default SelectAndBatchTable;
