import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextInput from './TextInput';

const PasswordWithConfirmation = ({ tabIndex1, tabIndex2, onChange }) => {
  const [password, setPassword] = useState('');
  const [validPassword, setValidPassword] = useState(false);
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [validPasswordConfirmation, setValidPasswordConfirmation] = useState(false);

  const changeInput = (event) => {
    const target = event.target;
    const { name, value } = target;
    let allValid = false;
    let newPassword = password;
    let newPasswordConfirmation = passwordConfirmation;
    let valid = false;
    switch (name) {
    case 'password':
      valid = validateValue(value);
      setPassword(value);
      setValidPassword(valid);
      allValid = valid && validPasswordConfirmation && (value === passwordConfirmation);
      newPassword = value;
      break;
    case 'passwordConfirmation':
      valid = validateValue(value);
      setPasswordConfirmation(value);
      setValidPasswordConfirmation(valid);
      allValid = valid && validPassword && (password === value);
      newPasswordConfirmation = value;
      break;
    default:
      break;
    }

    const newTarget = {
      name: 'password',
      value: newPassword,
      valid: allValid,
    };
    const extra = { password: newPassword, passwordConfirmation: newPasswordConfirmation, valid: allValid };
    onChange({ extra, target: newTarget });
  };

  const validateValue = val => (val !== '' && val.length >= 8);
  const validateConfirmation = val => (validateValue(val) && (password === passwordConfirmation));

  return (
    <>
      <TextInput
        label="Password"
        onChange={changeInput}
        name="password"
        value={password}
        validate={validateValue}
        tabIndex={tabIndex1}
        type="password"
        required
      />

      <TextInput
        label="Password Confirmation"
        onChange={changeInput}
        name="passwordConfirmation"
        value={passwordConfirmation}
        validate={validateConfirmation}
        tabIndex={tabIndex2}
        type="password"
        required
      />
    </>
  );
};

PasswordWithConfirmation.propTypes = {
  tabIndex1: PropTypes.number,
  tabIndex2: PropTypes.number,
  onChange: PropTypes.func,
};

PasswordWithConfirmation.defaultProps = {
  tabIndex1: 0,
  tabIndex2: 0,
  onChange: () => {},
};

export default PasswordWithConfirmation;
