/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { useTable, useGroupBy, useSortBy, useExpanded } from 'react-table';
import { Switch } from 'evergreen-ui';
import { fetchFCSchedule } from '../../../services/AggregateService';
import { computeWindow } from '../helpers/computeWindow';
import { generatesSortableDeliveryWindow } from '../helpers/generatesSortableDeliveryWindow';
import { LookupContext } from '../../../context/lookupContext';

const WINDOW_GROUP_ID_DELIMITER = '//';
const INFINITY_STRING = '∞';

const OrderCount = ({ suborders, date, fcId, isLocalFc }) => {
  const [data, setData] = React.useState([]);

  const [byDeliveryAreaToggle, setByDeliveryAreaToggle] = useState(false);

  const { deliveryAreas } = useContext(LookupContext);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    const windowGroups = {};

    const getSuborderAreaWindowName = (startTime, windowDuration, areaId) => {
      const window = computeWindow(startTime, windowDuration);

      if (!byDeliveryAreaToggle) {
        return window;
      }

      const areaName = deliveryAreas[areaId] ? deliveryAreas[areaId].description : `Area Id ${areaId}`;

      return `${window} ${areaName}`;
    };

    const getIdealTransportModeForWhenDeliveryAreaToggleIsOff = (transportMode) => {
      if (!isLocalFc) {
        if (transportMode.toLowerCase().includes('express')) {
          return 'Express';
        } if (transportMode.toLowerCase().includes('ground')) {
          return 'Ground';
        }
      }
      return transportMode;
    };

    const getWindowGroupId = (startTime, windowDuration, areaId, transportMode) => {
      const window = computeWindow(startTime, windowDuration);

      if (!byDeliveryAreaToggle) {
        const formId = (tMode) => `${window}${WINDOW_GROUP_ID_DELIMITER}${tMode}`;

        return formId(getIdealTransportModeForWhenDeliveryAreaToggleIsOff(transportMode));
      }

      const areaName = deliveryAreas[areaId] ? deliveryAreas[areaId].description : `Area Id ${areaId}`;

      return `${window} ${areaName}`;
    };

    suborders.forEach((s) => {
      try {
        const { delivery } = s;
        const { areaId } = delivery;
        const areaName = deliveryAreas[areaId] ? deliveryAreas[areaId].description : `Area Id ${areaId}`;

        const { transportMode } = deliveryAreas[areaId];

        const windowId = getWindowGroupId(delivery.startTime, delivery.windowDuration, areaId, transportMode);

        const deliveryWindow = generatesSortableDeliveryWindow(delivery.startTime, delivery.windowDuration);

        if (windowGroups[windowId]) {
          windowGroups[windowId].suborders.add(s.suborderId.split('-')[0]);
        } else {
          windowGroups[windowId] = { suborders: new Set([s.suborderId.split('-')[0]]) };
        }

        windowGroups[windowId].deliveryWindow = deliveryWindow;

        let team;
        if (byDeliveryAreaToggle) {
          team = areaName.split(' ')[0].concat(' ', transportMode);
        } else {
          team = capitalizeFirstLetter(getIdealTransportModeForWhenDeliveryAreaToggleIsOff(transportMode));
        }
        windowGroups[windowId].team = team;
        windowGroups[windowId].name = getSuborderAreaWindowName(delivery.startTime, delivery.windowDuration, areaId);
      } catch (error) {
        console.error('Suborder issue in with non-existant delivery area', { suborderId: s.suborderId });
      }
    });

    // Get delivery cap from FC schedules
    const getFCSchedule = async () => {
      const fcID = fcId;
      if (fcID && date) {
        const result = await fetchFCSchedule(date, fcID);
        if (result.success) {
          const { areas } = result.schedules;
          const deliverySchedules = [];
          for (const a in areas) {
            const sched = areas[a].schedule;
            if (sched) {
              deliverySchedules.push({
                ...sched,
                transportMode: areas[a].transportMode,
              });
            }
          }
          for (const deliverySchedule of deliverySchedules) {
            if (deliverySchedule.windows) {
              for (const window of deliverySchedule.windows) {
                const windowDuration = window.duration;
                const areaId = deliverySchedule.deliveryarea;
                const hour = DateTime.fromFormat(window.start_time.toUpperCase(), 'h:mm a');
                const windowStartTime = DateTime.fromObject({
                  year: date.split('-')[0],
                  month: date.split('-')[1],
                  day: date.split('-')[2],
                  hours: hour.hour,
                  minutes: hour.minute,
                  seconds: hour.seconds,
                }).toISO();
                const windowId = getWindowGroupId(windowStartTime, windowDuration, areaId, deliverySchedule.transportMode);
                if (windowGroups[windowId]) {
                  windowGroups[windowId].deliveryMax = window?.capacity?.capacity ? window?.capacity?.capacity : INFINITY_STRING;
                  windowGroups[windowId].deliveryWindow = generatesSortableDeliveryWindow(windowStartTime, windowDuration);
                }
              }
            }
          }
        }
        if (!fcID || result.success === false) {
          console.error('No schedules available');
        }
      }
    };

    const setDataWithCapacity = async () => {
      await getFCSchedule();

      const data = [];
      for (const window of Object.values(windowGroups)) {
        const deliveryMax = window.deliveryMax ? window.deliveryMax : window.suborders.size;
        const { deliveryWindow } = window;
        data.push({
          window: window.name,
          team: window.team,
          count: `${window.suborders.size} / ${deliveryMax}`,
          deliveryMax,
          deliveryWindow,
        });
      }

      setData(data);
    };

    setDataWithCapacity();
  }, [deliveryAreas, suborders, date, fcId, byDeliveryAreaToggle]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Window',
        accessor: 'window',
        canGroupBy: false,
      },
      {
        Header: 'Team',
        accessor: 'team',
      },
      {
        Header: 'Count',
        accessor: 'count',
        canGroupBy: false,
        // Aggregate the sum of all orders
        aggregate: (leafValues) => leafValues.reduce((acc, next) => acc + Number(next.split('/')[0]), 0),
        Aggregated: ({ value }) => `${value} (total)`,
      },
      {
        Header: 'Max',
        accessor: 'deliveryMax',
        canGroupBy: false,
        Cell: ({ value }) => `${value}`,
      },
      {
        Header: 'deliveryWindow',
        accessor: 'deliveryWindow',
        canGroupBy: false,
        Cell: ({ value }) => `${value}`,
      },
    ],
    [],
  );

  const defaultColumn = React.useMemo(() => ({}), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetExpanded: false,
      initialState: {
        groupBy: ['team'],
        sortBy: [
          {
            id: 'deliveryWindow',
            desc: false,
          },
        ],
        hiddenColumns: ['deliveryMax', 'deliveryWindow'],
      },
    },
    useGroupBy,
    useSortBy,
    useExpanded,
  );

  const teamOrType = isLocalFc ? 'Team' : 'Type';

  const printHeader = (column) => {
    if (column.id === 'team') {
      if (byDeliveryAreaToggle) return 'Delivery Area';
      return teamOrType;
    }
    return column.render('Header');
  };

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title">Order Count</h4>
        <div className="col" />
        <div className="col-auto">
          <span style={{ display: 'inline-block', verticalAlign: 'middle', paddingRight: '0.5em', fontSize: '13px' }}>By {teamOrType}</span>
          <Switch
            style={{ display: 'inline-block', verticalAlign: 'middle' }}
            checked={byDeliveryAreaToggle}
            onChange={(e) => setByDeliveryAreaToggle(e.target.checked)}
          />
          <span style={{ display: 'inline-block', verticalAlign: 'middle', paddingLeft: '0.5em', fontSize: '13px' }}>By Delivery Area</span>
        </div>
      </div>
      <div className="table-responsive mb-0">
        <table {...getTableProps()} className="table table-sm table-hover card-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    <div className="d-flex align-items-center">
                      <span>{printHeader(column)}</span>
                    </div>
                  </th>
                ))}
                <th />
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="list font-size-base">
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, i) => {
                    // print cell and row
                    return (
                      <td key={i}>
                        {cell.isGrouped ? (
                        // If it's a grouped cell, add an expander and row count
                          <>
                            <div>
                              {' '}
                              {cell.render('Cell')} ({row.subRows.length}){' '}
                            </div>
                            <span className="btn btn-info btn-sm ml-2 py-0" {...row.getToggleRowExpandedProps()}>
                              {row.isExpanded ? 'Hide expanded' : 'Expand'}
                            </span>
                          </>
                        ) : cell.isAggregated ? (
                        // If the cell is aggregated, use the Aggregated
                          cell.render('Aggregated')
                        ) : cell.isPlaceholder ? null : (
                        // Otherwise, just render the regular cell
                          cell.render('Cell')
                        )}
                      </td>
                    );
                  })}
                  <td />
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

OrderCount.propTypes = {
  suborders: PropTypes.array,
};

export default OrderCount;
