import { Auth } from 'aws-amplify';

import { ORDER_MANAGEMENT_SERVICE_BASE_URL } from '../../../config';

export const printNotecard = async (suborder) => {
  const currentSession = await Auth.currentSession();
  const idToken = currentSession.getIdToken();
  const authToken = idToken.getJwtToken();
  const w = window.open('/notecards.html', 'massprint-note', 'location=no,status=no,scrollbars=no,menubar=no,resizable=no,width=700,height=700');
  if (w != null) {
    w.orders = [suborder];
    w.apiKey = authToken;
    w.url = `${ORDER_MANAGEMENT_SERVICE_BASE_URL}`;
    w.onload = () => w.init();
    w.onafterprint = () => {
      return w.close();
    };
  }
};
