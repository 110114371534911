/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Button } from 'evergreen-ui';
import PropTypes from 'prop-types';

const STATUS_BADGE_COLORS = {
  confirmed: {
    background: '#BFECC4',
    text: '#205906',
  },
  on_order: {
    background: '#E7FCF9',
    text: '#346F66',
  },
  created: {
    background: '#E7FCF9',
    text: '#346F66',
  },
  'partially received': {
    background: '#FFEBD4',
    text: '#733D1E',
  },
  received: {
    background: '#BFECC4',
    text: '#205906',
  },
  cancelled: {
    background: '#FCDCDC',
    text: '#7E1F1F',
  },
};

function capitalizeFirstLetters(str) {
  // Convert the string to lowercase for consistency
  return str.toLowerCase().replace(/(^|\s)([a-z])/g, (match) => match.toUpperCase());
}

const BadgeForInventoryRecordStatus = ({ status, fontSize = '14px', ...props }) => {
  const existsInMap = status && status.length && STATUS_BADGE_COLORS[status.toLowerCase()];
  const color = existsInMap ? STATUS_BADGE_COLORS[status.toLowerCase()] : STATUS_BADGE_COLORS.confirmed;

  const buttonVersion = (
    <Button {...props} style={{ color: color.text, backgroundColor: color.background, borderRadius: '100px', pointerEvents: 'none' }}>
      <span style={{ fontSize }}>{capitalizeFirstLetters(status)}</span>
    </Button>
  );

  return buttonVersion;
};

BadgeForInventoryRecordStatus.propTypes = {
  status: PropTypes.string,
  fontSize: PropTypes.string,
};

BadgeForInventoryRecordStatus.defaultProps = {
  status: '',
  fontSize: '14px',
};

export default BadgeForInventoryRecordStatus;
