import React from 'react';
import { Button } from 'evergreen-ui';
import PropTypes from 'prop-types';

const Footer = ({
  backActionText,
  backAction,
  forwardActionText,
  forwardAction,
  disableForwardOn,
  disableBackOn,
  showBack,
  hasSkipButton,
  skipButtonFunctionality,
}) => {
  return (
    <>
      <hr />
      <div className="d-flex" style={{ display: 'flex' }}>
        {showBack && (
          <Button onClick={backAction} disabled={disableBackOn}>
            {backActionText}
          </Button>
        )}
        {hasSkipButton && (
          <Button onClick={skipButtonFunctionality} appearance="default" style={{ marginLeft: 'auto' }}>
            Skip
          </Button>
        )}
        <Button onClick={forwardAction} disabled={disableForwardOn} appearance="primary" style={{ marginLeft: hasSkipButton ? '8px' : 'auto' }}>
          {forwardActionText}
        </Button>
      </div>
    </>
  );
};

Footer.propTypes = {
  backActionText: PropTypes.string,
  backAction: PropTypes.func,
  forwardActionText: PropTypes.string,
  forwardAction: PropTypes.func,
  disableForwardOn: PropTypes.bool,
  disableBackOn: PropTypes.bool,
  showBack: PropTypes.bool,
  hasSkipButton: PropTypes.bool,
  skipButtonFunctionality: PropTypes.func,
};

Footer.defaultProps = {
  backActionText: '',
  backAction: () => {},
  forwardActionText: '',
  forwardAction: () => {},
  disableForwardOn: false,
  disableBackOn: false,
  showBack: true,
  hasSkipButton: false,
  skipButtonFunctionality: () => {},
};

export default Footer;
