import React from 'react';
import { Button, RefreshIcon } from 'evergreen-ui';
import PropTypes from 'prop-types';
import TabulatedButtons from './TabulatedButtons';
import './Filters.css';

const Filters = ({
  isModalOpen,
  kind,
  isRefreshFetching,
  activeFilter,
  handleFilterChange,
  lastRefreshed,
  onRefresh,
  data,
  showCreateEdit,
  activeText,
  inactiveText,
}) => {
  return (
    <div style={{ display: 'flex', padding: '0px 25px 0px 25px' }}>
      <div className="tab-buttons" style={{ width: '50%', margin: 'auto' }}>
        <TabulatedButtons
          defaultSelected={activeFilter}
          handleFilterChange={handleFilterChange}
          data={data}
          activeText={activeText}
          inactiveText={inactiveText}
        />
      </div>
      <div className="refresh-section" style={{ width: '50%', textAlign: 'right' }}>
        <span style={{ fontSize: 8, color: '#6D6D6D', marginRight: 5 }}> {lastRefreshed && `Last Refreshed [${lastRefreshed}]`}</span>
        <Button marginY={8} marginRight={12} iconAfter={<RefreshIcon className={isRefreshFetching ? 'rotate' : ''} />} onClick={onRefresh}>
          Refresh
        </Button>
        <Button disabled={isModalOpen} appearance="primary" onClick={() => showCreateEdit(null)}>
          {`Create New ${kind === 'deliveryWindow' ? 'Window' : 'Area'}`}
        </Button>
      </div>
    </div>
  );
};

Filters.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  kind: PropTypes.string.isRequired,
  isRefreshFetching: PropTypes.bool.isRequired,
  activeFilter: PropTypes.string.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  lastRefreshed: PropTypes.string.isRequired,
  onRefresh: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  showCreateEdit: PropTypes.func.isRequired,
  activeText: PropTypes.string.isRequired,
  inactiveText: PropTypes.string.isRequired,
};

export default Filters;
