import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'evergreen-ui';

const CellLastEdited = ({ row, setShowAuditLog, setId }) => {
  const handleSelect = async (e) => {
    e.preventDefault();
    setShowAuditLog(true);
    setId();
  };

  const formattedDate = new Date(row.updatedAt).toLocaleString('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  return (
    <Button appearance="minimal" onClick={(e) => handleSelect(e)} style={{ color: 'blue' }}>
      {formattedDate}
    </Button>
  );
};

CellLastEdited.propTypes = {
  row: PropTypes.object.isRequired,
  setShowAuditLog: PropTypes.func.isRequired,
  setId: PropTypes.func,
};

CellLastEdited.defaultProps = {
  setId: () => {},
};

export default CellLastEdited;
