import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner, Switch } from 'evergreen-ui';
import Footer from '../../../common/wizards/Footer';
import BestOptionDatePicker from '../../helpers/BestOptionDatePicker';
import { redistributeFulfillment, removeSuborderException } from '../../../../services/OrderManagementService';

const Step1 = ({ suborderIds, currentState, updateAction, closeModal, nextStep }) => {
  const fontSize = '14px';
  const [bestOptionDate, setBestOptionDate] = useState(null);
  const [forceNewFc, setForceNewFc] = useState(true);
  const [loadingRedistributeRequest, setLoadingRedistributeRequest] = useState(false);
  const [errorWhileDoingRedistribute, setErrorWhileDoingRedistribute] = useState(null);

  const runRedistributeRequest = async () => {
    setLoadingRedistributeRequest(true);

    try {
      const res = await redistributeFulfillment(suborderIds, bestOptionDate, forceNewFc, 'delete');
      if (!res.success) {
        setErrorWhileDoingRedistribute(res.error.message);
      } else {
        const { data: results } = res;
        const successes = [];
        const failures = [];

        for (const result of results) {
          const { id, message, status, suborderData } = result;

          const objToPush = { id, message, suborderData };

          if (status === 'success') {
            // eslint-disable-next-line no-await-in-loop
            await removeSuborderException(id);
            successes.push(objToPush);
          } else {
            failures.push(objToPush);
          }
        }

        updateAction({ subordersMoved: successes, subordersUnableToBeMoved: failures, completedRedistributeRequest: true });
      }
      setLoadingRedistributeRequest(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error', error);
      setErrorWhileDoingRedistribute(error);
    }
  };

  const columnCountForList = Math.ceil(suborderIds.length / 10);

  return (
    <>
      <h2 className="mb-2 text-center">Bulk Redistribute Suborders</h2>
      <div className="pb-2" style={{ fontSize }}>
        Suborders Selected: {suborderIds.length}
      </div>
      <div className="card" style={{ height: '240px', overflow: 'auto' }}>
        <div className="p-3" style={{ columnCount: columnCountForList, fontSize }}>
          {suborderIds.map((suborderId, index) => (
            <div key={`suborderIdkey-${suborderId}-${index}`}>{suborderId}</div>
          ))}
        </div>
      </div>

      <div style={{ maxWidth: 400 }}>
        <BestOptionDatePicker bestOptionDate={bestOptionDate} setBestOptionDate={setBestOptionDate} />
      </div>

      <div className="pt-3">
        <span style={{ verticalAlign: 'middle' }}>Force New FC</span>{' '}
        <Switch style={{ display: 'inline-block', verticalAlign: 'middle' }} checked={forceNewFc} onChange={(e) => setForceNewFc(e.target.checked)} />
      </div>

      <div style={{ height: 80 }}>
        {errorWhileDoingRedistribute !== null && (
          <div className="text-danger pt-4">Got error from API while doing redistribute: {errorWhileDoingRedistribute}</div>
        )}
        {loadingRedistributeRequest && <Spinner className="pt-4" marginX="auto" />}
        {currentState.completedRedistributeRequest && (
          <div className="pt-4">
            <span className="text-success">Suborders moved to best location: {currentState.subordersMoved.length}</span>
            <span className="pl-4 text-danger">Suborders unable to be moved: {currentState.subordersUnableToBeMoved.length}</span>
          </div>
        )}
      </div>

      <Footer
        backActionText="Cancel"
        backAction={closeModal}
        forwardActionText="Continue"
        forwardAction={currentState.completedRedistributeRequest ? nextStep : runRedistributeRequest}
        disableForwardOn={bestOptionDate == null || loadingRedistributeRequest || errorWhileDoingRedistribute}
      />
    </>
  );
};

Step1.propTypes = {
  suborderIds: PropTypes.array,
  updateAction: PropTypes.func,
  closeModal: PropTypes.func,
  nextStep: PropTypes.func,
  currentState: PropTypes.object,
};

Step1.defaultProps = {
  suborderIds: [],
  updateAction: () => {},
  closeModal: () => {},
  nextStep: () => {},
  currentState: {},
};

export default Step1;
