import React from 'react';

export const FcContext = React.createContext({
  fcId: '4',
  setFcId: (id) => { this.fcId = id; },
  allFcs: [],
  allFcsConfig: {},
  fcConfig: {},
  date: '',
  setDate: (date) => { this.date = date; },
});
