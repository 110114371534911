/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { LookupContext } from '../../context/lookupContext';
import { formattedValue } from './utils';
import getIdealProductName from '../order/helpers/getIdealProductName';

const LineItem = ({ products }) => {
  const { productFeed } = useContext(LookupContext);
  const getLineItemName = (sku, name) => {
    const product = productFeed[sku];
    return product ? getIdealProductName(productFeed, product) : name;
  };

  return products.map((product, index) => {
    const { amount, name, sku } = product;
    const strikeThrough = amount < 0 ? 'text-decoration-line-through' : '';
    const val = formattedValue(amount);
    return (
      <tr key={`lineitem-${index}`}>
        <td className={`px-0 py-1 ${strikeThrough}`}>{getLineItemName(sku, name)}</td>
        <td className={`px-0 py-1 text-right text-right ${strikeThrough}`}>{val}</td>
      </tr>
    );
  });
};

LineItem.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      discount: PropTypes.number,
    }),
  ),
};

export default LineItem;
