import React, { useState } from 'react';
import PropTypes from 'prop-types';

const TextInput = ({ label, onChange, tabIndex, value, name, validate, placeholder, required, type }) => {
  const [startValidate, setStartValidate] = useState(false);
  const generateClass = () => {
    if (!startValidate) {
      return '';
    }

    if (!validate(value)) {
      return 'is-invalid';
    }
  };

  const onValueChange = (event) => {
    event.target.valid = validate(event.target.value);
    onChange(event);
  };

  const onFocus = () => setStartValidate(true);
  return (
    <div className="form-group">
      { label &&
        <label className="form-label">
          {label}
        </label>
      }
      <input
        type={type}
        name={name}
        className={`form-control ${generateClass()}`}
        placeholder={placeholder}
        onChange={onValueChange}
        value={value}
        tabIndex={tabIndex}
        onFocus={onFocus}
        required={required}
      />
    </div>
  );
};

TextInput.propTypes = {
  onChange: PropTypes.func,
  tabIndex: PropTypes.number,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  validate: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string,
};

TextInput.defaultProps = {
  onChange: () => {},
  tabIndex: 0,
  value: '',
  validate: () => true,
  placeholder: '',
  required: false,
  label: '',
  type: 'text',
};

export default TextInput;
