/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ImportIcon, Icon, ThDerivedIcon, toaster } from 'evergreen-ui';
import { MultipleColumnFilter } from '../../common/tableHelpers/MultipleColumnFilter';
import CellAction from '../components/CellAction';
import CellLastEdited from '../components/CellLastEdited';
import DeliveryTable from '../components/DeliveryTable';
import CreateEditDeliveryModal from './CreateEditDeliveryModal';
import { roleChecks } from '../../../utilities/Role';
import { FcContext } from '../../../context/fcContext';
import { getDeliveryAreasByFc, getGeoJson } from '../../../services/AggregateService';
import AuditLog from '../components/AuditLog';
import { createDeliveryArea, updateDeliveryArea } from '../../../services/OrderManagementService';
import CellDeliveryArea from '../components/CellDeliveryArea';

const DeliveryArea = ({ userGroups }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showAuditLog, setShowAuditLog] = useState(false);
  const { fcId, allFcs } = useContext(FcContext);
  const [fcLabel, setFcLabel] = useState('');
  const [data, setData] = useState([]);
  const [lastRefreshed, setLastRefreshed] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [deliveryAreaSchedules, setDeliveryAreaSchedules] = useState([]);
  const [deliveryAreaId, setDeliveryAreaId] = useState(null);
  const activeText = 'active';

  const showCreateEditModal = (delivery) => {
    setSelectedRow(delivery);
    setModalIsOpen(true);
  };

  const openGeoJsonMap = async (deliveryAreaId, event) => {
    event.preventDefault();
    try {
      const { geojson } = await getGeoJson(deliveryAreaId);
      window.open(`https://geojson.io/#data=data:application/json,${encodeURIComponent(geojson)}`);
    } catch (error) {
      console.error('Error fetching GeoJSON:', error);
      toaster.danger('Error viewing GeoJSON');
    }
  };

  const multiValueFilterFn = (rows, columnIds, filterValue) => {
    return rows.filter((row) => {
      const rowValue = row.values[columnIds[0]];

      if (rowValue === null || rowValue === undefined) {
        return false;
      }

      return filterValue.some((filter) => rowValue.includes(filter));
    });
  };

  const downloadGeoJsonMap = async (deliveryAreaId, event) => {
    event.preventDefault();
    try {
      const { geojson } = await getGeoJson(deliveryAreaId);
      const file = new Blob([geojson], { type: 'application/json' });
      const element = document.createElement('a');
      element.href = URL.createObjectURL(file);
      element.download = `delivery_area_${deliveryAreaId}_map.json`;
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      console.error('Error downloading GeoJSON:', error);
      toaster.danger('Error downloading GeoJSON');
    }
  };
  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedRow(null);
  };

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: 'Active',
        accessor: 'active',
        sortType: 'customSort',
        Filter: MultipleColumnFilter,
      },
      {
        Header: 'Delivery Area',
        accessor: 'description',
        sortType: 'customSort',
        Filter: MultipleColumnFilter,
        Cell: CellDeliveryArea,
        filter: multiValueFilterFn,
      },
      {
        Header: 'Transport Delivery Platform',
        accessor: 'deliveryMechanism',
        sortType: 'customSort',
        Filter: MultipleColumnFilter,
        filter: multiValueFilterFn,
      },
      {
        Header: 'Carrier',
        accessor: 'carrier',
        sortType: 'customSort',
        Filter: MultipleColumnFilter,
        filter: multiValueFilterFn,
      },
      {
        Header: 'Transport Mode',
        accessor: 'transportMode',
        sortType: 'customSort',
        Filter: MultipleColumnFilter,
        filter: multiValueFilterFn,
      },
      {
        Header: 'Priority',
        accessor: 'priority',
        sortType: 'customSort',
        Filter: MultipleColumnFilter,
        filter: multiValueFilterFn,
      },
      {
        Header: 'Last Edited',
        accessor: 'updatedAt',
        sortType: 'customSort',
        Filter: MultipleColumnFilter,
        Cell: React.memo(({ row }) => {
          return (<CellLastEdited
            setId={() => {
              setDeliveryAreaId(row.original.id);
            }}
            row={row.original}
            setShowAuditLog={setShowAuditLog}
          />);
        }),
      },
    ];

    if (roleChecks.canEditOutboundDelivery(userGroups)) {
      baseColumns.push({
        Header: 'Action',
        Cell: ({ row }) => (
          <CellAction row={row} showCreateEditModal={showCreateEditModal} />
        ),
        disableFilters: true,
      });
    }

    baseColumns.push(
      {
        Header: 'Download',
        Cell: ({ row }) => (
          <Button className="button" onClick={(e) => downloadGeoJsonMap(row.original.id, e)}>
            <Icon style={{ marginRight: 5 }} icon={ImportIcon} size={12} /> Download (.json)
          </Button>
        ),
        disableFilters: true,
        headerStyle: { textAlign: 'center', whiteSpace: 'nowrap', paddingBottom: '10px' },
      },
      {
        Header: 'Map',
        Cell: ({ row }) => (
          <Button className="button" onClick={(e) => openGeoJsonMap(row.original.id, e)}>
            <Icon style={{ marginRight: 5 }} icon={ThDerivedIcon} size={12} /> View Map
          </Button>
        ),
        disableFilters: true,
      },
    );
    return baseColumns;
  }, [userGroups, showCreateEditModal]);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      setIsFetching(true);
      try {
        const { areas } = await getDeliveryAreasByFc(fcId);
        if (isMounted) {
          setData(areas);
        }
      } catch (error) {
        console.error('Error fetching the delivery areas:', error);
      } finally {
        if (isMounted) {
          setIsFetching(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [fcId]);

  const displayFCLabel = async (fcId, allFcs) => {
    const x = await allFcs.filter((option) => option.value === String(fcId));
    if (x.length) {
      return x[0]?.label;
    }
    return 'No FC Selected';
  };

  useEffect(() => {
    const updateFcLabel = async () => {
      if (fcId && allFcs) {
        const label = await displayFCLabel(fcId, allFcs);
        setFcLabel(label);
      }
    };

    updateFcLabel();
  }, [fcId, allFcs]);

  const handleRefresh = async () => {
    setIsFetching(true);
    try {
      const { areas } = await getDeliveryAreasByFc(fcId);
      setData(areas);
      setIsFetching(false);
      setLastRefreshed(new Date().toLocaleString());
    } catch (error) {
      console.error('Error fetching the delivery areas:', error);
      setIsFetching(false);
    }
  };

  const handleScheduleChange = (updatedSchedules) => {
    setDeliveryAreaSchedules(updatedSchedules);
  };

  const handleCreateUpdate = async (deliveryArea) => {
    setIsUpdating(true);
    if (!selectedRow) {
      try {
        const sanitizedSchedules = deliveryAreaSchedules.map((schedule) => {
          const { startTime, endTime, dayOfWeek } = schedule;

          const startHour = parseInt(startTime.split(':')[0], 10);
          const endHour = parseInt(endTime.split(':')[0], 10);

          const duration = endHour - startHour;

          return {
            startHour,
            duration,
            dayOfWeek: parseInt(dayOfWeek, 10),
          };
        });
        const { areas } = await createDeliveryArea(deliveryArea, sanitizedSchedules);
        if (areas.status === 'FAIL' || areas.status === 'ERROR') {
          toaster.danger(areas.data.message);
        } else {
          toaster.success('Success created delivery area');
        }
        setModalIsOpen(false);
        setIsUpdating(false);
      } catch (error) {
        console.error('Error creating delivery areas:', error);
        setIsUpdating(false);
        toaster.danger('Fail to create');
      }
    } else {
      try {
        const sanitizedSchedules = deliveryAreaSchedules.map((schedule) => {
          const { id, startTime, endTime, dayofweek } = schedule;

          const startHour = parseInt(startTime.split(':')[0], 10);
          const endHour = parseInt(endTime.split(':')[0], 10);

          const duration = endHour - startHour;

          return {
            id,
            startHour,
            duration,
            dayOfWeek: parseInt(dayofweek, 10),
          };
        });
        await updateDeliveryArea(deliveryArea, sanitizedSchedules);
        toaster.success('Success updated delivery area');
        setModalIsOpen(false);
        setIsUpdating(false);
      } catch (error) {
        console.error('Error updating delivery areas:', error);
        setIsUpdating(false);
        toaster.danger('Fail to update');
      }
    }
  };

  return (
    <FcContext.Consumer>
      {() => (
        <>
          <CreateEditDeliveryModal
            isModalOpen={modalIsOpen}
            onRequestClose={closeModal}
            selectedRow={selectedRow}
            activeText={activeText}
            downloadGeoJsonMap={downloadGeoJsonMap}
            isUpdating={isUpdating}
            setIsUpdating={setIsUpdating}
            onSchedulesChange={handleScheduleChange}
            handleCreateUpdate={handleCreateUpdate}
          />
          <AuditLog entity="DELIVERYAREA" deliveryAreaId={deliveryAreaId} showAuditLog={showAuditLog} setShowAuditLog={setShowAuditLog} />
          <DeliveryTable
            isModalOpen={modalIsOpen}
            title={`Delivery Areas on ${fcLabel}`}
            kind="deliveryArea"
            data={data}
            columns={columns}
            showCreateEdit={showCreateEditModal}
            showDatePicker={false}
            showFcPicker
            lastRefreshed={lastRefreshed}
            isRefreshFetching={isFetching}
            handleRefresh={handleRefresh}
          />
        </>
      )}
    </FcContext.Consumer>
  );
};

DeliveryArea.propTypes = {
  userGroups: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default DeliveryArea;
