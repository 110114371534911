import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

import { FcContext } from '../../../context/fcContext';
import DatePicker from './DatePicker';

const FulfillmentCenterAndDateForm = ({ showDatePicker, showFcPicker }) => {
  return (
    <FcContext.Consumer>
      {({ fcId, setFcId, allFcs, groupedFcs, date, setDate }) => (
        <form className="row row-cols-lg-auto g-3 align-items-center">
          {showFcPicker && (
            <div className="col-12" style={{ minWidth: '200px' }}>
              <Select
                id="selectFc"
                controlShouldRenderValue
                value={allFcs.filter((option) => option.value === fcId)}
                onChange={(e) => {
                  const eventValue = e && e.value ? e.value : undefined;
                  setFcId(eventValue);
                }}
                options={groupedFcs}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
              />
            </div>
          )}
          {showDatePicker && (
            <div className="col-12" style={{ color: 'white' }}>
              <DatePicker date={date} setDate={setDate} />
            </div>
          )}
        </form>
      )}
    </FcContext.Consumer>
  );
};

FulfillmentCenterAndDateForm.propTypes = {
  showDatePicker: PropTypes.bool,
  showFcPicker: PropTypes.bool,
};
FulfillmentCenterAndDateForm.defaultProps = {
  showDatePicker: false,
  showFcPicker: true,
};
export default FulfillmentCenterAndDateForm;
