import { DateTime } from 'luxon';

/**
 * Format the date to 'MMMM d yyyy' (e.g., 'April 30 2023')
 */
function formatSpoilDate(dateString) {
  const parsedDate = DateTime.fromISO(dateString);

  const formattedDate = parsedDate.toFormat('MMMM d yyyy');

  return formattedDate;
}

export default formatSpoilDate;
