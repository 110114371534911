import React from 'react';
import PropTypes from 'prop-types';

const Modal = ({ visible, title, children, onCancel, onConfirm, errorMessage, disableButtons, disableConfirmText }) => (
  <>
    <div style={{ display: visible ? 'block' : 'none' }} className={`modal fade ${visible ? 'show' : ''}`} tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title" id="exampleModalLabel">{title}</h3>
            <button onClick={onCancel} type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {children}
          </div>
          <div className="modal-footer">
            {errorMessage && <p className="text-danger small flex-grow-1">{errorMessage}</p>}
            <button disabled={disableButtons} onClick={onCancel} type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
            <button disabled={disableButtons} onClick={onConfirm} type="button" className="btn btn-primary">{disableButtons ? disableConfirmText : 'Confirm'}</button>
          </div>
        </div>
      </div>
    </div>
    {visible && <div className="modal-backdrop fade show"></div>}
  </>
);

Modal.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.element,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  errorMessage: PropTypes.string,
  disableButtons: PropTypes.bool,
  disableConfirmText: PropTypes.string,
};

Modal.defaultProps = {
  visible: false,
  title: '',
  children: null,
  onCancel: () => { },
  onConfirm: () => { },
  errorMessage: '',
  disableButtons: false,
  disableConfirmText: '',
};

export default Modal;