import React from 'react';
import { DateTime } from 'luxon';

const DeliveryDetails = ({ delivery, deliveryAreas, deliveryFc, showChangeDelivery, setShowChangeDelivery, newOrder  }) => {

  return (
    <>
      {newOrder && !showChangeDelivery &&
    <div className="card-header p-3">
      <button type="button" className="btn btn-link text-right" onClick={() => setShowChangeDelivery(true)}>
        Edit
      </button>
    </div>}
      <div className="row g-0">
        <div className="col-6 py-3 px-3 text-center">
          <h6 className="text-uppercase text-muted">
          Delivery Date
          </h6>
          <h5 className="mb-0">{newOrder ? DateTime.fromSQL(delivery.startTime, { setZone: true }).toLocaleString() : DateTime.fromISO(delivery.startTime, { setZone: true }).toLocaleString()}</h5>
        </div>
        <div className="col-6 py-3 px-3 text-center border-left">
          <h6 className="text-uppercase text-muted">
          Window
          </h6>
          <h5 className="mb-0">
            {newOrder ?
              `${DateTime.fromSQL(delivery.startTime, { setZone: true }).toLocaleString(DateTime.TIME_SIMPLE)}-
            ${DateTime.fromSQL(delivery.startTime, { setZone: true }).plus({hours: delivery.windowDuration}).toLocaleString(DateTime.TIME_SIMPLE)}` :
              `${DateTime.fromISO(delivery.startTime, { setZone: true }).toLocaleString(DateTime.TIME_SIMPLE)}-
            ${DateTime.fromISO(delivery.startTime, { setZone: true }).plus({hours: delivery.windowDuration}).toLocaleString(DateTime.TIME_SIMPLE)}`}
          </h5>
        </div>
      </div>
      <div className="row g-0 border-top">
        <div className="col-6 py-3 px-3 text-center">
          <h6 className="text-uppercase text-muted">
          FC
          </h6>
          <h5 className="mb-0">{deliveryFc ? deliveryFc.label : delivery.fc.fcId}</h5>
        </div>
        <div className="col-6 py-3 px-3 text-center border-left">
          <h6 className="text-uppercase text-muted">
          Transport Mode
          </h6>
          <h5 className="mb-0">{delivery.transportMode}</h5>
        </div>
      </div>
      <div className="row g-0 border-top">
        <div className="col py-3 px-3 text-center">
          <h6 className="text-uppercase text-muted">
          Area
          </h6>
          <h5 className="mb-0 px-3">{ deliveryAreas[delivery.areaId] && deliveryAreas[delivery.areaId].description }</h5>
        </div>
      </div>
      { delivery.transportVendorDelivery.serviceLevel &&
      <div className="row g-0 border-top">
        <div className="col py-3 px-3 text-center">
          <h6 className="text-uppercase text-muted">
            Service Level
          </h6>
          <h5 className="mb-0 px-3">{delivery.transportVendorDelivery.serviceLevel}</h5>
        </div>
      </div>
      }
    </>
  );
};

export default DeliveryDetails;