/* eslint-disable no-console */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import PasswordWithConfirmation from '../common/PasswordWithConfirmation';
import TextInput from '../common/TextInput';

const ChangePassword = ({ tempUser, setUser }) => {
  const [password, setPassword] = useState('');
  const [validPassword, setValidPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [changingPassword, setChangingPassword] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const changeInput = (event) => {
    const { target } = event;
    const { name, value, valid } = target;
    switch (name) {
      case 'password':
        setValidPassword(valid);
        setPassword(value);
        break;
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
      default:
        break;
    }
  };

  const validate = () => ((firstName !== '') && (lastName !== '') && validPassword && !changingPassword);

  const changePassword = async (event) => {
    event.preventDefault();
    if (!validate()) {
      return;
    }
    try {
      setChangingPassword(true);
      const attributes = {
        given_name: firstName,
        family_name: lastName,
      };
      const user = await Auth.completeNewPassword(tempUser, password, attributes);
      setUser(user);
    } catch (error) {
      console.error('Unable to process change password', error);
      setErrorMessage(error.message);
      setTimeout(() => setErrorMessage(''), 3000);
    } finally {
      setChangingPassword(false);
    }
  };

  const mustExist = (val) => !!val;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-5 col-lg-6 col-xl-4 vh-100 px-lg-6 py-5 overflow-auto">
          <h1 className="display-4 text-center mb-3">
            Set New Password
          </h1>
          <form>
            <TextInput
              label="First Name"
              onChange={changeInput}
              name="firstName"
              value={firstName}
              validate={mustExist}
              tabIndex={1}
              required
            />
            <TextInput
              label="Last Name"
              onChange={changeInput}
              name="lastName"
              value={lastName}
              validate={mustExist}
              tabIndex={2}
              required
            />
            <PasswordWithConfirmation
              onChange={changeInput}
              tabIndex1={3}
              tabIndex2={4}
            />
            <button
              className="btn btn-lg btn-block btn-primary mb-3"
              onClick={changePassword}
              disabled={!validate()}
              tabIndex="5"
            >
              Submit
            </button>

            <div className="text-danger">
              {errorMessage}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

ChangePassword.propTypes = {
  tempUser: PropTypes.shape({
    username: PropTypes.string.isRequired,
  }).isRequired,
  setUser: PropTypes.func.isRequired,
};

export default ChangePassword;
