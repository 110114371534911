import React, { useState } from 'react';
import { Dialog } from 'evergreen-ui';
import PropTypes from 'prop-types';
import StepWizard from 'react-step-wizard';
import { useQueryCache } from 'react-query';
import Nav from './Nav';
import Step1 from './Step1';
import { ENVIRONMENT_NAME } from '../../../../config';
import Summary from './Summary';
import Step2 from './Step2';

const BulkRedistributeSubordersWizard = ({ suborderIds, visible, closeModal }) => {
  const queryCache = useQueryCache();
  const [wizardState, updateWizardState] = useState({
    subordersMoved: [],
    subordersUnableToBeMoved: [],
    completedRedistributeRequest: false,
  });

  const updateState = (updateProps) => updateWizardState({ ...wizardState, ...updateProps });

  const subordersMovedIds = wizardState.subordersMoved.map((suborder) => suborder.id);

  const finalListOfSubordersUnableToBeMoved = wizardState.subordersUnableToBeMoved.filter((suborder) => !subordersMovedIds.includes(suborder.id));

  const subordersUnableToBeMovedMappedByData = wizardState.subordersUnableToBeMoved
    .filter((redistributeSuborderElement) => redistributeSuborderElement.suborderData !== undefined)
    .map((suborder) => suborder.suborderData);

  const pushSuborderToSuccessList = (data) => {
    const { subordersMoved } = wizardState;
    subordersMoved.push(data);
    updateState({ subordersMoved });
  };

  const closeModalWithAdditionalActions = () => {
    queryCache.invalidateQueries(['exceptions']);
    closeModal();
  };

  return (
    <Dialog
      isShown={visible}
      overlayProps={{ style: { paddingLeft: '150px' } }}
      width="1120px"
      topOffset="10px"
      onCloseComplete={closeModalWithAdditionalActions}
      hasFooter={false}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={ENVIRONMENT_NAME === 'local'}
      hasHeader={false}
      preventBodyScrolling
    >
      <StepWizard
        isLazyMount
        transitions={{}}
        nav={
          <Nav
            subordersUnableToBeMoved={wizardState.subordersUnableToBeMoved}
            indexOfSuborderBeingRemedied={wizardState.indexOfSuborderBeingRemedied}
          />
        }
      >
        <Step1 suborderIds={suborderIds} currentState={wizardState} updateAction={updateState} closeModal={closeModalWithAdditionalActions} />
        {subordersUnableToBeMovedMappedByData.length > 0 && (
          <Step2 suborders={subordersUnableToBeMovedMappedByData} pushToSuccesses={pushSuborderToSuccessList} />
        )}
        <Summary
          subordersMoved={wizardState.subordersMoved}
          subordersUnableToBeMoved={finalListOfSubordersUnableToBeMoved}
          closeModal={closeModalWithAdditionalActions}
        />
      </StepWizard>
    </Dialog>
  );
};

BulkRedistributeSubordersWizard.propTypes = {
  suborderIds: PropTypes.array,
  visible: PropTypes.bool,
  closeModal: PropTypes.func,
};

BulkRedistributeSubordersWizard.defaultProps = {
  suborderIds: [],
  visible: false,
  closeModal: () => {},
};

export default BulkRedistributeSubordersWizard;
