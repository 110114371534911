export const camelCaseToTitleize = (str) => {
  const result = str.replace(/([A-Z])/g, ' $1').replace('.', ' ');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const snakeCaseToNormal = (str) => str.replace(/_/g, ' ');

export const capitalizeFirstLetter = (input) => {
  // Lowercase the entire string and split it into an array of words
  const words = input.toLowerCase().split(' ');

  // Iterate through each word in the array
  for (let i = 0; i < words.length; i++) {
    // Capitalize the first letter of each word
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  // Join the words back together to form the final string
  return words.join(' ');
};
