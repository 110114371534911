import React from 'react';
import { Table } from 'evergreen-ui';
import PropTypes from 'prop-types';
import BadgeForInventoryRecordStatus from '../../common/BadgeForInventoryRecordStatus';

const Details = ({ details, status }) => {
  return details && details.length ? (
    <Table.Body>
      <Table.Head>
        <Table.TextCell flexBasis={560} flexShrink={0} flexGrow={0} maxWidth={150}>
          <h4 style={{ textTransform: 'capitalize' }}>Lot Details</h4>
        </Table.TextCell>
        <Table.TextCell flexBasis={560} flexShrink={0} flexGrow={0} maxWidth={100}>
          <h4 style={{ textTransform: 'capitalize' }}>Status:</h4>
        </Table.TextCell>
        <Table.TextCell maxWidth={200} className="pl-0 mb-1">
          <BadgeForInventoryRecordStatus status={status} />
        </Table.TextCell>
      </Table.Head>
      <Table.Body>
        {details.map((value, index) => (
          <Table.Row key={`details-row-${index}`} maxHeight={50}>
            <Table.TextCell flexBasis={560} flexShrink={0} flexGrow={0} maxWidth={200}>
              <h4>{value.column}</h4>
            </Table.TextCell>
            <Table.TextCell flexBasis={560} flexShrink={0} flexGrow={0} maxWidth={50} />
            <Table.TextCell maxWidth={200} className="pl-1">
              {value.value}
            </Table.TextCell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Body>
  ) : (
    <div />
  );
};

Details.propTypes = {
  details: PropTypes.array,
  status: PropTypes.string,
};

Details.defaultProps = {
  details: [],
  status: '',
};

export default Details;
