import React, { useState, useContext, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { BoxSeam, ArrowRightShort, PlusCircle } from 'react-bootstrap-icons';
import { Tooltip } from 'react-tippy';
import { Popover, Menu, Position, Dialog, Checkbox, toaster, Badge } from 'evergreen-ui';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { DateTime } from 'luxon';
// eslint-disable-next-line import/no-cycle
import { deallocateStrategies } from './ViewOrder';
import { LookupContext } from '../../context/lookupContext';
import { AuthContext } from '../../context/authContext';
import { FcContext } from '../../context/fcContext';
import {
  createSuborderRedelivery,
  createSuborderException,
  removeSuborderException,
  addLineItemToSuborder,
  removeLineItemFromSuborder,
  refundLineItem,
  updateSuborder,
  cancelSuborder,
  getSuborderStatusMap,
} from '../../services/OrderManagementService';
import { getInventory } from '../../services/AggregateService';
import { roleChecks } from '../../utilities/Role';
// eslint-disable-next-line import/no-cycle
import Delivery from './Delivery';
import Recipient from './Recipient';
// eslint-disable-next-line import/no-cycle
import UpgradeLineItem from './UpgradeLineItem';
import { createProductOption } from './helpers/createProductOption';
import { getOpsNameText } from './helpers/getOpsProductName';
import TextInput from '../common/TextInput';
// eslint-disable-next-line import/no-cycle
import SuborderIssueWizardModal from './SuborderIssueWizardModal';
import { printDeliveryLabel } from '../common/printingHelpers/PrintDeliveryLabel';
import { styleSuborderStatus } from '../common/suborderHelpers/styleSuborderStatus';
import { getProductSkusArrayForInventoryLookup } from './helpers/getProductSkusArrayForInventoryLookup';
import { addNonHandledProductComponentsToProductOptions } from './helpers/addNonHandledProductComponentsToProductOptions';
import {
  addExceptionReasons,
  redeliveryReasons,
  lineItemRedeliveryReasons,
  updateLineItemReasons,
  refundLineItemReasons,
  addLineItemReasons,
  cancelReasons,
  // eslint-disable-next-line import/extensions
} from './helpers/getReasonType.js';
import { NETSUITE_INVENTORY_FEATURE_FLAG } from '../../config';

const LINE_ITEM_INVENTORY_STATUS_MAP = {
  on_order: {
    color: 'yellow',
    text: 'On Order Committed',
  },
  booked_against_available: {
    color: 'green',
    text: 'On Hand Committed',
  },
  needs_inventory: {
    color: 'red',
    text: 'Not Committed',
  },
  non_lotted: {
    color: 'blue',
    text: 'Non-Lotted',
  },
};

const Suborder = ({ order, suborder, setOrder, queryCache, adjustments, setShowAuditLog, setDefaultAuditLogSelect }) => {
  const [newLineItem, setNewLineItem] = useState(null);
  const [showAddLineItem, setShowAddLineItem] = useState(false);
  const [newLineItemIsCharged, setNewLineItemIsCharged] = useState(false);
  const [deleteLineItem, setDeleteLineItem] = useState(null);
  const [lineItemToRefund, setLineItemToRefund] = useState(null);
  const [refundDeletedLineItem, setRefundDeletedLineItem] = useState(false);
  const [showConfirmRedelivery, setShowConfirmRedelivery] = useState(false);
  const [reason, setReason] = useState(null);
  const [refundReason, setRefundReason] = useState(null);
  const [refundAdditionalDetails, setRefundAdditionalDetails] = useState('');
  const [showCreateException, setShowCreateException] = useState(false);
  const [showRemoveExceptionDialog, setShowRemoveExceptionDialog] = useState(false);
  const [inventoryBySku, setInventoryBySku] = useState(null);
  const [productOptions, setProductOptions] = useState(null);
  const [showUpdateSuborderStatus, setShowUpdateSuborderStatus] = useState(false);
  const [showSuborderIssueModal, setShowSuborderIssueModal] = useState(false);
  const [newStatus, setNewStatus] = useState(null);
  const [showUpdateRecipient, setShowUpdateRecipient] = useState(false);
  const [showCancelSuborder, setShowCancelSuborder] = useState(false);
  const [cancelSuborderUpdateTotals, setCancelSuborderUpdateTotals] = useState(false);
  const [cancelSuborderDeallocate, setCancelSuborderDeallocate] = useState('delete');
  const [additionalDetail, setAdditionalDetail] = useState('');
  const [showUpgradeLineItem, setShowUpgradeLineItem] = useState(false);
  const [lineItemToReplace, setLineItemToReplace] = useState(null);
  const [addLineItemLoading, setAddLineItemLoading] = useState(false);
  const [removeLineItemLoading, setRemoveLineItemLoading] = useState(false);
  const [, setShowCancelButton] = useState(false);

  const { productFeed } = useContext(LookupContext);
  const { allFcsConfig } = useContext(FcContext);
  const { userGroups } = useContext(AuthContext);

  const [lineItemReasons, setLineItemReasons] = useState({});
  const [lineItemIndexToDetail, setLineItemIndexToDetail] = useState([]);

  const suborderStatusMap = getSuborderStatusMap();
  // logic found in ticket DASH-281
  const shouldAllowForDeletionInsteadOfJustRefund = suborderStatusMap[suborder.status] < suborderStatusMap.SuborderPrinted;

  const allowRedelivery = suborderStatusMap[suborder.status] >= suborderStatusMap.SuborderPrinted; // dash-380

  const allowAddLineItem = ['Received', 'Batched'].indexOf(suborder.status) !== -1 || suborder.inException;

  const allowRemoveLineItem =
    (['Received', 'Batched'].indexOf(suborder.status) !== -1 || suborder.inException) && roleChecks.canUpdateLineItems(userGroups);

  const canSetAny = roleChecks.canSetAnySuborderStatus(userGroups);

  // DASH-299 - specified roles can select all
  const suborderStatuses = [
    { value: 'Received', label: 'Received', isSelectable: true },
    { value: 'Batched', label: 'Batched', isSelectable: canSetAny },
    { value: 'BatchWorkorderPrinted', label: 'Batch Workorder Printed', isSelectable: canSetAny },
    { value: 'AtTable', label: 'At Table', isSelectable: true },
    { value: 'NotecardPrinted', label: 'Notecard Printed', isSelectable: canSetAny },
    { value: 'SuborderPrinted', label: 'Suborder Printed', isSelectable: true },
    { value: 'Packed', label: 'Packed', isSelectable: canSetAny },
    { value: 'BatchCompleted', label: 'Batch Completed', isSelectable: canSetAny },
    { value: 'AwaitingPickup', label: 'Awaiting Pickup', isSelectable: true },
    { value: 'LeftFulfillmentCenter', label: 'Left Fulfillment Center', isSelectable: canSetAny },
    { value: 'InTransit', label: 'In Transit', isSelectable: true },
    { value: 'Delivered', label: 'Delivered', isSelectable: canSetAny },
  ];

  const selectableSuborderStatuses = suborderStatuses.filter((status) => status.isSelectable);

  const flatLineItems =
    suborder.lineItems && suborder.lineItems.length
      ? suborder.lineItems.flatMap((li) => (li.quantity === 1 ? li : new Array(li.quantity).fill({ ...li, quantity: 1 })))
      : [];

  // eslint-disable-next-line consistent-return
  const addionalDetailInputForAddLineItem = () => {
    if (reason !== null && reason !== '' && reason !== 'RedeliveryResolution') {
      return (
        <TextInput
          label="Provide any additional details for the reason:"
          name="CustomInput"
          value={additionalDetail}
          onChange={(event) => {
            setAdditionalDetail(event.target.value);
          }}
        />
      );
    }
  };

  function computeWindow({ startTime, windowDuration }) {
    const startDateTime = DateTime.fromISO(startTime, { setZone: true });
    const endDateTime = startDateTime.plus({ hours: windowDuration });
    return `${startDateTime.toFormat('hh:mm a')} - ${endDateTime.toFormat('hh:mm a')}`;
  }

  const suborderDetails = (suborder) => {
    return {
      suborderId: suborder.suborderId,
      deliveryArea: suborder.delivery.areaId,
      status: suborder.status,
      lineItems: suborder.lineItems,
      window: computeWindow(suborder.delivery),
      startTime: suborder.delivery.startTime,
      transportMode: suborder.delivery.transportMode,
    };
  };

  const applyDetailsToApplicableLineItemReasons = async () => {
    const liReasons = lineItemReasons;
    Object.keys(liReasons).forEach((index) => {
      for (const reason of liReasons[index]) {
        reason.details = lineItemIndexToDetail[index];
      }
    });
    setLineItemReasons(liReasons);
  };

  const createRedelivery = async () => {
    const reasons = reason
      ? [
          {
            tag: reason.categoryLabel ? `${reason.categoryLabel}: ${reason.value}` : reason.value,
            details: additionalDetail,
          },
        ]
      : [];
    await applyDetailsToApplicableLineItemReasons();
    const liReasons = Object.keys(lineItemReasons)
      .map((key) => lineItemReasons[key])
      .flat();
    await createSuborderRedelivery(suborder.suborderId, reasons, liReasons);
    setLineItemReasons({});
    setLineItemIndexToDetail(suborder.lineItems.map(() => ''));
    setReason(null);
    setAdditionalDetail('');
    queryCache.invalidateQueries(['orders', order.orderId]);
  };

  const addLineItem = async () => {
    setAddLineItemLoading(true);
    if (newLineItem) {
      const reasons = [
        {
          tag: reason.categoryLabel ? `${reason.categoryLabel}: ${reason.value}` : reason.value,
          details: additionalDetail || null,
        },
      ];
      await addLineItemToSuborder(suborder.suborderId, newLineItem, reasons, !newLineItemIsCharged);

      queryCache.invalidateQueries(['orders', order.orderId]);
      setShowAddLineItem(false);
      setReason(null);
      setAdditionalDetail('');
      setAddLineItemLoading(false);
    } else {
      setAddLineItemLoading(false);
    }
  };

  const createException = async () => {
    const sku = suborder.lineItems ? suborder.lineItems.map((e) => e.sku).join(';') : '';
    await createSuborderException(suborder.suborderId, reason, sku);

    // queryCache.invalidateQueries(['orders', order.orderId]);
    setShowCreateException(false);
    setReason(null);
    setAdditionalDetail('');
  };

  const removeException = async () => {
    await removeSuborderException(suborder.suborderId);
    queryCache.invalidateQueries(['orders', order.orderId]);
    setShowRemoveExceptionDialog(false);
  };

  const formOmsFriendlyReasonArray = (inputReason, inputDetail) => {
    return [
      {
        tag: inputReason.categoryLabel ? `${inputReason.categoryLabel}: ${inputReason.value}` : inputReason.value,
        details: inputDetail,
      },
    ];
  };

  const resetDeleteAndRefundVariables = () => {
    setDeleteLineItem(null);
    setReason(null);
    setRefundReason(null);
    setLineItemToRefund(null);
    setRefundDeletedLineItem(false);
    setAdditionalDetail('');
    setRefundAdditionalDetails('');
  };

  const removeLineItem = async (lineItem) => {
    setRemoveLineItemLoading(true);
    const removeReasons = formOmsFriendlyReasonArray(reason, additionalDetail);
    const result = await removeLineItemFromSuborder(suborder.suborderId, lineItem.index, removeReasons);

    queryCache.invalidateQueries(['orders', order.orderId]);

    if (refundDeletedLineItem && result?.success === true) {
      // eslint-disable-next-line no-use-before-define
      await handleRefundLineItem(lineItem);
    }

    resetDeleteAndRefundVariables();
    setRemoveLineItemLoading(false);
  };

  const handleRefundLineItem = async (lineItem) => {
    const refundReasonsForOms = formOmsFriendlyReasonArray(refundReason, refundAdditionalDetails);
    await refundLineItem(suborder.suborderId, [lineItem], refundReasonsForOms);
    queryCache.invalidateQueries(['orders', order.orderId]);
    resetDeleteAndRefundVariables();
  };

  const updateSuborderStatus = async () => {
    const result = await updateSuborder(suborder.suborderId, { status: newStatus });

    if (result?.success) {
      queryCache.invalidateQueries(['orders', order.orderId]);
      setShowUpdateSuborderStatus(false);
      setNewStatus(null);
    }
  };

  useEffect(() => {
    setLineItemIndexToDetail(suborder.lineItems.map(() => ''));
  }, [suborder.lineItems]);

  useEffect(() => {
    const getInventoryBySkus = async () => {
      const fulfillmentDateString = suborder.fulfillmentDate;
      const result = await getInventory(
        [suborder.fcId],
        getProductSkusArrayForInventoryLookup({
          productFeed,
          canViewFulfillableProductsFromProductList: roleChecks.canViewFulfillableProductsFromProductList(userGroups),
        }),
        fulfillmentDateString,
      );

      if (result.success) {
        const skuInventory = {};

        const { stock } = result;

        for (const sku in stock) {
          if (stock[sku][suborder.fcId]) {
            skuInventory[sku] = stock[sku][suborder.fcId][fulfillmentDateString];
          }
        }

        setInventoryBySku(skuInventory);
      }
    };

    if (showAddLineItem) {
      getInventoryBySkus();
    }
  }, [productFeed, showAddLineItem, suborder.fcId, suborder.fulfillmentDate]);

  useEffect(() => {
    const buildProductOptions = () => {
      // check if suborder already contains a [non deleted] primary product
      const hasPrimaryProduct = !!suborder.lineItems.filter((lineItem) => lineItem.isPrimaryProduct && !lineItem.isDeleted).length;
      const hasVase = !!suborder.lineItems.filter(
        (lineItem) =>
          // eslint-disable-next-line implicit-arrow-linebreak
          (lineItem.sku.startsWith('FLRL-V') ||
            (lineItem.components && lineItem.components.some((component) => component.sku.startsWith('FLRL-V')))) &&
          !lineItem.isDeleted,
      ).length;

      const productOptionsAllowed = []; // allowedTransportMode
      const productOptionsOther = [];
      const fulfillableProductComponents = [];

      const suborderStartTime = DateTime.fromISO(suborder.delivery.startTime);

      const handledSkus = new Set();

      const bypassChecks = roleChecks.canViewFulfillableProductsFromProductList(userGroups);

      // show products with same FC, available on suborder start date
      // filter out primary product if there is one in suborer already, separate by transport mode
      for (const product of Object.values(productFeed)) {
        const productFromTime = DateTime.fromISO(product.availableForDeliveryFrom);
        const productToTime = DateTime.fromISO(product.availableForDeliveryTo);
        const componentsIncludeVase = product.kit && product.kit.some((component) => component.sku.startsWith('FLRL-V'));

        const passesPrimaryRules =
          (!hasPrimaryProduct || !product.isPrimary) && (!hasVase || !(product.sku.startsWith('FLRL-V') || componentsIncludeVase));
        const passesSecondaryRules =
          suborderStartTime.diff(productFromTime).toObject().milliseconds >= 0 && productToTime.diff(suborderStartTime).toObject().milliseconds > 0;

        if (bypassChecks || passesPrimaryRules) {
          if (bypassChecks || passesSecondaryRules) {
            const opsName = getOpsNameText(productFeed, product);

            const option = createProductOption(product, inventoryBySku, userGroups, opsName);
            if (!option) {
              // eslint-disable-next-line no-continue
              continue;
            }
            if (product.allowedTransportModes.includes(suborder.delivery.transportMode)) {
              productOptionsAllowed.push(option);
            } else {
              // disable selecting a product that isn’t allowed in the current transport mode if user is not CareManager or OpsManager
              if (!roleChecks.canSelectNewProductOption(userGroups)) {
                option.isDisabled = true;
              }
              productOptionsOther.push(option);
            }
          }
        }

        handledSkus.add(product.sku);
      }

      addNonHandledProductComponentsToProductOptions(productFeed, handledSkus, inventoryBySku, userGroups, fulfillableProductComponents);

      setProductOptions([
        {
          label: 'Allowed transport mode',
          options: productOptionsAllowed,
        },
        {
          label: 'Other',
          options: [...productOptionsOther, ...fulfillableProductComponents],
        },
      ]);
    };

    buildProductOptions();
  }, [inventoryBySku, productFeed, suborder, userGroups]);

  const handleCancelSuborder = async () => {
    const reasons = [
      {
        tag: reason.categoryLabel ? `${reason.categoryLabel}: ${reason.value}` : reason.value,
        details: additionalDetail,
      },
    ];
    const result = await cancelSuborder(suborder.suborderId, cancelSuborderUpdateTotals, cancelSuborderDeallocate, reasons);

    if (result?.success) {
      queryCache.invalidateQueries(['orders', order.orderId]);
    } else {
      toaster.warning('Failed to cancel suborder');
    }
  };

  const handleSetLineItemReason = (lineItem, reasons) => {
    const { index } = lineItem;
    const detailMap = lineItemIndexToDetail;
    const details = detailMap[index] || '';
    if (!detailMap[index]) {
      detailMap[index] = details;
      setLineItemIndexToDetail(detailMap);
    }

    const liReasons = { ...lineItemReasons };

    if (reasons && reasons.length) {
      const arrayForCurrentLi = [];
      for (const subreason of reasons) {
        // eslint-disable-next-line no-use-before-define
        const tag = getTagFromReason(subreason);
        const reasonObject = {
          lineItemIndex: index,
          tag,
          details,
          suborderId: suborder.suborderId,
        };
        arrayForCurrentLi.push(reasonObject);
      }
      liReasons[index] = arrayForCurrentLi;
    } else {
      delete liReasons[index];
    }
    setLineItemReasons(liReasons);
  };

  const handleSetLineItemAdditionalDetail = (lineItem, additionalDetail) => {
    const { index } = lineItem;

    const detailArray = [...lineItemIndexToDetail];
    detailArray[index] = additionalDetail;
    setLineItemIndexToDetail(detailArray);
  };

  const getTagFromReason = (inputReason) => (inputReason.categoryLabel ? `${inputReason.categoryLabel}: ${inputReason.value}` : inputReason.value);

  const generateRemoveExceptionLink = () => {
    const currentDateTime = DateTime.now();
    const orderFulfilledByDate = DateTime.fromISO(suborder.delivery.orderFulfilledBy);
    if (currentDateTime > orderFulfilledByDate) {
      setShowRemoveExceptionDialog(true);
    } else {
      removeException();
    }
  };

  const getRefundLineItemDialogPortion = () => (
    <>
      <div className="mb-4">Select reason for refunding line item:</div>
      <div className="mb-4">
        <Select
          id="selectRefundLineItemReason"
          onChange={(option) => setRefundReason(option)}
          options={refundLineItemReasons}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
        />
      </div>
      <div className="mb-4">Provide any additional details for refund:</div>
      <div className="mb-4">
        <TextInput
          className="w-100"
          name="refundAdditionalDetails"
          value={refundAdditionalDetails}
          onChange={(event) => {
            setRefundAdditionalDetails(event.target.value);
          }}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
      </div>
    </>
  );

  const orderHasMultipleActiveSuborders = () => {
    const nonCancelledSuborders = [];
    for (const s of order.suborders) {
      if (s.status !== 'Cancelled') {
        nonCancelledSuborders.push(s.status);
      }
    }
    if (nonCancelledSuborders.length > 1) {
      return true;
    }
    return false;
  };

  return (
    <LookupContext.Consumer>
      {({ productFeed }) => (
        <>
          <div className="row">
            <div className="col">
              <h2 id={suborder.suborderId}>
                <BoxSeam size="1.5em" className="mr-2" />
                <span>Suborder {suborder.suborderId}</span>
                <Tooltip title={suborder.statusDate} position="bottom" arrow="true">
                  <span className={styleSuborderStatus(suborder.status)}>{suborder.status}</span>
                </Tooltip>
                {suborder.inException && (
                  <Tooltip
                    position="bottom"
                    arrow="true"
                    html={
                      <ul>
                        {(suborder.exceptionsList || []).map((exception, i) => (
                          <li key={i}>{exception.reason}</li>
                        ))}
                      </ul>
                    }
                  >
                    <span className="ml-3 badge bg-danger">Exception</span>
                  </Tooltip>
                )}
              </h2>
              {suborder.redeliveryOfSuborderId && <div className="mb-2">Redelivery of: {suborder.redeliveryOfSuborderId}</div>}

              {suborder.batchId && (
                <div className="mb-4">
                  Batch:
                  <Link to={{ pathname: `/batches/${suborder.batchId}` }}> {suborder.batchId} </Link>
                </div>
              )}
              {suborder?.redeliveryOrderId && (
                <div className="mb-4">
                  Redelivery DASH ID: &nbsp;
                  <NavLink exact target="_blank" to={`/orders/${suborder.redeliveryOrderId}`}>
                    {suborder.redeliveryOrderId}
                  </NavLink>
                </div>
              )}
            </div>
            <div className="col-auto">
              {roleChecks.canUpdateSuborders(userGroups) && (
                <>
                  <button type="button" className="mr-2 btn btn-danger" onClick={() => setShowSuborderIssueModal(true)}>
                    Report a Problem
                  </button>
                  {showSuborderIssueModal && (
                    <SuborderIssueWizardModal
                      order={order}
                      suborder={suborder}
                      visible
                      onDone={() => setShowSuborderIssueModal(false)}
                      closeModal={() => {
                        setShowSuborderIssueModal(false);
                        queryCache.invalidateQueries(['orders', order.orderId]);
                      }}
                      allowRedelivery={allowRedelivery}
                    />
                  )}
                  <Popover
                    position={Position.BOTTOM_LEFT}
                    content={
                      <Menu>
                        <Menu.Group>
                          <Menu.Item>
                            <a href="#!" onClick={() => setShowUpdateSuborderStatus(true)}>
                              Update suborder status
                            </a>
                          </Menu.Item>
                          <Menu.Item>
                            <a href="#!" onClick={() => setShowUpdateRecipient(true)}>
                              Update Recipient
                            </a>
                          </Menu.Item>
                        </Menu.Group>
                        <Menu.Divider />
                        <Menu.Group>
                          <Menu.Item>
                            {suborder.inException ? (
                              roleChecks.canUpdateSuborders(userGroups) ? (
                                <a href="#!" onClick={() => generateRemoveExceptionLink()}>
                                  Remove exception
                                </a>
                              ) : (
                                'Remove Exception'
                              )
                            ) : (
                              <a href="#!" onClick={() => setShowCreateException(true)}>
                                Create exception
                              </a>
                            )}
                          </Menu.Item>
                          {orderHasMultipleActiveSuborders() && (
                            <Menu.Item>
                              <a href="#!" onClick={() => setShowCancelSuborder(true)}>
                                Cancel suborder
                              </a>
                            </Menu.Item>
                          )}
                        </Menu.Group>
                        <Menu.Group>
                          <Menu.Item>
                            <a
                              href="#!"
                              onClick={() => {
                                setDefaultAuditLogSelect(suborder.suborderId);
                                setShowAuditLog(true);
                              }}
                            >
                              View Audit Log
                            </a>
                          </Menu.Item>
                        </Menu.Group>
                      </Menu>
                    }
                  >
                    <button type="button" className="btn btn-primary">
                      Actions
                    </button>
                  </Popover>
                </>
              )}
              <Dialog
                isShown={showAddLineItem}
                title="Add New Line Item"
                onCloseComplete={() => {
                  setShowAddLineItem(false);
                }}
                isConfirmLoading={addLineItemLoading}
                confirmLabel="Confirm"
                onConfirm={addLineItem}
                isConfirmDisabled={!(newLineItem && reason)}
              >
                <div>
                  <div className="mb-4">
                    Choose Line Item:
                    <Select
                      id="selectLineItem"
                      onChange={(event) => setNewLineItem(event.value)}
                      options={productOptions}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      menuPortalTarget={document.body}
                    />
                  </div>
                  <div className="mb-4">
                    <Select
                      id="selectAddLineItemReason"
                      onChange={(option) => setReason(option)}
                      options={addLineItemReasons}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      menuPortalTarget={document.body}
                    />
                  </div>
                  <div>{addionalDetailInputForAddLineItem()}</div>
                  {NETSUITE_INVENTORY_FEATURE_FLAG !== 'true' && newLineItem !== null && (
                    <div className="mb-4">
                      <Checkbox
                        label={`Will the customer be charged for this addition?${newLineItem ? ` ($${newLineItem.price})` : ''}`}
                        checked={newLineItemIsCharged}
                        onChange={(e) => setNewLineItemIsCharged(e.target.checked)}
                      />
                    </div>
                  )}
                </div>
              </Dialog>
              <Dialog
                isShown={showConfirmRedelivery}
                title="Select Reasons for Redelivery"
                onCloseComplete={() => {
                  setShowConfirmRedelivery(false);
                }}
                confirmLabel="Confirm"
                onConfirm={createRedelivery}
                isConfirmDisabled={!reason && !Object.keys(lineItemReasons).length}
              >
                <div className="mb-4">
                  <div>Select a suborder redelivery reason</div>
                  <Select
                    id="selectRedeliveryReason"
                    onChange={(option) => setReason(option)}
                    options={redeliveryReasons}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                    isMulti
                  />
                </div>
                {reason && (
                  <TextInput
                    label="Provide any additional details for the reason:"
                    name="CustomInput"
                    value={additionalDetail}
                    onChange={(event) => {
                      setAdditionalDetail(event.target.value);
                    }}
                  />
                )}
                {suborder.lineItems.map((lineItem) => (
                  <React.Fragment key={lineItem.index}>
                    <div className="mb-4">
                      <div>
                        Select a redelivery reason for {lineItem.name} ({lineItem.sku})
                      </div>
                      <Select
                        id={`select-reason-${suborder.suborderId}-${lineItem.index}`}
                        onChange={(option) => handleSetLineItemReason(lineItem, option)}
                        options={lineItemRedeliveryReasons}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        menuPortalTarget={document.body}
                        isMulti
                      />
                    </div>
                    {lineItemReasons[lineItem.index] && (
                      <TextInput
                        label={`Provide any additional details for the reason (${lineItem.sku}):`}
                        name={`additional-detail-${lineItem.index}`}
                        value={lineItemIndexToDetail[lineItem.index]}
                        onChange={(event) => {
                          handleSetLineItemAdditionalDetail(lineItem, event.target.value);
                        }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </Dialog>
              <Dialog
                isShown={deleteLineItem !== null}
                title="Confirm Delete Line Item"
                onCloseComplete={resetDeleteAndRefundVariables}
                onCancel={resetDeleteAndRefundVariables}
                confirmLabel="Confirm"
                isConfirmLoading={removeLineItemLoading}
                onConfirm={() => removeLineItem(deleteLineItem)}
                isConfirmDisabled={!reason || (refundDeletedLineItem && !refundReason)}
              >
                <div className="mb-4">Select reason for deleting line item:</div>
                <div className="mb-4">
                  <Select
                    id="selectDeleteLineItemReason"
                    onChange={(option) => setReason(option)}
                    options={updateLineItemReasons}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                  />
                </div>
                <div className="mb-4">Provide any additional details for reason:</div>
                <div className="mb-4">
                  <TextInput
                    className="w-100"
                    name="additionalDetails"
                    value={additionalDetail}
                    onChange={(event) => {
                      setAdditionalDetail(event.target.value);
                    }}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  />
                </div>
                {deleteLineItem &&
                  (!suborder.saleLineItems || (deleteLineItem.saleLineItemIndex !== undefined && deleteLineItem.saleLineItemIndex >= 0)) && (
                    <>
                      <div className="mb-4">
                        <Checkbox
                          label={`Refund Line Item${deleteLineItem ? ` (Refund Amount: $${deleteLineItem.price})` : ''}`}
                          checked={refundDeletedLineItem}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setRefundDeletedLineItem(isChecked);
                            if (!isChecked) {
                              setRefundReason(null);
                            }
                          }}
                        />
                      </div>
                      {refundDeletedLineItem && getRefundLineItemDialogPortion()}
                    </>
                  )}
              </Dialog>
              <Dialog
                isShown={lineItemToRefund !== null}
                title={`Confirm Line Item Refund${lineItemToRefund ? ` (Refund Amount: $${lineItemToRefund.price})` : ''}`}
                onCloseComplete={resetDeleteAndRefundVariables}
                onCancel={resetDeleteAndRefundVariables}
                confirmLabel="Confirm"
                onConfirm={() => handleRefundLineItem(lineItemToRefund)}
                isConfirmDisabled={!refundReason}
              >
                {getRefundLineItemDialogPortion()}
              </Dialog>
              <Dialog
                isShown={showCreateException}
                title="Confirm Create Exception"
                onCloseComplete={() => {
                  setShowCreateException(false);
                }}
                confirmLabel="Confirm"
                onConfirm={() => createException()}
                isConfirmDisabled={!reason}
              >
                <div className="mb-4">
                  <CreatableSelect
                    id="createExceptionReason"
                    isClearable={false}
                    onChange={(event) => setReason(event.value)}
                    options={addExceptionReasons}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                  />
                </div>
              </Dialog>
              <Dialog
                isShown={showUpdateSuborderStatus}
                title="Update Suborder Status"
                onCloseComplete={() => {
                  setShowUpdateSuborderStatus(false);
                }}
                confirmLabel="Confirm"
                onConfirm={() => updateSuborderStatus()}
                isConfirmDisabled={!newStatus}
              >
                <Select
                  options={selectableSuborderStatuses}
                  name="UpdateSuborderStatus"
                  defaultValue={selectableSuborderStatuses.find((s) => s.value === suborder.status)}
                  onChange={(s) => setNewStatus(s.value)}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                />
              </Dialog>
              <Dialog
                isShown={showCancelSuborder}
                title="Cancel Suborder"
                onCloseComplete={() => {
                  setShowCancelSuborder(false);
                  setCancelSuborderUpdateTotals(false);
                  setCancelSuborderDeallocate('delete');
                  setReason(null);
                }}
                confirmLabel="Confirm"
                onConfirm={handleCancelSuborder}
                isConfirmDisabled={!(reason && cancelSuborderDeallocate)}
              >
                <div>
                  {NETSUITE_INVENTORY_FEATURE_FLAG && (
                    <div className="mb-4">
                      What should we do with the inventory?
                      <Select
                        id="selectDallocateType"
                        defaultValue={deallocateStrategies.find((x) => x.value === cancelSuborderDeallocate)}
                        onChange={(event) => setCancelSuborderDeallocate(event.value)}
                        options={deallocateStrategies}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        menuPortalTarget={document.body}
                      />
                    </div>
                  )}
                  <div className="mb-4">
                    <Checkbox
                      label="Subtract the price of these items from order"
                      checked={cancelSuborderUpdateTotals}
                      onChange={(e) => setCancelSuborderUpdateTotals(e.target.checked)}
                    />
                    Select reason for deleting suborder:
                  </div>
                  <div className="mb-4">
                    <Select
                      id="selectDeleteSuborderReason"
                      onChange={(option) => setReason(option)}
                      options={cancelReasons}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      menuPortalTarget={document.body}
                    />
                  </div>
                  <div className="mb-4">Provide any additional details for reason:</div>
                  <div className="mb-4">
                    <TextInput
                      className="w-100"
                      name="additionalDetails"
                      value={additionalDetail}
                      onChange={(event) => {
                        setAdditionalDetail(event.target.value);
                      }}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    />
                  </div>
                </div>
              </Dialog>
              <Dialog
                isShown={showRemoveExceptionDialog}
                title="Clear Exception"
                onCloseComplete={() => {
                  setShowRemoveExceptionDialog(false);
                }}
                confirmLabel="Confirm"
                onConfirm={removeException}
              >
                <div>
                  This suborder has a delivery date of {DateTime.fromISO(suborder.delivery.startTime).toFormat('f')} and will not be fulfilled. Are
                  you sure you want to clear exception?
                </div>
              </Dialog>

              {showUpdateRecipient && (
                <Recipient
                  order={order}
                  suborders={[suborder]}
                  recipient={suborder.recipient}
                  setOrder={() => {}}
                  edit
                  newOrder={false}
                  setRecipientFields={() => {}}
                  canUpdate
                />
              )}

              {showUpgradeLineItem && (
                <UpgradeLineItem
                  suborderDetails={[suborderDetails(suborder)]}
                  oldLineItem={lineItemToReplace}
                  fcId={suborder.delivery.fc.fcId}
                  fulfillmentDate={suborder.delivery.orderFulfilledBy}
                  showUpgradeLineItem={showUpgradeLineItem}
                  setShowUpgradeLineItem={setShowUpgradeLineItem}
                  onComplete={() => {
                    queryCache.invalidateQueries(['orders', suborder.orderId]);
                    setShowUpgradeLineItem(false);
                  }}
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="card">
                <div className="py-0 card-body">
                  <div className="list-group list-group-flush">
                    {flatLineItems.map((lineItem, i) => (
                      <div className={`list-group-item ${lineItem.isDeleted ? 'disabled' : ''}`} key={i}>
                        <div className="row align-items-center">
                          <div className="col-auto">
                            {lineItem.images && (
                              <div className="avatar avatar-lg avatar-4by3">
                                <img src={lineItem.images.large} alt="..." className="rounded avatar-img" />
                              </div>
                            )}
                          </div>
                          <div className="col ml-n2">
                            <h4 className={`mb-1 name ${lineItem.isDeleted ? 'text-decoration-line-through' : ''}`}>
                              {lineItem.name} {getOpsNameText(productFeed, lineItem)} <span className="text-muted">{lineItem.sku}</span>
                            </h4>
                            {lineItem.components && lineItem.components.length && (
                              <p className="card-text small">
                                Includes:
                                {lineItem.components.map((component) => (
                                  <React.Fragment key={component.sku}>
                                    <br />
                                    {component.quantity}x {component.name} {getOpsNameText(productFeed, component)}
                                    <span className="text-muted">{component.sku}</span>
                                  </React.Fragment>
                                ))}
                              </p>
                            )}
                          </div>
                          {!lineItem.isDeleted && lineItem.inventoryStatus && LINE_ITEM_INVENTORY_STATUS_MAP[lineItem.inventoryStatus] && (
                            <div className="col-auto">
                              <Badge color={LINE_ITEM_INVENTORY_STATUS_MAP[lineItem.inventoryStatus].color}>
                                {LINE_ITEM_INVENTORY_STATUS_MAP[lineItem.inventoryStatus].text}
                              </Badge>
                            </div>
                          )}
                          {allowRemoveLineItem && !lineItem.isDeleted && (
                            <>
                              {roleChecks.canUpgradeLineItems(userGroups) && (
                                <div className="col-auto">
                                  <a
                                    href="#!"
                                    className="btn btn-sm btn-white d-inline-block"
                                    onClick={() => {
                                      setLineItemToReplace(lineItem);
                                      setShowUpgradeLineItem(true);
                                    }}
                                  >
                                    Forced Upgrade
                                  </a>
                                </div>
                              )}
                              <div className="col-auto">
                                {lineItem.isRefunded ? (
                                  <span>Refunded</span>
                                ) : shouldAllowForDeletionInsteadOfJustRefund ? (
                                  <a href="#!" className="btn btn-sm btn-white d-inline-block" onClick={() => setDeleteLineItem(lineItem)}>
                                    Delete
                                  </a>
                                ) : !suborder.saleLineItems || (lineItem.saleLineItemIndex !== undefined && lineItem.saleLineItemIndex >= 0) ? (
                                  <a href="#!" className="btn btn-sm btn-white d-inline-block" onClick={() => setLineItemToRefund(lineItem)}>
                                    Refund
                                  </a>
                                ) : (
                                  // eslint-disable-next-line react/jsx-no-useless-fragment
                                  <></>
                                )}
                              </div>
                            </>
                          )}
                          {lineItem.isDeleted && lineItem.isRefunded && <div className="col-auto">Refunded</div>}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="text-center card-body">
                {allowAddLineItem && (
                  <div
                    className="card card-inactive"
                    style={{ cursor: 'pointer' }}
                    onKeyUp={() => {}}
                    tabIndex={0}
                    onMouseOver={(e) => {
                      e.currentTarget.className = 'card';
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.className = 'card card-inactive';
                    }}
                    role="button"
                    onClick={() => setShowAddLineItem(true)}
                    onFocus={() => {}}
                    onBlur={() => {}}
                  >
                    <div className="text-center card-body">
                      <PlusCircle size="2em" /> <span className="h4">Add a new line item</span>
                    </div>
                  </div>
                )}
              </div>

              {adjustments && adjustments.length > 0 && (
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-header-title">Adjustments:</h4>
                  </div>
                  <div className="py-2 card-body">
                    {adjustments.map((a, i) => (
                      <div className="row" key={a.adjustmentId}>
                        <p className="card-text small">Product Name: {a.productName}</p>
                        <p className="card-text small">SKU: {a.productSku}</p>
                        <p className="card-text small">Adustment Quantity: {a.adjustmentQuantity}</p>
                        <a href={`/inventory/${a.shipmentId}#Adjustments`} target="_blank" rel="noopener noreferrer">
                          Shipment: {a.shipmentId}
                        </a>
                        <p>
                          {a.forStock && <span className="mx-2 badge bg-info">Claimed</span>}
                          {a.forCount && <span className="mx-2 badge bg-info">Cleared</span>}
                        </p>
                        {i !== adjustments.length - 1 && <hr />}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="col-auto px-0 py-5 mx-0 d-none d-md-block">
              <ArrowRightShort size="2em" className="text-muted" />
            </div>
            <div className="col">
              <Delivery
                order={order}
                suborder={suborder}
                delivery={suborder.delivery}
                setOrder={setOrder}
                queryCache={queryCache}
                reprintAction={() => printDeliveryLabel(suborder, () => {}, setShowCancelButton, allFcsConfig)}
              />
            </div>
          </div>
        </>
      )}
    </LookupContext.Consumer>
  );
};

Suborder.propTypes = {
  order: PropTypes.object.isRequired,
  suborder: PropTypes.object.isRequired,
  setOrder: PropTypes.func.isRequired,
  queryCache: PropTypes.object.isRequired,
  adjustments: PropTypes.array.isRequired,
  setShowAuditLog: PropTypes.func.isRequired,
  setDefaultAuditLogSelect: PropTypes.func.isRequired,
};

export default Suborder;
