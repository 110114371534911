import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Dialog } from 'evergreen-ui';

import { getAuditLog } from '../../services/OrderManagementService';

import AuditLogEntryDetailsExpanded from './AuditLogEntryDetailsExpanded';

// "ORDER", "1234", {ORDER}, true, func
const AuditLog = ({ entityType, id, entity, showAuditLog, setShowAuditLog, defaultSelect, setDefaultAuditLogSelect }) => {
  const [auditLog, setAuditLog] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [entityOptions, setEntityOptions] = useState(null);
  const [selectEntity, setSelectEntity] = useState(defaultSelect, 'order');
  const [filterAuditLog, setFilterAuditLog] = useState(null);
  const [expand, setExpand] = useState(null); // audit log entry index or null

  useEffect(() => {
    const fetchAuditLog = async (entityType, id) => {
      const result = await getAuditLog(entityType, id);

      if (result.success && result.auditLogEntries) {
        // Order by timestamp in reverse chronological order
        const auditLog = result.auditLogEntries;
        auditLog.sort((a, b) => {
          if (DateTime.fromISO(a.timestamp) < DateTime.fromISO(b.timestamp)) {
            return 1;
          }

          if (DateTime.fromISO(a.timestamp) > DateTime.fromISO(b.timestamp)) {
            return -1;
          }

          return 0;
        });

        setAuditLog(auditLog);
      } else {
        setErrorMessage('Could not fetch audit log');
      }
    };

    if (showAuditLog && entityType && id) {
      fetchAuditLog(entityType, id);
    }
  }, [entityType, id, showAuditLog]);

  // filter audit log entries by entityId
  useEffect(() => {
    if (auditLog && selectEntity && selectEntity !== 'order') {
      const filtered = auditLog.filter((e) => {
        return e.entityId === selectEntity;
      });
      setFilterAuditLog(filtered);
    } else {
      setFilterAuditLog(auditLog);
    }
  }, [auditLog, selectEntity]);

  useEffect(() => {
    if (entityType === 'ORDER') {
      const order = entity;

      if (order && order.orderId && order.suborders) {
        let options = [];
        options = order.suborders.map((s) => {
          return { value: s.suborderId, label: `SUBORDER ${s.suborderId}` };
        });
        options.unshift({ value: 'order', label: `ORDER ${order.orderId}` });
        setEntityOptions(options);
      }
    } else {
      const options = [];
      options.push({ value: id, label: `${entityType} ${id}` });
      setEntityOptions(options);
    }
  }, [entity, entityType, id]);

  // update selected entity (suborder)
  useEffect(() => {
    if (entityOptions) {
      if (defaultSelect) {
        setSelectEntity(entityOptions.find((o) => o.value === defaultSelect).value);
      } else {
        setSelectEntity(entityOptions.find((o) => o.value === 'order').value);
      }
    }
  }, [defaultSelect, entityOptions]);

  return (
    <Dialog
      isShown={showAuditLog}
      title="Audit Log"
      onCloseComplete={() => {
        setShowAuditLog(false);
        setSelectEntity('order');
        setDefaultAuditLogSelect(null);
        setExpand(null);
      }}
      hasClose={false}
      confirmLabel="Close"
      hasCancel={false}
      shouldCloseOnOverlayClick={false}
      width="1000px"
    >
      <div>
        {errorMessage && (
          <div className="alert alert-danger" role="alert">
            {errorMessage}
          </div>
        )}

        {entityOptions && !errorMessage && (
          <div className="mb-4">
            Select entity to view audit log for:
            <Select
              id="selectSubordersToView"
              onChange={(option) => setSelectEntity(option.value)}
              options={entityOptions}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              defaultValue={
                selectEntity === 'order' ? entityOptions.find((o) => o.value === 'order') : entityOptions.find((o) => o.value === selectEntity)
              }
            />
          </div>
        )}
        <div className="table-responsive">
          <table className="table table-sm table-hover card-table">
            <thead>
              <tr>
                <th>Entity</th>
                <th>Timestamp</th>
                <th>Performed By</th>
                <th>Event</th>
              </tr>
            </thead>
            <tbody className="list font-size-base">
              {filterAuditLog &&
                !errorMessage &&
                filterAuditLog.map((e, i) => {
                  return (
                    <React.Fragment key={i}>
                      <tr className="cursor-pointer">
                        <td>
                          <span className="text-break">{e.entityType}</span> <span>{e.entityId}</span>
                        </td>
                        <td>
                          <span className="text-break">{DateTime.fromISO(e.timestamp).toLocaleString(DateTime.DATETIME_SHORT)}</span>
                        </td>
                        <td>
                          <span>{e.performedBy}</span>
                        </td>
                        <td className="d-flex flex-column align-items-start">
                          <span className="text-break">{e.actionPerformed}</span>
                          {expand !== i && (
                            <a href="#!" onClick={() => setExpand(i)} className="btn btn-link" style={{ fontSize: '13px' }}>
                              See full details
                            </a>
                          )}
                        </td>
                      </tr>
                      {expand === i && <AuditLogEntryDetailsExpanded index={i} expand={expand} setExpand={setExpand} data={e.actionData} />}
                    </React.Fragment>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </Dialog>
  );
};

AuditLog.propTypes = {
  entityType: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  entity: PropTypes.object.isRequired,
  showAuditLog: PropTypes.bool.isRequired,
  setShowAuditLog: PropTypes.func.isRequired,
  defaultSelect: PropTypes.string.isRequired,
  setDefaultAuditLogSelect: PropTypes.func.isRequired,
};

export default AuditLog;
