/**
 * This is a copy from src/components/order/ChargeOrderForm.js @ Line 8 - 14
 * Made some adjustments
 * @param {order.paymentSummary}
 * @returns string dollar
 */
export const getOutstandingBalance = ({ paymentSummary }) => paymentSummary.outstandingBalance;

export const outstandingBalanceDisplay = (amount) => {
  return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};
