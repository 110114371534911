import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';

const DatePicker = ({ date, setDate }) => {
  return (
    <Flatpickr
      value={date}
      onChange={(d) => {
        setDate(new Date(d[0].getTime() - d[0].getTimezoneOffset() * 60000).toISOString().split('T')[0]);
      }}
    />
  );
};

export default DatePicker;
