import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import Email from '../common/Email';

const ForgotPassword = () => {
  const [username, setUsername] = useState('');
  const [validUsername, setValidUsername] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [forgettingPassword, setForgettingPassword] = useState(false);
  const history = useHistory();
  const validate = () => validUsername && !forgettingPassword;

  const changeInput = (event) => {
    setUsername(event.target.value);
    setValidUsername(event.target.valid);
  };

  const forgetPassword = async (event) => {
    event.preventDefault();
    if (!validate()) {
      return;
    }
    try {
      setForgettingPassword(true);
      await Auth.forgotPassword(username);
      setSuccessMessage('Email sent!');
      setTimeout(() => {
        setSuccessMessage('');
        history.push('/');
      }, 3000);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Unable to process forget password', e);
      setErrorMessage(e.message);
      setTimeout(() => setErrorMessage(''), 3000);
    } finally {
      setForgettingPassword(false);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-5 col-lg-6 col-xl-4 vh-100 px-lg-6 py-5 overflow-auto">
          <h1 className="display-4 text-center mb-3">Forgot Password</h1>
          <form>
            <div className="form-group">
              <Email onChange={changeInput} value={username} tabIndex={1} />
            </div>
            <button className="btn btn-lg btn-block btn-primary mb-3" onClick={forgetPassword} disabled={!validate()} tabIndex="2">
              Submit
            </button>

            <div className="text-danger">{errorMessage}</div>
            <div className="text-success">{successMessage}</div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
