const getOpsName = (lineItem, productFeed) => {
  const productFromFeed = productFeed[lineItem.sku];
  if (productFromFeed && productFromFeed.opsName && productFromFeed.opsName.length) {
    return productFromFeed.opsName;
  }

  return lineItem.name;
};

export const applyOpsNamesToLineItemsAndComponentsInSuborder = (suborder, productFeed) => {
  for (const lineItem of suborder.lineItems) {
    lineItem.opsName = getOpsName(lineItem, productFeed);
    if (lineItem.components) {
      for (const component of lineItem.components) {
        component.opsName = getOpsName(component, productFeed);
      }
    }
  }
};
