import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTable, usePagination, useRowSelect, useFilters, useSortBy } from 'react-table';
import { useQueryCache } from 'react-query';
import IndeterminateCheckbox from '../../common/tableHelpers/IndeterminateCheckbox';
import { DefaultColumnFilter, SelectColumnFilter, fuzzyTextFilterFn } from '../../common/tableHelpers/Filters';
import { LookupContext } from '../../../context/lookupContext';
import { DateTime } from 'luxon';
import BulkSuborderActionModal from '../common/BulkSuborderActionModal';
import { styleSuborderStatus } from '../../common/suborderHelpers/styleSuborderStatus';
import { ExclamationTriangleFill } from 'react-bootstrap-icons';
import getProductTypeFromClassificationOrCategory from '../../helpers/getProductTypeFromClassificationOrCategory';

const BatchSuborders = ({ fcId, fcConfig, batch, suborders }) => {
  const [data, setData] = React.useState([]);
  const queryCache = useQueryCache();

  const { productFeed, deliveryAreas } = useContext(LookupContext);

  function computeWindow({ startTime, windowDuration }) {
    const startDateTime = DateTime.fromISO(startTime);
    const endDateTime = startDateTime.plus({ hours: windowDuration });
    return `${startDateTime.toFormat('hh:mm a')} - ${endDateTime.toFormat('hh:mm a')}`;
  }

  function computeProductsAndType(lineItems) {
    let skuQuantities = {},
      skuNames = {};
    lineItems.forEach((lineItem) => {
      const { sku, quantity, name, components } = lineItem;
      if (!lineItem.isDeleted) {
        if (components && components.length) {
          const product = productFeed[sku];
          const includesFloral = product?.classification === 'Floral - Live';
          components.forEach((component) => {
            const { sku: subSku, quantity: subQuantity, name: subName } = component;
            skuQuantities[subSku] = (skuQuantities[subSku] || 0) + subQuantity * quantity;
            skuNames[subSku] = subName;
          });
        } else {
          skuQuantities[sku] = (skuQuantities[sku] || 0) + quantity;
          skuNames[sku] = name;
        }
      }
    });

    const skus = Object.keys(skuQuantities);
    const products = [];
    const types = {};
    for (const sku in skuQuantities) {
      if (sku.indexOf('CRDISL') < 0) {
        const product = productFeed[sku];
        products.push(`${skuQuantities[sku]}x ${(product && product.opsName) || skuNames[sku]}`);
        if (product) {
          const type = getProductTypeFromClassificationOrCategory(product);
          if (type) {
            types[type] = true;
          }
        }
      }
    }
    let type = 'Other';
    const typeArray = Object.keys(types);
    const sortingArray = ['Floral', 'Plant', 'Dried/Wreath', 'Vase', 'Addon'];

    if (typeArray.length) {
      if (typeArray.length === 1 && typeArray.includes('Floral')) {
        type = `${typeArray[0]} Only`;
      } else {
        typeArray.sort((a, b) => {
          return sortingArray.indexOf(a) - sortingArray.indexOf(b);
        });
        type = typeArray.join(' & ');
      }
    }
    // remove dangling line break
    return { skus, products: products.join(','), type };
  }

  useEffect(() => {
    const sos = suborders.map((suborder) => ({
      suborderId: suborder.suborderId,
      deliveryArea: suborder.delivery.areaId,
      deliveryAreaName: deliveryAreas[suborder.delivery.areaId]?.description || suborder.delivery.areaId,
      status: suborder.status,
      lineItems: suborder.lineItems,
      window: computeWindow(suborder.delivery),
      batchId: suborder.batchId === 'undefined' || !suborder.batchId ? '' : suborder.batchId,
      inException: !!suborder.inException,
      startTime: suborder.delivery.startTime,
      ...computeProductsAndType(suborder.lineItems),
    }));
    setData(sos);
    let hiddenColumns = ['batchId', 'inException'];
    if (!fcConfig.showWindows) {
      hiddenColumns.push('window');
    }
    setHiddenColumns(hiddenColumns);
  }, [suborders]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Suborder #',
        accessor: 'suborderId',
        Cell: ({ value, row }) => {
          let splitVal = value.split('-');
          const orderId = splitVal.shift();
          const inException = row.original.inException;
          return (
            <>
              <Link to={{ pathname: `/orders/${orderId}` }}>{orderId}</Link>-{splitVal.join('-')}
              {inException && <ExclamationTriangleFill size="1em" fill="#E63757" style={{ padding: '0 0 2px 2px' }} />}
            </>
          );
        },
      },
      {
        Header: 'Batch',
        accessor: 'batchId',
        filter: 'equals',
      },
      {
        Header: 'Products',
        accessor: 'products',
        Filter: SelectColumnFilter,
        filter: 'includes',
        Cell: ({ value }) => {
          const lineBreaked = value.split(',').join('<br />');
          return <div dangerouslySetInnerHTML={{ __html: lineBreaked }} />;
        },
      },
      {
        Header: 'Window',
        accessor: 'window',
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: 'Delivery Area',
        accessor: 'deliveryAreaName',
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: 'Type',
        accessor: 'type',
        Filter: SelectColumnFilter,
        filter: 'includes',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: SelectColumnFilter,
        filter: 'equals',
        Cell: ({ value }) => <span className={`${styleSuborderStatus(value)}`}>{value}</span>,
      },
      {
        Header: 'In Exception',
        accessor: 'inException',
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
    ],
    [],
  );

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase()) : true;
        });
      },
    }),
    [],
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    pageOptions,
    gotoPage,
    toggleAllRowsSelected,
    initialRows,
    setPageSize,
    setHiddenColumns,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    { columns, data, defaultColumn, filterTypes, initialState: { hiddenColumns: ['batchId', 'inException'], filters: [], pageSize: 50 } },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div className="form-check mb-n2">
              <IndeterminateCheckbox className="form-check-input list-checkbox-all" {...getToggleAllRowsSelectedProps()} />
              <label className="form-check-label"></label>
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div className="form-check">
              <IndeterminateCheckbox className="form-check-input list-checkbox" {...row.getToggleRowSelectedProps()} />
              <label className="form-check-label"></label>
            </div>
          ),
        },
        ...columns,
      ]);
    },
  );

  return (
    <div className="card">
      <div className="card-header">
        <div className="row align-items-center">
          <div className="col-auto">
            <h4 className="card-header-title">Suborders in batch</h4>
          </div>
          <div className="col-auto">
            <form>
              <select
                className="form-select form-select-sm form-control-flush"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize} per page
                  </option>
                ))}
              </select>
            </form>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table {...getTableProps()} className="table table-sm table-hover table-nowrap card-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    <div>
                      <span {...column.getSortByToggleProps()}>
                        {column.render('Header')}
                        {/* Add a sort direction indicator */}
                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                      </span>
                    </div>
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                    {/* <a className="list-sort text-muted" data-sort="item-name" href="#">Suborder #</a> */}
                  </th>
                ))}
                <th></th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="list font-size-base">
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                  <td></td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="card-footer d-flex justify-content-between">
        <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
          <li className="page-item">
            <button
              className={`page-link pl-0 pr-4 border-right fade ${canPreviousPage && 'show'}`}
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              <i className="mr-1 fe fe-arrow-left"></i> Prev
            </button>
          </li>
        </ul>

        <ul className="overflow-auto list-pagination pagination pagination-tabs card-pagination">
          {pageOptions.map((p, i) => (
            <li key={i} className={p === pageIndex ? 'active' : ''}>
              <span className="page" onClick={() => gotoPage(p)}>
                {p + 1}
              </span>
            </li>
          ))}
        </ul>

        <ul className="list-pagination-next pagination pagination-tabs card-pagination">
          <li className="page-item">
            <button className={`page-link pl-4 pr-0 border-left fade ${canNextPage && 'show'}`} onClick={() => nextPage()} disabled={!canNextPage}>
              Next <i className="ml-1 fe fe-arrow-right"></i>
            </button>
          </li>
        </ul>

        <BulkSuborderActionModal
          fcId={fcId}
          batchId={batch.batchId}
          selectedRowIds={selectedRowIds}
          selectedFlatRows={initialRows.filter((row) => row.isSelected)}
          toggleAllRowsSelected={toggleAllRowsSelected}
          queryCache={queryCache}
          deliveryAreas={deliveryAreas}
          fcConfig={fcConfig}
          fulfillmentDate={batch.fulfillmentDate || DateTime.now().toISO()}
        />
      </div>
    </div>
  );
};

BatchSuborders.propTypes = {
  batch: PropTypes.object.isRequired,
  suborders: PropTypes.array.isRequired,
};

export default BatchSuborders;
