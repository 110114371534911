import { datadogRum } from '@datadog/browser-rum';
import { ENVIRONMENT_NAME, DATADOG_APPLICATION_ID, DATADOG_CLIENT_TOKEN } from './config';

const config = {
  prod: {
    applicationId: DATADOG_APPLICATION_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'dash.urbanstems.com',
    env: 'prod',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  },
  staging: {
    applicationId: DATADOG_APPLICATION_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'dash.urbanstems.com',
    env: 'staging',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  },
};

const configToUse = ENVIRONMENT_NAME === 'prod' ? config.prod : (ENVIRONMENT_NAME === 'staging' ? config.staging : null);

export const initializeDatadogRUM = () => {
  datadogRum.init(configToUse);
  datadogRum.startSessionReplayRecording();
};
