/* eslint-disable no-console */
import React, { useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'evergreen-ui';
import Progress from '../../common/Progress';
import { removeSubordersFromBatch } from '../../../services/OrderManagementService';
import 'flatpickr/dist/flatpickr.css';

const BatchRemoveSuborderModal = ({ visible, onDone, batchId, suborders }) => {
  const [totalToSave, setTotalToSave] = useState(0);
  const [saveFailures, setSaveFailures] = useState([]);
  const [totalSaved, totalSavedDispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'increment':
        return state + 1;
      case 'reset':
        return 0;
      default:
    }
  }, 0);

  const performRemoveSubordersFromBatch = (close, suborders) => (
    new Promise(() => {
      let countdown = suborders.length;
      const failures = [];
      const suborderIds = suborders.map((suborder) => suborder.suborderId);
      return removeSubordersFromBatch(batchId, suborderIds)
        .then((res) => {
          for (const [suborderId, value] of Object.entries(res.subordersRemoved)) {
            totalSavedDispatch({ type: 'increment' });
            countdown--;
            if (value !== true) {
              failures.push({ id: suborderId, value });
            }
          }
          if (!res.success) {
            failures.push({ id: batchId, ...res });
          }
        }).catch((err) => {
          console.error(err);
          failures.push({ id: batchId, error: err.message, success: false });
        }).finally(() => {
          if (!countdown) {
            if (failures.length) {
              setSaveFailures(failures);
            }
            setTimeout(() => {
              setTotalToSave(0);
              totalSavedDispatch({ type: 'reset' });
              if (!failures.length) {
                close();
              }
            }, 2000);
          }
        });
    })
  );

  const onConfirm = async (close) => {
    // nothing to save or save already in progress
    if (!suborders.length || totalToSave) {
      return;
    }

    setSaveFailures([]);
    setTotalToSave(suborders.length);

    performRemoveSubordersFromBatch(close, suborders);
  };

  return (
    <Dialog
      isShown={visible}
      title="Bulk Remove From Batch"
      confirmLabel="Confirm Remove"
      onCloseComplete={onDone}
      onConfirm={onConfirm}
    >
      Suborders: {suborders.map((s) => s.suborderId).join(', ')}
      {
        (totalToSave > 0) &&
        <Progress percentage={Math.ceil(totalSaved / totalToSave) * 100} />
      }

      {
        (totalToSave > 0) &&
        (totalToSave === totalSaved) &&
        (!saveFailures.length) &&
        <p className="text-success">Done!</p>
      }

      {
        (!!saveFailures.length) &&
        (
          <div>
            <p className="text-danger mt-4">Update Failures</p>
            <ul>
              {saveFailures.map((f) => <li className="text-danger">{f.id}</li>)}
            </ul>
          </div>
        )
      }
    </Dialog>
  );
};

BatchRemoveSuborderModal.propTypes = {
  visible: PropTypes.bool,
  onDone: PropTypes.func.isRequired,
  fieldName: PropTypes.string,
  inputType: PropTypes.oneOf(['datetime', 'text', 'number', 'status', 'deliveryArea']),
  suborders: PropTypes.arrayOf(PropTypes.shape({
    batchId: PropTypes.string,
    deliveryArea: PropTypes.number,
    lineItems: PropTypes.array,
    products: PropTypes.string,
    sku: PropTypes.arrayOf(PropTypes.string),
    status: PropTypes.string,
    suborderId: PropTypes.string,
    type: PropTypes.string,
    window: PropTypes.string,
  })),
};

BatchRemoveSuborderModal.defaultProps = {
  visible: false,
  fieldName: '',
  inputType: 'text',
  suborders: [],
};

export default BatchRemoveSuborderModal;
