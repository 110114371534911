/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateSuborderException = /* GraphQL */ `
  subscription OnCreateSuborderException(
    $filter: ModelSubscriptionSuborderExceptionFilterInput
  ) {
    onCreateSuborderException(filter: $filter) {
      id
      fcId
      createdAt
      updatedAt
      deliveryDate
      fulfillmentDate
      exceptionReason
      sku
      exceptionType
      orderType
    }
  }
`;
export const onUpdateSuborderException = /* GraphQL */ `
  subscription OnUpdateSuborderException(
    $filter: ModelSubscriptionSuborderExceptionFilterInput
  ) {
    onUpdateSuborderException(filter: $filter) {
      id
      fcId
      createdAt
      updatedAt
      deliveryDate
      fulfillmentDate
      exceptionReason
      sku
      exceptionType
      orderType
    }
  }
`;
export const onDeleteSuborderException = /* GraphQL */ `
  subscription OnDeleteSuborderException(
    $filter: ModelSubscriptionSuborderExceptionFilterInput
  ) {
    onDeleteSuborderException(filter: $filter) {
      id
      fcId
      createdAt
      updatedAt
      deliveryDate
      fulfillmentDate
      exceptionReason
      sku
      exceptionType
      orderType
    }
  }
`;
