/* eslint-disable no-console */
import React, { useState, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'evergreen-ui';
import Select from 'react-select';
import { AuthContext } from '../../../context/authContext';
import Progress from '../../common/Progress';

import { updateSuborder } from '../../../services/OrderManagementService';
import { snakeCaseToNormal } from '../../../utilities/Strings';
import { roleChecks } from '../../../utilities/Role';
import { LookupContext } from '../../../context/lookupContext';
import 'flatpickr/dist/flatpickr.css';

const BatchUpdateModal = ({ visible, onDone, createSuborder, fieldName, suborders, fcId }) => {
  const [value, setValue] = useState('');
  const [totalToSave, setTotalToSave] = useState(0);
  const [saveFailures, setSaveFailures] = useState([]);
  const [totalSaved, totalSavedDispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'increment':
        return state + 1;
      case 'reset':
        return 0;
      default:
    }
  }, 0);

  const { userGroups } = useContext(AuthContext);
  const canSetAny = roleChecks.canSetAnySuborderStatus(userGroups);

  const suborderStatuses = [
    { value: 'Received', label: 'Received', isSelectable: true },
    { value: 'AtTable', label: 'At Table', isSelectable: true },
    { value: 'SuborderPrinted', label: 'Suborder Printed', isSelectable: true },
    { value: 'AwaitingPickup', label: 'Awaiting Pickup', isSelectable: true },
    { value: 'LeftFulfillmentCenter', label: 'Left Fulfillment Center', isSelectable: canSetAny },
    { value: 'InTransit', label: 'In Transit', isSelectable: true },
  ];
  const selectableSuborderStatuses = suborderStatuses.filter((status) => status.isSelectable);

  const { deliveryAreas } = useContext(LookupContext);

  const currentFcId = Number(fcId);
  const deliveryAreaOptions = [];

  for (const area in deliveryAreas) {
    if (deliveryAreas[area].fcId === currentFcId && deliveryAreas[area].isActive === true) {
      deliveryAreaOptions.push({ value: deliveryAreas[area].id, label: deliveryAreas[area].description });
    }
  }

  const updateBatch = (close, value, createSuborder, suborders) => new Promise((resolve, reject) => {
    const newSuborder = createSuborder(value);
    if (!newSuborder) {
      return;
    }

    let countdown = suborders.length;
    const failures = [];
    /* eslint-disable no-loop-func */
    for (const suborder of suborders) {
      new Promise((resolve, reject) => updateSuborder(suborder.suborderId, newSuborder)
        .then((res) => {
          if (!res.success) {
            failures.push({ id: suborder.suborderId, ...res });
          }
        })
        .catch((err) => {
          console.error(err);
          failures.push({ id: suborder.suborderId, error: err.message, success: false });
        })
        .finally(() => {
          totalSavedDispatch({ type: 'increment' });
          countdown--;
          if (!countdown) {
            if (failures.length) {
              setSaveFailures(failures);
            }
            setTimeout(() => {
              setTotalToSave(0);
              totalSavedDispatch({ type: 'reset' });
              if (!failures.length) {
                close();
              }
            }, 2000);
          }
        }));
    }
    /* eslint-enable no-loop-func */
  });

  const onConfirm = async (close) => {
    // nothing to save or save already in progress
    if (!suborders.length || totalToSave) {
      return;
    }

    setSaveFailures([]);
    setTotalToSave(suborders.length);

    updateBatch(close, value, createSuborder, suborders);
  };

  return (
    <Dialog isShown={visible} title="Bulk Update" confirmLabel="Update" onCloseComplete={onDone} onConfirm={onConfirm}>
      Suborders: {suborders.map((s) => s.suborderId).join(', ')}
      <form className="mt-4">
        <div className="form-group">
          <label className="form-label">New {snakeCaseToNormal(fieldName.toLowerCase())}</label>

          {fieldName === 'STATUS' && (
            <Select
              options={selectableSuborderStatuses}
              name={fieldName}
              defaultValue={value}
              onChange={(v) => setValue(v.value)}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
            />
          )}
        </div>
      </form>
      {totalToSave > 0 && <Progress percentage={Math.ceil(totalSaved / totalToSave) * 100} />}
      {totalToSave > 0 && totalToSave === totalSaved && !saveFailures.length && <p className="text-success">Done!</p>}
      {!!saveFailures.length && (
        <div>
          <p className="text-danger mt-4">Update Failures</p>
          <ul>
            {saveFailures.map((f) => (
              <li className="text-danger">{f.id}</li>
            ))}
          </ul>
        </div>
      )}
    </Dialog>
  );
};

BatchUpdateModal.propTypes = {
  visible: PropTypes.bool,
  onDone: PropTypes.func.isRequired,
  createSuborder: PropTypes.func.isRequired,
  fieldName: PropTypes.string,
  suborders: PropTypes.arrayOf(
    PropTypes.shape({
      batchId: PropTypes.string,
      deliveryArea: PropTypes.number,
      lineItems: PropTypes.array,
      products: PropTypes.string,
      sku: PropTypes.arrayOf(PropTypes.string),
      status: PropTypes.string,
      suborderId: PropTypes.string,
      type: PropTypes.string,
      window: PropTypes.string,
    }),
  ),
};

BatchUpdateModal.defaultProps = {
  visible: false,
  fieldName: '',
  suborders: [],
};

export default BatchUpdateModal;
