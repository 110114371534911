import axios from 'axios';
import { toaster } from 'evergreen-ui';

import { createDelivery, getZplLabelForSuborder } from '../../../services/OrderManagementService';
import { ORDER_MANAGEMENT_SERVICE_BASE_URL } from '../../../config';

import { printZpl, printImage } from './ZebraPrint';
import isSuborderACdlOrder from '../suborderHelpers/isSuborderACdlOrder';

const imageCarriersLowercase = ['fedex', 'ups'];
const zplProxyBaseUrl = 'https://dash.urbanstems.com/zpl';

export const printDeliveryLabel = async (suborder, onSuccess, setShowCancelButton, allFcsConfig) => {
  const currentFcConfig = allFcsConfig[suborder.fcId] || {};

  if (suborder.delivery?.transportMode?.toLowerCase().includes('ups_worldease') && currentFcConfig.isUpsWorldeaseFc) {
    const response = await getZplLabelForSuborder(suborder.suborderId);
    if (response && response.data && response.data.label) {
      return printZpl([response.data.label], onSuccess);
    }
    toaster.warning('Failed to print label - bad response error.');
  }

  if (suborder.delivery && suborder.delivery.transportVendorDelivery) {
    if (suborder.delivery.transportVendorDelivery.name.toLowerCase() === 'onfleet') {
      if (!suborder.delivery.transportVendorDelivery.onfleetTaskId) {
        const response = await createDelivery(suborder.suborderId);
        if (response.success) {
          setShowCancelButton(true);
        } else {
          toaster.warning('Failed to send to onfleet');
        }
      }
      const localDeliveryLabelOptions = currentFcConfig.useCompactLocalLabels ? '?compact=true' : '';
      const { data } = await axios.get(
        `${ORDER_MANAGEMENT_SERVICE_BASE_URL}/suborders/${suborder.suborderId}/local-delivery-label${localDeliveryLabelOptions}`,
      );
      if (data) {
        return printZpl(data.data.localDeliveryLabelZpl, onSuccess);
      }
    } else if (suborder.delivery?.transportVendorDelivery?.name?.toLowerCase() === 'easypost') {
      let { delivery } = suborder;
      if (!suborder.delivery.transportVendorDelivery.easypostShipmentId) {
        const response = await createDelivery(suborder.suborderId);
        if (response.success) {
          setShowCancelButton(true);
          delivery = response.delivery;
        } else {
          toaster.warning('Failed to create label');
        }
      }
      const carrierName = delivery?.transportVendorDelivery?.carrierName?.toLowerCase();

      if (imageCarriersLowercase.includes(carrierName)) {
        return printImage([delivery.transportVendorDelivery.labelUrlPng], onSuccess);
      }
      if (isSuborderACdlOrder(suborder)) {
        if (currentFcConfig.useZplForCdlInGuidedFulfillment && delivery?.transportVendorDelivery?.easypostLabelUrl) {
          const labelUrl = delivery?.transportVendorDelivery?.labelUrlZpl ?? delivery.transportVendorDelivery.easypostLabelUrl;

          const zpl = await axios.get(`${zplProxyBaseUrl}?zplurl=${labelUrl}`, {
            headers: { Accept: '*/*' },
          });

          if (zpl.data) {
            return printZpl(zpl.data, onSuccess);
          }
        } else if (delivery?.transportVendorDelivery?.labelUrlPng) {
          return printImage([delivery.transportVendorDelivery.labelUrlPng], onSuccess);
        } else {
          toaster.warning('No label available to print for this CDL order');
        }
      } else {
        toaster.warning('Failed to print label - missing carrier error');
      }
    }
  }

  toaster.warning('Failed to print label - unknown error');

  return false;
};
