/* eslint-disable import/prefer-default-export */
/**
 * @param {string} value
 * @param {string} addMinus
 * @return {string}
 */
export const formattedValue = (value, addMinus) => {
  let result;
  if (typeof value === 'string') {
    try {
      if (value.charAt(0) === '$') {
        result = Number(value.substring(1)).toFixed(2);
      }
    } catch (err) {
      // ignore error and go with happy path
      result = value;
    }
  } else {
    try {
      result = Number(value).toFixed(2);
    } catch (err) {
      // ignore error and go with happy path
      result = value;
    }
    result = `$${result}`;
  }

  if (addMinus) {
    result = `${addMinus}${result}`;
  }
  return result;
};
