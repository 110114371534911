import React from 'react';
import PropTypes from 'prop-types';

const CellDeliveryArea = ({ row }) => (
  <div>
    <div>{row?.original?.description}</div>
    <div style={{ color: 'gray', fontSize: 'smaller' }}>{`Map ID: ${row?.original?.id} Map`}</div>
  </div>
);

CellDeliveryArea.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.number,
    }),
  }).isRequired,
};

export default CellDeliveryArea;
