import React, { useState } from 'react';

const DeliveryOptionSelectionComponent = ({ fcName, deliveryAreaName, window, isSelected, setIsSelected, onSelection = () => { } }) => {
  const toggle = () => {
    if (!isSelected) {
      setIsSelected(true);
      onSelection();
    }
  };

  return (
    <div className={'card' + (isSelected ? ' border-2 border-primary' : '') + ' mb-2 col-lg-6'} onClick={toggle}>
      <div className="card-body p-3">
        <div className="row align-items-center">
          <div className="col-auto">
            <input className="form-check-input" type="radio" checked={isSelected} onChange={() => { }} />
          </div>
          <div className='col'>
            <div className="row">
              {fcName}
            </div>
            <div className='row'>
              {deliveryAreaName}
            </div>
            <div className='row'>
              {window}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryOptionSelectionComponent;
