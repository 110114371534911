import React from 'react';
import { Badge, TickIcon } from 'evergreen-ui';
import PropTypes from 'prop-types';

const Nav = ({ totalSteps, currentStep, subordersUnableToBeMoved }) => {
  const stepTitles = subordersUnableToBeMoved.length > 0 ? ['Select Date', 'Remedy By Suborder', 'Summary'] : ['Select Date', 'Summary'];
  const steps = [];
  for (let i = 1; i <= totalSteps; i += 1) {
    const isActive = currentStep === i;
    const isComplete = currentStep > i;
    let badge = (
      <Badge color="neutral" marginRight={6}>
        {i}
      </Badge>
    );
    if (isActive) {
      badge = (
        <Badge color="blue" marginRight={6}>
          {i}
        </Badge>
      );
    } else if (isComplete) {
      badge = (
        <Badge color="green" marginRight={6} paddingX={1}>
          <TickIcon marginBottom={4} size={14} />
        </Badge>
      );
    }
    const title = stepTitles[i - 1];
    steps.push(
      <div
        key={`step-${i}`}
        className={`wizardnav-step d-inline-block ${isActive ? 'active' : ''}`}
        style={{ cursor: 'default', height: '64px', lineHeight: '64px' }}
      >
        {badge}
        <span style={{ color: isActive ? 'blue' : 'inherit' }}>{title}</span>
      </div>,
    );
  }

  return <div className="wizardnav d-flex justify-content-between mt-2 mb-2">{steps}</div>;
};

Nav.propTypes = {
  totalSteps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  subordersUnableToBeMoved: PropTypes.array.isRequired,
};

export default Nav;
