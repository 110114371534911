/* eslint-disable no-console */
import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTable, useFilters, useSortBy } from 'react-table';
import PageHeader from '../../nav/PageHeader';
import Filters from './Filters';
import { TextInputColumnFilter, fuzzyTextFilterFn, multiLineTextFilterFn } from '../../common/tableHelpers/Filters';
import { FcContext } from '../../../context/fcContext';
import './DeliveryTable.css';

const DeliveryTable = ({
  isModalOpen,
  title,
  data,
  columns,
  kind,
  showDatePicker,
  showFcPicker,
  handleRefresh,
  isRefreshFetching,
  lastRefreshed,
  showCreateEdit,
}) => {
  const [activeFilter, setActiveFilter] = useState('all');
  const [filteredData, setFilteredData] = useState([]);
  let activeText = 'active';
  let inactiveText = 'inactive';
  if (kind === 'deliveryWindow') {
    activeText = 'open';
    inactiveText = 'close';
  } else if (kind === 'deliveryArea') {
    activeText = 'active';
    inactiveText = 'inactive';
  }

  useEffect(() => {
    let filtered = data;
    if (activeFilter === activeText) {
      filtered = data.filter((item) => item.active);
    } else if (activeFilter === inactiveText) {
      filtered = data.filter((item) => !item.active);
    }
    setFilteredData(filtered);
  }, [activeFilter, data]);

  const handleFilterChange = (filter) => {
    setActiveFilter(filter);
    if (filter === activeText) {
      setFilteredData(data.filter((item) => item.active));
    } else if (filter === inactiveText) {
      setFilteredData(data.filter((item) => !item.active));
    } else {
      setFilteredData(data);
    }
  };

  const defaultColumn = useMemo(
    () => ({
      Filter: TextInputColumnFilter,
    }),
    [],
  );

  const filterActives = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      multiLineText: multiLineTextFilterFn,
    }),
    [],
  );

  const sortActives = useMemo(
    () => ({
      customSort: (rowA, rowB, columnId) => {
        if (rowA.original.active && !rowB.original.active) {
          return -1;
        }
        if (!rowA.original.active && rowB.original.active) {
          return 1;
        }
        // Use string comparison for the 'description' column or others
        const valueA = rowA.values[columnId] ? rowA.values[columnId].toLowerCase() : '';
        const valueB = rowB.values[columnId] ? rowB.values[columnId].toLowerCase() : '';
        return valueA.localeCompare(valueB);
      },
    }),
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: filteredData,
      defaultColumn,
      filterActives,
      sortActives,
      initialState: {
        hiddenColumns: ['active'],
      },
    },
    useFilters,
    useSortBy,
  );

  const renderTableRows = (rows) => {
    if (rows.length === 0) {
      return (
        <tr>
          <td colSpan={columns.length} style={{ textAlign: 'center' }}>
            {isRefreshFetching ? 'Fetching...' : 'No results'}
          </td>
        </tr>
      );
    }

    // Separate active and inactive rows
    const activeRows = [];
    const inactiveRows = [];

    rows.forEach((row) => {
      prepareRow(row);
      if (row.original.active || row.original.type === 'open') {
        activeRows.push(row);
      } else {
        inactiveRows.push(row);
      }
    });

    const renderRows = (rowList, status) => (
      <>
        <tr>
          <td colSpan={columns.length}>
            <div className={`section-header d-a-category-row-${status}`}>
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </div>
          </td>
        </tr>
        {rowList.map((row) => (
          <tr {...row.getRowProps()} key={row.id}>
            {row.cells.map((cell, index) => (
              <td style={{ position: 'relative' }} {...cell.getCellProps()} key={cell.column.id}>
                {index === 0 && <div className={`d-a-border-left-${status}`} />} {cell.render('Cell')}
              </td>
            ))}
          </tr>
        ))}
      </>
    );

    return (
      <>
        {(activeRows.length) ? renderRows(activeRows, activeText) : null}
        {(inactiveRows.length) ? renderRows(inactiveRows, inactiveText) : null}
      </>
    );
  };

  const capitalizeHeader = (header) => header.charAt(0).toUpperCase() + header.slice(1).toLowerCase();

  return (
    <FcContext.Consumer>
      {() => (
        <>
          <PageHeader
            backgroundColorClass="bg-navy"
            borderBottom={0}
            category="Outbound Delivery"
            title={title}
            showDatePicker={showDatePicker}
            showFcPicker={showFcPicker}
          />
          <div className="container-fluid mt-n6" style={{ marginTop: '-10px', position: 'relative', top: '-39px', width: '98%' }}>
            <div className="row">
              <div className="col-12 p-0">
                <div style={{ padding: '10px 0px 0px 0px', background: 'white' }}>
                  <Filters
                    isModalOpen={isModalOpen}
                    kind={kind}
                    isRefreshFetching={isRefreshFetching}
                    activeFilter={activeFilter}
                    handleFilterChange={handleFilterChange}
                    lastRefreshed={lastRefreshed}
                    onRefresh={handleRefresh}
                    data={data}
                    showCreateEdit={showCreateEdit}
                    activeText={activeText}
                    inactiveText={inactiveText}
                  />
                  <div className="table-responsive table-container-inventory">
                    <table {...getTableProps()} className="table table-sm table-hover table-nowrap card-table">
                      <thead>
                        {headerGroups.map((headerGroup, index) => (
                          <tr {...headerGroup.getHeaderGroupProps()} key={`header-group-${index}`}>
                            {headerGroup.headers.map((column, index) => (
                              <th {...column.getHeaderProps()} key={`inventory-column-header-${index}`} className="sticky-header aligned-header">
                                <div style={{ textAlign: 'left' }}>
                                  <div className="noclass1">
                                    <span {...column.getSortByToggleProps()} style={{ color: '#6D6D6D', textTransform: 'initial', fontSize: 16 }}>
                                      {capitalizeHeader(column.render('Header'))}
                                      {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : null}
                                    </span>
                                  </div>
                                  <div className="filter-area">{column.canFilter ? column.render('Filter') : null}</div>
                                </div>
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()} className="list font-size-base">
                        {renderTableRows(rows)}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </FcContext.Consumer>
  );
};

DeliveryTable.propTypes = {
  isModalOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  kind: PropTypes.string.isRequired,
  showDatePicker: PropTypes.bool.isRequired,
  showFcPicker: PropTypes.bool.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  isRefreshFetching: PropTypes.bool.isRequired,
  lastRefreshed: PropTypes.string.isRequired,
  downloadGeoJsonMap: PropTypes.func,
  showCreateEdit: PropTypes.func,
};

DeliveryTable.defaultProps = {
  isModalOpen: false,
  downloadGeoJsonMap: () => {},
  showCreateEdit: () => {},
};

export default DeliveryTable;
