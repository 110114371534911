import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { path } from 'ramda';
import PropTypes from 'prop-types';
import { Person } from 'react-bootstrap-icons';
import { camelCaseToTitleize } from '../../utilities/Strings';
import { updateOrder } from '../../services/OrderManagementService';

const Customer = ({ order, suborder, customer, setOrder, edit, newOrder, setCustomerFields, canUpdate }) => {
  const [editing, setEditing] = useState(edit);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [firstName, setFirstName] = useState(customer.firstName);
  const [lastName, setLastName] = useState(customer.lastName);
  const [phone, setPhone] = useState(customer.phone);
  const [email, setEmail] = useState(customer.email);
  const [zipCode, setZipCode] = useState(customer.zipCode);
  const [parentCustomerName, setParentCustomerName] = useState(customer.parentCustomerName);
  const [customerGroups, setCustomerGroups] = useState(customer.customerGroups);

  const fields = ['firstName', 'lastName', 'email', 'phone', 'zipCode', 'parentCustomerName', 'customerGroups'];

  const onChange = (e) => {
    const val = e.target.value;
    switch (e.target.name) {
      case 'firstName':
        setFirstName(val);
        break;
      case 'lastName':
        setLastName(val);
        break;
      case 'phone':
        setPhone(val);
        break;
      case 'email':
        setEmail(val);
        break;
      case 'zipCode':
        setZipCode(val);
        break;
      case 'parentCustomerName':
        setParentCustomerName(val);
        break;
      case 'customerGroups':
        setCustomerGroups(val);
        break;
      default:
        break;
    }
  };

  const onClickEdit = (e) => setEditing(!editing);
  const cancelEdit = (e) => {
    setFirstName(customer.firstName);
    setLastName(customer.lastName);
    setPhone(customer.phone);
    setEmail(customer.email);
    setZipCode(customer.zipCode);
    setParentCustomerName(customer.parentCustomerName);
    setCustomerGroups(customer.customerGroups);

    setEditing(false);
    setSuccessMessage('');
    setErrorMessage('');
  };
  const renderValue = (field) => {
    const p = field.split('.').pop();
    let val = '';
    switch (p) {
      case 'firstName':
        val = firstName;
        break;
      case 'lastName':
        val = lastName;
        break;
      case 'phone':
        val = phone;
        break;
      case 'email':
        val = email;
        break;
      case 'zipCode':
        val = zipCode;
        break;
      case 'parentCustomerName':
        val = parentCustomerName;
        break;
      case 'customerGroups':
        val = customerGroups;
        break;
      default:
        break;
    }
    if (editing) {
      return <input value={val || ''} name={p} onChange={onChange} className="form-control" />;
    }
    return path(field.split('.'), customer);
  };

  const saveEdit = async (e) => {
    if (newOrder) {
      if (!(firstName && lastName && phone && email)) {
        setErrorMessage('Fix empty fields');
      } else {
        setCustomerFields({
          firstName: firstName,
          lastName: lastName,
          email: email,
          phone: phone,
          zipCode: zipCode,
        });
      }
    } else {
      const { success, order: savedOrder } = await updateOrder(order.orderId, {
        firstName,
        lastName,
        parentCustomerName,
        phone,
        email,
        zipCode,
        customerGroups,
      });

      if (success) {
        setSuccessMessage('Successfully saved');
        setEditing(false);
        const suborders = order.suborders.map((sb) => {
          if (sb.suborderId === suborder.suborderId) {
            return { ...suborder, recipient: { ...savedOrder.recipient } };
          }
          return sb;
        });
        setOrder({ ...order, suborders });
      } else {
        setErrorMessage('Error saving. Please wait and try again.');
      }
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title">
          <Person size="1.5em" className="mr-2" /> <span>Sender</span>
        </h4>
        <div>
          {errorMessage && <span className="text-danger h5">{errorMessage}</span>}
          {successMessage && <span className="text-success h5">{successMessage}</span>}
          {/*!editing && canUpdate &&
            <button type="button" className="btn btn-link" onClick={onClickEdit}>
              Edit
            </button>
            */}
          {editing && (
            <div className="d-inline-flex">
              <button type="button" className="btn btn-primary mx-1" onClick={saveEdit}>
                Save
              </button>
              {!newOrder && (
                <button type="button" className="btn btn-secondary" onClick={cancelEdit}>
                  Cancel
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="card-body">
        {editing &&
          fields.map((field, i) => (
            <div className="mb-2" key={i}>
              <label className="form-label text-muted">{camelCaseToTitleize(field.split('.').pop())}</label>
              {renderValue(field)}
            </div>
          ))}
        {!editing && (
          <>
            <h4 className="mb-1">
              {firstName} {lastName}
            </h4>
            <div className="mb-1">
              {parentCustomerName && <div>{parentCustomerName}</div>}
              <div>{email}</div>
              <div>{phone}</div>
              {zipCode && <div>{zipCode}</div>}
              {customerGroups && (
                <div>
                  {customerGroups.map((e) => (
                    <div key={e} className="badge bg-dark text-white ml-3">
                      {e}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <Link to={{ pathname: `/customers/${customer.email}` }}>Customer Profile</Link>
          </>
        )}
      </div>
    </div>
  );
};

Customer.propTypes = {
  order: PropTypes.object,
  suborder: PropTypes.object,
  customer: PropTypes.object.isRequired,
  setOrder: PropTypes.func,
  edit: PropTypes.bool.isRequired,
  newOrder: PropTypes.bool.isRequired,
  setCustomerFields: PropTypes.func,
  canUpdate: PropTypes.bool.isRequired,
};

export default Customer;
