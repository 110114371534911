import React from 'react';
import { DuplicateIcon, IconButton, toaster } from 'evergreen-ui';
import PropTypes from 'prop-types';

const AuditLogEntryDetailsExpanded = ({ index, expand, setExpand, data }) => {
  const auditLogDetailString = JSON.stringify(data, null, 2);
  return (
    <tr>
      {index === expand && (
        <td colSpan="4" className="border-top-0">
          <IconButton
            icon={DuplicateIcon}
            onClick={() => {
              navigator.clipboard.writeText(auditLogDetailString);
              toaster.notify('Copied to clipboard.', { duration: 2 });
            }}
            style={{ float: 'right' }}
          />
          <pre style={{ whiteSpace: 'pre-wrap' }}>{auditLogDetailString}</pre>
          <a href="#!" onClick={() => setExpand(false)} className="btn btn-link" style={{ float: 'right', fontSize: '13px' }}>
            Hide full details
          </a>
        </td>
      )}
    </tr>
  );
};

AuditLogEntryDetailsExpanded.propTypes = {
  index: PropTypes.number.isRequired,
  expand: PropTypes.string.isRequired,
  setExpand: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default AuditLogEntryDetailsExpanded;
