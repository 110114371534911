import { DateTime } from 'luxon';
import { getInventory } from '../../../services/AggregateService';
import { getProductSkusArrayForInventoryLookup } from './getProductSkusArrayForInventoryLookup';
import { roleChecks } from '../../../utilities/Role';
import { getOpsNameText } from './getOpsProductName';
import { createProductOption } from './createProductOption';
import { addNonHandledProductComponentsToProductOptions } from './addNonHandledProductComponentsToProductOptions';

const getInventoryForUseEffect = ({ delivery, deliveryDate, deliveryAreas, productFeed, userGroups, setProductOptions }) => {
  const getInventoryBySkus = async () => {
    const fcId = delivery && delivery.fc && delivery.fc.fcId ? delivery.fc.fcId : null;

    if (fcId) {
      const deliveryStartTimeString = delivery.startTime.split('T')[1];
      const fulfillmentDateString = DateTime.fromISO(`${deliveryDate}T${deliveryStartTimeString}`)
        .minus({ minutes: deliveryAreas[delivery.areaId]?.travelTime })
        .toISODate();
      const result = await getInventory(
        [fcId],
        getProductSkusArrayForInventoryLookup({
          productFeed,
          canViewFulfillableProductsFromProductList: roleChecks.canViewFulfillableProductsFromProductList(userGroups),
        }),
        fulfillmentDateString,
      ); // returns {sku: {fc: {date: stock}}}

      if (result.success) {
        const skuInventory = {};

        const { stock } = result;

        for (const sku in stock) {
          if (stock[sku][fcId]) {
            skuInventory[sku] = stock[sku][fcId][fulfillmentDateString];
          }
        }

        return skuInventory;
      }
    }
    return null;
  };

  const buildProductOptions = async () => {
    const productOptions = [];

    const suborderStartTime = DateTime.fromISO(delivery.startTime);

    const inventoryBySkus = await getInventoryBySkus();

    const handledSkus = new Set();

    const bypassChecks = roleChecks.canViewFulfillableProductsFromProductList(userGroups);

    for (const product of Object.values(productFeed)) {
      const productFromTime = DateTime.fromISO(product.availableForDeliveryFrom);
      const productToTime = DateTime.fromISO(product.availableForDeliveryTo);

      if (bypassChecks || (suborderStartTime.diff(productFromTime).toMillis() >= 0 && productToTime.diff(suborderStartTime).toMillis() > 0)) {
        const opsName = getOpsNameText(productFeed, product);

        const option = createProductOption({ ...product, price: 0 }, inventoryBySkus, userGroups, opsName);
        if (!option) {
          // eslint-disable-next-line no-continue
          continue;
        }

        productOptions.push(option);
      }

      handledSkus.add(product.sku);
    }

    addNonHandledProductComponentsToProductOptions(productFeed, handledSkus, inventoryBySkus, userGroups, productOptions);

    setProductOptions(productOptions);
  };

  buildProductOptions();
};

export default getInventoryForUseEffect;
